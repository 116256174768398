import { Box } from '@mui/material'
import loadingGIF from '../../assets/Overview/gambit-loading.gif'

const MainLoading = () => {
	return (
		<Box sx={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100vw' }}>
			<img src={loadingGIF} />
		</Box>
	)
}

export default MainLoading
