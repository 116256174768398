import { FC, useEffect, useState, ChangeEvent } from 'react'
import { useCollection } from 'react-firebase-hooks/firestore'
import { getServicesQuery } from '../../utils/queries'
import { Service } from '../../models/Service'
import { Box, Button, Container, Grid, TextField, Typography } from '@mui/material'
import { useLocation, useNavigate, useParams } from 'react-router'
import { ROUTES } from '../../constants/routes'
import { addDoc, collection } from 'firebase/firestore'
import { db } from '../../firebase'
import { COLLECTION_NAMES } from '../../constants/collections'
import ServicesBanner from '../../assets/Services/services-banner.jpg'
import { EmailOutlined } from '@mui/icons-material'
import parser from 'html-react-parser'

const Services: FC = () => {
	const [services, setServices] = useState<Service[]>([])
	const [selectedService, setSelectedService] = useState<Service>()
	const [feedback, setFeedback] = useState({
		fName: '',
		lName: '',
		email: '',
		phNo: '',
		message: ''
	})
	const location = useLocation()
	const isFeedbackPage = location.pathname === ROUTES.SERVICES_FEEDBACK
	const { slug } = useParams<{ slug: string }>()
	const navigator = useNavigate()
	const q = getServicesQuery()
	const [snapshot, loading, error] = useCollection(q, {
		snapshotListenOptions: { includeMetadataChanges: true }
	})

	const feedbackChangeHandler = ({ target: { name, value } }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setFeedback((p) => ({ ...p, [name]: value }))
	}
	const serviceSelectionHandler = (service: Service) => {
		setSelectedService(service)
		navigator(`${ROUTES.SERVICES}/${service.slug}`)
	}

	const feedbackSubmitHandler = () => {
		addDoc(collection(db, COLLECTION_NAMES.CONTACTS), feedback).then((docRef) => {
			navigator(ROUTES.SERVICES)
		})
	}

	useEffect(() => {
		if (location.pathname === ROUTES.SERVICES) {
			setSelectedService(undefined)
		}
	}, [location.pathname])

	useEffect(() => {
		if (!loading && error == null) {
			const servicesRes = snapshot?.docs.map((doc) => ({
				...doc.data(),
				id: doc.id
			})) as Service[]
			if (slug && !isFeedbackPage) {
				const selectedService = servicesRes.find((s) => s.slug === slug)
				if (selectedService) {
					setSelectedService(selectedService)
				} else {
					navigator(ROUTES.SERVICES)
				}
			}
			setServices([...servicesRes])
		}
	}, [loading, snapshot])

	return (
		<Container className="p-0" maxWidth="xl">
			<Box sx={{ minHeight: '100vh' }}>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						width: '100%',
						height: '600px',
						maxHeight: { xs: '300px', sm: '400px', md: '450px', lg: '500px', xl: '600px' },
						backgroundRepeat: 'no-repeat',
						backgroundSize: 'cover',
						backgroundImage: `url(${ServicesBanner})`,
						padding: '20px',
						backgroundPosition: 'center'
					}}
				>
					<Typography
						variant="h1"
						sx={{
							background: '#ffffff29',
							padding: '10px 20px',
							border: '3px solid #ffffff61',
							textAlign: 'center',
							color: 'white',
							fontSize: { xs: '30px', md: '50px', lg: '70px' },
							fontWeight: 'bold'
						}}
					>
						{selectedService && !isFeedbackPage ? selectedService.title : 'SERVICES'}
					</Typography>
				</Box>
				<Container maxWidth="xl" className="py-5">
					<Grid container spacing={2}>
						<Grid order={{ xs: 2, md: 1 }} item xs={12} md={8}>
							{isFeedbackPage ? (
								<Box sx={{ display: 'flex', flexGrow: 1, border: '1px solid grey', borderRadius: '5px', padding: '20px', flexDirection: 'column', gap: '20px' }}>
									<Typography textAlign="center" fontWeight="bold" variant="h5">
										Contact Us
									</Typography>
									<TextField label="First Name" value={feedback.fName} name="fName" onChange={feedbackChangeHandler} />
									<TextField label="Last Name" value={feedback.lName} name="lName" onChange={feedbackChangeHandler} />
									<TextField label="Email" value={feedback.email} name="email" onChange={feedbackChangeHandler} />
									<TextField label="Phone Number" value={feedback.phNo} name="phNo" onChange={feedbackChangeHandler} />
									<TextField label="Your Message" multiline rows={5} value={feedback.message} name="message" onChange={feedbackChangeHandler} />
									<Button variant="outlined" type="button" onClick={feedbackSubmitHandler}>
										Submit Message
									</Button>
								</Box>
							) : (
								<>
									{selectedService ? (
										<>
											<Box flexDirection={{ xs: 'column', md: 'row' }} id="selected-service" sx={{ alignSelf: 'start', display: 'flex', gap: '20px' }}>
												<img style={{ maxWidth: '300px', height: '100%' }} src={selectedService.preview_url} />
												<Box>
													<Typography margin="10px 0" fontWeight="bold" variant="h6">
														{parser(selectedService.title)}
													</Typography>
													{selectedService.description ? <Typography>{parser(selectedService.description)}</Typography> : null}
												</Box>
											</Box>
											{selectedService.features ? <Box>{parser(selectedService.features)}</Box> : null}
										</>
									) : (
										<Box id="all-services" sx={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>
											{services.map((service) => (
												<Box
													key={service.slug}
													className="p-5"
													sx={{ maxWidth: '300px', backgroundColor: '#DFDFDF', color: 'white', textAlign: 'center', cursor: 'pointer' }}
													onClick={() => serviceSelectionHandler(service)}
												>
													<Typography sx={{ fontSize: '20px', marginBottom: '10px', color: 'black' }} variant="h5">
														{service.title}
													</Typography>
													<Typography sx={{ fontSize: '15px', color: 'black' }}>{service.short_description}</Typography>
												</Box>
											))}
										</Box>
									)}
								</>
							)}
						</Grid>
						<Grid order={{ xs: 1, md: 2 }} item xs={12} md={4}>
							<Box
								flexDirection={{ xs: 'row', md: 'column' }}
								width={{ xs: '100%', md: 'unset' }}
								order={{ xs: 1, md: 2 }}
								flexGrow={{ xs: 1, md: 0 }}
								sx={{ alignSelf: 'start', display: 'flex', gap: '15px' }}
							>
								<Box
									className="p-5"
									textAlign="center"
									sx={{
										boxShadow: '0px 0px 1px black',
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
										gap: '10px',
										border: '1px solid grey',
										borderRadius: '5px',
										flexGrow: 1,
										h6: {
											display: 'flex',
											gap: '5px',
											alignItems: 'center'
										}
									}}
								>
									<Typography variant="h4" sx={{ whiteSpace: 'nowrap', fontSize: '20px', fontWeight: 'bold' }}>
										Questions ?
									</Typography>
									<Typography variant="h6" fontSize="15px" sx={{ justifyContent: 'center', cursor: 'pointer' }} onClick={() => navigator(ROUTES.SERVICES_FEEDBACK)}>
										<EmailOutlined /> Send a message
									</Typography>
								</Box>
								<Box
									sx={{
										display: 'flex',
										gap: '10px',
										flexGrow: 1,
										border: '1px solid grey',
										borderRadius: '5px',
										flexDirection: 'column',
										textAlign: 'center',
										padding: '10px',
										boxShadow: '0px 0px 1px black'
									}}
								>
									<Typography variant="h5">All Services</Typography>
									{services.slice(0, 5).map((service) => (
										<Typography
											onClick={() => serviceSelectionHandler(service)}
											key={service.slug}
											sx={{ cursor: 'pointer', padding: '5px 10px', color: '#0062FF', backgroundColor: 'white', border: '1px solid #0062FF', borderRadius: '5px' }}
										>
											{service.title}
										</Typography>
									))}
								</Box>
							</Box>
						</Grid>
					</Grid>
				</Container>
			</Box>
		</Container>
	)
}

export default Services
