export const emailTemplate: string = `
    <p style="white-space: pre;">{message}\nClick the following link to open the job.
    </p>
    <a href="{link}">Click here</a>
`

export const referEmailTemplate: string = `
    <p style="white-space: pre;">{message}\nClick the following link to open the job.
    </p>
    <a href="{link}">Click here</a>
`
