import ArrowLeft from '../../../assets/JobDetail/ArrowLeft.png'
import { Box, Button, Typography } from '@mui/material'
import { FC, SyntheticEvent, useContext, useEffect, useState } from 'react'
import useAddBlog from '../../../hooks/useAddBlog'
import BlogFields from '../../../components/BlogFields.tsx'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { BlogTempate, FormObj } from '../../../models/Blog'
import { EventInfo } from '@ckeditor/ckeditor5-utils'
import { useNavigate, useParams } from 'react-router'
import { getBlogCategories, getTemplateByName } from '../../../utils/queries'
import { useCollection } from 'react-firebase-hooks/firestore'
import { ROUTES } from '../../../constants/routes'
import BlogStaticField from '../../../components/BlogBuilderComponents/BlogStaticFields'
import { LoaderContext } from '../../../contexts/loader-context'
const AddBlog: FC<{}> = () => {
	const {
		blogForm,
		getConfigByBlogTemplates,
		updateFromValues,
		handleAddClicked,
		handleRemoveClicked,
		saveBlog,
		categoryName,
		setCategoryName,
		previewFile,
		setPreviewFile,
		backgroundImageFile,
		setBackgroundImageFile,
		handleAiButtonClicked,
		tags,
		setTags
	} = useAddBlog()
	const { setShowLoading } = useContext(LoaderContext)
	const [template, setTemplate] = useState<BlogTempate>()
	const navigate = useNavigate()
	useEffect(() => {
		if (template) getConfigByBlogTemplates(template)
	}, [template])

	const { slug } = useParams()
	const q = getTemplateByName(slug ?? '')
	const [jobSnap, jobLoading, error] = useCollection(q, {
		snapshotListenOptions: { includeMetadataChanges: true }
	})
	const qCategories = getBlogCategories()
	const [jobSnapCategory, jobCategoryLoading, errorCategory] = useCollection(qCategories, {
		snapshotListenOptions: { includeMetadataChanges: true }
	})
	const [categoryNames, setCategoryNames] = useState<Array<{ name: string; slug: string }>>([])
	useEffect(() => {
		if (!jobLoading) {
			const doc = jobSnap?.docs[0]
			setTemplate(doc?.data() as BlogTempate)
		}
	}, [jobLoading])

	useEffect(() => {
		if (!jobCategoryLoading) {
			const docs = jobSnapCategory?.docs?.map((doc) => {
				return doc?.data()
			})
			setCategoryNames(docs as Array<{ name: string; slug: string }>)
			setCategoryName((docs as Array<{ name: string; slug: string }>)[0]?.slug ?? '')
		}
	}, [jobCategoryLoading])

	useEffect(() => {
		setShowLoading(true)
		if ((!jobCategoryLoading || errorCategory) && (!jobLoading || error)) {
			setTimeout(() => setShowLoading(false), 1000)
		}
	}, [jobCategoryLoading, errorCategory, jobLoading, error])
	const handleFileChange = (file: File, fileObj: FormObj | null, i: number) => {
		updateFromValues(i, fileObj as FormObj, file, 'file')
	}

	const handleTextChange = (e: SyntheticEvent<HTMLInputElement, Event>, fileObj: FormObj | null, i: number) => updateFromValues(i, fileObj as FormObj, e.currentTarget.value, 'text')

	const handleRtcChange = (event: EventInfo, editor: ClassicEditor, fileObj: FormObj | null, i: number) => updateFromValues(i, fileObj as FormObj, editor.data?.get(), 'rtcData')

	return (
		<Box
			sx={{
				minHeight: '100vh',
				backgroundColor: { sm: '#DFDFDF', xs: '#ffffff' },
				width: '100%'
			}}
		>
			<Box>
				<Box sx={{ display: 'flex' }}>
					<Box
						sx={{
							paddingX: { lg: '40px', sm: '20px', xs: '12px' },
							flex: 'auto'
						}}
					>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								padding: '30px 0 20px 0',
								justifyContent: 'space-between'
							}}
						>
							<Box sx={{ display: 'flex', alignItems: 'center' }}>
								<Box
									onClick={() => navigate(ROUTES.BLOG_BUILDER)}
									sx={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										backgroundColor: '#171725',
										boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.02);',
										borderRadius: '50%',
										cursor: 'pointer',
										width: { lg: '46px', sm: '35px', xs: '30px' },
										height: { lg: '46px', sm: '35px', xs: '30px' }
									}}
								>
									<Box component="img" src={ArrowLeft} />
								</Box>
								<Box
									sx={{
										display: 'flex',
										alignItems: 'center',
										gap: '10px',
										cursor: 'pointer'
									}}
									// onClick={() => setEditName(true)}
								>
									<Typography
										variant="h2"
										sx={{
											fontWeight: '600',
											fontSize: { lg: '24px', md: '20px', xs: '17px' },
											lineHeight: '36px',
											letterSpacing: '0.1px',
											marginLeft: { sm: '18px', xs: '12px' },
											color: '#171725'
										}}
									>
										Add Blog
									</Typography>
								</Box>
							</Box>
							<Button
								sx={{
									textTransform: 'none',
									borderRadius: '12px',
									marginLeft: '5px',
									padding: { md: '10px 24px;', sm: '10px 16px;', sx: '8px 16px' },
									fontSize: { md: '18px', xs: '16px' },
									fontWeight: '600',
									lineHeight: { sm: '27px', xs: '24px' },
									letterSpacing: '2%',
									width: { xs: '50%', sm: 'unset' }
								}}
								variant="contained"
								color="primary"
								onClick={async () => await saveBlog()}
							>
								Save Blog
							</Button>
						</Box>
					</Box>
				</Box>
				<Box
					sx={{
						borderRadius: '20px',
						width: '94%',
						display: 'flex',
						flexDirection: 'column',
						padding: '30px 30px 30px 30px',
						background: '#fff',
						margin: 'auto',
						marginBottom: '20px'
					}}
				>
					<BlogStaticField
						previewFile={previewFile}
						setPreviewFile={setPreviewFile}
						backgroundImageFile={backgroundImageFile}
						setBackgroundImageFile={setBackgroundImageFile}
						categoryName={categoryName}
						setCategoryName={setCategoryName}
						categoryNames={categoryNames}
						tags={tags}
						setTags={setTags}
					/>
					{blogForm?.map((formData: FormObj | null, i) => {
						return (
							<div key={`key_${i}`}>
								{!formData?.isMultiple ? (
									<BlogFields
										key={`key_${i}`}
										isFile={formData?.isFile}
										isText={formData?.isText}
										isRitchTextField={formData?.isRtc}
										fileUploadName={formData?.fileText}
										textFieldName={formData?.descText}
										rtcName={formData?.rtcText}
										file={formData?.file as File}
										onFileChange={(file) => handleFileChange(file, formData, i)}
										text={formData?.text}
										onTextChange={(e) => handleTextChange(e, formData, i)}
										rtcData={formData?.rtcData}
										onRtcDataChange={(event, editor) => handleRtcChange(event, editor, formData, i)}
										showAiButton={formData?.isAiButton}
										onAiButtonClicked={async (e) => await handleAiButtonClicked(e, i, formData?.text ?? '')}
										targetTemplate={formData?.targetTemplate}
									/>
								) : (
									<>
										<div className="dynamic-points" key={i}>
											<Typography
												component="p"
												sx={{
													background: '#0062ff',
													color: '#fff',
													padding: '10px',
													margin: '10px 0px',
													fontWeight: { sm: '600', xs: '700' },
													fontSize: { sm: '25px', xs: '18px' },
													lineHeight: { sm: '27px', xs: '18px' },
													marginBottom: { sm: '16px', xs: '10px' }
												}}
											>
												{formData.dynamicPointsTitle}
											</Typography>
											<hr />
											{formData?.values?.map((formDataMulti, i) => (
												<div key={`key_inner_${i}`}>
													<BlogFields
														isFile={formDataMulti?.isFile}
														isText={formDataMulti?.isText}
														isRitchTextField={formDataMulti?.isRtc}
														fileUploadName={formDataMulti?.fileText}
														textFieldName={formDataMulti?.descText}
														rtcName={formDataMulti?.rtcText}
														onFileChange={(file) => handleFileChange(file, formData, i)}
														text={formDataMulti?.text}
														onTextChange={(e) => handleTextChange(e, formData, i)}
														rtcData={formDataMulti?.rtcData}
														onRtcDataChange={(event, editor) => handleRtcChange(event, editor, formData, i)}
														file={formDataMulti?.file as File}
														showAiButton={formDataMulti?.isAiButton}
														onAiButtonClicked={async (e) => await handleAiButtonClicked(e, i, formDataMulti?.text ?? '')}
														targetTemplate={formDataMulti?.targetTemplate}
													/>
													<Button
														sx={{
															marginTop: '10px',
															marginBottom: '10px',
															textTransform: 'none',
															borderRadius: '12px',
															marginLeft: '5px',
															padding: { md: '10px 24px;', sm: '10px 16px;', sx: '8px 16px' },
															fontSize: { md: '18px', xs: '16px' },
															fontWeight: '600',
															lineHeight: { sm: '27px', xs: '24px' },
															letterSpacing: '2%',
															width: { xs: '50%', sm: 'unset' }
														}}
														variant="contained"
														color="primary"
														onClick={() => handleAddClicked(formData, i)}
													>
														Add
													</Button>
													{i !== 0 ? (
														<Button
															sx={{
																marginTop: '10px',
																marginBottom: '10px',
																textTransform: 'none',
																borderRadius: '12px',
																marginLeft: '5px',
																padding: { md: '10px 24px;', sm: '10px 16px;', sx: '8px 16px' },
																fontSize: { md: '18px', xs: '16px' },
																fontWeight: '600',
																lineHeight: { sm: '27px', xs: '24px' },
																letterSpacing: '2%',
																width: { xs: '50%', sm: 'unset' }
															}}
															variant="contained"
															color="error"
															onClick={() => handleRemoveClicked(formData, i)}
														>
															Remove
														</Button>
													) : null}
												</div>
											))}
										</div>
									</>
								)}
							</div>
						)
					})}
				</Box>
			</Box>
		</Box>
	)
}

export default AddBlog
