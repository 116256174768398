import { TextField } from '@mui/material'
import { FC } from 'react'
import ReadMoreText from '../ReadMoreText'

const MobileJobSection2Description: FC<{
	editMode: boolean
	description: string
	setDescription: (index: number, value: string) => void
	jobLoading: boolean
	index: number
}> = ({ editMode, description, setDescription, jobLoading, index }) => {
	return (
		<>
			{editMode ? (
				<TextField
					variant="outlined"
					value={description}
					multiline
					rows={7}
					style={{
						width: '100%'
					}}
					onChange={(e) => setDescription(index, e.target.value)}
					sx={{
						input: {
							color: '#44444F',
							fontWeight: '700',
							fontSize: '14px',
							lineHeight: '18px',
							letterSpacing: '0.1px',
							width: '100%'
						}
					}}
				/>
			) : (
				<ReadMoreText
					text={!jobLoading && description !== '' ? description : ''}
					maxLetterLength={70}
					styles={{
						color: '#7F879E',
						fontWeight: '400',
						fontSize: '14px',
						lineHeight: '22px'
					}}
				/>
			)}
		</>
	)
}

export default MobileJobSection2Description
