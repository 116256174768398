import { useContext, useState } from 'react'
import { BlogSections, BlogTempate, FormObj } from '../models/Blog'
import BlogFormFactory from '../utils/blogFormFactory'
import { Timestamp, addDoc, collection, doc, serverTimestamp, setDoc } from 'firebase/firestore'
import { textToSlug } from '../utils/functions'
import { db, storage } from '../firebase'
import { useNavigate } from 'react-router'
import { ROUTES } from '../constants/routes'
import { UploadResult, getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { generateBlogFileName, makeid } from '../utils'
import { LoaderContext } from '../contexts/loader-context'
import { getGptContent } from '../utils/httpTrigger'

const useAddBlog = () => {
	const [blogForm, setBlogForm] = useState<Array<FormObj | null>>([])
	const navigate = useNavigate()
	const [categoryName, setCategoryName] = useState<string>('')
	const [previewFile, setPreviewFile] = useState<File | null>(null)
	const [backgroundImageFile, setBackgroundImageFile] = useState<File | null>(null)
	const { setShowLoading } = useContext(LoaderContext)
	const [tags, setTags] = useState<string[]>([])

	const handleAiButtonClicked = async (blogSection: BlogSections, index: number, title: string) => {
		try {
			setShowLoading(true)
			await new Promise((resolve) => setTimeout(() => resolve(true), 1000))
			const result = await getGptContent(title)
			const content = (await result.json()).result ?? ''

			const newBlogForm = blogForm?.map((blogForm) => {
				if (blogForm?.templateName === blogSection && !blogForm?.isMultiple) return { ...blogForm, rtcData: content }
				else if (blogForm?.templateName === blogSection && blogForm?.isMultiple) {
					const multivalueSections = blogForm?.values?.map((blogFormValue, innerIndex) => {
						if (innerIndex === index) return { ...blogFormValue, rtcData: content }
						return blogFormValue
					})
					return { ...blogForm, values: multivalueSections }
				}
				return blogForm
			})
			setBlogForm(newBlogForm)
			setShowLoading(false)
		} catch (e) {}
	}
	const getConfigByBlogTemplates = (template: BlogTempate) => {
		if (template?.sections?.length) {
			const blogForm = template?.sections?.map((sectionName) => BlogFormFactory.createBlogObject(sectionName))
			setBlogForm(blogForm)
		}
	}

	const updateFromValues = (i: number, fileObj: FormObj, value: string | File, key: string) => {
		setBlogForm((formData: any) => {
			return formData?.map((data: FormObj, index: number) => {
				if (data === fileObj && fileObj?.isMultiple) {
					const newValues = data?.values?.map((valueObj: FormObj, index: number) => {
						if (index === i) {
							return {
								...valueObj,
								[key]: value
							}
						}
						return valueObj
					})
					return {
						...data,
						values: newValues
					}
				} else if (index === i && data === fileObj) {
					return {
						...data,
						[key]: value
					}
				}
				return data
			})
		})
	}

	const handleAddClicked = (fileObj: FormObj | null, i: number) => {
		setBlogForm((formData: any) => {
			return formData?.map((data: FormObj, index: number) => {
				if (data === fileObj && fileObj?.isMultiple) data.values = [...(data.values ?? []), BlogFormFactory.getDynamicObject(fileObj)] as FormObj[] | undefined
				return data
			})
		})
	}

	const handleRemoveClicked = (fileObj: FormObj | null, i: number) => {
		setBlogForm((formData: any) => {
			return formData?.map((data: FormObj, index: number) => {
				if (data === fileObj && fileObj?.isMultiple) {
					const valueCopy = [...(fileObj?.values ?? [])]
					data.values = valueCopy?.splice(i, 1) as FormObj[] | undefined
				}
				return data
			})
		})
	}

	const handleBlogConcatenation = (blogData: FormObj, blog: string[], index: number, cbk: (template: BlogSections) => void, tocList: string[]) => {
		if (blogData?.templateName === BlogSections.titleBannerImage) {
			blog.push(`<div class="MuiBox-root css-p3xfdl">
			<img class='blog-image' src="${blogData?.fileUrl}" alt="blog image"/>
			<h1 class="MuiTypography-root MuiTypography-h1 css-1gucwa5-MuiTypography-root">${blogData?.text ?? ''}</h1><!--file${index}--></div>`)
		}
		if (blogData?.templateName === BlogSections.toc) blog.push('<!--' + BlogSections.toc + '-->')

		if (blogData?.templateName === BlogSections.advertisement) blog.push('<!--' + BlogSections.advertisement + '-->')

		if (blogData?.isFile && blogData?.fileUrl) blog.push(`<img class='blog-image' src="${blogData?.fileUrl}" alt="blog image"/>`)

		if (blogData?.templateName === BlogSections.summery) blog.push(blogData?.rtcData ? `<h2>Summary</h2>` : '')

		if (blogData?.templateName !== BlogSections.title && blogData?.templateName !== BlogSections.toc && blogData?.templateName !== BlogSections.advertisement) {
			if (blogData?.isText) {
				blog.push(blogData?.text ? `<h2>${blogData?.text}</h2>` : '')
			}

			if (blogData?.isRtc) {
				blog.push('<div style="position:relative">')
				if (blogData?.templateName === BlogSections.dynamicImagePoints || blogData?.templateName === BlogSections.dynamicPoints)
					blog.push(`<a style="position:absolute;top:-130px;" name="${blogData?.text ? blogData?.text : blogData?.descText}"></a>`)

				blog.push(blogData?.rtcData ?? '')
				tocList.push((blogData?.text ? blogData?.text : blogData?.descText) as string)
				blog.push('</div>')
			}
		}

		cbk(blogData?.templateName as BlogSections)
	}

	const uploadFile = async (formField: FormObj) => {
		return await new Promise((resolve, reject) => {
			const destinationName = generateBlogFileName(formField?.file as File, makeid(7))
			const resumeRef = ref(storage, destinationName)
			uploadBytes(resumeRef, formField?.file as File)
				.then(async (response) => {
					const downloadableUrl = await getDownloadURL(resumeRef)
					formField.fileUrl = downloadableUrl
					resolve({ response, formField })
				})
				.catch(() => reject(new Error('Unable to upload file')))
		})
	}
	const uploadFileGetUrl = async (formField: File) => {
		return await new Promise((resolve, reject) => {
			const destinationName = generateBlogFileName(formField, makeid(7))
			const resumeRef = ref(storage, destinationName)
			uploadBytes(resumeRef, formField)
				.then(async (response) => {
					const downloadableUrl = await getDownloadURL(resumeRef)
					resolve(downloadableUrl)
				})
				.catch(() => reject(new Error('Unable to upload file')))
		})
	}
	const saveBlog = async () => {
		try {
			if (!previewFile) {
				alert('Preview image is required')
				return
			}

			if (!backgroundImageFile) {
				alert('backdround image is required')
				return
			}
			setShowLoading(true)
			await new Promise((resolve) => setTimeout(() => resolve(true), 1))
			const previewFileDowbloadUrl = await uploadFileGetUrl(previewFile)
			const backgroundImageDownloadUrl = await uploadFileGetUrl(backgroundImageFile)
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			let blogTitle: string | undefined | null = null
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			let isToc = false

			const fileUploads: Array<Promise<{ response: UploadResult; formField: FormObj }>> = []
			blogForm?.forEach((formField) => {
				if (formField?.templateName === BlogSections.titleBannerImage) {
					if (formField?.isFile && formField?.file) {
						fileUploads.push(uploadFile(formField) as Promise<{ response: UploadResult; formField: FormObj }>)
					}
				}
				if (formField?.templateName === BlogSections.dynamicImagePoints) {
					formField?.values?.forEach((formFieldValue) => {
						if (formFieldValue?.isFile && formFieldValue?.file) fileUploads.push(uploadFile(formFieldValue) as Promise<{ response: UploadResult; formField: FormObj }>)
					})
				}
			})
			await Promise.all(fileUploads)
			const tocList: string[] = []
			let blogText = blogForm?.reduce(
				(blog: string[], blogData, index) => {
					handleBlogConcatenation(
						blogData as FormObj,
						blog,
						index,
						(templateName) => {
							if (templateName === BlogSections.title || templateName === BlogSections.titleBannerImage) blogTitle = blogData?.text ?? ''
							if (templateName === BlogSections.toc) isToc = true
						},
						tocList
					)
					if (blogData?.isMultiple) {
						blog.push(
							`<div class="blog-post-details">${
								blogData?.values
									?.reduce((innerBlog: string[], blogData, innerIndex) => {
										handleBlogConcatenation(
											blogData,
											innerBlog,
											index,
											(templateName) => {
												if (templateName === BlogSections.title || templateName === BlogSections.titleBannerImage) blogTitle = blogData?.text ?? ''
												if (templateName === BlogSections.toc) isToc = true
											},
											tocList
										)
										return innerBlog
									}, [])
									.join('') as string
							}</div>`
						)
					}
					return blog
				},
				[`<div class='blog-post'>`]
			)

			if (!(blogTitle ?? '').trim()) {
				alert('Blog title is required.')
				setShowLoading(false)
				return
			}
			if (blogText.length <= 1) {
				alert('Blog details should not be empty.')
				setShowLoading(false)
				return
			}

			if (tocList.length > 0) {
				blogText = blogText.map((placeholder) => {
					if (placeholder === '<!--' + BlogSections.toc + '-->') {
						return `<div>
						<h2>Table of contents</h2>
						<ul>${tocList
							.map((name) => {
								if (!name) return ''
								return `<li><a href="#${name}">${name}</a></li>`
							})
							.join('')}</ul><div>`
					}
					return placeholder
				})
			}
			const details = `${blogText.join('')}</div>`
			const category = categoryName
			const createdAt = serverTimestamp() as Timestamp
			const order = 1
			const previewUrl = previewFileDowbloadUrl
			const slug = textToSlug(blogTitle ?? '')
			const title = blogTitle
			const backgroundTitleImage = backgroundImageDownloadUrl
			addDoc(collection(db, 'blogs'), {
				details,
				category,
				createdAt,
				order,
				preview_url: previewUrl,
				slug,
				title,
				background_title_image: backgroundTitleImage,
				tags
			})
				.then((docRef) => {
					setDoc(doc(db, 'sitemap', docRef.id), {
						changefreq: 'monthly',
						loc: window.location.origin + '/blog/' + (category ?? 'all') + '/' + slug,
						priority: 0.5
					})
						.then(() => {
							setShowLoading(false)
							navigate(ROUTES.BLOG_BUILDER)
						})
						.catch((error) => {
							setShowLoading(false)
							console.log({ error })
						})
				})
				.catch((error) => {
					setShowLoading(false)
					console.log({ error })
				})
		} catch (error) {
			console.log(error)
			setShowLoading(false)
		}
	}
	return {
		getConfigByBlogTemplates,
		blogForm,
		updateFromValues,
		handleAddClicked,
		handleRemoveClicked,
		saveBlog,
		categoryName,
		setCategoryName,
		previewFile,
		setPreviewFile,
		backgroundImageFile,
		setBackgroundImageFile,
		handleAiButtonClicked,
		tags,
		setTags
	}
}

export default useAddBlog
