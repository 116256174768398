import { getAdditionalUserInfo, signInAnonymously, updateProfile, UserCredential } from 'firebase/auth'
import { httpsCallable } from 'firebase/functions'
import { Dispatch, SetStateAction } from 'react'
import { auth, functions } from '../firebase'

export const setUserData = httpsCallable(functions, 'setUserData')

export const updateAnonymousUser = async (userCredential: UserCredential) => {
	const additionalInfo = getAdditionalUserInfo(userCredential)
	if (additionalInfo?.isNewUser) {
		const newUser = userCredential.user
		await updateProfile(newUser, {
			displayName: 'Guest'
		})
		await setUserData({
			referral: null,
			firstName: '',
			lastName: ''
		})
	}
}

export const signInAnonymous = (setHasAnonymousJobs: Dispatch<SetStateAction<boolean>>, setAnonymousJobs: Dispatch<SetStateAction<string[]>>, successCallback?: () => void): void => {
	signInAnonymously(auth)
		.then(async (userCredential) => {
			setHasAnonymousJobs(false)
			setAnonymousJobs([])
			successCallback?.()
			updateAnonymousUser(userCredential)
		})
		.catch((error) => {
			const errorCode = error.code
			const errorMessage: string = error.message
			console.log('Error Signing In Anonymously : ', errorCode, errorMessage)
			alert(`Error Signing In Anonymously : ${errorMessage}`)
		})
}
