import { Box, Typography, TextField } from '@mui/material'
import { FC, useEffect, useRef, useState } from 'react'
import CustomFileField from '../AddJobDialog/CustomFileField'
import { ResumeInputMode } from './types'
import ReadMoreText from '../ReadMoreText'
const JobFieldsSections1Fields2: FC<{
	editMode: boolean
	jobText: string
	setJobText: (index: number, value: string) => void
	file: File | null
	setFile: (index: number, value: File | null) => void
	description: string
	setDescription: (index: number, value: string) => void
	resumeMode: ResumeInputMode
	setResumeMode: (index: number, value: ResumeInputMode) => void
	selectedResume: string
	setSelectedResume: (index: number, value: string) => void
	resumeIndex: number
	newResume?: boolean
	handleDelete?: (index: number) => void
	hideResume?: boolean
	hideDescription?: boolean
	fileName?: string
}> = ({
	editMode,
	jobText,
	setJobText,
	file,
	setFile,
	description,
	setDescription,
	resumeMode,
	setResumeMode,
	selectedResume,
	setSelectedResume,
	resumeIndex,
	newResume,
	handleDelete,
	hideResume,
	hideDescription,
	fileName
}) => {
	const [descriptionText, setDescriptionText] = useState(description)
	const timeoutRef = useRef<null | number | NodeJS.Timeout>(null)
	useEffect(() => {
		if (description) {
			setDescriptionText(description)
		}
	}, [description])
	return (
		<Box
			sx={{
				marginTop: { md: '63px', sm: '45px', xs: '30px' },
				display: 'flex'
			}}
		>
			{!hideResume ? (
				<Box
					sx={{
						width: '100%',
						marginRight: '5%'
					}}
				>
					<Typography
						component="p"
						sx={{
							color: '#44444F',
							fontWeight: '700',
							fontSize: '14px',
							lineHeight: '18px',
							letterSpacing: '0.1px',
							marginBottom: { sm: '11px', xs: '5px' }
						}}
					>
						Resume
					</Typography>
					{editMode ? (
						<>
							<CustomFileField
								handleDelete={handleDelete}
								newResume={newResume}
								resumeIndex={resumeIndex}
								name=""
								jobText={jobText}
								setJobText={(value: string) => setJobText(resumeIndex, value)}
								file={file}
								setFile={(value: File | null) => setFile(resumeIndex, value)}
								resumeMode={resumeMode}
								setResumeMode={setResumeMode}
								setSelectedResume={setSelectedResume}
								selectedResume={selectedResume}
								resumeFileName={fileName}
							/>
						</>
					) : (
						<>
							<Typography
								component="pre"
								sx={{
									color: '#44444F',
									fontWeight: '400',
									fontSize: { lg: '14px', xs: '12px' },
									lineHeight: '24px',
									letterSpacing: '0.1px',
									overflowX: 'auto',
									whiteSpace: 'pre-wrap',
									wordWrap: 'break-word'
								}}
							>
								<ReadMoreText
									text={jobText !== '' ? jobText : ''}
									maxLetterLength={70}
									styles={{
										color: '#7F879E',
										fontWeight: '400',
										fontSize: '14px',
										lineHeight: '22px'
									}}
								/>
							</Typography>
						</>
					)}
				</Box>
			) : null}

			{/* Detail section */}

			{!hideDescription && (
				<Box
					sx={{
						width: '100%'
					}}
				>
					<>
						<Typography
							component="p"
							sx={{
								color: '#44444F',
								fontWeight: '700',
								fontSize: '14px',
								lineHeight: '18px',
								letterSpacing: '0.1px',
								marginBottom: { sm: '11px', xs: '5px' }
							}}
						>
							Description
						</Typography>

						{editMode ? (
							<Box
								sx={{
									marginTop: {
										md: '15px',
										sm: '12px',
										xs: '10px'
									},
									width: '100%'
								}}
							>
								<TextField
									id="outlined-basic"
									label={'Description'}
									variant="outlined"
									value={descriptionText}
									multiline
									rows={18}
									style={{
										width: '100%'
									}}
									onChange={(e) => {
										setDescriptionText(e.target.value)
										if (timeoutRef.current) {
											clearTimeout(timeoutRef.current)
										}
										timeoutRef.current = setTimeout(() => {
											timeoutRef.current = null
											setDescription(resumeIndex, e.target.value)
										}, 1000)
									}}
									sx={{
										input: {
											color: '#44444F',
											fontWeight: '700',
											fontSize: '14px',
											lineHeight: '18px',
											letterSpacing: '0.1px',
											width: '100%'
										}
									}}
								/>
							</Box>
						) : (
							<>
								<Typography
									component="pre"
									sx={{
										color: '#44444F',
										fontWeight: '400',
										fontSize: { lg: '14px', xs: '12px' },
										lineHeight: '24px',
										letterSpacing: '0.1px',
										overflowX: 'auto',
										whiteSpace: 'pre-wrap',
										wordWrap: 'break-word'
									}}
								>
									<ReadMoreText
										text={description !== '' ? description : ''}
										maxLetterLength={70}
										styles={{
											color: '#7F879E',
											fontWeight: '400',
											fontSize: '14px',
											lineHeight: '22px'
										}}
									/>
								</Typography>
							</>
						)}
					</>
				</Box>
			)}

			{/* description end */}
		</Box>
	)
}

export default JobFieldsSections1Fields2
