import { Box, MenuItem, Select, Typography } from '@mui/material'
import FileUpload from '../FileUpload'
import { Dispatch, FC, SetStateAction } from 'react'
import SkillsForm from '../Resume/Forms/SkillsForm'

const BlogStaticField: FC<{
	previewFile: File | null
	setPreviewFile: (file: File | null) => void
	backgroundImageFile: File | null
	setBackgroundImageFile: (file: File | null) => void
	categoryName: string
	setCategoryName: (s: string) => void
	categoryNames: Array<{ name: string; slug: string }>
	tags: string[]
	setTags: Dispatch<SetStateAction<string[]>>
}> = ({ previewFile, setPreviewFile, backgroundImageFile, setBackgroundImageFile, categoryName, setCategoryName, categoryNames, tags, setTags }) => {
	return (
		<>
			<Box
				sx={{
					marginBottom: { sm: '16px', xs: '10px' }
				}}
			>
				<Typography
					component="p"
					sx={{
						color: '#171725',
						fontWeight: { sm: '600', xs: '700' },
						fontSize: { sm: '18px', xs: '14px' },
						lineHeight: { sm: '27px', xs: '18px' },
						marginBottom: { sm: '16px', xs: '10px' }
					}}
				>
					Category
				</Typography>
				<Select
					sx={{
						width: '100%'
					}}
					label="Category"
					labelId={`Category`}
					value={categoryName}
					onChange={(e) => setCategoryName(e.target.value)}
				>
					{categoryNames.map((category) => (
						<MenuItem key={category.slug} value={category.slug}>
							{category.name}
						</MenuItem>
					))}
				</Select>
			</Box>
			<div style={{ marginBottom: 10 }}>
				<FileUpload
					width="72%"
					title={'Preview Image'}
					size={20971520} // 20 MB
					mimeTypes={['image/png', 'image/jpeg', 'image/jpg']}
					onValidationError={(error) => {
						alert(error)
					}}
					sizeErrorMessage={'File should be less then 20 Mb'}
					mimeTypesErrorMessage={'Only Jpeg  and png is allowed'}
					onFileChange={(file) => setPreviewFile(file)}
					file={previewFile as File}
				/>
				<label> The image will appear as a thumbnail on the blog listing screen.</label>
			</div>
			<div style={{ marginBottom: 10 }}>
				<FileUpload
					width="72%"
					title={'Tile background image'}
					size={20971520} // 20 MB
					mimeTypes={['image/png', 'image/jpeg', 'image/jpg']}
					onValidationError={(error) => {
						alert(error)
					}}
					sizeErrorMessage={'File should be less then 20 Mb'}
					mimeTypesErrorMessage={'Only Jpeg  and png is allowed'}
					onFileChange={(file) => setBackgroundImageFile(file)}
					file={backgroundImageFile as File}
				/>
				<label>The image will appear in the background of the blog title.</label>
			</div>
			<SkillsForm skills={tags} setSkills={setTags} label="Tags" placeholder="Tags like news,trending etc..." />
		</>
	)
}

export default BlogStaticField
