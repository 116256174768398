import { Box, Button, Typography, CircularProgress } from '@mui/material'
import { motion } from 'framer-motion'
import { Dispatch, FC, MutableRefObject, SetStateAction } from 'react'
import JobDetailBar from '../JobDetailBar'
import ShowNotifications from '../ShowNotifications'
import { container, item } from '../JobDetailComponents/JobConstants'
import { alertInterface } from './OverviewConstants'
import { Job } from '../../models/Job'
import SearchInput from '../Header/SearchInput'
import AddIcon from '@mui/icons-material/Add'
import JobsFilters from './JobsFilters'
import { JobsFiltersTabs } from '../../constants/jobs'

const MobileOverview: FC<{
	alertDetail: MutableRefObject<alertInterface>
	loading: boolean
	activeTab: number
	tabFilterJobs: Job[]
	createNewJobPost: Dispatch<SetStateAction<boolean>>
	jobsFiltersHandler: (selectedTab: number) => void
	isMultiResume?: boolean
}> = ({ alertDetail, loading, activeTab, tabFilterJobs, createNewJobPost, jobsFiltersHandler, isMultiResume }) => {
	return (
		<Box className="mobile-container" sx={{ flexGrow: 1, backgroundColor: '#DFDFDF' }}>
			<Box sx={{ paddingX: '24px' }}>
				<ShowNotifications />
			</Box>
			<Box sx={{ paddingX: '24px' }}>
				<SearchInput />
				<Button onClick={() => createNewJobPost(true)} variant="contained" sx={{ width: '100%', marginBottom: '20px' }} startIcon={<AddIcon />}>
					New Job Post
				</Button>
				<JobsFilters activeTab={activeTab} jobsFiltersHandler={jobsFiltersHandler} />

				{/* Showing Active jobs for Mobile View */}
				{!loading ? (
					<>
						{tabFilterJobs.length > 0 ? (
							<>
								{activeTab === 0 ? `${JobsFiltersTabs[0].text} ` : `All ${JobsFiltersTabs[activeTab].text} jobs`} listed here.
								<motion.div variants={container} initial="hidden" animate="show">
									<Box className="job-list" sx={{ paddingBottom: '20px' }} component={motion.div} variants={item}>
										{tabFilterJobs.map((job: Job) => {
											return (
												<Box sx={{ marginTop: '20px', marginBottom: '20px' }} component={motion.div} variants={item} key={job.id}>
													<JobDetailBar
														id={job?.id ?? ''}
														title={job.title}
														company={job.company}
														location={job.location}
														description={job.description}
														moreInfo={job.moreInfo}
														status={job.status}
														created={job.created !== undefined && job.created !== null ? new Date(job.created.seconds * 1000).toDateString() : undefined}
														lastMod={job.lastMod !== undefined && job.lastMod !== null ? new Date(job.lastMod.seconds * 1000).toDateString() : undefined}
														isMultiResume={isMultiResume}
														groupId={job?.groupId}
													/>
												</Box>
											)
										})}
									</Box>
								</motion.div>
							</>
						) : (
							<>
								<Typography
									variant="h2"
									sx={{
										fontWeight: '600',
										fontSize: { lg: '24px', md: '20px', xs: '18px' },
										paddingTop: { lg: alertDetail.current.open ? '30px' : '42px', sm: '28px', xs: '28px' },
										textAlign: 'center',
										lineHeight: '36px',
										letterSpacing: '0.1px',
										paddingBottom: { lg: '30px', xs: '15px' }
									}}
								>
									No {activeTab === 0 ? `Job` : `${JobsFiltersTabs[activeTab].text} job`} Found.
								</Typography>
							</>
						)}
					</>
				) : (
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
							mt: '30px'
						}}
					>
						<Typography
							sx={{
								color: '#0062FF',
								fontStyle: 'normal',
								fontWeight: '600',
								fontSize: { md: '24px', sm: '20px', xs: '18px' },
								lineHeight: '36px',
								letterSpacing: '0.15px'
							}}
							component={'p'}
						>
							Loading Active Jobs Please wait ...
						</Typography>
						<Box
							sx={{
								marginTop: '20px'
							}}
							component={'div'}
						>
							<CircularProgress />
						</Box>
					</Box>
				)}
				{/* Showing Active jobs for Mobile View */}
			</Box>
		</Box>
	)
}

export default MobileOverview
