import { Box, Typography } from '@mui/material'
import { ChangeEvent, FC, useEffect, useState } from 'react'
import JobAnalysisTable from '../JobAnalysisTable'
import PercentProgress from '../PercentProgress'
import SuggestSkillModal from '../SuggestSkillModal'
import TopJobTitleTable from '../TopJobTitleTable'
import { TABLE_NAMES } from '../../constants'
import SalesIndexTable from '../SalesIndexTable'
import { calculateAveragePercentage } from '../../utils'
import JobFieldSections2Checkboxes from './JobFieldsSections2Checkboxes'
import { JobFieldsSections2Props } from './types2'
import RelatedSkills from './RelatedSkills'
const JobFieldsSections2: FC<JobFieldsSections2Props> = ({
	skillsCheckboxHandler,
	percentHard,
	tableHard,
	addSuggestedSkill,
	percentSoft,
	tableSoft,
	skillsOnly,
	percentSales,
	resumeAnalysis,
	tableRelated,
	topTitles,
	markInaccurateTitle,
	isMobile,
	markIrrelevantSkill,
	resumeIndex,
	percentAvg = 0.0,
	isMultiResumes,
	isSorted,
	sortAccordingToHardSkill,
	isFitScore,
	isFullWidth
}) => {
	const [percentAvgNumber, setPercentAvgNumber] = useState(percentAvg)
	useEffect(() => {
		if (isMultiResumes && isSorted) {
			setPercentAvgNumber(percentHard)
			return
		}

		if (isMultiResumes && !isSorted) {
			setPercentAvgNumber(calculateAveragePercentage(skillsOnly, percentHard, percentSoft, percentSales))
			return
		}

		if (!isMultiResumes) {
			setPercentAvgNumber(calculateAveragePercentage(skillsOnly, percentHard, percentSoft, percentSales))
		}
	}, [percentHard, percentSoft, percentSales, skillsOnly, isSorted])
	return (
		<>
			{!isMultiResumes ? (
				<JobFieldSections2Checkboxes
					skillsOnly={skillsOnly}
					skillsCheckboxHandler={skillsCheckboxHandler}
					isMultiResumes={isMultiResumes}
					resumeIndex={resumeIndex}
					isSorted={isSorted}
					sortAccordingToHardSkill={sortAccordingToHardSkill as (e: ChangeEvent<HTMLInputElement>) => void}
				/>
			) : null}

			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					gap: '5%',
					marginBottom: { sm: '11px', xs: '5px' }
				}}
			>
				<Box>
					<Typography
						component="p"
						sx={{
							color: '#44444F',
							fontWeight: '700',
							fontSize: '14px',
							lineHeight: '18px',
							letterSpacing: '0.1px'
						}}
					>
						{isFitScore ? 'Fit Score' : 'Job Analysis'}
					</Typography>
				</Box>
				<PercentProgress percent={percentAvgNumber} width="20%" />
			</Box>
			<Box
				sx={{
					display: 'flex',
					flexWrap: 'wrap-reverse'
				}}
			>
				<Box
					sx={{
						width: isFullWidth ? '100%' : '45%',
						minWidth: isFullWidth ? '100%' : 600,
						marginRight: '50px'
					}}
				>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							marginBottom: '4px',
							marginTop: '20px'
						}}
					>
						<Box>
							<Typography
								component="span"
								sx={{
									color: '#44444F',
									fontWeight: '700',
									fontSize: '14px',
									lineHeight: '18px',
									letterSpacing: '0.1px',
									marginBottom: { sm: '11px', xs: '5px' },
									marginTop: { sm: '18px', xs: '10px' }
								}}
							>
								Hard Skills match
							</Typography>
						</Box>
						<Box>
							<SuggestSkillModal
								isMobile={isMobile}
								modalSkillType={TABLE_NAMES.HARD_SKILLS}
								handleAddSkill={(skillType, skill) => {
									const skillstring = skill.join(', ') // Convert array to a comma-separated string
									addSuggestedSkill(resumeIndex, skillType, skillstring)
								}}
							/>
						</Box>
						<PercentProgress percent={percentHard} />
					</Box>
					<JobAnalysisTable
						rows={tableHard}
						markIrrelevantSkill={(skill, tableName) => {
							markIrrelevantSkill(resumeIndex, skill, tableName)
						}}
						tableName={TABLE_NAMES.HARD_SKILLS}
					/>
					{!(isMultiResumes && isSorted) ? (
						<>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
									marginBottom: '4px',
									marginTop: '20px'
								}}
							>
								<Box>
									<Typography
										component="span"
										sx={{
											color: '#44444F',
											fontWeight: '700',
											fontSize: '14px',
											lineHeight: '18px',
											letterSpacing: '0.1px',
											marginBottom: { sm: '11px', xs: '5px' },
											marginTop: { sm: '18px', xs: '10px' }
										}}
									>
										Soft Skills match
									</Typography>
								</Box>
								<Box>
									<SuggestSkillModal
										isMobile={isMobile}
										modalSkillType={TABLE_NAMES.SOFT_SKILLS}
										handleAddSkill={(skillType, skill) => {
											const skillstring = skill.join(', ')
											addSuggestedSkill(resumeIndex, skillType, skillstring)
										}}
									/>
								</Box>
								<PercentProgress percent={percentSoft} />
							</Box>
							<JobAnalysisTable
								rows={tableSoft}
								markIrrelevantSkill={(skill, tableName) => {
									markIrrelevantSkill(resumeIndex, skill, tableName)
								}}
								tableName={TABLE_NAMES.SOFT_SKILLS}
							/>
							{!skillsOnly && (
								<>
									<Box
										sx={{
											display: 'flex',
											justifyContent: 'space-between',
											alignItems: 'center',
											marginBottom: '4px',
											marginTop: '20px'
										}}
									>
										<Box>
											<Typography
												component="span"
												sx={{
													color: '#44444F',
													fontWeight: '700',
													fontSize: '14px',
													lineHeight: '18px',
													letterSpacing: '0.1px',
													marginBottom: {
														sm: '11px',
														xs: '5px'
													},
													marginTop: {
														sm: '18px',
														xs: '10px'
													}
												}}
											>
												Sales Index
											</Typography>
										</Box>
										<PercentProgress percent={percentSales} />
									</Box>
									<SalesIndexTable resume={resumeAnalysis} />
								</>
							)}
							{!isMultiResumes ? <RelatedSkills tableRelated={tableRelated} markIrrelevantSkill={markIrrelevantSkill} /> : null}
						</>
					) : null}
				</Box>
				{!isMultiResumes ? (
					<Box>
						<Box
							sx={{
								height: 65,
								marginBottom: '4px',
								marginTop: '20px',
								display: 'flex',
								alignItems: 'center'
							}}
						>
							<Typography
								component="p"
								sx={{
									color: '#44444F',
									fontWeight: '700',
									fontSize: '14px',
									lineHeight: '18px',
									letterSpacing: '0.1px'
								}}
							>
								We found these top job titles for that match your job description
							</Typography>
						</Box>
						<TopJobTitleTable
							rows={topTitles}
							markInaccurateTitle={(title) => {
								markInaccurateTitle(resumeIndex, title)
							}}
						/>
					</Box>
				) : null}
			</Box>
		</>
	)
}

export default JobFieldsSections2
