import { Dispatch, SetStateAction, FC } from 'react'
import CustomTextField from '../../../AddJobDialog/CustomTextField'
import { Box } from '@mui/material'

interface Props {
	email: string
	fullName: string
	city: string
	country: string
	title: string
	phone: string
	setEmail: Dispatch<SetStateAction<string>>
	setFullName: Dispatch<SetStateAction<string>>
	setCity: Dispatch<SetStateAction<string>>
	setCountry: Dispatch<SetStateAction<string>>
	setTitle: Dispatch<SetStateAction<string>>
	setPhone: Dispatch<SetStateAction<string>>
}

const PersonalForm: FC<Props> = ({ email, fullName, city, country, title, phone, setEmail, setCity, setCountry, setFullName, setPhone, setTitle }) => {
	return (
		<>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: { md: '48px', sm: '20px', xs: '15px' }
				}}
			>
				<Box>
					<CustomTextField name="Full Name" value={fullName} onChange={(e) => setFullName(e.target.value)} placeholder="John Doe" type="text" />
				</Box>
				<Box>
					<CustomTextField name="Title" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Senior Java Developer" type="text" />
				</Box>
				<Box>
					<CustomTextField name="Email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="abc@example.com" type="email" />
				</Box>
				<Box>
					<CustomTextField name="Phone" value={phone} onChange={(e) => setPhone(e.target.value)} placeholder="+11234567890" type="tel" />
				</Box>
				<Box>
					<CustomTextField name="City" value={city} onChange={(e) => setCity(e.target.value)} placeholder="City" type="text" />
				</Box>
				<Box>
					<CustomTextField name="Country" value={country} onChange={(e) => setCountry(e.target.value)} placeholder="Country" type="text" />
				</Box>
			</Box>
		</>
	)
}

export default PersonalForm
