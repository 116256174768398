import { Box, Typography, Button } from '@mui/material'
import { FC } from 'react'
import { NavigateFunction } from 'react-router'
import Logo from '../../../assets/Navbars/Logo.png'
import { ROUTES } from '../../../constants/routes'
import { Timestamp } from 'firebase/firestore'

const MobileResumeDetailBar: FC<{
	title: string
	navigate: NavigateFunction
	id: string
	createdAt?: Timestamp
}> = ({ title, navigate, id, createdAt }) => {
	return (
		<Box
			sx={{
				backgroundColor: 'white',
				marginBottom: '10px',
				padding: '14px',
				borderRadius: '8px',
				boxShadow: '0px 4px 100px rgba(184, 186, 198, 0.1)'
			}}
		>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between'
				}}
			>
				<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
					<Box
						sx={{
							width: '48px',
							height: '48px',
							backgroundColor: '#F8F8FD',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							borderRadius: '10px',
							marginRight: '14px'
						}}
					>
						<Box component="img" sx={{ width: '24px', height: '24px' }} src={Logo} />
					</Box>
					<Box>
						<Typography
							paragraph
							sx={{
								color: '#7F879E;',
								fontWeight: '500',
								fontSize: '12px',
								lineHeight: '19px',
								marginBottom: '4px',
								whiteSpace: {
									lg: 'auto',
									xs: 'nowrap',
									sm: 'nowrap'
								},
								width: 180,
								textOverflow: 'ellipsis',
								overflow: 'hidden'
							}}
						>
							{title}
						</Typography>
					</Box>
					<Box>
						<Typography
							paragraph
							sx={{
								color: '#7F879E;',
								fontWeight: '500',
								fontSize: '12px',
								lineHeight: '19px',
								marginBottom: '4px',
								whiteSpace: {
									lg: 'auto',
									xs: 'nowrap',
									sm: 'nowrap'
								},
								width: { lg: 'auto', xs: 'auto', sm: '100px' },
								overflow: {
									lg: 'auto',
									xs: 'auto',
									sm: 'hidden'
								},
								textOverflow: {
									lg: 'auto',
									xs: 'auto',
									sm: 'ellipsis'
								}
								// border: "1px solid #44444F",
							}}
						>
							{createdAt !== undefined && createdAt !== null && createdAt ? new Date(createdAt.seconds * 1000).toDateString() : undefined}
						</Typography>
					</Box>
				</Box>
			</Box>
			<Button
				fullWidth
				variant="outlined"
				onClick={() => navigate(`${ROUTES.BLOG_BUILDER}/${id}`)}
				sx={{
					height: '38px',
					marginBottom: '16px',
					border: '1.5px solid #3860E2',
					borderRadius: '8px',
					fontWeight: '700',
					fontSize: '12px',
					lineHeight: '16px',
					textTransform: 'none'
				}}
			>
				View
			</Button>
		</Box>
	)
}

export default MobileResumeDetailBar
