import { FC } from 'react'
import { JobDetailsContentWrapperProps } from './types'
import MobileJob from './MobileJob'
import Job from './Job'
import { Box } from '@mui/material'

const JobDetailsContentWrapper: FC<JobDetailsContentWrapperProps> = ({
	jobError,
	navigate,
	container,
	jobLoading,
	progressBtnClickHandler,
	isTab,
	editMode,
	setEditMode,
	company,
	setCompany,
	setTitle,
	setLocation,
	moreInfo,
	setMoreInfo,
	location,
	created,
	jobRef,
	user,
	jobText,
	setJobText,
	file,
	setFile,
	description,
	setDescription,
	skillsOnly,
	skillsCheckboxHandler,
	percentAvg,
	isMobile,
	addSuggestedSkill,
	percentHard,
	tableHard,
	markIrrelevantSkill,
	percentSoft,
	tableSoft,
	percentSales,
	tableRelated,
	resumeAnalysis,
	topTitles,
	markInaccurateTitle,
	status,
	item,
	title,
	resumeMode,
	setResumeMode,
	selectedResume,
	setSelectedResume,
	isMultiResumes,
	resumes,
	handleAddResume,
	updateJobDetails,
	deleteJob,
	isSorted,
	sortAccordingToHardSkill,
	handleDelete,
	setResumeName,
	setResumeTitle,
	setResumeLocation,
	setResumeContact,
	setResumeSalary,
	resumeCandidateName,
	resumeTitle,
	resumeCity,
	resumePhone,
	resumeSalary,
	isDisabled
}) => (
	<Box
		sx={{
			position: 'relative',
			minHeight: 'calc(100vh - 60px)',
			backgroundColor: { sm: '#DFDFDF', xs: '#ffffff' },
			width: '100%',
			marginBottom: '20px'
		}}
	>
		{!isMobile ? (
			<>
				<Job
					isDisabled={isDisabled}
					handleDelete={handleDelete}
					handleAddResume={handleAddResume}
					title={title}
					resumeMode={resumeMode}
					setResumeMode={setResumeMode}
					navigate={navigate}
					container={container}
					jobLoading={jobLoading}
					progressBtnClickHandler={progressBtnClickHandler}
					isTab={isTab}
					editMode={editMode}
					updateJobDetails={updateJobDetails}
					setEditMode={setEditMode}
					deleteJob={deleteJob}
					company={company}
					setCompany={setCompany}
					setTitle={setTitle}
					setLocation={setLocation}
					moreInfo={moreInfo}
					setMoreInfo={setMoreInfo}
					jobError={jobError}
					location={location}
					created={created}
					jobRef={jobRef}
					user={user}
					jobText={jobText}
					setJobText={setJobText}
					file={file}
					setFile={setFile}
					description={description}
					setDescription={setDescription}
					skillsOnly={skillsOnly}
					skillsCheckboxHandler={skillsCheckboxHandler}
					percentAvg={percentAvg}
					isMobile={isMobile}
					addSuggestedSkill={addSuggestedSkill}
					percentHard={percentHard}
					tableHard={tableHard}
					markIrrelevantSkill={markIrrelevantSkill}
					percentSoft={percentSoft}
					tableSoft={tableSoft}
					percentSales={percentSales}
					tableRelated={tableRelated}
					resumeAnalysis={resumeAnalysis}
					topTitles={topTitles}
					markInaccurateTitle={markInaccurateTitle}
					status={status}
					item={item}
					setSelectedResume={setSelectedResume}
					selectedResume={selectedResume}
					isMultiResumes={isMultiResumes}
					resumes={resumes}
					isSorted={isSorted}
					sortAccordingToHardSkill={sortAccordingToHardSkill}
					setResumeName={setResumeName}
					setResumeTitle={setResumeTitle}
					setResumeLocation={setResumeLocation}
					setResumeContact={setResumeContact}
					setResumeSalary={setResumeSalary}
					resumeCandidateName={resumeCandidateName}
					resumeTitle={resumeTitle}
					resumeCity={resumeCity}
					resumePhone={resumePhone}
					resumeSalary={resumeSalary}
				/>
			</>
		) : (
			<MobileJob
				isSorted={isSorted}
				sortAccordingToHardSkill={sortAccordingToHardSkill}
				handleDelete={handleDelete}
				isMultiResumes={isMultiResumes}
				resumes={resumes}
				handleAddResume={handleAddResume}
				resumeMode={resumeMode}
				setResumeMode={setResumeMode}
				editMode={editMode}
				updateJobDetails={updateJobDetails}
				setEditMode={setEditMode}
				navigate={navigate}
				jobLoading={jobLoading}
				jobError={jobError}
				company={company}
				setCompany={setCompany}
				title={title}
				setTitle={setTitle}
				moreInfo={moreInfo}
				setMoreInfo={setMoreInfo}
				setLocation={setLocation}
				jobText={jobText}
				setJobText={setJobText}
				file={file}
				setFile={setFile}
				progressBtnClickHandler={progressBtnClickHandler}
				created={created}
				description={description}
				setDescription={setDescription}
				skillsOnly={skillsOnly}
				skillsCheckboxHandler={skillsCheckboxHandler}
				topTitles={topTitles}
				markInaccurateTitle={markInaccurateTitle}
				isMobile={isMobile}
				addSuggestedSkill={addSuggestedSkill}
				percentHard={percentHard}
				tableHard={tableHard}
				markIrrelevantSkill={markIrrelevantSkill}
				percentSoft={percentSoft}
				tableSoft={tableSoft}
				percentSales={percentSales}
				tableRelated={tableRelated}
				location={location}
				resumeAnalysis={resumeAnalysis}
				percentAvg={percentAvg}
				status={status}
				setSelectedResume={setSelectedResume}
				selectedResume={selectedResume}
				setResumeName={setResumeName}
				setResumeTitle={setResumeTitle}
				setResumeLocation={setResumeLocation}
				setResumeContact={setResumeContact}
				setResumeSalary={setResumeSalary}
			/>
		)}
	</Box>
)

export default JobDetailsContentWrapper
