import { Auth } from 'firebase/auth'
import { addDoc, collection, doc, DocumentData, FieldValue, getDoc, serverTimestamp, setDoc, updateDoc } from 'firebase/firestore'
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage'
import { db } from '../../firebase'

const LOADING_IMAGE_URL = 'https://www.google.com/images/spin-32.gif?a'
export const sendMessages = async (isAdminUserChat: boolean, userName: string, uid: string, selectedUser: React.MutableRefObject<DocumentData | null>, messageText: string) => {
	try {
		const docMessage: {
			firstMessageTimestamp?: FieldValue
			name?: string
			lastMessageTimestamp: FieldValue
			newMessage?: boolean
			newMessageTo?: string
			uid?: string
		} = {
			lastMessageTimestamp: serverTimestamp(),
			newMessage: true,
			newMessageTo: isAdminUserChat ? 'user' : 'admin'
		}

		if (!isAdminUserChat) {
			docMessage.name = userName
			docMessage.uid = uid
		}
		const isChatExists = await getDoc(doc(db, 'chat_messages', isAdminUserChat ? (selectedUser?.current?.uid as string) : uid))
		if (!isChatExists.exists()) {
			docMessage.firstMessageTimestamp = serverTimestamp()
			await setDoc(doc(db, 'chat_messages', isAdminUserChat ? (selectedUser?.current?.uid as string) : uid), docMessage)
		} else {
			await updateDoc(doc(db, 'chat_messages', isAdminUserChat ? (selectedUser?.current?.uid as string) : uid), docMessage)
		}
		const docRef = collection(db, `chat_messages/${isAdminUserChat ? selectedUser?.current?.uid : uid}/messages`)

		const addedRef = await addDoc(docRef, {
			name: userName,
			id: '',
			text: messageText,
			from: selectedUser.current ? 'admin' : uid,
			to: selectedUser.current ? selectedUser?.current?.uid : 'admin',
			// from,to
			sendReciverInfo: [selectedUser.current ? 'admin' : uid, selectedUser.current ? selectedUser?.current?.uid : 'admin'],
			timestamp: serverTimestamp()
		})
		await updateDoc(addedRef, {
			id: addedRef.id
		})
	} catch (error) {
		console.error('Error writing new message to Firebase Database', error)
	}
}

export const saveImageMessage = async (file: File, isAdminUserChat: boolean, username: string, selectedUser: React.MutableRefObject<DocumentData | null>, uid: string, auth: Auth) => {
	try {
		const docMessage: {
			firstMessageTimestamp?: FieldValue
			name?: string
			lastMessageTimestamp: FieldValue
			newMessage?: boolean
			newMessageTo?: string
			uid?: string
		} = {
			lastMessageTimestamp: serverTimestamp(),
			newMessage: true,
			newMessageTo: isAdminUserChat ? 'user' : 'admin'
		}

		if (!isAdminUserChat) {
			docMessage.name = username
			docMessage.uid = uid
		}
		const isChatExists = await getDoc(doc(db, 'chat_messages', isAdminUserChat ? (selectedUser?.current?.uid as string) : uid))
		if (!isChatExists.exists()) {
			docMessage.firstMessageTimestamp = serverTimestamp()
			await setDoc(doc(db, 'chat_messages', isAdminUserChat ? (selectedUser?.current?.uid as string) : uid), docMessage)
		} else {
			await updateDoc(doc(db, 'chat_messages', isAdminUserChat ? (selectedUser?.current?.uid as string) : uid), docMessage)
		}
		const docRef = collection(db, `chat_messages/${isAdminUserChat ? (selectedUser?.current?.uid as string) : uid}/messages`)

		const messageRef = await addDoc(docRef, {
			name: username,
			text: '',
			from: selectedUser.current ? 'admin' : uid,
			to: selectedUser.current ? selectedUser?.current?.uid : 'admin',
			sendReciverInfo: [selectedUser.current ? 'admin' : uid, selectedUser.current ? selectedUser?.current?.uid : 'admin'],
			timestamp: serverTimestamp(),
			imageUrl: LOADING_IMAGE_URL
		})

		// 2 - Upload the image to Cloud Storage.
		const filePath = `${auth?.currentUser?.uid}/${messageRef.id}/${randomFileName() + file.name}`
		const newImageRef = ref(getStorage(), filePath)
		const fileSnapshot = await uploadBytesResumable(newImageRef, file)

		// 3 - Generate a public URL for the file.
		const publicImageUrl = await getDownloadURL(newImageRef)

		// 4 - Update the chat message placeholder with the image's URL.
		await updateDoc(messageRef, {
			imageUrl: publicImageUrl,
			storageUri: fileSnapshot.metadata.fullPath
		})
	} catch (error) {
		// console.error('There was an error uploading a file to Cloud Storage:', error)
	}
}

export const randomFileName = () => {
	const randomFileNumber = Math.random() * 10000
	return `${new Date()?.toISOString()}_${randomFileNumber}_`
}
