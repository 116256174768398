import { useEffect } from 'react'

const usePageTitle = () => {
	const setPageTitle = (pageTitle: string, moreInfo = '', appendStaticString = true) => {
		window.document.title = `${appendStaticString ? 'GambitResume' : ''}
            ${pageTitle ? `${appendStaticString ? ' | ' : ''}${pageTitle}` : ''}
            ${pageTitle && moreInfo ? ` - ${moreInfo}` : ''}
        `
	}

	useEffect(() => {
		return () => {
			window.document.title = 'GambitResume.com | Free Resume Scanner & Optimizer'
		}
	}, [])

	return [setPageTitle]
}

export default usePageTitle
