export interface UserData {
	firstName: string
	lastName: string
	type: string
	uid: string
	displayName?: string
}

export interface MessageData {
	text: string
	from: string
	imageUrl: string
	storageUri?: string
}

export class MessageDataObj implements MessageData {
	text = ''
	from = ''
	imageUrl = ''
	storageUri = ''
	id = ''
	isCustom = true
	constructor(text: string, from: string) {
		this.text = text
		this.from = from
	}
}

export interface EventObj {
	target: { files: File[]; value: string }
}

export interface ScrollElement {
	scrollHeight: number
	scrollTop: number
	clientHeight: number
}

export const LIMIT = 20
export const LIMIT_USERS = 10
export interface UserProfileData {
	email: string
	firstName: string
	lastName: string
	role?: string
}

export const INITIAL_MESSAGES = [
	new MessageDataObj("Got any questions? I'm happy to help (I'm a live person 10am-6pm CT).", 'admin'),
	new MessageDataObj("Feel free to ask your question here, and I'll be with you as soon as I can be.", 'admin')
]
