import { arrayUnion, DocumentData, DocumentReference, setDoc } from 'firebase/firestore'
import { REGEX } from '../constants'
import { sendShareEmail } from './email'
import jobTitles from '../constants/jobTitles.json'

export const shareJob = async (email: string, subject: string, message: string, docRef: DocumentReference<DocumentData>): Promise<void> => {
	await setDoc(
		docRef,
		{
			sharedWith: arrayUnion(email)
		},
		{ merge: true }
	)
	await sendShareEmail(email, subject, message)
}

export const analyzeJobDescription = (
	description: string
): {
	analyzedTitle: string
	analyzedCompany: string
	analyzedLocation: string
	analyzedMoreInfo: string
} => {
	let analyzedTitle = ''
	let analyzedCompany = ''
	let analyzedLocation = ''
	let analyzedMoreInfo = ''
	if (description.length !== 0) {
		const lowercaseDesc = description.toLowerCase()
		const matchedTitles = jobTitles.filter((title) => {
			return lowercaseDesc.includes(title.toLowerCase().trim())
		})

		if (matchedTitles.length > 1) {
			const sortedMatchedTitles = matchedTitles.sort((title1, title2) => title2.length - title1.length)
			analyzedTitle = sortedMatchedTitles[0] ?? ''
		} else if (matchedTitles.length === 1) {
			analyzedTitle = matchedTitles[0] ?? ''
		}
		if (!analyzedTitle) {
			for (let index = 0; index < REGEX.JOB_TITLE.length; index++) {
				const exp = REGEX.JOB_TITLE[index]
				const titleMatch = lowercaseDesc.match(exp)
				if (titleMatch !== null && titleMatch.length !== 0 && titleMatch[1].length !== 0) {
					analyzedTitle = titleMatch[1].trim()
					break
				}
			}
		}

		for (let index = 0; index < REGEX.COMPANY_NAME.length; index++) {
			const exp = REGEX.COMPANY_NAME[index]
			const companyNameMatch = lowercaseDesc.match(exp)
			if (companyNameMatch !== null && companyNameMatch.length !== 0 && companyNameMatch[1].length !== 0) {
				analyzedCompany = companyNameMatch[1].trim()
				break
			}
		}

		for (let index = 0; index < REGEX.LOCATION.length; index++) {
			const exp = REGEX.LOCATION[index]
			const match = lowercaseDesc.match(exp)
			if (match !== null) {
				if (analyzedLocation !== '') analyzedLocation += ' ; '
				analyzedLocation += match[1].trim()
			}
		}

		for (let index = 0; index < REGEX.MORE_INFO.length; index++) {
			const exp = REGEX.MORE_INFO[index]
			const match = lowercaseDesc.match(exp)
			if (match !== null) {
				if (analyzedMoreInfo !== '') analyzedMoreInfo += ' ; '
				analyzedMoreInfo += match[1].trim()
			}
		}
	}
	return {
		analyzedTitle,
		analyzedCompany,
		analyzedLocation,
		analyzedMoreInfo
	}
}
