import { Button } from '@mui/material'
import { ACCEPTED_FILE_TYPES } from '../../constants'
import { FC } from 'react'

const UploadResumes: FC<{
	handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}> = ({ handleChange }) => {
	return (
		<Button
			variant="contained"
			component="label"
			color="info"
			sx={{
				margin: '10px 0px',
				textTransform: 'none',
				borderRadius: '12px',
				padding: { md: '16px 24px;', sm: '10px 16px;', sx: '8px 16px' },
				fontSize: { md: '18px', xs: '16px' },
				fontWeight: '600',
				lineHeight: { sm: '27px', xs: '24px' },
				letterSpacing: '2%',
				width: 314
			}}
		>
			Upload Multiple Resumes
			<input
				multiple
				type="file"
				hidden
				onChange={(e) => {
					handleChange(e)
				}}
				onClick={(e) => {
					;(e.target as HTMLInputElement).value = ''
				}}
				accept={ACCEPTED_FILE_TYPES.join(',')}
			/>
		</Button>
	)
}

export default UploadResumes
