import { Typography } from '@mui/material'
import TopJobTitleTable from '../TopJobTitleTable'
import { FC } from 'react'

const ResumesMatchingJobTitles: FC<{
	rows: string[]
	markInaccurateTitle: (value: string) => void
}> = ({ rows, markInaccurateTitle }) => {
	return (
		<>
			<Typography
				component="p"
				sx={{
					color: '#44444F',
					fontWeight: 'bold',
					fontSize: '14px',
					lineHeight: '18px',
					letterSpacing: '0.1px',
					marginBottom: { sm: '11px', xs: '5px' },
					marginTop: { sm: '25px', xs: '10px' }
				}}
			>
				Based on this job description, this role is closely aligned with the following job titles
			</Typography>
			<TopJobTitleTable rows={rows} markInaccurateTitle={markInaccurateTitle} />
		</>
	)
}

export default ResumesMatchingJobTitles
