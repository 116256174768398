import { Box, Button } from '@mui/material'
import { motion } from 'framer-motion'
import { Dispatch, FC, SetStateAction } from 'react'
import ShowNotifications from '../../components/ShowNotifications'
import { container, item } from '../OverviewComponents/OverviewConstants'
import MobileResumeNoJobsFound from './MobileResumeNoJobsFound'
import MobileResumeLoadingJobs from './MobileResumeLoadingJobs'
import { ResumeType } from '../../models/Resume'
import ResumeDetailBar from '../Resume/ResumeDetailBar'
import AddIcon from '@mui/icons-material/Add'

const MobileResumes: FC<{
	search: string
	setSearch: Dispatch<SetStateAction<string>>
	loading: boolean
	newResumeHandler: () => void
	resumes: ResumeType[]
}> = ({ newResumeHandler, loading, resumes }) => {
	return (
		<Box className="mobile-container" sx={{ backgroundColor: '#DFDFDF', flexGrow: 1 }}>
			<Box sx={{ paddingX: '24px' }}>
				<ShowNotifications />
			</Box>
			<Box sx={{ paddingX: '24px' }}>
				{/* // */}
				{/* Showing resumes for Mobile View */}
				{!loading ? (
					<>
						<Button onClick={newResumeHandler} variant="contained" sx={{ width: '100%', marginY: '20px' }} startIcon={<AddIcon />}>
							New Resume
						</Button>
						{resumes.length > 0 ? (
							<motion.div variants={container} initial="hidden" animate="show">
								<Box
									className="job-list"
									sx={{
										paddingBottom: '20px'
									}}
									component={motion.div}
									variants={item}
								>
									<>
										All Resumes listed here
										{/* All Resumes listed here */}
										{resumes.map((resume: ResumeType) => {
											return (
												<Box
													sx={{
														marginTop: '20px',
														marginBottom: '20px'
													}}
													component={motion.div}
													variants={item}
													key={resume.id}
												>
													<ResumeDetailBar
														id={resume.id ?? ''}
														title={resume.title}
														resumeName={resume.resumeName}
														skills={resume.skills}
														created={resume.created !== undefined && resume.created !== null ? new Date(resume.created.seconds * 1000).toDateString() : undefined}
													/>
												</Box>
											)
										})}
										{/* All Resumes listed here */}
									</>
								</Box>
							</motion.div>
						) : (
							<>
								<MobileResumeNoJobsFound />
							</>
						)}
					</>
				) : (
					<MobileResumeLoadingJobs />
				)}
				{/* Showing resumes for Mobile View */}
			</Box>
		</Box>
	)
}

export default MobileResumes
