import { Box, Container, Typography } from '@mui/material'
import { FC, useEffect } from 'react'
import usePageTitle from '../../hooks/usePageTitle'
import { TERMS_AND_CONDITIONS } from '../../constants'

const TermsAndConditions: FC<{}> = () => {
	const [setPageTitle] = usePageTitle()

	useEffect(() => {
		setPageTitle('Terms And Conditions')
	}, [])

	return (
		<>
			<Container
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					paddingTop: '20px'
				}}
			>
				<Box marginY="20px">
					<Typography component="h2" fontSize={{ md: '42px', xs: '30px' }}>
						Terms And Conditions
					</Typography>
				</Box>
				<Box padding="20px">
					<Typography component="p" fontSize="18px">
						Welcome to GambitResume! a platform that connects job seekers with professional resume writers and career coaches. By using our website, you agree to be bound by the following
						terms and conditions:
					</Typography>
					{TERMS_AND_CONDITIONS.map((term, index) => (
						<Typography marginY="15px" key={`${index} - ${term.slice(0, 5)}`} component="p">
							{index + 1}. {term}
						</Typography>
					))}
				</Box>
			</Container>
		</>
	)
}

export default TermsAndConditions
