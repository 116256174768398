import { Box, Typography, Link } from '@mui/material'
import { FC } from 'react'
import { ROUTES } from '../../constants/routes'
import SignupSection1Form from './SignupSection1Form'
import { SignupSection1FormProps } from './SignupProps'

const SignupSection1: FC<SignupSection1FormProps> = ({
	userLoading,
	navigate,
	callback,
	handleSignUpWithEmail,
	firstName,
	setFirstName,
	email,
	setEmail,
	password,
	setPassword,
	confirmPassword,
	setConfirmPassword,
	username,
	setUsername,
	lastName,
	setLastName
}) => {
	return (
		<>
			<Box
				component={'div'}
				sx={{
					width: { md: '560px', sm: '450px', xs: '90%' },
					height: { sm: 'auto' },
					margin: '0 auto',
					paddingBottom: '0px',
					// border: "1px solid #000000",
					background: '#FFFFFF',
					boxShadow: '3px -5px 40px rgba(205, 205, 212, 0.1)',
					borderRadius: '20px',
					padding: { sm: '30px', xs: '15px' }
				}}
			>
				{!userLoading && (
					<Box component="div">
						<Box component={'div'}>
							{/* <Typography
                                component="div"
                                sx={{
                                    width: '100%',
                                    textAlign: 'center',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center'
                                }}
                            >
                                <Typography
                                    variant="h5"
                                    component="div"
                                    sx={{
                                        fontFamily: "'Poppins'",
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        fontSize: { md: '24px', sm: '20px', xs: '16px' },
                                        lineHeight: '36px',
                                        letterSpacing: '0.15px',
                                        color: '#171725'
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            color: '#0062FF',
                                            fontStyle: 'normal',
                                            fontWeight: '600',
                                            fontSize: { md: '24px', sm: '20px', xs: '18px' },
                                            lineHeight: '36px',
                                            letterSpacing: '0.15px'
                                        }}
                                        component={'span'}
                                    >
                                        SignUp Page For {USER_TYPE_NAMES[userType as keyof typeof USER_TYPE_NAMES]}
                                    </Typography>
                                </Typography>

                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box
                                        onClick={() => handleAnonymousSignIn()}
                                        component={'img'}
                                        sx={{
                                            display: 'block',
                                            marginLeft: '14px',
                                            width: { sm: '36px', xs: '24px' },
                                            height: { sm: '36px', xs: '24px' },
                                            cursor: 'pointer'
                                        }}
                                        src={nextImage}
                                        title={'Continue as anonymous'}
                                        alt="next"
                                    />
                                </Box>
                            </Typography> */}

							<Typography
								component="div"
								variant="h5"
								sx={{
									fontFamily: "'Poppins'",
									fontStyle: 'normal',
									fontWeight: '400',
									fontSize: { sm: '18px', xs: '15px' },
									lineHeight: '27px',
									letterSpacing: '0.02em',
									color: '#94959B',
									textAlign: 'center'
									// marginTop: '11px'
								}}
							>
								Do you already have an account?{' '}
								<Link
									onClick={() => navigate(ROUTES.LOGIN + (callback !== null ? '?callback=' + encodeURIComponent(callback) : ''))}
									sx={{
										fontFamily: "'Poppins'",
										fontStyle: 'normal',
										fontWeight: '500',
										cursor: 'pointer',
										fontSize: { sm: '18px', xs: '15px' },
										lineHeight: '27px',
										letterSpacing: '0.02em',
										color: '#3865F3'
									}}
									component={'span'}
								>
									Login
								</Link>
							</Typography>
							{/* <Typography
                                component="div"
                                variant="h5"
                                sx={{
                                    fontFamily: "'Poppins'",
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: { sm: '18px', xs: '15px' },
                                    lineHeight: '27px',
                                    letterSpacing: '0.02em',
                                    color: '#94959B',
                                    textAlign: 'center',
                                    marginTop: '11px'
                                }}
                            >
                                Are you a{' '}
                                {USER_TYPE_NAMES[userType as keyof typeof USER_TYPE_NAMES] === USER_TYPE_NAMES.recruiter
                                    ? USER_TYPE_NAMES.job_seeker
                                    : USER_TYPE_NAMES.recruiter}
                                ? {' '}
                                <Link
                                    onClick={() =>
                                        navigate(
                                            ROUTES.SIGNUP +
                                            (callback !== null
                                                ? '?callback=' +
                                                encodeURIComponent(callback) +
                                                '&'
                                                : '?') +
                                            'userType=' +
                                            (userType === USER_TYPES.RECRUITER
                                                ? USER_TYPES.JOB_SEEKER
                                                : USER_TYPES.RECRUITER) +
                                            (sig !== null
                                                ? '&sig=' + sig
                                                : ''
                                            )
                                        )
                                    }
                                    sx={{
                                        fontFamily: "'Poppins'",
                                        fontStyle: 'normal',
                                        fontWeight: '500',
                                        cursor: 'pointer',
                                        fontSize: { sm: '18px', xs: '15px' },
                                        lineHeight: '27px',
                                        letterSpacing: '0.02em',
                                        color: '#3865F3'
                                    }}
                                    component={'span'}
                                >
                                    Sign up as{' '}
                                    {USER_TYPE_NAMES[userType as keyof typeof USER_TYPE_NAMES] === USER_TYPE_NAMES.recruiter
                                        ? USER_TYPE_NAMES.job_seeker
                                        : USER_TYPE_NAMES.recruiter}
                                </Link>
                            </Typography> */}
						</Box>

						{/* ####################################################################### */}
						{/* ________________________ Complete Sign Up Form ________________________ */}
						{/* ####################################################################### */}

						<SignupSection1Form
							handleSignUpWithEmail={handleSignUpWithEmail}
							firstName={firstName}
							setFirstName={setFirstName}
							email={email}
							setEmail={setEmail}
							password={password}
							setPassword={setPassword}
							confirmPassword={confirmPassword}
							setConfirmPassword={setConfirmPassword}
							username={username}
							setUsername={setUsername}
							lastName={lastName}
							setLastName={setLastName}
						/>

						{/* social icons section */}
						{/* <SocialAuth userType={userType} callback={callback} sig={sig} /> */}

						{/* social icons section */}
						{/* ####################################################################### */}
						{/* ________________________ Complete Sign Up Form ________________________ */}
						{/* ####################################################################### */}
					</Box>
				)}
			</Box>

			<br />
			<br />
		</>
	)
}
export default SignupSection1
