import { Box, Typography, Button } from '@mui/material'
import { FC } from 'react'
import { NavigateFunction } from 'react-router'
import Logo from '../../../assets/Navbars/Logo.png'
import Calendar from '../../../assets/JobDetail/Calendar.png'
import { ROUTES } from '../../../constants/routes'

const MobileResumeDetailBar: FC<{
	resumeName: string
	title: string
	skills: string[]
	created: string | undefined
	navigate: NavigateFunction
	id: string
}> = ({ resumeName, title, skills, created, navigate, id }) => {
	return (
		<Box
			sx={{
				backgroundColor: 'white',
				marginBottom: '10px',
				padding: '14px',
				borderRadius: '8px',
				boxShadow: '0px 4px 100px rgba(184, 186, 198, 0.1)'
			}}
		>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between'
				}}
			>
				<Box sx={{ display: 'flex' }}>
					<Box
						sx={{
							width: '48px',
							height: '48px',
							backgroundColor: '#F8F8FD',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							borderRadius: '10px',
							marginRight: '14px'
						}}
					>
						<Box component="img" sx={{ width: '24px', height: '24px' }} src={Logo} />
					</Box>
					<Box>
						<Typography
							variant="h5"
							sx={{
								color: '#1B2124',
								fontWeight: '700',
								fontSize: '14px',
								lineHeight: '18px',
								marginBottom: '6px',
								whiteSpace: {
									lg: 'auto',
									xs: 'nowrap',
									sm: 'nowrap'
								},
								width: { lg: 'auto', xs: '100px', sm: '100px' },
								overflow: {
									lg: 'auto',
									xs: 'hidden',
									sm: 'hidden'
								},
								textOverflow: {
									lg: 'auto',
									xs: 'ellipsis',
									sm: 'ellipsis'
								}
								// border: { lg: "none", xs: "1px solid #44444F", sm: "1px solid #44444F" },
							}}
						>
							{resumeName}
						</Typography>
						<Typography
							paragraph
							sx={{
								color: '#7F879E;',
								fontWeight: '500',
								fontSize: '12px',
								lineHeight: '19px',
								marginBottom: '4px',
								whiteSpace: {
									lg: 'auto',
									xs: 'nowrap',
									sm: 'nowrap'
								},
								width: { lg: 'auto', xs: 'auto', sm: '100px' },
								overflow: {
									lg: 'auto',
									xs: 'auto',
									sm: 'hidden'
								},
								textOverflow: {
									lg: 'auto',
									xs: 'auto',
									sm: 'ellipsis'
								}
								// border: "1px solid #44444F",
							}}
						>
							{title}
						</Typography>
						<Typography
							paragraph
							sx={{
								color: '#7F879E;',
								fontWeight: '500',
								fontSize: '12px',
								lineHeight: '19px'
							}}
						>
							{skills.join(', ')}
						</Typography>
					</Box>
				</Box>
			</Box>
			<Box sx={{ marginBottom: '20px' }}>
				{created && (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							marginBottom: '15px'
						}}
					>
						<Box
							component="img"
							src={Calendar}
							sx={{
								width: '20px',
								height: '20px',
								marginRight: '8px'
							}}
						/>
						<Typography
							paragraph
							sx={{
								fontWeight: '500',
								fontSize: '12px',
								lineHeight: '19px',
								color: '#7F879E',
								margin: '0'
							}}
						>
							{created}
						</Typography>
					</Box>
				)}
			</Box>
			<Button
				fullWidth
				variant="outlined"
				onClick={() => navigate(`${ROUTES.RESUMES}/${id}`)}
				sx={{
					height: '38px',
					marginBottom: '16px',
					border: '1.5px solid #3860E2',
					borderRadius: '8px',
					fontWeight: '700',
					fontSize: '12px',
					lineHeight: '16px',
					textTransform: 'none'
				}}
			>
				View
			</Button>
		</Box>
	)
}

export default MobileResumeDetailBar
