import { Box } from '@mui/material'
import CustomLoader from '..'

const FixedLoader = () => {
	return (
		<Box
			component="div"
			onClick={(e) => e.stopPropagation()}
			sx={{ position: 'fixed', zIndex: 100, inset: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#00000075' }}
		>
			<CustomLoader />
		</Box>
	)
}

export default FixedLoader
