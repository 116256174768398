import { FC } from 'react'
import { Box, useMediaQuery } from '@mui/material'

import theme from '../../theme'
import ShowNotifications from '../../components/ShowNotifications'
import SupportLinks from '../../components/SupportLinks'

const Support: FC = () => {
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
	return (
		<>
			{isMobile ? (
				<Box sx={{ width: '100%', flexGrow: 1 }}>
					<Box sx={{ paddingX: { lg: '40px', xs: '20px' } }}>
						<ShowNotifications />
					</Box>
					<SupportLinks />
				</Box>
			) : (
				<Box
					sx={{
						minHeight: '100vh',
						backgroundColor: '#DFDFDF',
						position: 'relative',
						width: '100%'
					}}
				>
					<Box sx={{ display: 'flex', minHeight: screen.height - 70 }}>
						<Box sx={{ width: '100%' }}>
							<Box sx={{ paddingX: { lg: '40px', xs: '20px' } }}>
								<ShowNotifications />
							</Box>
							<SupportLinks />
						</Box>
					</Box>
				</Box>
			)}
		</>
	)
}

export default Support
