import { FC } from 'react'
import { Box, Button } from '@mui/material'
import { JobsFiltersTabs } from '../../constants/jobs'

const JobsFilters: FC<{ activeTab: number; jobsFiltersHandler: (selectedTab: number) => void }> = ({ activeTab, jobsFiltersHandler }) => {
	return (
		<Box
			sx={{
				height: '48px',
				backgroundColor: '#ECECEC',
				borderRadius: '16px',
				padding: '4px',
				marginBottom: { xs: '16px', sm: '0' },
				button: {
					minWidth: 'unset',
					width: { xs: '25%', sm: 'unset' },
					paddingX: { xs: '5px', sm: '10px', md: '15px' },
					height: '40px',
					borderRadius: '12px',
					fontWeight: '600',
					fontSize: '14px',
					lineHeight: '20px',
					textTransform: 'none',
					':hover': {
						backgroundColor: '#fafafa'
					}
				}
			}}
		>
			{JobsFiltersTabs.map(({ text }, index) => (
				<Button
					key={text}
					color="inherit"
					className="button-group-text"
					onClick={() => jobsFiltersHandler(index)}
					sx={{
						backgroundColor: activeTab === index ? 'white' : 'inherit'
					}}
				>
					{text}
				</Button>
			))}
		</Box>
	)
}

export default JobsFilters
