import { Box, Typography, Button } from '@mui/material'
import { FC } from 'react'
import { ROUTES } from '../../constants/routes'
import { useNavigate } from 'react-router'

const JobDetailContentError: FC<{}> = () => {
	const navigate = useNavigate()

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				mt: '30px'
			}}
		>
			<Typography
				sx={{
					color: '#0062FF',
					fontStyle: 'normal',
					fontWeight: '600',
					fontSize: { md: '24px', sm: '20px', xs: '18px' },
					lineHeight: '36px',
					letterSpacing: '0.15px'
				}}
				component={'p'}
			>
				Error Loading Job Detail Page ....
			</Typography>
			<Box sx={{ marginTop: '20px' }} component={'div'}>
				<Button
					variant="contained"
					color="primary"
					onClick={() => {
						navigate(ROUTES.OVERVIEW)
					}}
				>
					Go to Home
				</Button>
			</Box>
		</Box>
	)
}

export default JobDetailContentError
