import { Alert } from '@mui/material'
import { FC } from 'react'
import { useCollection } from 'react-firebase-hooks/firestore'
import { COLOR_TO_SEVERITIES } from '../../constants/notifications'
import { getNotificationsQuery } from '../../utils/queries'
import CustomAlert from '../CustomAlert'

const ShowNotifications: FC = () => {
	const [notifications, notificationLoading, notificationError] = useCollection(getNotificationsQuery())

	return (
		<>
			{!notificationLoading && notificationError == null && notifications?.docs && notifications?.docs?.length > 0 ? (
				<>
					{notifications?.docs.map((notification) => (
						<CustomAlert
							key={notification.id}
							title={notification.data().title && notification.data().title.length ? notification.data().title : ''}
							detail={notification.data().detail}
							severity={COLOR_TO_SEVERITIES[notification.data().color as keyof typeof Alert]}
						/>
					))}
				</>
			) : (
				<></>
			)}
		</>
	)
}

export default ShowNotifications
