export const container = {
	show: {
		transition: {
			staggerChildren: 0.05
		}
	}
}
export const item = {
	hidden: { opacity: 0, y: 40 },
	show: { opacity: 1, y: 0 }
}
