import React from 'react'
import { FC } from 'react'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'

const MenuDropDown: FC<{ values: Array<{ name: string; slug: string }>; title: string; handleDropdownSelected: (title: string) => void; width: string }> = ({
	values,
	title,
	handleDropdownSelected,
	width
}) => {
	return (
		<PopupState variant="popover" popupId="demo-popup-menu">
			{(popupState) => (
				<React.Fragment>
					<Button sx={{ width }} variant="contained" {...bindTrigger(popupState)}>
						{title}
					</Button>
					<Menu {...bindMenu(popupState)}>
						{values.map((value, i) => (
							<MenuItem
								key={i}
								onClick={() => {
									handleDropdownSelected(value.slug)
									popupState.close()
								}}
							>
								{value.name}
							</MenuItem>
						))}
					</Menu>
				</React.Fragment>
			)}
		</PopupState>
	)
}

export default MenuDropDown
