import { Dispatch, SetStateAction, FC, ChangeEvent } from 'react'
import { Box, Button, IconButton, TextField } from '@mui/material'
import { Add, Close } from '@mui/icons-material'
import { Education } from '../../../../models/Education'

interface Props {
	education: Education[]
	setEducation: Dispatch<SetStateAction<Education[]>>
}

const EducationForm: FC<Props> = ({ education, setEducation }) => {
	const educationAddHandler = () => {
		const tempEducation: Education = {
			institute: '',
			completionYear: '',
			degree: '',
			field: ''
		}
		setEducation([tempEducation, ...education])
	}

	const removeHandler = (index: number) => {
		setEducation([...education.filter((temp, idx) => idx !== index)])
	}

	const changeHandler = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, idx: number, field: keyof Education) => {
		const tempEducation: Education[] = [...education]
		tempEducation[idx][field] = e.target.value
		setEducation([...tempEducation])
	}

	return (
		<>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: { md: '48px', sm: '20px', xs: '15px' },
					justifyContent: 'center',
					alignItems: 'center'
				}}
			>
				<Button
					sx={{
						textTransform: 'none',
						borderRadius: 50,
						fontSize: { md: '18px', xs: '16px' },
						fontWeight: '600',
						letterSpacing: '2%',
						textAlign: 'center',
						width: '80%'
					}}
					variant="outlined"
					color="primary"
					onClick={educationAddHandler}
				>
					<Add /> Add New
				</Button>
				{education.map((data, idx) => (
					<>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								gap: '15px',
								position: 'relative',
								width: '80%',
								backgroundColor: '#F6F6F6',
								padding: '20px',
								paddingTop: '30px',
								borderRadius: '20px'
							}}
						>
							<IconButton
								color="error"
								onClick={() => removeHandler(idx)}
								sx={{
									position: 'absolute',
									top: 4,
									right: 4,
									cursor: 'pointer'
								}}
							>
								<Close />
							</IconButton>
							<TextField
								label="University/School"
								value={data.institute}
								onChange={(e) => changeHandler(e, idx, 'institute')}
								type="text"
								variant="standard"
								sx={{
									fontWeight: { sm: '400', xs: '500' },
									fontFamily: { sm: 'roboto' }
								}}
								fullWidth
								size="small"
								InputProps={{
									sx: {
										fontSize: '14px'
									}
								}}
							/>
							<TextField
								label="Degree"
								value={data.degree}
								onChange={(e) => changeHandler(e, idx, 'degree')}
								type="text"
								variant="standard"
								sx={{
									fontWeight: { sm: '400', xs: '500' },
									fontFamily: { sm: 'roboto' }
								}}
								fullWidth
								size="small"
								InputProps={{
									sx: {
										fontSize: '14px'
									}
								}}
							/>
							<TextField
								label="Graduation Year"
								value={data.completionYear}
								onChange={(e) => changeHandler(e, idx, 'completionYear')}
								type="text"
								variant="standard"
								sx={{
									fontWeight: { sm: '400', xs: '500' },
									fontFamily: { sm: 'roboto' }
								}}
								fullWidth
								size="small"
								InputProps={{
									sx: {
										fontSize: '14px'
									}
								}}
							/>
							<TextField
								label="Field of Study"
								value={data.field}
								onChange={(e) => changeHandler(e, idx, 'field')}
								type="text"
								variant="standard"
								sx={{
									fontWeight: { sm: '400', xs: '500' },
									fontFamily: { sm: 'roboto' }
								}}
								fullWidth
								size="small"
								InputProps={{
									sx: {
										fontSize: '14px'
									}
								}}
							/>
						</Box>
					</>
				))}
			</Box>
		</>
	)
}

export default EducationForm
