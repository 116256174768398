import { Box, Typography, Button } from '@mui/material'
import { FC } from 'react'
import Logo from '../../assets/Navbars/Logo.png'
import Location from '../../assets/JobDetail/Location.png'
import BoxIcon from '../../assets/JobDetail/Box.png'
import Calendar from '../../assets/JobDetail/Calendar.png'
import { NavigateFunction } from 'react-router'
import { ROUTES } from '../../constants/routes'
import { JobStatus } from '../../models/Job'
import { JobStatuses } from '../../constants/jobs'

interface MobileJobDetailsBarProps {
	company: string
	moreInfo?: string
	title?: string
	location: string
	created?: string
	lastMod?: string
	description: string
	navigate: NavigateFunction | (() => {})
	id: string
	status: JobStatus
	isMultiResume?: boolean
	groupId?: string
}

const MobileJobDetailsBar: FC<MobileJobDetailsBarProps> = ({ company, moreInfo, location, created, lastMod, description, navigate = () => {}, id, status, title, isMultiResume, groupId }) => {
	return (
		<Box
			sx={{
				minHeight: '283px',
				backgroundColor: 'white',
				marginBottom: '10px',
				padding: '14px',
				borderRadius: '8px',
				boxShadow: '0px 4px 100px rgba(184, 186, 198, 0.1)'
			}}
		>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between'
				}}
			>
				<Box sx={{ display: 'flex' }}>
					<Box
						sx={{
							width: '48px',
							height: '48px',
							backgroundColor: '#F8F8FD',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							borderRadius: '10px',
							marginRight: '14px'
						}}
					>
						<Box component="img" sx={{ width: '24px', height: '24px' }} src={Logo} />
					</Box>
					<Box>
						<Typography
							variant="h5"
							sx={{
								color: '#1B2124',
								fontWeight: '700',
								fontSize: '14px',
								lineHeight: '18px',
								marginBottom: '6px',
								whiteSpace: { lg: 'auto', xs: 'nowrap', sm: 'nowrap' },
								width: { lg: 'auto', xs: '100px', sm: '100px' },
								overflow: { lg: 'auto', xs: 'hidden', sm: 'hidden' },
								textOverflow: { lg: 'auto', xs: 'ellipsis', sm: 'ellipsis' }
								// border: { lg: "none", xs: "1px solid #44444F", sm: "1px solid #44444F" },
							}}
						>
							{company}
						</Typography>
						<Typography
							paragraph
							sx={{
								color: '#7F879E;',
								fontWeight: '500',
								fontSize: '12px',
								lineHeight: '19px',
								marginBottom: '4px',
								whiteSpace: { lg: 'auto', xs: 'nowrap', sm: 'nowrap' },
								width: { lg: 'auto', xs: 'auto', sm: '100px' },
								overflow: { lg: 'auto', xs: 'auto', sm: 'hidden' },
								textOverflow: { lg: 'auto', xs: 'auto', sm: 'ellipsis' }
							}}
						>
							{title}
						</Typography>
						<Typography
							paragraph
							sx={{
								color: '#7F879E;',
								fontWeight: '500',
								fontSize: '12px',
								lineHeight: '19px'
							}}
						>
							{moreInfo}
						</Typography>
					</Box>
				</Box>
				<Box
					sx={{
						marginRight: '6px',
						minWidth: '94px',
						textAlign: 'center',
						height: '24px',
						borderRadius: '12px',
						color: JobStatuses[status].textColor,
						padding: '2px 12px',
						border: '1px solid',
						borderColor: JobStatuses[status].textColor,
						backgroundColor: 'transparent',
						fontWeight: '500',
						fontSize: '12px',
						whiteSpace: 'nowrap'
					}}
				>
					{JobStatuses[status].text}
				</Box>
			</Box>
			<Box sx={{ marginBottom: '20px' }}>
				<Box
					sx={{
						display: 'flex',
						marginBottom: '15px',
						alignItems: 'center'
					}}
				>
					<Box
						component="img"
						src={Location}
						sx={{
							width: '20px',
							height: '20px',
							marginRight: '8px'
						}}
					/>
					<Typography
						paragraph
						sx={{
							fontWeight: '500',
							fontSize: '12px',
							lineHeight: '19px',
							color: '#7F879E',
							margin: '0'
						}}
					>
						{location}
					</Typography>
				</Box>

				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						marginBottom: '15px'
					}}
				>
					<Box
						component="img"
						src={BoxIcon}
						sx={{
							width: '20px',
							height: '20px',
							marginRight: '8px'
						}}
					/>
					<Typography
						paragraph
						sx={{
							fontWeight: '500',
							fontSize: '12px',
							lineHeight: '19px',
							color: '#7F879E',
							margin: '0'
						}}
					>
						UNIUX
					</Typography>
				</Box>

				{created && (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							marginBottom: '15px'
						}}
					>
						<Box
							component="img"
							src={Calendar}
							sx={{
								width: '20px',
								height: '20px',
								marginRight: '8px'
							}}
						/>
						<Typography
							paragraph
							sx={{
								fontWeight: '500',
								fontSize: '12px',
								lineHeight: '19px',
								color: '#7F879E',
								margin: '0'
							}}
						>
							{created}
						</Typography>
					</Box>
				)}

				{lastMod && (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center'
						}}
					>
						<Box
							component="img"
							src={Calendar}
							sx={{
								width: '20px',
								height: '20px',
								marginRight: '8px'
							}}
						/>
						<Typography
							paragraph
							sx={{
								fontWeight: '500',
								fontSize: '12px',
								lineHeight: '19px',
								color: '#7F879E',
								margin: '0'
							}}
						>
							{lastMod}
						</Typography>
					</Box>
				)}
			</Box>
			<Box className="box" sx={{ width: '100%', marginBottom: '20px' }}>
				<Typography
					className="para"
					paragraph
					sx={{
						margin: '0',
						fontWeight: '400',
						fontSize: '12px',
						lineHeight: '19px',
						display: '-webkit-box',
						WebkitLineClamp: '2',
						WebkitBoxOrient: 'vertical',
						overflow: 'hidden'
					}}
				>
					{description}
				</Typography>
			</Box>
			<Button
				fullWidth
				variant="outlined"
				onClick={() => {
					navigate(isMultiResume ? ROUTES.MULTIPLE_RESUMES_JOBS_DETAIL.replace(':id', groupId ?? '') : `${ROUTES.OVERVIEW}/job-detail/${id}`)
				}}
				sx={{
					height: '38px',
					marginBottom: '16px',
					border: '1.5px solid #3860E2',
					borderRadius: '8px',
					fontWeight: '700',
					fontSize: '12px',
					lineHeight: '16px',
					textTransform: 'none'
				}}
			>
				View
			</Button>
		</Box>
	)
}

export default MobileJobDetailsBar
