import { Box, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../constants/routes'

const AlternativesLinks = () => {
	return (
		<Box textAlign={{ sx: 'start', sm: 'center' }}>
			<Typography
				sx={{
					color: '#B5B5B5',
					letterSpacing: '4px',
					padding: '5px 0',
					textAlign: 'start',
					fontSize: '14px'
				}}
			>
				SWITICHING FROM
			</Typography>
			<Link style={{ color: 'white', cursor: 'pointer', textDecoration: 'none' }} to={ROUTES.ALTERNATIVES}>
				Alternative to JobScan.Co
			</Link>
		</Box>
	)
}

export default AlternativesLinks
