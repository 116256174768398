import { Box, Button } from '@mui/material'
import FileUpload from '../FileUpload'
import CustomTextField from '../AddJobDialog/CustomTextField'
import { FC, SyntheticEvent } from 'react'
import RitchTextArea from '../RitchTextArea'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { EventInfo } from '@ckeditor/ckeditor5-utils'
import SupportAgentIcon from '@mui/icons-material/SupportAgent'
import { BlogSections } from '../../models/Blog'

const BlogFields: FC<{
	isFile?: boolean
	isText?: boolean
	isRitchTextField?: boolean
	fileUploadName?: string
	textFieldName?: string
	rtcName?: string
	file?: File
	onFileChange: (file: File) => void
	text?: string
	onTextChange: (e: SyntheticEvent<HTMLInputElement>) => void
	rtcData?: string
	onRtcDataChange: (event: EventInfo, editor: ClassicEditor) => void
	onAiButtonClicked: (target: BlogSections) => void
	showAiButton?: boolean
	targetTemplate?: BlogSections
}> = ({
	isFile,
	isText,
	isRitchTextField,
	fileUploadName,
	textFieldName,
	rtcName,
	onFileChange = (file: File) => {},
	text,
	onTextChange = (e) => {},
	rtcData,
	onRtcDataChange = (event: EventInfo, editor: ClassicEditor) => {},
	file,
	showAiButton,
	onAiButtonClicked,
	targetTemplate
}) => {
	return (
		<Box>
			{isText ? (
				<div
					style={{
						display: 'flex',
						flexWrap: 'wrap',
						alignItems: 'center'
					}}
				>
					<CustomTextField name={textFieldName ?? ''} value={text ?? ''} onChange={onTextChange} placeholder={textFieldName ?? ''} type="text" width={showAiButton ? '85%' : ''} />
					{showAiButton ? (
						<Button
							disabled={!text?.trim()}
							sx={{
								textTransform: 'none',
								borderRadius: '12px',
								marginLeft: '2px',
								width: 40,
								minWidth: 40,
								minHeight: 40,
								height: 40
							}}
							variant="contained"
							color="primary"
							onClick={() => targetTemplate && onAiButtonClicked(targetTemplate)}
						>
							<SupportAgentIcon />
						</Button>
					) : null}
				</div>
			) : null}
			{isFile ? (
				<FileUpload
					title={fileUploadName ?? ''}
					size={20971520} // 20 MB
					mimeTypes={['image/png', 'image/jpeg']}
					onValidationError={(error) => {
						alert(error)
					}}
					sizeErrorMessage={'File should be less then 20 Mb'}
					mimeTypesErrorMessage={'Only Jpeg  and png is allowed'}
					onFileChange={onFileChange}
					file={file}
				/>
			) : null}
			{isRitchTextField ? <RitchTextArea data={rtcData ?? ''} label={rtcName ?? ''} onChange={onRtcDataChange} /> : null}
		</Box>
	)
}

export default BlogFields
