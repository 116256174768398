import { useRef, useState, FC, Dispatch, SetStateAction, ChangeEvent, useMemo, useEffect } from 'react'
import { Button, Typography, Link, Box, TextField, useMediaQuery, FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import { ACCEPTED_FILE_TYPES } from '../../../constants'
import theme from '../../../theme'
import { SelectResume } from '../../JobDetailComponents/types'
import { useCollection } from 'react-firebase-hooks/firestore'
import { collection } from 'firebase/firestore'
import { auth, db } from '../../../firebase'
import { ResumeType } from '../../../models/Resume'
import { COLLECTION_NAMES } from '../../../constants/collections'
import { useAuthState } from 'react-firebase-hooks/auth'
import DeleteIcon from '@mui/icons-material/Delete'
import RadioControls from './RadioControls'
import { FileFieldProps } from '../../../components/JobDetailComponents/types2'
import { getResumeData } from '../../../utils/functions'
const CustomFileField: FC<FileFieldProps> = ({
	name,
	setFile,
	setJobText,
	jobText,
	resumeMode,
	setResumeMode,
	selectedResume,
	setSelectedResume,
	selectedResumeSelectionKey,
	dropdownLabel = 'Resumes',
	resumeCount,
	handleDelete,
	resumeIndex = null,
	newResume,
	onResumeData,
	resumeFileName
}) => {
	const [user] = useAuthState(auth)
	const [resumesSnap] = useCollection(user ? collection(db, COLLECTION_NAMES.USERS_COLLECTION, user.uid, COLLECTION_NAMES.RESUMES) : null)
	const [resumeContent, setResumeContent] = useState(jobText)
	const timeoutRef = useRef<null | number | NodeJS.Timeout>(null)
	const fetchResumeData = (selectedResume: string) => {
		getResumeData(db, user, selectedResume, (value) => {
			if (onResumeData) onResumeData(value.metaData)
		})
	}
	useEffect(() => {
		if (resumeFileName) setFileName(resumeFileName ?? '')
	}, [resumeFileName])

	useEffect(() => {
		if (jobText) setResumeContent(jobText)
	}, [jobText])
	const resumesList: SelectResume[] = useMemo(() => {
		if (!resumesSnap) return []
		const resumes = resumesSnap.docs
		const uniqueKeys: { [key: string]: boolean } = {}
		const filteredResumes: SelectResume[] = []
		resumes.forEach((doc) => {
			const resume = doc.data() as ResumeType
			const customResumeObj: SelectResume = {
				id: doc.id,
				name: resume.resumeName,
				contact: `${resume.phone ? resume.phone : resume.email}${resume.phone && resume.email ? ` | ${resume.email}` : ''}`,
				location: `${resume.city ? resume.city : resume.country}${resume.city && resume.country ? `, ${resume.country}` : ''}`
			}
			if (!uniqueKeys[customResumeObj[selectedResumeSelectionKey ?? 'id']]) {
				uniqueKeys[customResumeObj[selectedResumeSelectionKey ?? 'id']] = true
				filteredResumes.push(customResumeObj)
			}
		})
		return filteredResumes
	}, [resumesSnap])

	const [fileName, setFileName] = useState<string>('')
	const [error, setError] = useState('')
	const ref = useRef(null)
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
		if (!e.target.files) return

		const tempFile: File = e.target.files[0]

		if (ACCEPTED_FILE_TYPES.includes(tempFile.type)) {
			setError('')
			setFile(tempFile)
			setFileName(tempFile.name)
		} else {
			setFileName('')
			setFile(null)
			setError('Can only upload pdf, docx, or txt files!')
		}
	}

	const handleRemove = (): void => {
		setFileName('')
		setFile(null)
	}

	return (
		<>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between'
				}}
			>
				{name !== undefined && name !== '' && (
					<Typography
						component="p"
						sx={{
							color: '#171725',
							fontWeight: { sm: '600', xs: '700' },
							fontSize: { sm: '18px', xs: '14px' },
							lineHeight: { sm: '27px', xs: '18px' },
							marginBottom: { sm: '16px', xs: '10px' }
						}}
					>
						{name} {resumeCount && resumeCount !== 0 ? resumeCount : null}
					</Typography>
				)}
				{resumeIndex && newResume ? (
					<Button
						sx={{
							marginLeft: 'auto'
						}}
						onClick={() => {
							handleDelete && handleDelete(resumeIndex ?? 0)
						}}
						variant="contained"
						color="error"
					>
						<DeleteIcon />
					</Button>
				) : null}
			</Box>
			<Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
				{!setResumeMode ? null : <RadioControls isMobile={isMobile} setResumeMode={setResumeMode} resumeMode={resumeMode} resumeIndex={resumeIndex} />}
				<Box sx={{ width: isMobile ? '100%' : '80%' }}>
					{resumeMode === 'file' && (
						<>
							<Button
								variant="contained"
								component="label"
								color="info"
								sx={{
									textTransform: 'none',
									borderRadius: '12px',
									padding: { md: '16px 24px;', sm: '10px 16px;', sx: '8px 16px' },
									fontSize: { md: '18px', xs: '16px' },
									fontWeight: '600',
									lineHeight: { sm: '27px', xs: '24px' },
									letterSpacing: '2%',
									width: { xs: '50%', sm: 'unset' }
								}}
							>
								Upload Resume
								<input
									type="file"
									hidden
									ref={ref}
									onChange={(e) => handleChange(e)}
									onClick={(e) => ((e.target as HTMLInputElement).value = '')}
									accept={ACCEPTED_FILE_TYPES.join(',')}
								/>
							</Button>
							{fileName !== '' && (
								<>
									<Typography
										component="span"
										sx={{
											color: '#171725',
											fontWeight: { sm: '400', xs: '500' },
											fontSize: { sm: '16px', xs: '12px' },
											lineHeight: { sm: '27px', xs: '18px' },
											marginBottom: { sm: '16px', xs: '10px' },
											marginLeft: { sm: '16px', xs: '10px' }
										}}
									>
										{fileName}
									</Typography>
									<Link
										sx={{
											textDecoration: 'none',
											marginLeft: {
												sm: '5px',
												xs: '2px'
											},
											fontSize: { sm: '12px', xs: '9px' },
											':hover': {
												cursor: 'pointer'
											}
										}}
										onClick={handleRemove}
									>
										&#10060;
									</Link>
								</>
							)}
							{error !== '' && (
								<>
									<Typography
										component="span"
										sx={{
											color: 'orange',
											fontWeight: { sm: '400', xs: '500' },
											fontSize: { sm: '16px', xs: '12px' },
											lineHeight: { sm: '27px', xs: '18px' },
											marginBottom: { sm: '16px', xs: '10px' },
											marginLeft: { sm: '16px', xs: '10px' }
										}}
									>
										{error}
									</Typography>
								</>
							)}
						</>
					)}
					{resumeMode === 'text' && (
						<>
							<Box
								sx={{
									marginTop: '5px'
								}}
							>
								<TextField
									id="outlined-basic"
									label={'Resume'}
									variant="outlined"
									value={resumeContent}
									multiline
									rows={18}
									style={{
										width: '100%'
									}}
									onChange={(e) => {
										setResumeContent(e.target.value)
										if (timeoutRef.current) {
											clearTimeout(timeoutRef.current)
											timeoutRef.current = null
										}
										timeoutRef.current = setTimeout(() => {
											timeoutRef.current = null
											setJobText(e.target.value)
										}, 1000)
									}}
									sx={{
										input: {
											color: '#44444F',
											fontWeight: '700',
											fontSize: '14px',
											lineHeight: '18px',
											letterSpacing: '0.1px',
											width: '100%'
										}
									}}
								/>
							</Box>
						</>
					)}
					{resumeMode === 'resumes' && (
						<>
							<Box
								sx={{
									marginTop: '5px'
								}}
							>
								<FormControl
									variant="standard"
									size="small"
									sx={{
										minWidth: '150px',
										fontWeight: { sm: '400', xs: '500' },
										fontFamily: { sm: 'roboto' },
										width: '100%'
									}}
								>
									<InputLabel id="resumes">{dropdownLabel}</InputLabel>
									<Select
										label="Resumes"
										labelId="resumes"
										value={selectedResume}
										onChange={(e) => {
											resumeIndex !== null
												? (setSelectedResume as (index: number, value: string) => void)(resumeIndex, e.target.value)
												: (setSelectedResume as Dispatch<SetStateAction<string>>)(e.target.value)
											fetchResumeData(e.target.value)
										}}
									>
										{resumesList.map((resume) =>
											resume[selectedResumeSelectionKey ?? 'id'] ? (
												<MenuItem key={resume.id} value={resume[selectedResumeSelectionKey ?? 'id']}>
													{resume[selectedResumeSelectionKey ?? 'name']}
												</MenuItem>
											) : null
										)}
									</Select>
								</FormControl>
							</Box>
						</>
					)}
				</Box>
			</Box>
		</>
	)
}

export default CustomFileField
