import { Timestamp, addDoc, collection, setDoc } from 'firebase/firestore'
import { auth, db } from '../firebase'
import { COLLECTION_NAMES } from '../constants/collections'
import { CoverLetterType } from '../models/CoverLetter'
import { getRandomParagraph } from '../constants/coverLetter'
import jsPDF from 'jspdf'
import dayjs from 'dayjs'

export const addNewCoverLetter = async () => {
	if (auth.currentUser) {
		const coverLetter: CoverLetterType = {
			created: Timestamp.fromDate(new Date()),
			id: '',
			contact: auth.currentUser.email ?? '',
			closing_paragraph: getRandomParagraph('CL_CLOSING_PARAGRAPH_DEFAULT_TEXT'),
			location: '',
			name: '',
			greetings: '',
			opening_paragraph: getRandomParagraph('CL_OPENING_PARAGRAPH_DEFAULT_TEXT'),
			signature: getRandomParagraph('CL_SIGNATURE_DEFAULT_TEXT'),
			title: 'Cover Letter'
		}
		const docRef = await addDoc(collection(db, COLLECTION_NAMES.USERS_COLLECTION, auth.currentUser.uid, COLLECTION_NAMES.COVER_LETTERS), coverLetter)
		setDoc(docRef, { id: docRef.id }, { merge: true })
		return docRef.id
	} else {
		return 'unauthenticated'
	}
}

export const createPDF = (name: string, date: Date, contact: string, location: string, greetings: string, openingParagraph: string, closingParagraph: string, signature: string) => {
	const JSPDF = jsPDF
	const doc = new JSPDF()
	const x = 15
	const marginBetweenParagraphs = 10
	const marginBetweenParagraphsLines = 5
	const noOfWordsPerLine = 180
	let y = 30

	const getTextXCenter = (text: string) => (doc.internal.pageSize.getWidth() - doc.getTextWidth(text)) / 2
	const addParagraphToPDF = (paragraph: string = '') => {
		paragraph.split('\n').forEach((l: string) => {
			const paragraphhLines = doc.splitTextToSize(l.replace(/\t/g, '    '), noOfWordsPerLine)
			doc.text(paragraphhLines, x, y)
			y += marginBetweenParagraphsLines * paragraphhLines.length
		})
	}

	if (name) {
		doc.setFontSize(24)
		doc.text(name, getTextXCenter(name), y)
		y += marginBetweenParagraphs
	}
	doc.setFontSize(12)
	if (location) {
		doc.text(location, getTextXCenter(location), y)
		y += marginBetweenParagraphs
	}
	if (contact) {
		doc.text(contact, getTextXCenter(contact), y)
		y += marginBetweenParagraphs
	}
	if (date) {
		doc.text(dayjs(date).format('MMMM DD YYYY'), x, y)
		y += marginBetweenParagraphs
	}
	if (greetings) {
		doc.text(greetings, x, y)
		y += marginBetweenParagraphs
	}
	if (openingParagraph) {
		addParagraphToPDF(openingParagraph)
		y += marginBetweenParagraphs
	}
	if (closingParagraph) {
		addParagraphToPDF(closingParagraph)
		y += marginBetweenParagraphs
	}
	if (signature) {
		addParagraphToPDF(signature)
	}
	return doc
}

export const getPDFPreview = (doc: jsPDF) => {
	return doc.output('dataurlstring')
}

export const downloadPDF = (doc: jsPDF, title: string) => {
	doc.save(`${title.trim() === '' ? 'Cover Letter' : title}.pdf`)
}
