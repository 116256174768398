import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

// Importing all the pages components
//

// Importing Custom Loader Component
import CustomLoader from '../../components/CustomLoader'

// Importing the useAuthState hook
import { useAuthState } from 'react-firebase-hooks/auth'

import { ROUTES } from '../../constants/routes'
import { auth } from '../../firebase'

const Referral = () => {
	const [user, loading] = useAuthState(auth)

	const [searchParams] = useSearchParams()

	const callback = searchParams.get('callback')
	const sig = searchParams.get('sig')

	const navigate = useNavigate()

	useEffect(() => {
		if (loading) return
		if (user === null || user === undefined) {
			navigate(ROUTES.SIGNUP + (callback !== null ? '?callback=' + callback : '?') + (sig ? '&sig=' + sig : ''))
		} else {
			if (callback !== null) {
				navigate(callback)
			} else {
				navigate(ROUTES.OVERVIEW)
			}
		}
	}, [user, loading])

	return <CustomLoader />
}

export default Referral
