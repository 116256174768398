import { Box, Button } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { EditOutlined, Save } from '@mui/icons-material'
import { MobileJobProps } from './types'
import MobileJobMoreInfoField from './MobileJobMoreInfoField'
import MobileJobSection1 from './MobileJobSection1'
import MobileJobSection2 from './MobileJobSection2'
// import AddResumeButton from '../AddJobDialog/AddResumeButton'
import MobileJobBar from './MobileJobBar'
import { RowType } from '../JobAnalysisTable/jobType'
import { ResumeAnalysis } from '../../models/ResumeAnalysis'
import { Job } from '../../models/Job'
import MobileResumeSummeryTable from './MobileResumeSummeryTable'
import MobileMultipleJobTitles from './MobileMultipleJobTitles'
import MobileFitScoreCheckbox from './MobileFitScoreCheckbox'
import MobileJobSection2Edit from './MobileJobSection2Edit'
import MobileJobTitle from './MobileJobTitle'
import MobileCompanyName from './MobileCompanyName'

const MobileJob: FC<MobileJobProps> = ({
	editMode,
	updateJobDetails = () => {},
	setEditMode = () => {},
	jobLoading,
	jobError,
	company,
	setCompany = () => {},
	title = '',
	setTitle = () => {},
	moreInfo = '',
	setMoreInfo = () => {},
	setLocation = () => {},
	jobText,
	setJobText,
	file,
	setFile,
	progressBtnClickHandler,
	created,
	description,
	setDescription = () => {},
	skillsOnly,
	skillsCheckboxHandler,
	topTitles,
	markInaccurateTitle,
	isMobile,
	addSuggestedSkill,
	percentHard,
	tableHard,
	markIrrelevantSkill,
	percentSoft,
	tableSoft,
	percentSales,
	tableRelated,
	location,
	resumeAnalysis,
	percentAvg,
	status,
	resumeMode,
	setResumeMode,
	selectedResume,
	setSelectedResume,
	resumes,
	isMultiResumes,
	handleDelete,
	isSorted,
	handleAddResume,
	sortAccordingToHardSkill,
	setResumeName,
	setResumeTitle,
	setResumeLocation,
	setResumeContact,
	setResumeSalary
}) => {
	const [viewMetrixStatus, setViewMetrixStatus] = useState<{ data: Job; index: number } | null>(null)
	useEffect(() => {
		if (viewMetrixStatus) {
			setViewMetrixStatus(resumes ? { data: resumes[viewMetrixStatus.index], index: viewMetrixStatus.index } : null)
		}
	}, [viewMetrixStatus])
	return (
		<>
			<Button
				sx={{
					position: 'fixed',
					top: '80px',
					right: '10px',
					zIndex: 10,
					height: '30px',
					fontSize: '12px',
					letterSpacing: '0.1px',
					borderRadius: '12px',
					textTransform: 'none'
				}}
				variant="contained"
				color={editMode ? 'success' : 'primary'}
				startIcon={editMode ? <Save fontSize="inherit" /> : <EditOutlined fontSize="inherit" />}
				onClick={editMode ? () => updateJobDetails() : () => setEditMode(true)}
			>
				{editMode ? 'Save Job' : 'Edit Job'}
			</Button>
			<Box
				className="mb__page-container"
				sx={{
					paddingX: '24px'
				}}
			>
				<MobileJobBar isMultiResume={isMultiResumes} status={status} />
				<Box>
					<MobileCompanyName editMode={editMode} resumes={resumes} setCompany={setCompany} company={company} jobLoading={jobLoading} jobError={jobError} />
					<MobileJobTitle editMode={editMode} resumes={resumes} setTitle={setTitle} title={title} jobLoading={jobLoading} jobError={jobError} />
					<MobileJobMoreInfoField
						editMode={editMode}
						moreInfo={resumes && resumes.length ? resumes[0]?.moreInfo : moreInfo}
						setMoreInfo={setMoreInfo}
						jobLoading={jobLoading}
						jobError={jobError}
					/>
				</Box>
				{!editMode && isMultiResumes ? (
					<>
						<MobileFitScoreCheckbox isSorted={isSorted} sortAccordingToHardSkill={sortAccordingToHardSkill} />
					</>
				) : null}
				{!editMode && isMultiResumes ? (
					<MobileResumeSummeryTable
						resumes={resumes}
						isSorted={isSorted}
						sortAccordingToHardSkill={sortAccordingToHardSkill}
						jobError={jobError}
						handleDelete={handleDelete}
						setResumeMode={setResumeMode}
						editMode={editMode}
						setJobText={setJobText}
						setFile={setFile}
						setDescription={setDescription}
						skillsCheckboxHandler={skillsCheckboxHandler}
						percentAvg={percentAvg}
						isMobile={isMobile}
						addSuggestedSkill={addSuggestedSkill}
						tableHard={tableHard}
						markIrrelevantSkill={markIrrelevantSkill}
						markInaccurateTitle={markInaccurateTitle}
						jobLoading={jobLoading}
						setSelectedResume={setSelectedResume}
						selectedResume={selectedResume}
					/>
				) : null}
				{!editMode && isMultiResumes && resumes?.length ? (
					<>
						<MobileMultipleJobTitles
							rows={resumes.reduce<string[]>((prev, next): string[] => {
								const titles = next.top_titles.filter((title) => !prev.includes(title))
								return [...prev, ...titles]
							}, [])}
							markInaccurateTitle={markInaccurateTitle}
						/>
					</>
				) : null}
				{isMultiResumes && resumes?.length ? (
					<MobileJobSection2
						jobError={jobError}
						index={0}
						jobLoading={jobLoading}
						editMode={editMode}
						description={resumes[0].description}
						setDescription={setDescription}
						hideResume={true}
					/>
				) : null}
				{!editMode && (
					<MobileJobSection1
						editMode={editMode}
						setLocation={setLocation}
						location={resumes && resumes.length ? resumes[0]?.location : location}
						jobLoading={jobLoading}
						jobError={jobError}
						created={created}
						status={status}
						progressBtnClickHandler={progressBtnClickHandler}
					/>
				)}

				{editMode && isMultiResumes && (
					<MobileJobSection2Edit
						resumes={resumes}
						jobError={jobError}
						setResumeMode={setResumeMode}
						editMode={editMode}
						setJobText={setJobText}
						setFile={setFile}
						percentAvg={percentAvg}
						isMobile={isMobile}
						jobLoading={jobLoading}
						setSelectedResume={setSelectedResume}
						selectedResume={selectedResume}
						setResumeName={setResumeName}
						setResumeTitle={setResumeTitle}
						setResumeLocation={setResumeLocation}
						setResumeContact={setResumeContact}
						setResumeSalary={setResumeSalary}
						handleAddResume={handleAddResume}
					/>
				)}
				{!isMultiResumes && (
					<>
						<MobileJobSection2
							isMultiResumes={isMultiResumes}
							jobError={jobError}
							index={0}
							resumeMode={resumeMode}
							setResumeMode={setResumeMode}
							editMode={editMode}
							jobText={jobText as string}
							setJobText={setJobText}
							file={file}
							setFile={setFile}
							description={description}
							setDescription={setDescription}
							skillsOnly={skillsOnly}
							skillsCheckboxHandler={skillsCheckboxHandler}
							percentAvg={percentAvg as number}
							isMobile={isMobile}
							addSuggestedSkill={addSuggestedSkill}
							percentHard={percentHard as number}
							tableHard={tableHard as RowType[]}
							markIrrelevantSkill={markIrrelevantSkill}
							percentSoft={percentSoft as number}
							tableSoft={tableSoft as RowType[]}
							percentSales={percentSales as number}
							tableRelated={tableRelated as RowType[]}
							resumeAnalysis={resumeAnalysis as ResumeAnalysis}
							topTitles={topTitles as string[]}
							markInaccurateTitle={markInaccurateTitle}
							jobLoading={jobLoading}
							setSelectedResume={setSelectedResume}
							selectedResume={selectedResume}
							showMoreDetails={true}
						/>
					</>
				)}
			</Box>
		</>
	)
}

export default MobileJob
