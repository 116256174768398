import { Box, Container, Typography } from '@mui/material'
import { FC, useEffect } from 'react'
import usePageTitle from '../../hooks/usePageTitle'
import { FAQs } from '../../constants'

const FAQ: FC<{}> = () => {
	const [setPageTitle] = usePageTitle()

	useEffect(() => {
		setPageTitle('FAQ')
	}, [])

	return (
		<>
			<Container
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					paddingTop: '20px'
				}}
			>
				<Box marginY="20px">
					<Typography component="h2" fontSize={{ md: '42px', xs: '30px' }}>
						FAQ
					</Typography>
				</Box>
				<Box padding="20px">
					<Typography component="p" fontSize="18px">
						Write 100 FAQ with their answers. these FAQs are focused on resume writing ,resume scanning , interview preparation and job hunting in general. These FAQs are from candidate
						point of view
					</Typography>
					{FAQs.map(({ question, answer }, index) => (
						<Box key={`${index} - ${answer.slice(0, 5)}`} marginY="15px">
							<Typography component="p">
								<b>Q{index + 1}</b>. {question}
							</Typography>
							<Typography component="p">
								<b>A</b>. {answer}
							</Typography>
						</Box>
					))}
				</Box>
			</Container>
		</>
	)
}

export default FAQ
