import { Checkbox, FormControlLabel, Typography } from '@mui/material'
import { FC } from 'react'

const MobileFitScoreCheckbox: FC<{
	isSorted?: boolean
	sortAccordingToHardSkill?: (e: React.ChangeEvent<HTMLInputElement>) => void
}> = ({ isSorted, sortAccordingToHardSkill }) => (
	<>
		<FormControlLabel
			control={<Checkbox checked={isSorted} onChange={(e) => sortAccordingToHardSkill && sortAccordingToHardSkill(e)} inputProps={{ 'aria-label': 'controlled' }} />}
			label={<Typography sx={{ fontWeight: 'bold' }}>Calculate Fit Score Based Only on Hard Skills</Typography>}
		/>
		<Typography variant="subtitle2" sx={{ fontStyle: 'oblique' }} paragraph>
			When enabled, this checkbox allows the resume scanner to assess candidates exclusively on their hard skills, disregarding soft skills, buzzwords, and other factors.
		</Typography>
	</>
)

export default MobileFitScoreCheckbox
