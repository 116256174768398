type PARAGRAPH_TYPE = 'CL_OPENING_PARAGRAPH_DEFAULT_TEXT' | 'CL_CLOSING_PARAGRAPH_DEFAULT_TEXT' | 'CL_SIGNATURE_DEFAULT_TEXT'

export const CL_OPENING_PARAGRAPH_DEFAULT_TEXT = [
	`I am writing to express my interest in the Software Developer position at Apple Inc. With a strong background in computer science and a passion for technology, I am confident that I would be a valuable asset to the team. My experience developing and implementing software solutions has provided me with the skills and knowledge necessary to excel in this role. In my current position as a software developer at XYZ Company, I have contributed to the successful launch of several software products, including increasing efficiency and reducing costs by 20%. I am excited about the opportunity to bring my skills and experience to Apple Inc. and help drive innovation and growth.`,
	`I am writing to express my interest in the Sales Representative position at Microsoft Corporation. With a proven track record of success in sales and a passion for technology, I am confident that I would be a valuable asset to the team. My experience in sales has provided me with the skills and knowledge necessary to excel in this role. In my current position as a sales representative at XYZ Company, I have exceeded my sales targets by 20% and have been recognized as the top performer for the past two years. I am excited about the opportunity to bring my skills and experience to Microsoft Corporation and help drive revenue and growth.`,
	`I am writing to express my interest in the Customer Service Representative position at Amazon.com Inc. With a strong background in customer service and a passion for e-commerce, I am confident that I would be a valuable asset to the team. My experience in customer service has provided me with the skills and knowledge necessary to excel in this role. In my current position as a customer service representative at XYZ Company, I have consistently received positive feedback from customers and have been recognized as the top performer for the past year. I am excited about the opportunity to bring my skills and experience to Amazon.com Inc. and help provide exceptional customer service.`,
	`I am writing to express my interest in the Software Developer position at Apple Inc. My qualifications and relevant experience make me a strong candidate for the position. I have a Bachelor's degree in Computer Science and 5 years of experience working as a software developer at a leading tech company. My skills include proficiency in Python, Java, and C++, as well as experience with Agile development methodologies. I have a proven track record of developing high-quality software and contributing to the success of my previous employer. I am excited about the opportunity to work at a company that is known for its innovative technology and commitment to excellence.`,
	`I am writing to express my interest in the Sales Representative position at Microsoft Corporation. I am a highly motivated and results-driven professional with over 5 years of experience in sales and account management. My experience in the technology industry and my ability to build relationships with clients has enabled me to consistently exceed sales targets. I have a proven track record of effectively communicating the value of products and services to potential customers, and I am confident in my ability to do the same for Microsoft Corporation.`,
	`I am writing to express my interest in the Customer Service Representative position at Amazon.com Inc. I have over 3 years of experience working in customer service, and I am committed to providing excellent service to every customer. My ability to communicate effectively and my ability to problem-solve quickly make me an ideal candidate for this position. I am a quick learner and I am confident in my ability to learn the ins and outs of Amazon.com Inc's products and services.`,
	`I am writing to express my interest in the Software Developer position at Apple Inc. As a highly skilled and experienced software developer, I am confident that my qualifications and experience make me a strong candidate for this opportunity. I have several years of experience in the field, including a strong background in developing and implementing software applications for a wide range of industries. My experience has taught me to be well-versed in multiple programming languages, including Java, C++, and Python. I am also well-versed in software development methodologies such as Agile and Scrum. In my current role, I have been responsible for developing and maintaining software applications for a large retail company, which has allowed me to demonstrate my technical expertise and problem-solving skills. I have also been responsible for managing and leading a team of developers, which has helped me to develop my leadership and team management skills. I would be honored to bring my skills, experience, and passion to Apple Inc. and to be a part of the team that is driving innovation and technology forward.`,
	`I am writing to express my interest in the Sales Representative position at Microsoft Corporation. As a highly motivated and experienced sales representative, I am confident that my qualifications and experience make me a strong candidate for this opportunity. I have several years of experience in the field, including a strong background in sales and business development. My experience has taught me to be well-versed in sales methodologies such as SPIN selling and consultative selling. In my current role, I have been responsible for developing and maintaining relationships with key clients and partners, which has allowed me to demonstrate my ability to build and maintain relationships. I have also been responsible for managing and leading a team of sales representatives, which has helped me to develop my leadership and team management skills. I would be honored to bring my skills, experience, and passion to Microsoft Corporation and to be a part of the team that is driving innovation and technology forward.`,
	`I am writing to express my interest in the position of Software Developer at Apple Inc. I am a highly skilled software developer with over 5 years of experience in the field. My expertise in programming languages such as Java, Python, and C++ make me a strong candidate for this position. In my current role as a software developer at XYZ Company, I have been responsible for developing and maintaining software applications for various clients. I have also contributed to the design and implementation of new features, resulting in increased productivity and customer satisfaction. I am excited about the opportunity to bring my skills and experience to Apple Inc. and contribute to the company's mission of creating innovative technology. Thank you for considering my application.`,
	`I am writing to express my interest in the position of Sales Representative at Microsoft Corporation. I am an experienced sales professional with over 7 years of experience in the field. My skills in relationship building, negotiation, and problem-solving make me a strong candidate for this position. In my current role as a sales representative at XYZ Company, I have been responsible for generating new business and maintaining relationships with existing clients. I have consistently exceeded sales targets and received positive feedback from clients for my ability to understand their needs and provide solutions. I am excited about the opportunity to bring my skills and experience to Microsoft Corporation and contribute to the company's mission of empowering people and organizations to achieve more. Thank you for considering my application.`
]

export const CL_CLOSING_PARAGRAPH_DEFAULT_TEXT = [
	`I have always admired Apple Inc.'s commitment to creating cutting-edge technology and making a positive impact on the world. The company's mission to design and develop innovative products and services aligns with my own personal values and career goals. I am particularly impressed by the company's focus on sustainability and accessibility, and I would be honored to contribute to these efforts as a member of the team.`,
	`I have always admired Microsoft Corporation's commitment to innovation and its role in shaping the technology industry. The company's mission to empower people and organizations to achieve more aligns with my own personal values and career goals. I am particularly impressed by the company's focus on diversity and inclusion, and I would be honored to contribute to these efforts as a member of the team.`,
	`I have always admired Amazon.com Inc.'s commitment to providing the best customer experience and its role in revolutionizing e-commerce. The company's mission to be Earth's most customer-centric company aligns with my own personal values and career goals. I am particularly impressed by the company's focus on innovation and customer satisfaction, and I would be honored to contribute to these efforts as a member of the team.`,
	`I am particularly impressed by Apple Inc's mission to design and develop products that enrich people's lives. I have always admired the company's dedication to creating cutting-edge technology and the way it is constantly pushing boundaries in the industry. I am passionate about being a part of a team that is making a real impact in the world and I believe that my skills and experience would be a valuable asset to the team. 	 I would be honored to meet with you to discuss my qualifications further and learn more about the position. Please find my resume and references enclosed.`,
	`I am particularly excited about the opportunity to work for a company that is known for its innovative technology and commitment to excellence. Microsoft Corporation's mission to empower every person and every organization on the planet to achieve more aligns with my own values and I am excited about the opportunity to be a part of such a forward-thinking organization. 	 I would appreciate the opportunity to meet with you to discuss my qualifications further and learn more about the position. Please find my resume and references enclosed.`,
	`I am particularly impressed by Amazon.com Inc's mission to be Earth's most customer-centric company. I have always admired the company's dedication to providing the best possible customer service and I am excited about the opportunity to be a part of a team that is dedicated to making a difference in the lives of customers. 	 I would be honored to meet with you to discuss my qualifications further and learn more about the position. Please find my resume and references enclosed.`,
	`I am also impressed with Apple Inc.'s mission and values, particularly its commitment to innovation and customer satisfaction. I am excited by the opportunity to work with a company that is dedicated to creating cutting-edge technology and making a positive impact on the world. Additionally, I have always admired Apple Inc.'s sleek and sophisticated design, which I believe is a reflection of the company's commitment to excellence.`,
	`I am also impressed with Microsoft Corporation's mission and values, particularly its commitment to empowering people and organizations to achieve more. I am excited by the opportunity to work with a company that is dedicated to creating cutting-edge technology and making a positive impact on the world. Additionally, I have always admired Microsoft Corporation's commitment to innovation and collaboration, which I believe is a reflection of the company's commitment to excellence.`,
	`I am a huge admirer of Apple Inc.'s mission and values. I have always been impressed by the company's commitment to innovation and its ability to consistently produce cutting-edge technology. I am particularly drawn to the company's focus on user experience and design. I am excited about the opportunity to work with such a talented team and contribute to the development of products that will change the world. I am also impressed by the company's commitment to sustainability and diversity, which aligns with my own values. I would be honored to have the opportunity to meet with you and discuss how my skills and experience align with the needs of Apple Inc. I am confident that my qualifications make me a strong candidate for the position and I am eager to learn more about the role and the company. Please find my resume and references enclosed.`,
	`I am a huge admirer of Microsoft Corporation's mission and values. I have always been impressed by the company's commitment to innovation and its ability to consistently produce cutting-edge technology. I am particularly drawn to the company's focus on empowering people and organizations to achieve more. I am excited about the opportunity to work with such a talented team and contribute to the development of products that will change the world. I am also impressed by the company's commitment to sustainability and diversity, which aligns with my own values. I would be honored to have the opportunity to meet with you and discuss how my skills and experience align with the needs of Microsoft Corporation. I am confident that my qualifications make me a strong candidate for the position and I am eager to learn more about the role and the company. Please find my resume and references enclosed.`
]

export const CL_SIGNATURE_DEFAULT_TEXT = [
	`Thank you for considering my application. I look forward to the opportunity to discuss my qualifications further and how they align with the needs of Apple Inc. I have included my resume as an enclosure for your reference.`,
	`Thank you for considering my application. I look forward to the opportunity to discuss my qualifications further and how they align with the needs of Microsoft Corporation. I have included my resume as an enclosure for your reference.`,
	`Thank you for considering my application. I look forward to the opportunity to discuss my qualifications further and how they align with the needs of Amazon.com Inc. I have included my resume as an enclosure for your reference.`,
	`Thank you for considering my application. I look forward to hearing from you soon`,
	`Thank you for considering my application. I look forward to hearing from you soon`,
	`Thank you for considering my application. I look forward to hearing from you soon`,
	`Thank you for considering my application. I am eager to meet with you to discuss my qualifications further and am available for an interview at your convenience. Please find my resume and references enclosed for your review. I look forward to hearing from you soon.`,
	`Thank you for considering my application. I am eager to meet with you to discuss my qualifications further and am available for an interview at your convenience. Please find my resume and references enclosed for your review. I look forward to hearing from you soon.`,
	`Thank you for considering my application.`,
	`Thank you for considering my application.`
]

const CL_DEFAULTS = {
	CL_OPENING_PARAGRAPH_DEFAULT_TEXT,
	CL_CLOSING_PARAGRAPH_DEFAULT_TEXT,
	CL_SIGNATURE_DEFAULT_TEXT
}
export const getRandomParagraph = (paragraphType: PARAGRAPH_TYPE) => {
	const defaultArray = CL_DEFAULTS[paragraphType]
	return defaultArray[Math.floor(Math.random() * defaultArray.length)]
}
