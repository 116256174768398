import { Box, Typography } from '@mui/material'
import { motion } from 'framer-motion'
import { FC } from 'react'
import ArrowLeft from '../../assets/JobDetail/ArrowLeft.png'
import JobFields from './JobFields'
import { JobProps } from './types2'
import JobTabs from './JobTabs'
import { ROUTES } from '../../constants/routes'
import { JobStatuses } from '../../constants/jobs'

const Job: FC<JobProps> = ({
	navigate = () => {},
	title,
	container,
	jobLoading,
	progressBtnClickHandler = (index: number) => {},
	isTab,
	editMode,
	updateJobDetails = () => {},
	setEditMode = () => {},
	deleteJob = () => {},
	company,
	setCompany = () => {},
	setTitle = () => {},
	setLocation = () => {},
	moreInfo,
	setMoreInfo = () => {},
	jobError,
	location,
	created,
	jobRef,
	user,
	jobText,
	setJobText = () => {},
	file,
	setFile,
	description,
	setDescription,
	skillsOnly,
	skillsCheckboxHandler,
	percentAvg,
	isMobile,
	addSuggestedSkill,
	percentHard,
	tableHard,
	markIrrelevantSkill,
	percentSoft,
	tableSoft,
	percentSales,
	tableRelated,
	resumeAnalysis,
	topTitles,
	markInaccurateTitle,
	status,
	item,
	resumeMode,
	setResumeMode,
	selectedResume,
	setSelectedResume,
	isMultiResumes,
	resumes,
	handleAddResume,
	isSorted,
	sortAccordingToHardSkill,
	handleDelete,
	setResumeName,
	setResumeTitle,
	setResumeLocation,
	setResumeContact,
	setResumeSalary,
	resumeCandidateName,
	resumeTitle,
	resumeCity,
	resumePhone,
	resumeSalary,
	isDisabled
}) => {
	return (
		<>
			<Box sx={{ display: 'flex' }}>
				<Box
					sx={{
						paddingX: { lg: '40px', sm: '20px', xs: '12px' },
						flex: 'auto'
					}}
				>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							padding: '30px 0 20px 0'
						}}
					>
						<Box
							onClick={() => (isMultiResumes ? navigate(ROUTES.MULTIPLE_RESUMES_JOBS) : navigate(ROUTES.JOBS))}
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								backgroundColor: '#171725',
								boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.02);',
								borderRadius: '50%',
								cursor: 'pointer',
								width: { lg: '46px', sm: '35px', xs: '30px' },
								height: { lg: '46px', sm: '35px', xs: '30px' }
							}}
						>
							<Box component="img" src={ArrowLeft} />
						</Box>
						<Typography
							variant="h2"
							sx={{
								fontWeight: '600',
								fontSize: { lg: '24px', md: '20px', xs: '17px' },
								lineHeight: '36px',
								letterSpacing: '0.1px',
								marginLeft: { sm: '18px', xs: '12px' },
								color: '#171725'
							}}
						>
							Job Detail {status === 'draft' && '/ Draft'}
						</Typography>
					</Box>

					<motion.div variants={container} initial="hidden" animate="show">
						<Box component={motion.div} variants={item}>
							<Box
								sx={{
									height: { lg: '92px', xs: '70px' },
									backgroundColor: 'white',
									borderRadius: '20px',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'space-between'
								}}
							>
								<Box
									sx={{
										display: 'flex',
										alignItems: 'center'
									}}
								>
									<Typography
										component="p"
										sx={{
											color: '#92929D',
											fontWeight: '400',
											fontSize: {
												lg: '14px',
												xs: '12px'
											},
											lineHeight: '17px',
											letterSpacing: '0.1px',
											marginLeft: {
												lg: '41px',
												xs: '25px'
											},
											marginRight: {
												lg: '22px',
												xs: '15px'
											}
										}}
									>
										Status
									</Typography>
									<Box
										sx={{
											padding: {
												lg: '8px 17px',
												xs: '5px 12px'
											},
											fontSize: {
												lg: '14px',
												xs: '12px'
											},
											fontWeight: '500',
											lineHeight: '21px',
											borderRadius: '10px',
											background: JobStatuses[status].backgroundColor,
											color: JobStatuses[status].textColor
										}}
									>
										{jobLoading ? <>Loading...</> : <>{JobStatuses[status].text}</>}
									</Box>
								</Box>
								<JobTabs
									isTab={isTab}
									status={status}
									progressBtnClickHandler={() => progressBtnClickHandler(0)}
									jobLoading={jobLoading}
									editMode={editMode}
									updateJobDetails={updateJobDetails}
									setEditMode={setEditMode}
									deleteJob={deleteJob}
									isDisabled={isDisabled}
								/>
							</Box>
							<Box sx={{ paddingBottom: '20px', width: '100%' }}>
								<JobFields
									handleDelete={handleDelete}
									handleAddResume={handleAddResume}
									isMultiResumes={isMultiResumes}
									resumes={resumes}
									resumeMode={resumeMode}
									setResumeMode={setResumeMode}
									editMode={editMode}
									setTitle={setTitle}
									title={title}
									setCompany={setCompany}
									company={company}
									jobLoading={jobLoading}
									setLocation={setLocation}
									moreInfo={moreInfo}
									setMoreInfo={setMoreInfo}
									location={location}
									jobError={jobError}
									created={created}
									jobRef={jobRef}
									user={user}
									jobText={jobText}
									setJobText={setJobText}
									file={file}
									setFile={setFile}
									description={description}
									setDescription={setDescription}
									skillsOnly={skillsOnly}
									skillsCheckboxHandler={skillsCheckboxHandler}
									percentAvg={percentAvg}
									isMobile={isMobile}
									addSuggestedSkill={addSuggestedSkill}
									percentHard={percentHard}
									tableHard={tableHard}
									markIrrelevantSkill={markIrrelevantSkill}
									percentSoft={percentSoft}
									tableSoft={tableSoft}
									percentSales={percentSales}
									tableRelated={tableRelated}
									resumeAnalysis={resumeAnalysis}
									topTitles={topTitles}
									markInaccurateTitle={markInaccurateTitle}
									setSelectedResume={setSelectedResume}
									selectedResume={selectedResume}
									isSorted={isSorted}
									sortAccordingToHardSkill={sortAccordingToHardSkill}
									setResumeName={setResumeName}
									setResumeTitle={setResumeTitle}
									setResumeLocation={setResumeLocation}
									setResumeContact={setResumeContact}
									setResumeSalary={setResumeSalary}
									resumeCandidateName={resumeCandidateName}
									resumeTitle={resumeTitle}
									resumeCity={resumeCity}
									resumePhone={resumePhone}
									resumeSalary={resumeSalary}
								/>
							</Box>
						</Box>
					</motion.div>
				</Box>
			</Box>
		</>
	)
}

export default Job
