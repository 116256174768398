import { Typography } from '@mui/material'
import { FC } from 'react'

const MobileJobSection2JobMatchTitle: FC<{}> = () => {
	return (
		<Typography
			component="span"
			sx={{
				fontWeight: '700',
				fontSize: '11px',
				marginBottom: { sm: '11px', xs: '5px' },
				marginTop: { sm: '18px', xs: '10px' }
			}}
		>
			We found these top job titles for that match your job description
		</Typography>
	)
}

export default MobileJobSection2JobMatchTitle
