import { Box, List, ListItem, ListItemText } from '@mui/material'
import { Dispatch, SetStateAction, FC } from 'react'
import { RESUME_FORM_FIELDS } from '../../../../constants/resume'
import { motion } from 'framer-motion'
import theme from '../../../../theme'

interface Props {
	currentField: string
	setCurrentField: Dispatch<SetStateAction<string>>
	isMobile: boolean
	handleDrawerClose?: () => void
}

const CategoryList: FC<Props> = ({ currentField, setCurrentField, isMobile, handleDrawerClose }) => {
	const handleClick = (key: string) => {
		setCurrentField(key)
		if (handleDrawerClose) {
			handleDrawerClose()
		}
	}

	return (
		<List
			sx={{
				width: '250px',
				marginTop: '20px'
			}}
		>
			{RESUME_FORM_FIELDS.map((field, idx) => (
				<ListItem key={field.key} disablePadding={!isMobile} sx={{ position: 'relative', cursor: 'pointer' }} onClick={() => handleClick(field.key)}>
					<Box
						sx={{
							width: currentField === field.key ? '10px' : '5px',
							height: currentField === field.key ? '10px' : '5px',
							backgroundColor: currentField === field.key ? theme.palette.primary.main : 'grey',
							borderRadius: '50%',
							marginRight: '15px'
						}}
					/>
					<ListItemText
						primary={field.name}
						primaryTypographyProps={{
							fontWeight: currentField === field.key ? 700 : 400
						}}
					/>
					{currentField === field.key ? (
						<motion.div
							style={{
								position: 'absolute',
								top: '-1px',
								left: '-5px',
								right: '-1px',
								bottom: '-1px',
								backgroundColor: '#098edb56',
								borderRadius: '5px',
								marginRight: '5px'
							}}
							layoutId="backgroundAnimate"
						></motion.div>
					) : null}
				</ListItem>
			))}
		</List>
	)
}

export default CategoryList
