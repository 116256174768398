import { Timestamp } from 'firebase/firestore'
import { Experience } from './Experience'
import { Education } from './Education'

export interface ResumeType {
	resumeName: string
	fullName: string
	city?: string
	country?: string
	title: string
	email: string
	phone: string
	summary: string
	skills: string[]
	experience: Experience[]
	id?: string
	created?: Timestamp
	education?: Education[]
}

export const defaultResume = {
	groupId: null,
	uid: '',
	email: '',
	displayName: '',
	title: '',
	company: '',
	location: '',
	description: '',
	moreInfo: '',
	created: '',
	lastMod: '',
	status: '',
	resume: '',
	top_titles: [],
	table_hard: [],
	table_soft: [],
	table_related: [],
	irrelevant_words: [],
	inaccurate_titles: [],
	table_hard_suggested: [],
	table_soft_suggested: [],
	sharedWith: [],
	resume_analysis: {},
	percentage_hard: 0.0,
	percentage_soft: 0.0,
	percentage_sales: 0.0,
	skillsOnly: [],
	selectedResume: '',
	resumeMode: ''
}
