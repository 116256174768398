import { Button } from '@mui/material'
import { FC, SyntheticEvent, useRef } from 'react'

const FileUpload: FC<{
	title: string
	size: number
	mimeTypes: string[]
	onValidationError: (message: string) => void
	sizeErrorMessage: string
	mimeTypesErrorMessage: string
	onFileChange: (file: File) => void
	file?: File
	width?: string
}> = ({ title, size, mimeTypes, onValidationError, sizeErrorMessage, mimeTypesErrorMessage, onFileChange, file, width }) => {
	const fileUploadRef = useRef<HTMLInputElement | null>(null)
	const handleClick = () => fileUploadRef.current?.click()

	const handleFileUpload = (e: SyntheticEvent<HTMLInputElement>) => {
		if (e.currentTarget.files && e.currentTarget.files?.length) {
			const file = e.currentTarget.files[0]
			if (file) {
				if (file.size <= size) {
					if (mimeTypes.includes(file.type)) {
						onFileChange(file)
						return
					}
					if (onValidationError) onValidationError(mimeTypesErrorMessage)
					return
				}
				if (onValidationError) onValidationError(sizeErrorMessage)
			}
		}
	}

	return (
		<div>
			<input
				style={{
					display: 'none'
				}}
				ref={(ref) => (fileUploadRef.current = ref)}
				type="file"
				onChange={handleFileUpload}
			/>
			<Button
				sx={{
					marginTop: '10px',
					marginBottom: '10px',
					textTransform: 'none',
					borderRadius: '12px',
					marginLeft: '5px',
					padding: { md: '10px 24px;', sm: '10px 16px;', sx: '8px 16px' },
					fontSize: { md: '18px', xs: '16px' },
					fontWeight: '600',
					lineHeight: { sm: '27px', xs: '24px' },
					letterSpacing: '2%',
					width: { xs: width ?? '50%', sm: 'unset' }
				}}
				variant="contained"
				color="primary"
				onClick={handleClick}
			>
				{title}
			</Button>
			&nbsp;
			<label>{file?.name}</label>
		</div>
	)
}

export default FileUpload
