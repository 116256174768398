import { addDoc, collection, doc, getDoc } from 'firebase/firestore'
import { emailTemplate } from '../constants/emailTemplate'
import { auth, db } from '../firebase'
import { COLLECTION_NAMES } from '../constants/collections'

export const isValidEmail = (email: string): boolean => {
	return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)
}

export const sendShareEmail = async (email: string, subject: string, message: string): Promise<void> => {
	let link = await getReferralLink()
	if (link === '') {
		link = window.location.href
	} else {
		link += `&callback=${encodeURIComponent(window.location.pathname)}`
	}
	console.log(link)

	let html = emailTemplate.replace('{link}', link)
	html = html.replace('{message}', message.length !== 0 ? message : 'You have been invited to edit a job at Gambit Resume.')
	const colRef = collection(db, COLLECTION_NAMES.MAIL_COLLECTION)
	await addDoc(colRef, {
		to: email,
		message: {
			subject: subject.length !== 0 ? subject : 'Job edit invitation!',
			html
		}
	})
}

export const getReferralLink = async (): Promise<string> => {
	let link = ''

	if (!auth.currentUser) return link

	link = (await getDoc(doc(db, COLLECTION_NAMES.USERS_COLLECTION, auth.currentUser.uid))).data()?.referralUrl ?? ''

	return link
}
