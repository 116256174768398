import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Container, useMediaQuery } from '@mui/material'
import desktopBanner from '../../assets/Login/desktop-banner.jpg'
import mobileBanner from '../../assets/Login/mobile-banner.jpg'
import { AnonymousJobsCTX } from '../../contexts/anonymousJobs'
import { signInAnonymous } from '../../utils/authentication'
import theme from '../../theme'
import WelcomeText from '../../components/WelcomeScreen/WelcomeScreen'
import { ROUTES } from '../../constants/routes'
import FixedLoader from '../../components/CustomLoader/FixedLoader'
import { auth } from '../../firebase'
import { useAuthState } from 'react-firebase-hooks/auth'

const Welcome = () => {
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
	const { setHasAnonymousJobs, setAnonymousJobs, loading: loadingForAnonymousUserCreation, setLoading: setLoadingForAnonymousUserCreation } = useContext(AnonymousJobsCTX)
	const navigate = useNavigate()
	const [user, userLoading] = useAuthState(auth)

	const handleAnonymousSignIn = () => {
		setLoadingForAnonymousUserCreation?.(true)
		if (setHasAnonymousJobs && setAnonymousJobs) {
			signInAnonymous(setHasAnonymousJobs, setAnonymousJobs, () => {
				navigate(ROUTES.OVERVIEW, { replace: true })
				setLoadingForAnonymousUserCreation?.(false)
			})
		}
	}

	useEffect(() => {
		if (userLoading) return
		if (user !== null && !loadingForAnonymousUserCreation) {
			navigate(ROUTES.OVERVIEW, { replace: true })
		}
	}, [user, userLoading])

	return (
		<Box sx={{ position: 'relative' }}>
			{loadingForAnonymousUserCreation ? <FixedLoader /> : null}
			{isMobile ? (
				<Box>
					<Box sx={{ display: 'flex', height: '100vh' }}>
						<Box sx={{ position: 'fixed', width: '100vw', height: '100vh' }}>
							<img height="100%" width="100%" src={mobileBanner} />
						</Box>
						<WelcomeText handleAnonymousSignIn={handleAnonymousSignIn} />
					</Box>
				</Box>
			) : (
				<>
					<Container sx={{ display: 'flex' }}>
						<WelcomeText handleAnonymousSignIn={handleAnonymousSignIn} />
						<Box sx={{ width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
							<img style={{ width: '95%', borderRadius: '10px', maxHeight: '90%' }} src={desktopBanner} />
						</Box>
					</Container>
				</>
			)}
		</Box>
	)
}

export default Welcome
