import { Box, Container, Typography } from '@mui/material'
import { FC, useEffect } from 'react'
import usePageTitle from '../../hooks/usePageTitle'

const AboutUs: FC<{}> = () => {
	const [setPageTitle] = usePageTitle()

	useEffect(() => {
		setPageTitle('About Us')
	}, [])

	return (
		<>
			<Container
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					padding: '20px'
				}}
			>
				<Box marginY="20px">
					<Typography component="h2" fontSize={{ md: '42px', xs: '30px' }}>
						About Us
					</Typography>
				</Box>
				<Box padding="20px">
					<Typography marginY="10px" component="p" fontSize="18px">
						GambitResume (GR) is the ultimate gig economy tool for job seekers and hiring managers alike. Think of GR as the greyhound of the gig economy – fast, agile, and always one step
						ahead of the competition. We understand that flexibility is key in today's job market, which is why we are dedicated to helping individuals find work that fits their lifestyle.
						Whether you're looking for a part-time gig, a flexible schedule, or a remote position, GR has you covered.
					</Typography>
					<br />
					<Typography marginY="10px" component="p" fontSize="18px">
						We also offer a variety of tools and resources to help users through the interview process, whether you're the interviewer, the interviewee, interview coach or an agency
						looking to find the best candidates. Our resume writing and scanning tools give you 10x speed than traditional methods, ensuring that your resume will pass most applicant
						tracking systems (ATS). With GR, you can focus on finding the perfect job without worrying about the technicalities of the application process. Join us today and see the
						difference GambitResume can make in your job search.
					</Typography>
				</Box>
			</Container>
		</>
	)
}

export default AboutUs
