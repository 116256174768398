enum ScreenWidths {
	ExtraExtraLargeDesktops = 2300,
	ExtraLargeDesktops = 1900,
	LargeDesktops = 1440,
	SmallDesktops = 1200,
	LargeLaptops = 1024,
	SmallLaptops = 992,
	Tablets = 768,
	LargeMobiles = 576,
	MediumMobiles = 425,
	SmallMobiles = 375
}

export const getResponsiveNoOfSlides = () => {
	const screenWidth = window.document.body.clientWidth || 0
	let noOfSlidesForBlogs = 0
	if (screenWidth <= ScreenWidths.SmallMobiles) {
		noOfSlidesForBlogs = 1
	} else if (screenWidth <= ScreenWidths.MediumMobiles) {
		noOfSlidesForBlogs = 1
	} else if (screenWidth <= ScreenWidths.LargeMobiles) {
		noOfSlidesForBlogs = 1
	} else if (screenWidth <= ScreenWidths.Tablets) {
		noOfSlidesForBlogs = 2
	} else if (screenWidth <= ScreenWidths.SmallLaptops) {
		noOfSlidesForBlogs = 2
	} else if (screenWidth <= ScreenWidths.LargeLaptops) {
		noOfSlidesForBlogs = 2
	} else if (screenWidth <= ScreenWidths.SmallDesktops) {
		noOfSlidesForBlogs = 3
	} else if (screenWidth <= ScreenWidths.LargeDesktops) {
		noOfSlidesForBlogs = 3
	} else if (screenWidth <= ScreenWidths.ExtraLargeDesktops) {
		noOfSlidesForBlogs = 3
	} else if (screenWidth <= ScreenWidths.ExtraExtraLargeDesktops) {
		noOfSlidesForBlogs = 3
	} else {
		noOfSlidesForBlogs = 3
	}
	return { noOfSlidesForBlogs }
}
