import { Box, Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { FC, useEffect } from 'react'
import usePageTitle from '../../hooks/usePageTitle'
import { AlternativesFeatureComparison } from '../../constants'

const Alternatives: FC<{}> = () => {
	const [setPageTitle] = usePageTitle()

	useEffect(() => {
		setPageTitle('Looking for JobScan.Co Alternative, Try GambitResume', '', false)
	}, [])

	return (
		<>
			<Container
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					paddingTop: '20px'
				}}
			>
				<Box marginTop={{ sx: '20px', sm: '50px' }}>
					<Typography component="h1" fontSize={{ lg: '60px', md: '45px', xs: '30px' }} textAlign="center" maxWidth="900px">
						Looking for JobScan.Co Alternatives?
					</Typography>
				</Box>
				<Box maxWidth="640px" textAlign="center" margin="30px 0">
					<Typography component="p">
						Tired of overpaying for complex features you don't need? Find your new home at GambitResume — a straightforward ATS that scans your resume so that you edit it 10x faster. Join
						today and get 50% off your three months subscription.
					</Typography>
				</Box>
				<Box
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						textAlign: 'center',
						paddingTop: '50px',
						width: '100%',
						maxWidth: '720px'
					}}
				>
					<Typography component="h2" fontSize={{ md: '42px', xs: '30px' }} marginBottom="50px">
						Feature comparison
					</Typography>
					<TableContainer>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell
										style={{
											width: '96%',
											padding: '16px 25px'
										}}
									>
										Features
									</TableCell>
									<TableCell
										style={{
											width: '2%',
											whiteSpace: 'nowrap',
											padding: '16px 25px'
										}}
									>
										GambitResume
									</TableCell>
									<TableCell
										style={{
											width: '2%',
											whiteSpace: 'nowrap',
											padding: '16px 25px'
										}}
									>
										JobScan.Co
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{AlternativesFeatureComparison.map(({ name, offeredByGR, offeredByJS }) => (
									<TableRow key={name}>
										<TableCell
											sx={{
												padding: '16px 25px'
											}}
										>
											{name}
										</TableCell>
										<TableCell
											sx={{
												padding: '16px 25px',
												textAlign: 'center',
												color: offeredByGR.color
											}}
										>
											{offeredByGR.title}
										</TableCell>
										<TableCell
											sx={{
												padding: '16px 25px',
												textAlign: 'center',
												color: offeredByJS.color
											}}
										>
											{offeredByJS.title}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
					<Box margin="50px 0">
						<Typography component="p">
							<b>Our conclusion</b>. JobScan.Co is a great all-in-one tool that does many things at once: Resume ATS Scanning, Resume Building, etc. While GambitResume is focused on
							Light Weight High Speed ATS Scanning and Building Tool to Give Users the Advantage of 10x speed building Bespoke resumes.
						</Typography>
					</Box>
				</Box>
			</Container>
		</>
	)
}

export default Alternatives
