import { Link, Typography } from '@mui/material'
import { FC } from 'react'

const FilenameView: FC<{
	fileName: string
	handleRemove: () => void
}> = ({ fileName, handleRemove }) => {
	return (
		<div>
			<Typography
				component="span"
				sx={{
					color: '#171725',
					fontWeight: { sm: '400', xs: '500' },
					fontSize: { sm: '16px', xs: '12px' },
					lineHeight: { sm: '27px', xs: '18px' },
					marginBottom: { sm: '16px', xs: '10px' },
					marginLeft: { sm: '16px', xs: '10px' }
				}}
			>
				{fileName}
			</Typography>
			<Link
				sx={{
					textDecoration: 'none',
					marginLeft: {
						sm: '5px',
						xs: '2px'
					},
					fontSize: { sm: '12px', xs: '9px' },
					':hover': {
						cursor: 'pointer'
					}
				}}
				onClick={handleRemove}
			>
				&#10060;
			</Link>
		</div>
	)
}

export default FilenameView
