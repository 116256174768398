import React, { Dispatch, FC, useEffect, useRef, useState } from 'react'
import AdminHideShowIcon from './AdminHideShowIcon'
import { UserData, EventObj, MessageDataObj } from './adminChatModals'
import { DocumentData } from 'firebase/firestore'
import ImageIcon from '@mui/icons-material/Image'
import { ScrollElement } from './adminChatModals'
import { useMediaQuery } from '@mui/material'
import theme from '../../theme'
import LoadMore from './LoadMore'
interface AdminUserListProps {
	isSelectedUser: boolean
	hideUserList: boolean
	setHideUserList: Dispatch<boolean> | Dispatch<(arg: boolean) => boolean>
	userList: UserData[] | DocumentData[]
	loadMessages?: (isAdmin?: boolean) => void
	setIsSelectedUser: Dispatch<boolean>
	setSelectedUser: (user: DocumentData) => void
	selectedUser: DocumentData | null
	message: string
	messages: DocumentData[] | MessageDataObj[]
	setEnableSendButton: Dispatch<React.SetStateAction<boolean>>
	setMessage: Dispatch<string>
	enableSendButton: boolean
	sendClickHandler: () => {}
	sendButtonText: string
	onMediaFileSelected: (event: EventObj) => void
	newMessageArrived: {
		status: boolean
		to: string
	}
	onBottom: () => void
	showLoadMore: boolean
	onLoadMore: () => void
	notOnTop: () => void
	onTop: () => void
	onUserTop: () => void
	userNotOnTop: () => void
	onUserBottom: () => void
	showUserLoadMore: boolean
	onUserLoadMore: () => void
	setUserList: (call: (a: UserData[] | DocumentData[]) => UserData[] | DocumentData[]) => void
}

const AdminUserList: FC<AdminUserListProps> = ({
	hideUserList,
	setHideUserList,
	userList,
	messages,
	setIsSelectedUser,
	setSelectedUser,
	isSelectedUser,
	selectedUser,
	message,
	setEnableSendButton,
	setMessage,
	enableSendButton,
	sendClickHandler,
	sendButtonText,
	onMediaFileSelected,
	newMessageArrived,
	onBottom,
	showLoadMore,
	onLoadMore,
	notOnTop,
	onTop,
	onUserTop,
	userNotOnTop,
	onUserBottom,
	showUserLoadMore,
	onUserLoadMore,
	setUserList
}) => {
	const messageListRef = useRef<null | HTMLDivElement>(null)
	const userMessageListRef = useRef<null | HTMLUListElement>(null)
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
	const [selectedUserState, setSelectedUserState] = useState<DocumentData | null>(selectedUser)
	useEffect(() => {
		setSelectedUserState(selectedUser)
		if (selectedUser)
			setTimeout(() => {
				if (messageListRef && messageListRef.current) messageListRef?.current?.scrollTo(0, messageListRef.current.scrollHeight)
			}, 1000)
	}, [selectedUser])

	useEffect(() => {
		if (messageListRef.current) {
			messageListRef.current.addEventListener('scroll', (e) => {
				const element: ScrollElement = e.target as unknown as ScrollElement
				if (element.scrollTop === 0 && onTop) {
					onTop()
				}

				if (element.scrollTop !== 0 && notOnTop) {
					notOnTop()
				}
				if (Math.ceil(element.scrollHeight - element.scrollTop) === element.clientHeight && onBottom) {
					onBottom()
				}
			})
		}
	}, [messageListRef.current])

	useEffect(() => {
		if (userMessageListRef.current) {
			userMessageListRef.current.addEventListener('scroll', (e) => {
				const element: ScrollElement = e.target as unknown as ScrollElement
				if (element.scrollTop === 0 && onUserTop) {
					onUserTop()
				}

				if (element.scrollTop !== 0 && userNotOnTop) {
					userNotOnTop()
				}
				if (Math.ceil(element.scrollHeight - element.scrollTop) === element.clientHeight && onUserBottom) {
					onUserBottom()
				}
			})
		}
	}, [userMessageListRef.current])

	return (
		<>
			<div
				className="admin-chat"
				style={{
					bottom: hideUserList ? '-400px' : '0px',
					right: isMobile ? 'calc((100% - 360px) / 2)' : '0px'
				}}
			>
				<div className="top-bar">
					<span> Users Messages </span>
					<span
						style={{
							cursor: 'pointer'
						}}
						onClick={() => {
							if (setHideUserList) {
								;(setHideUserList as Dispatch<(arg: boolean) => boolean>)((hideUserList: boolean) => {
									return !hideUserList
								})
							}
						}}
						className="close"
					>
						<AdminHideShowIcon hideUserList={hideUserList} />
					</span>
				</div>
				<div className="user-list-container">
					<ul
						ref={(ref) => (userMessageListRef.current = ref)}
						className="user-list"
						style={{
							visibility: hideUserList && isMobile ? 'hidden' : 'inherit'
						}}
					>
						{userList.map((data: DocumentData) => {
							// const userData = data
							const displayName = data.name ? data.name : 'Anonymous'

							return (
								<>
									<li
										onClick={() => {
											setSelectedUser(data)
											setIsSelectedUser(true)
										}}
									>
										<div
											className="message-title"
											style={{
												fontWeight: selectedUserState && selectedUserState.uid === data.uid ? 'bold' : 'inherit',
												color: selectedUserState && selectedUserState.uid === data.uid ? '#000' : 'inherit'
											}}
										>
											{displayName}
											{data.newMessage && data.newMessageTo === 'admin' && (
												<span
													style={{
														display: 'inline-block',
														width: '10px',
														height: '10px',
														background: 'rgb(255,0,0)',
														borderRadius: '50%',
														position: 'relative',
														bottom: '5px',
														right: '2px'
													}}
												></span>
											)}
										</div>
									</li>
								</>
							)
						})}
					</ul>
					{showUserLoadMore ? <LoadMore onUserLoadMoreHandler={onUserLoadMore} /> : null}
				</div>
			</div>
			{isSelectedUser ? (
				<>
					<div
						className="admin-chat"
						style={{
							right: isMobile ? 'calc((100% - 360px) / 2)' : '390px',
							bottom: '0px'
						}}
					>
						<div className="top-bar">
							<div>
								{selectedUserState?.name ?? 'Anonymous'}
								{newMessageArrived.status && newMessageArrived.to === 'admin' && (
									<span
										style={{
											display: 'inline-block',
											width: '10px',
											height: '10px',
											background: 'rgb(255,0,0)',
											borderRadius: '50%',
											position: 'relative',
											bottom: '5px',
											right: '5px'
										}}
									></span>
								)}
							</div>
							<span
								style={{
									cursor: 'pointer'
								}}
								onClick={() => {
									setSelectedUserState(null)
									setIsSelectedUser(false)
								}}
								className="close"
							>
								&#10060;
							</span>
						</div>
						<div className="message-container">
							<div className="messages" ref={(ref) => (messageListRef.current = ref)}>
								{messages.map((msg: MessageDataObj | DocumentData) => {
									return (
										<>
											<div className="message-item">
												{msg?.text ? <div className="message-title"> {msg?.text} </div> : null}
												{msg.imageUrl && !msg.storageUri ? <img src={msg.imageUrl} style={{ width: 'auto' }} /> : null}
												{msg.imageUrl && msg.storageUri ? <img src={msg.imageUrl} style={{ width: '200px' }} /> : null}
												<div className="message-name">{msg?.from === 'admin' ? 'You' : 'User'}</div>
											</div>
										</>
									)
								})}
							</div>
							{showLoadMore ? (
								<div className="load-more" onClick={onLoadMore}>
									Load More
								</div>
							) : null}
						</div>
						<div className="bottom-bar">
							<input
								value={message}
								onKeyDown={(e) => {
									if (e.key === 'Enter' && message && message.trim() && enableSendButton) {
										sendClickHandler()
									}
								}}
								onChange={(e) => {
									if (!e.target.value || !e.target.value.trim()) {
										setEnableSendButton(false)
									} else {
										setEnableSendButton(true)
									}
									setMessage(e.target.value)
								}}
								type="text"
								className="message-input-box"
								placeholder="message..."
							/>
							<button disabled={!enableSendButton} onClick={sendClickHandler} className="message-send-btn">
								{sendButtonText}
							</button>
							<span
								onClick={() => {
									const fileUpload: HTMLInputElement = document.querySelector('.file-upload') as HTMLInputElement
									if (fileUpload) {
										fileUpload?.click()
									}
								}}
								className={'message-upload-file-btn'}
							>
								<ImageIcon />
								<input type="file" onChange={(event) => onMediaFileSelected(event as unknown as EventObj)} className="file-upload" style={{ display: 'none' }} />
							</span>
						</div>
					</div>
				</>
			) : null}
		</>
	)
}

export default AdminUserList
