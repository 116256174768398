import { FC } from 'react'
interface Props {
	onUserLoadMoreHandler: () => void
}
const LoadMore: FC<Props> = ({ onUserLoadMoreHandler }) => {
	return (
		<div className="load-more" onClick={onUserLoadMoreHandler}>
			Load More
		</div>
	)
}

export default LoadMore
