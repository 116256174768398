import { Box, Checkbox, FormControlLabel } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { JobFieldsProps } from './types'
import JobFieldsSections2 from './JobFieldsSections2'
import JobFieldsSections1 from './JobFieldsSections1'
import { RowType } from '../JobAnalysisTable/jobType'
import { ResumeAnalysis } from '../../models/ResumeAnalysis'
import { Job } from '../../models/Job'
import Typography from '@mui/material/Typography'
import ResumesMatchingJobTitles from './ResumesMatchingJobTitles'
import JobFieldSection1Edit from './JobFieldSection1Edit'
import ResumeFields from '../ResumeFields'
import JobSummeryTable from './JobSummeryTable'
import UploadResumes from './UploadResumes'
const JobFields: FC<JobFieldsProps> = ({
	editMode,
	setTitle,
	title,
	setCompany = () => {},
	company,
	jobLoading,
	setLocation,
	moreInfo,
	setMoreInfo = () => {},
	location,
	jobError,
	created,
	jobRef,
	user,
	jobText,
	setJobText,
	file,
	setFile,
	description,
	setDescription,
	skillsOnly,
	skillsCheckboxHandler,
	percentAvg,
	isMobile,
	addSuggestedSkill,
	percentHard,
	tableHard,
	markIrrelevantSkill,
	percentSoft,
	tableSoft,
	percentSales,
	tableRelated,
	resumeAnalysis,
	topTitles,
	markInaccurateTitle,
	resumeMode,
	setResumeMode,
	selectedResume,
	setSelectedResume,
	isMultiResumes,
	resumes,
	handleAddResume,
	isSorted,
	sortAccordingToHardSkill,
	handleDelete,
	setResumeName,
	setResumeTitle,
	setResumeLocation,
	setResumeContact,
	setResumeSalary,
	resumeCandidateName,
	resumeTitle,
	resumeCity,
	resumePhone,
	resumeSalary
}) => {
	const [viewMetrixStatus, setViewMetrixStatus] = useState<{ data: Job; index: number } | null>(null)
	useEffect(() => {
		if (viewMetrixStatus) setViewMetrixStatus(resumes ? { data: resumes[viewMetrixStatus.index], index: viewMetrixStatus.index } : null)
	}, [viewMetrixStatus])
	return (
		<Box
			sx={{
				backgroundColor: 'white',
				borderRadius: '20px',
				marginTop: '19px',
				paddingLeft: { sm: '40px', xs: '20px' },
				paddingRight: { sm: '50px', xs: '25px' },
				paddingBottom: { sm: '40px', xs: '20px' },
				width: '100%'
			}}
		>
			{isMultiResumes && resumes?.length ? (
				<JobFieldsSections1
					showMoreDetails={false}
					handleDelete={handleDelete}
					isMultiResumes={true}
					resumeIndex={0}
					resumeMode={resumes[0].resumeMode}
					setResumeMode={setResumeMode}
					editMode={editMode}
					setTitle={setTitle}
					setCompany={setCompany}
					setLocation={setLocation}
					moreInfo={resumes[0].moreInfo}
					setMoreInfo={setMoreInfo}
					jobError={jobError}
					jobRef={jobRef}
					title={resumes[0].title}
					user={user}
					jobText={resumes[0].resume}
					setDescription={setDescription}
					description={resumes[0].description}
					jobLoading={jobLoading}
					company={resumes[0].company}
					location={resumes[0].location}
					file={resumes[0]?.file as File}
					setFile={setFile}
					setJobText={setJobText}
					created={resumes[0].created}
					setSelectedResume={setSelectedResume}
					selectedResume={resumes[0].selectedResume}
					newResume={resumes[0].newResume}
					resumeCandidateName={resumes[0].resumeCandidateName}
					resumeTitle={resumes[0].resumeTitle}
					resumePhone={resumes[0].resumePhone}
					resumeCity={resumes[0].resumeCity}
					resumeEmail={resumes[0].resumeEmail}
				/>
			) : null}
			{!editMode && isMultiResumes ? (
				<>
					<FormControlLabel
						control={<Checkbox checked={isSorted} onChange={(e) => sortAccordingToHardSkill && sortAccordingToHardSkill(e)} inputProps={{ 'aria-label': 'controlled' }} />}
						label={<Typography sx={{ fontWeight: 'bold' }}>Calculate Fit Score Based Only on Hard Skills</Typography>}
					/>
					<Typography variant="subtitle2" sx={{ fontStyle: 'oblique' }} paragraph>
						When enabled, this checkbox allows the resume scanner to assess candidates exclusively on their hard skills, disregarding soft skills, buzzwords, and other factors.
					</Typography>
				</>
			) : null}
			{isMultiResumes && resumes && resumes?.length > 0 ? (
				<JobSummeryTable
					isMultiResumes={isMultiResumes}
					viewMetrixStatus={viewMetrixStatus}
					editMode={editMode}
					resumes={resumes}
					handleDelete={handleDelete}
					setJobText={setJobText}
					file={file}
					setFile={setFile}
					setDescription={setDescription}
					resumeMode={resumeMode}
					setResumeMode={setResumeMode}
					setSelectedResume={setSelectedResume}
					selectedResume={selectedResume}
					setViewMetrixStatus={setViewMetrixStatus}
					isSorted={isSorted}
					sortAccordingToHardSkill={sortAccordingToHardSkill}
					skillsCheckboxHandler={skillsCheckboxHandler}
					addSuggestedSkill={addSuggestedSkill}
					markInaccurateTitle={markInaccurateTitle}
					isMobile={isMobile}
					markIrrelevantSkill={markIrrelevantSkill}
					setTitle={setTitle}
					setCompany={setCompany}
					setLocation={setLocation}
					setMoreInfo={setMoreInfo}
					jobError={jobError}
					jobRef={jobRef}
					user={user}
					jobLoading={jobLoading}
				/>
			) : null}

			{!editMode && isMultiResumes && resumes?.length ? (
				<ResumesMatchingJobTitles
					rows={resumes.reduce<string[]>((prev, next): string[] => [...prev, ...next.top_titles.filter((title) => !prev.includes(title))], [])}
					markInaccurateTitle={(title) => {
						markInaccurateTitle(0, title)
					}}
				/>
			) : null}
			{editMode && isMultiResumes ? (
				<UploadResumes
					handleChange={(e) => {
						if (e.target.files && e.target.files.length > 0) {
							for (let i = 0; i < e.target.files.length; i++) {
								handleAddResume && handleAddResume(e.target.files[i])
							}
							alert(`${e.target.files.length} resume(s) added.`)
						}
						e.target.value = ''
					}}
				/>
			) : null}
			{editMode && isMultiResumes && (
				<JobFieldSection1Edit
					setResumeName={setResumeName}
					setResumeTitle={setResumeTitle}
					setResumeLocation={setResumeLocation}
					setResumeContact={setResumeContact}
					setResumeSalary={setResumeSalary}
					resumes={resumes}
					handleDelete={handleDelete}
					setResumeMode={setResumeMode}
					jobError={jobError}
					jobRef={jobRef}
					jobLoading={jobLoading}
					setFile={setFile}
					setSelectedResume={setSelectedResume}
					setJobText={setJobText}
				/>
			)}

			{!isMultiResumes && (
				<>
					<JobFieldsSections1
						resumeIndex={0}
						isMultiResumes={false}
						resumeMode={resumeMode}
						setResumeMode={setResumeMode}
						editMode={editMode}
						setTitle={setTitle}
						setCompany={setCompany}
						setLocation={setLocation}
						moreInfo={moreInfo}
						setMoreInfo={setMoreInfo}
						jobError={jobError}
						jobRef={jobRef}
						title={title}
						user={user}
						jobText={jobText as string}
						setDescription={setDescription}
						description={description}
						jobLoading={jobLoading}
						company={company}
						location={location}
						file={file}
						setFile={setFile}
						setJobText={setJobText}
						created={created}
						setSelectedResume={setSelectedResume}
						selectedResume={selectedResume}
						showMoreDetails={true}
						showBasicDetails={true}
					/>
					{editMode && !isMultiResumes ? (
						<ResumeFields
							index={0}
							name={resumeCandidateName ?? ''}
							title={resumeTitle ?? ''}
							location={resumeCity ?? ''}
							contact={resumePhone ?? ''}
							salary={resumeSalary ?? ''}
							setName={setResumeName}
							setTitle={setResumeTitle}
							setLocation={setResumeLocation}
							setContact={setResumeContact}
							setSalary={setResumeSalary}
							resumeText={jobText ?? ''}
							isEdit
						/>
					) : null}
					<JobFieldsSections2
						resumeIndex={0}
						isMultiResumes={false}
						skillsCheckboxHandler={skillsCheckboxHandler}
						percentAvg={percentAvg}
						percentHard={percentHard as number}
						tableHard={tableHard as RowType[]}
						addSuggestedSkill={addSuggestedSkill}
						percentSoft={percentSoft as number}
						tableSoft={tableSoft as RowType[]}
						skillsOnly={skillsOnly}
						percentSales={percentSales as number}
						resumeAnalysis={resumeAnalysis as ResumeAnalysis}
						tableRelated={tableRelated as RowType[]}
						topTitles={topTitles as string[]}
						markInaccurateTitle={markInaccurateTitle}
						isMobile={isMobile}
						markIrrelevantSkill={markIrrelevantSkill}
						isSorted={isSorted}
						sortAccordingToHardSkill={sortAccordingToHardSkill}
					/>
				</>
			)}
		</Box>
	)
}

export default JobFields
