import { Box } from '@mui/material'
import { FC, Dispatch, SetStateAction } from 'react'
import EducationForm from '../Resume/Forms/EducationForm'
import ExperienceForm from '../Resume/Forms/ExperienceForm'
import PersonalForm from '../Resume/Forms/PersonalForm'
import SkillsForm from '../Resume/Forms/SkillsForm'
import SummaryForm from '../Resume/Forms/SummaryForm'
import { Education } from '../../models/Education'
import { Experience } from '../../models/Experience'

const ResumeForm: FC<{
	currentField: string
	email: string
	fullName: string
	city: string
	country: string
	resumeTitle: string
	phone: string
	setEmail: Dispatch<SetStateAction<string>>
	setFullName: Dispatch<SetStateAction<string>>
	setCity: Dispatch<SetStateAction<string>>
	setCountry: Dispatch<SetStateAction<string>>
	setResumeTitle: Dispatch<SetStateAction<string>>
	setPhone: Dispatch<SetStateAction<string>>
	summary: string
	setSummary: Dispatch<SetStateAction<string>>
	education: Education[]
	setEducation: Dispatch<SetStateAction<Education[]>>
	experience: Experience[]
	setExperience: Dispatch<SetStateAction<Experience[]>>
	skills: string[]
	setSkills: Dispatch<SetStateAction<string[]>>
}> = ({
	currentField,
	email,
	fullName,
	city,
	country,
	resumeTitle,
	phone,
	setEmail,
	setFullName,
	setCity,
	setCountry,
	setResumeTitle,
	setPhone,
	summary,
	setSummary,
	education,
	setEducation,
	experience,
	setExperience,
	skills,
	setSkills
}) => {
	return (
		<Box
			sx={{
				backgroundColor: 'white',
				justifyContent: 'space-between',
				paddingTop: { md: '32px', sm: '25px', xs: '20px' },
				width: '100%',
				borderRadius: '20px',
				marginTop: '19px',
				marginLeft: '20px',
				paddingLeft: { sm: '40px', xs: '0px' },
				paddingRight: { sm: '50px', xs: '25px' },
				paddingBottom: { sm: '40px', xs: '20px' }
			}}
		>
			{currentField === '1' && (
				<PersonalForm
					email={email}
					fullName={fullName}
					city={city}
					country={country}
					title={resumeTitle}
					phone={phone}
					setEmail={setEmail}
					setFullName={setFullName}
					setCity={setCity}
					setCountry={setCountry}
					setTitle={setResumeTitle}
					setPhone={setPhone}
				/>
			)}
			{currentField === '2' && <SummaryForm summary={summary} setSummary={setSummary} />}
			{currentField === '3' && <EducationForm education={education} setEducation={setEducation} />}
			{currentField === '4' && <ExperienceForm experience={experience} setExperience={setExperience} />}
			{currentField === '5' && <SkillsForm skills={skills} setSkills={setSkills} />}
		</Box>
	)
}

export default ResumeForm
