import { FC, Fragment } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { Box, Button, SvgIconTypeMap } from '@mui/material'
import { signOut, User } from 'firebase/auth'
import { auth } from '../../firebase'
import { ROUTES } from '../../constants/routes'
// import { addNewResume } from '../../utils/resumes'
// import { toast } from 'react-toastify'
// import { AnonymousJobsCTX } from '../../contexts/anonymousJobs'
// import { signInAnonymous } from '../../utils/authentication'
import { LOGIN_SIGNUP_ROUTES } from '../../constants'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { Description, PhoneInTalk, Work, TableView } from '@mui/icons-material'
import useAuthProfileData from '../../hooks/useAuthProfileData'
import { UserProfileData } from '../AdminChat/adminChatModals'

interface HeaderLinksProps {
	isMobileHeader?: boolean
	isLoginSignupLinks?: boolean
	user: User | null | undefined
	userLoading: boolean
}

interface NavBarLink {
	text: string
	isLink: boolean
	Icon?: OverridableComponent<SvgIconTypeMap>
	to?: string
	onClick?: () => void
}

export const HeaderLinks: FC<HeaderLinksProps> = ({ isMobileHeader = false, isLoginSignupLinks = false, user, userLoading }) => {
	const navigate = useNavigate()
	const authData = useAuthProfileData()
	// const { setHasAnonymousJobs, setAnonymousJobs, loading: loadingForAnonymousUserCreation, setLoading: setLoadingForAnonymousUserCreation } = useContext(AnonymousJobsCTX)

	// const addNewResumeHandler = () => {
	// 	addNewResume()
	// 		.then((id) => {
	// 			if (id !== 'unauthenticated') {
	// 				navigate(ROUTES.RESUMES + `/${id}`)
	// 				setLoadingForAnonymousUserCreation?.(false)
	// 			}
	// 		})
	// 		.catch(() => {
	// 			setLoadingForAnonymousUserCreation?.(false)
	// 			toast.error('an error occured in saving resume')
	// 		})
	// }
	// const newResumeHandler = () => {
	// 	if (!user) {
	// 		setLoadingForAnonymousUserCreation?.(true)
	// 		if (setHasAnonymousJobs && setAnonymousJobs) {
	// 			signInAnonymous(setHasAnonymousJobs, setAnonymousJobs, addNewResumeHandler)
	// 		}
	// 	}
	// }

	const loginBtnHandler = async () => {
		if (user !== null) await signOut(auth)
		navigate(ROUTES.LOGIN)
	}

	const signUpBtnHandler = async () => {
		if (user !== null) await signOut(auth)
		navigate(ROUTES.SIGNUP)
	}

	const authenticatedLinks: NavBarLink[] = [
		{ text: 'Jobs', isLink: true, to: ROUTES.JOBS, Icon: Work },
		{ text: 'Scan Multiple Resumes', isLink: true, to: ROUTES.MULTIPLE_RESUMES_JOBS, Icon: TableView },
		{ text: 'Resumes', isLink: true, to: ROUTES.RESUMES, Icon: Description },
		{ text: 'Cover Letters', isLink: true, to: ROUTES.COVER_LETTER, Icon: Description },
		{ text: 'Support', isLink: true, to: ROUTES.SUPPORT, Icon: PhoneInTalk }
	]
	const unAuthenticatedLinks: NavBarLink[] = [
		// { text: 'Build Resume', isLink: false, onClick: newResumeHandler },
		// { text: 'Practice An Interview', isLink: false, onClick: () => {} }
	]
	const publicLinks: NavBarLink[] = [
		{ text: 'Services', isLink: true, to: ROUTES.SERVICES },
		{ text: 'Blogs', isLink: true, to: ROUTES.BLOGS }
	]
	const Links: NavBarLink[] = [...publicLinks, ...(user && isMobileHeader ? authenticatedLinks : []), ...(user ? [] : unAuthenticatedLinks)]

	return isLoginSignupLinks ? (
		<>
			{!userLoading && (user == null || user.isAnonymous) && !LOGIN_SIGNUP_ROUTES.includes(window.location?.pathname) ? (
				<Box
					sx={{
						display: 'flex',
						gap: isMobileHeader ? '15px' : '5px',
						marginY: isMobileHeader ? '10px' : 0,
						width: isMobileHeader ? '100%' : 'fit-content',
						button: {
							color: 'black',
							borderRadius: '50px',
							border: '1px solid black',
							padding: '5px 15px',
							flexGrow: 1
						}
					}}
				>
					<Button onClick={loginBtnHandler}>Log In</Button>
					<Button onClick={signUpBtnHandler}>Sign Up</Button>
				</Box>
			) : null}
		</>
	) : (
		<Box
			sx={{
				flexGrow: 1,
				display: 'flex',
				gap: { xs: '5px', md: '20px' },
				justifyContent: { xs: 'center', sm: 'flex-end' },
				alignItems: isMobileHeader ? 'flex-start' : 'center',
				flexDirection: isMobileHeader ? 'column' : 'row',
				'a, button': {
					color: '#171725',
					textDecoration: 'none',
					svg: { color: 'grey' },
					'&.active': { svg: { color: '#0062FF' }, button: { color: '#0062FF' } },
					'&:hover': { backgroundColor: 'transparent' }
				}
			}}
		>
			{(authData as UserProfileData)?.role === 'admin' ? (
				<NavLink className={location.pathname === ROUTES.BLOG_BUILDER ? 'active' : ''} to={ROUTES.BLOG_BUILDER} style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
					<Button sx={{ minWidth: 'unset' }}>Blog Manager</Button>
				</NavLink>
			) : null}

			{Links.map(({ text, isLink, Icon, to, onClick }) => {
				return (
					<Fragment key={to}>
						{isLink && to ? (
							<NavLink
								className={({ isActive }) => ((to === ROUTES.JOBS && location.pathname === ROUTES.OVERVIEW) || isActive ? 'active' : '')}
								key={to}
								to={to}
								style={{ display: 'flex', alignItems: 'center', gap: '5px' }}
							>
								{Icon ? <Icon sx={{ width: '20px', height: '20px' }} /> : null}
								<Button sx={{ minWidth: 'unset' }}>{text}</Button>
							</NavLink>
						) : null}
						{!isLink && onClick ? <Button onClick={onClick}>{text}</Button> : null}
					</Fragment>
				)
			})}
		</Box>
	)
}

export default HeaderLinks
