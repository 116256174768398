import { FC, useEffect } from 'react'
import './style.css'
import Backdrop from './Backdrop'
import hourglass from '../../assets/Loader/hourglass_loader.gif'
const Loader: FC<{ hideBackdrop?: boolean }> = ({ hideBackdrop }) => {
	useEffect(() => {
		const element = document?.activeElement as HTMLElement
		if (element) element.blur()
	}, [])
	return (
		<>
			{!hideBackdrop ? (
				<Backdrop>
					<div>
						<img className="hourglass" alt="loader" src={hourglass} />
					</div>
				</Backdrop>
			) : null}
			{hideBackdrop ? (
				<Backdrop>
					<div>
						<img className="hourglass" alt="loader" src={hourglass} />
					</div>
				</Backdrop>
			) : null}
		</>
	)
}

export default Loader
