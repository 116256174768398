import { Box, Button, CircularProgress, FormControl, FormControlLabel, List, ListItem, Modal, Radio, RadioGroup, Typography } from '@mui/material'
import { DocumentData, DocumentSnapshot, addDoc, collection, doc, getDoc, onSnapshot, orderBy, query } from 'firebase/firestore'
import { FC, useState, ChangeEvent, useEffect } from 'react'
import { useCollection } from 'react-firebase-hooks/firestore'
import { COLLECTION_NAMES } from '../../../constants/collections'
import { ROUTES } from '../../../constants/routes'
import { auth, db } from '../../../firebase'
import CustomLoader from '../../CustomLoader'
import { FirestoreUser } from '../../../models/FirestoreUser'
import { useNavigate } from 'react-router'

interface Props {
	id: string
	name: string
	description: string
	features: string
}

const SubscriptionCard: FC<Props> = ({ id, name, description, features }) => {
	const navigate = useNavigate()
	const [prices, loading] = useCollection(query(collection(db, COLLECTION_NAMES.STRIPE_PRODUCTS, id, COLLECTION_NAMES.PRODUCT_PRICES), orderBy('unit_amount', 'asc')))
	const [isSubscriptionClicked, setIsSubscriptionClicked] = useState<boolean>(false)
	const [selectedPrice, setSelectedPrice] = useState<DocumentSnapshot<DocumentData> | undefined>(undefined)

	const handlePriceChange = (e: ChangeEvent, value: string) => {
		const price = prices?.docs.find((doc) => doc.id === value)
		setSelectedPrice(price)
	}

	const subscribeHandler = async (): Promise<void> => {
		if (!auth.currentUser) navigate(ROUTES.LOGIN)
		setIsSubscriptionClicked(true)

		const checkoutDoc: any = {
			price: selectedPrice?.id ?? '',
			success_url: `${window.location.protocol}//${window.location.host}${ROUTES.OVERVIEW}?session_success=1`,
			cancel_url: `${window.location.protocol}//${window.location.host}${ROUTES.OVERVIEW}`,
			trial_from_plan: true
		}

		const userData: FirestoreUser = (await getDoc(doc(db, COLLECTION_NAMES.USERS_COLLECTION, auth.currentUser?.uid ?? ''))).data() as FirestoreUser

		if (userData.referral) {
			checkoutDoc.client_reference_id = userData.referral
		}

		const docRef = await addDoc(collection(db, COLLECTION_NAMES.USERS_COLLECTION, auth.currentUser?.uid ?? '', COLLECTION_NAMES.STRIPE_CHECKOUT_SESSIONS), checkoutDoc)
		const unsub = onSnapshot(docRef, (snap) => {
			const { error, url } = snap.data() as { error: Error; url: string }

			if (error) {
				// Show an error to your customer and
				// inspect your Cloud Function logs in the Firebase console.
				console.log(`An error occured: ${error.message}`)
				setIsSubscriptionClicked(false)
			}
			if (url) {
				// We have a Stripe Checkout URL, let's redirect.
				window.location.replace(url)
				unsub()
			}
		})
	}

	useEffect(() => {
		if (!prices) return
		setSelectedPrice(prices.docs[1])
	}, [prices])

	return (
		<>
			<Box
				sx={{
					boxShadow: '0 8px 8px rgba(0,0,0,0.2)',
					width: '100%',
					height: '100%',
					borderRadius: '30px',
					backgroundColor: '#f1f1f1',
					padding: '30px'
				}}
			>
				<Typography component="h4" fontWeight="bold">
					Billed
				</Typography>
				<FormControl size="small">
					{selectedPrice && (
						<RadioGroup
							aria-labelledby="demo-controlled-radio-buttons-group"
							name="controlled-radio-buttons-group"
							sx={{ flexDirection: 'row' }}
							value={selectedPrice.id}
							onChange={handlePriceChange}
						>
							{!loading &&
								prices &&
								prices.docs.map((doc) => (
									<FormControlLabel
										key={doc.id}
										value={doc.id}
										control={<Radio size="small" />}
										label={doc.data().interval === 'month' ? (doc.data().interval_count === 1 ? 'monthly' : 'half-yearly') : 'yearly'}
									/>
								))}
						</RadioGroup>
					)}
				</FormControl>
				<Typography component="h2" fontWeight="bold" variant="h5">
					{name}
				</Typography>
				<Typography
					component="p"
					sx={{
						color: 'gray'
					}}
				>
					{description}
				</Typography>
				{!loading && (selectedPrice?.data()?.interval !== 'month' || selectedPrice?.data()?.interval_count > 1) && (
					<Box
						sx={{
							width: '100%',
							background: 'green',
							textAlign: 'center',
							borderRadius: 50,
							color: 'white',
							padding: '3px',
							fontSize: '20px'
						}}
					>
						Save {selectedPrice?.data()?.interval === 'month' ? '30' : '50'}%
					</Box>
				)}
				<Typography
					component="p"
					fontSize="medium"
					sx={{
						marginTop: '10px',
						padding: '0px'
					}}
				>
					Includes:
				</Typography>
				<List
					sx={{
						listStyleType: 'disc',
						pl: 2,
						'& .MuiListItem-root': {
							display: 'list-item'
						}
					}}
				>
					{features &&
						features.split(',').map((feature, idx) => (
							<ListItem disableGutters disablePadding key={idx} sx={{ fontSize: '14px' }}>
								{feature}
							</ListItem>
						))}
					{selectedPrice?.data()?.trial_period_days !== null && (
						<ListItem disableGutters disablePadding sx={{ fontSize: '14px' }}>
							{selectedPrice?.data()?.trial_period_days} days free trial
						</ListItem>
					)}
				</List>
				<Box
					sx={{
						marginTop: '20px',
						height: '30px',
						display: 'flex',
						gap: '5px'
					}}
				>
					{loading ? (
						<CircularProgress />
					) : (
						<Typography
							sx={{
								fontSize: '30px',
								fontWeight: 'bold',
								lineHeight: 1
							}}
						>
							$
							{(
								(selectedPrice?.data()?.interval === 'month' ? selectedPrice?.data()?.unit_amount / selectedPrice?.data()?.interval_count : selectedPrice?.data()?.unit_amount / 12) /
								100
							).toFixed(2)}
						</Typography>
					)}
					{!loading && (
						<Box>
							<Typography
								sx={{
									fontSize: '14px',
									color: 'gray',
									lineHeight: 0.9
								}}
							>
								per
							</Typography>
							<Typography
								sx={{
									fontSize: '14px',
									color: 'gray',
									lineHeight: 0.9
								}}
							>
								month
							</Typography>
						</Box>
					)}
				</Box>
				{loading ? (
					<CircularProgress />
				) : (
					<Button variant="contained" fullWidth sx={{ borderRadius: 50, marginTop: '20px' }} onClick={subscribeHandler}>
						{selectedPrice?.data()?.trial_period_days !== null ? 'Start trial' : 'Subscribe'}
					</Button>
				)}
			</Box>
			<Modal open={isSubscriptionClicked} onClose={() => {}} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
				<Box>
					<CustomLoader />
				</Box>
			</Modal>
		</>
	)
}

export default SubscriptionCard
