import { Button, Box } from '@mui/material'
import { FC } from 'react'
import Apple from '../../assets/Login/Apple.png'

const SignupApple: FC<{}> = () => {
	return (
		<Button
			variant="outlined"
			startIcon={<Box component="img" src={Apple} />}
			fullWidth
			sx={{
				fontWeight: '600',
				fontSize: '16px',
				lineHeight: '24px',
				paddingY: '16px',
				marginBottom: '10px',
				borderRadius: '16px',
				border: '1px solid #E5EBF0',
				textTransform: 'none',
				'& .MuiButton-startIcon': {
					position: 'absolute',
					left: '24px',
					top: '18px'
				}
			}}
		>
			Login with Apple
		</Button>
	)
}

export default SignupApple
