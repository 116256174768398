import { useState, useEffect, FC, useContext } from 'react'
import { useNavigate, useParams } from 'react-router'
import { Box } from '@mui/material'
import { Button, Typography, CircularProgress } from '@mui/material'
import { auth, db } from '../../../firebase'
import { doc, setDoc } from 'firebase/firestore'
import { useDocument } from 'react-firebase-hooks/firestore'
import { toast } from 'react-toastify'
import { useAuthState } from 'react-firebase-hooks/auth'
import { COLLECTION_NAMES } from '../../../constants/collections'
import usePageTitle from '../../../hooks/usePageTitle'
import { CoverLetterType } from '../../../models/CoverLetter'
import CoverLetterForm from '../../../components/CoverLetter/CoverLetterForm'
import { Timestamp } from 'firebase/firestore'
import dayjs from 'dayjs'
import { LoaderContext } from '../../../contexts/loader-context'

const CoverLetterBuilder: FC = () => {
	const navigate = useNavigate()
	const [setPagetitle] = usePageTitle()

	const [clName, setclName] = useState<string>('')
	const [date, setDate] = useState<Date>(new Date())
	const [contact, setContact] = useState<string>('')
	const [location, setLocation] = useState<string>('')
	const [greetings, setGreetings] = useState<string>('')
	const [closingParagraph, setClosingParagraph] = useState<string>('')
	const [openingParagraph, setOpeningParagraph] = useState<string>('')
	const [signature, setSignature] = useState<string>('')

	const [clTitle, setclTitle] = useState<string>('')
	const [editTitle, setEditTitle] = useState<boolean>(false)
	const { setShowLoading } = useContext(LoaderContext)
	// Get the userId param from the URL.
	const { id } = useParams()

	const [user] = useAuthState(auth)

	const [coverLetterSnap, coverLetterLoading, coverLetterError] = useDocument(user && id ? doc(db, COLLECTION_NAMES.USERS_COLLECTION, user.uid, COLLECTION_NAMES.COVER_LETTERS, id) : null, {
		snapshotListenOptions: { includeMetadataChanges: true }
	})

	// Updating the Cover letter details
	const updateCoverLetterDetails = (): void => {
		if (!coverLetterLoading) {
			setShowLoading(true)
			const updatedDoc: CoverLetterType = {
				title: clTitle,
				name: clName,
				created: Timestamp.fromDate(dayjs(date).toDate()),
				contact,
				location,
				greetings,
				closing_paragraph: closingParagraph,
				opening_paragraph: openingParagraph,
				signature
			}
			console.log('Data : ', updatedDoc)
			console.log('User : ', user?.uid, id)
			setDoc(doc(db, COLLECTION_NAMES.USERS_COLLECTION, user?.uid ?? '', COLLECTION_NAMES.COVER_LETTERS, id ?? ''), updatedDoc, {
				merge: true
			})
				.then(async () => {
					setShowLoading(false)
				})
				.catch((err: Error) => {
					setShowLoading(false)
					console.log('Error updating document: ', err)
					alert('Error updating document: ' + err.message)
				})
		} else {
			// alert("Please fill all the fields correctly. Empty fields are not allowed.");
		}
	}

	useEffect(() => {
		if (user) {
			if (coverLetterError !== undefined && coverLetterError.code === 'permission-denied') {
				toast("You don't have permission to view the Cover Letters", {
					type: 'error'
				})
				navigate('/')
			}
		}
	}, [coverLetterError, navigate])

	useEffect(() => {
		if (coverLetterSnap) {
			const data = coverLetterSnap.data() as CoverLetterType

			setclTitle(data.title)
			setclName(data.name)
			setDate(data.created?.toDate() ?? new Date())
			setContact(data.contact ?? '')
			setLocation(data.location ?? '')
			setGreetings(data.greetings ?? '')
			setOpeningParagraph(data.opening_paragraph)
			setClosingParagraph(data.closing_paragraph)
			setSignature(data.signature)
		}
	}, [coverLetterSnap])

	useEffect(() => {
		setPagetitle(clTitle)
	}, [clTitle])

	return (
		<>
			{coverLetterLoading && (
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						padding: '40px 20px',
						flexGrow: 1
					}}
				>
					<Box sx={{ textAlign: 'center' }}>
						<Typography
							sx={{
								color: '#0062FF',
								fontStyle: 'normal',
								fontWeight: '600',
								fontSize: { md: '24px', sm: '20px', xs: '18px' },
								lineHeight: '36px',
								letterSpacing: '0.15px'
							}}
							component={'p'}
						>
							Please wait Loading Cover Letter Builder Page ....
						</Typography>
						<Box
							sx={{
								marginTop: '20px'
							}}
							component={'div'}
						>
							<CircularProgress />
						</Box>
					</Box>
				</Box>
			)}
			{coverLetterError && (
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						mt: '30px'
					}}
				>
					<Typography
						sx={{
							color: '#0062FF',
							fontStyle: 'normal',
							fontWeight: '600',
							fontSize: { md: '24px', sm: '20px', xs: '18px' },
							lineHeight: '36px',
							letterSpacing: '0.15px'
						}}
						component={'p'}
					>
						Error Loading Cover Letter Detail Page ....
					</Typography>
					<Box
						sx={{
							marginTop: '20px'
						}}
						component={'div'}
					>
						<Button
							variant="contained"
							color="primary"
							onClick={() => {
								navigate('/')
							}}
						>
							Go to Home
						</Button>
					</Box>
				</Box>
			)}
			{user && !coverLetterError && !coverLetterLoading && (
				<CoverLetterForm
					clName={clName}
					setclName={setclName}
					contact={contact}
					location={location}
					clTitle={clTitle}
					openingParagraph={openingParagraph}
					setContact={setContact}
					setLocation={setLocation}
					setclTitle={setclTitle}
					setOpeningParagraph={setOpeningParagraph}
					closingParagraph={closingParagraph}
					setClosingParagraph={setClosingParagraph}
					signature={signature}
					setSignature={setSignature}
					editTitle={editTitle}
					setEditTitle={setEditTitle}
					date={date}
					setDate={setDate}
					greetings={greetings}
					setGreetings={setGreetings}
					navigate={navigate}
					updateCoverLetterDetails={updateCoverLetterDetails}
				/>
			)}
		</>
	)
}
export default CoverLetterBuilder
