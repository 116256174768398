import { Grid } from '@mui/material'
import { ChangeEvent, FC, useEffect, useState } from 'react'
import MultiResumesSummeryTable from './MultiResumesSummeryTable'
import MobileJobSection2 from './MobileJobSection2'
import { Job } from '../../models/Job'
import { FirestoreError } from 'firebase/firestore'
import { ResumeInputMode } from './types'
import { RowType } from '../JobAnalysisTable/jobType'

const MobileResumeSummeryTable: FC<{
	resumes: Job[] | undefined
	isSorted?: boolean
	sortAccordingToHardSkill?: (e: ChangeEvent<HTMLInputElement>) => void
	jobError?: FirestoreError
	handleDelete?: (index: number) => void
	setResumeMode?: (index: number, value: ResumeInputMode) => void
	editMode?: boolean
	setJobText?: (index: number, value: string) => void
	setFile?: (index: number, value: File | null) => void
	setDescription: (index: number, value: string) => void
	skillsCheckboxHandler?: (index: number, e: React.ChangeEvent<HTMLInputElement>) => Promise<void>
	percentAvg?: number
	isMobile?: boolean
	addSuggestedSkill?: (index: number, tableName: string, skill: string) => Promise<void>
	tableHard?: RowType[]
	markIrrelevantSkill?: (index: number, skill: string, tableName: string) => Promise<void>
	markInaccurateTitle?: (index: number, title: string) => Promise<void>
	jobLoading?: boolean
	setSelectedResume?: (index: number, value: string) => void
	selectedResume?: string
}> = ({
	resumes = [],
	isSorted,
	sortAccordingToHardSkill,
	jobError,
	handleDelete,
	setResumeMode,
	editMode,
	setJobText,
	setFile,
	setDescription,
	skillsCheckboxHandler,
	percentAvg,
	isMobile,
	addSuggestedSkill,
	tableHard,
	markIrrelevantSkill,
	markInaccurateTitle,
	jobLoading,
	setSelectedResume,
	selectedResume
}) => {
	const [viewMetrixStatus, setViewMetrixStatus] = useState<{ data: Job; index: number } | null>(null)
	useEffect(() => {
		if (viewMetrixStatus) {
			setViewMetrixStatus(resumes ? { data: resumes[viewMetrixStatus.index], index: viewMetrixStatus.index } : null)
		}
	}, [resumes])
	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<MultiResumesSummeryTable
					isMobile={true}
					resumes={resumes}
					onView={(isShow, i) => setViewMetrixStatus(isShow && resumes ? { data: resumes[i], index: i } : null)}
					isHardSkill={isSorted}
				/>
			</Grid>
			<Grid item xs={12}>
				{viewMetrixStatus ? (
					<>
						<MobileJobSection2
							isFitScore={true}
							showResumeOnFooter={true}
							newResume={viewMetrixStatus.data.newResume}
							isSorted={isSorted}
							sortAccordingToHardSkill={sortAccordingToHardSkill}
							jobError={jobError}
							handleDelete={handleDelete}
							index={0}
							isMultiResumes={true}
							resumeMode={viewMetrixStatus.data.resumeMode}
							setResumeMode={setResumeMode}
							editMode={editMode}
							jobText={viewMetrixStatus.data.resume}
							setJobText={setJobText}
							file={viewMetrixStatus.data.file ?? null}
							setFile={setFile}
							description={viewMetrixStatus.data.description}
							setDescription={setDescription}
							skillsOnly={viewMetrixStatus.data.skillsOnly}
							skillsCheckboxHandler={skillsCheckboxHandler}
							percentAvg={percentAvg as number}
							isMobile={isMobile}
							addSuggestedSkill={addSuggestedSkill}
							percentHard={viewMetrixStatus.data.percentage_hard}
							tableHard={viewMetrixStatus.data.table_hard}
							markIrrelevantSkill={markIrrelevantSkill}
							percentSoft={viewMetrixStatus.data.percentage_soft}
							tableSoft={viewMetrixStatus.data.table_soft && viewMetrixStatus.data.table_soft.length > 0 ? viewMetrixStatus.data.table_soft : []}
							percentSales={viewMetrixStatus.data.percentage_sales}
							tableRelated={viewMetrixStatus.data.table_related && viewMetrixStatus.data.table_related.length > 0 ? viewMetrixStatus.data.table_related : []}
							resumeAnalysis={viewMetrixStatus.data.resume_analysis}
							topTitles={viewMetrixStatus.data.top_titles}
							markInaccurateTitle={markInaccurateTitle}
							jobLoading={jobLoading}
							setSelectedResume={setSelectedResume}
							selectedResume={selectedResume}
							resumeTitle={viewMetrixStatus.data.resumeTitle}
							resumePhone={viewMetrixStatus.data.resumePhone}
							resumeCity={viewMetrixStatus.data.resumeCity}
							resumeEmail={viewMetrixStatus.data.resumeEmail}
							resumeCandidateName={viewMetrixStatus.data.resumeCandidateName}
							hideDescription={true}
							hideResume={true}
							showMoreDetails={true}
						/>
					</>
				) : null}
			</Grid>
		</Grid>
	)
}

export default MobileResumeSummeryTable
