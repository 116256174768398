import { useState, useEffect, FC, useRef } from 'react'

import { Box } from '@mui/material'
import { Button, Typography, useMediaQuery, CircularProgress } from '@mui/material'
import { getSurveysQuery } from '../../utils/queries'

import { motion } from 'framer-motion'

import { Add } from '@mui/icons-material'

import JobDetailBar from '../../components/JobDetailBar'
import theme from '../../theme'

import { useCollection } from 'react-firebase-hooks/firestore'
import { getManyResumesJobs } from '../../utils/queries'
import ShowNotifications from '../../components/ShowNotifications'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ROUTES } from '../../constants/routes'
import { Job } from '../../models/Job'
import { DocumentData, getDocs } from 'firebase/firestore'
import SurveyModal from '../../components/SurveyModal'
import { alertInterface } from '../../components/OverviewComponents/OverviewConstants'
import MobileOverview from '../../components/OverviewComponents/MobileOverview'
import { container, item, Props } from '../../components/OverviewComponents/OverviewConstants'
import useNavBar from '../../hooks/useNavBar'
import usePageTitle from '../../hooks/usePageTitle'
import JobsFilters from '../../components/OverviewComponents/JobsFilters'
import { JobsFiltersTabs } from '../../constants/jobs'

const JobPosts: FC<Props> = ({ user }) => {
	const [searchParams] = useSearchParams()
	const navigate = useNavigate()
	const [setPagetitle] = usePageTitle()
	const [activeTab, setActiveTab] = useState(0)

	const sessionSuccess = searchParams.get('session_success')
	useEffect(() => {
		if (sessionSuccess !== null) {
			toast.success('Congratulations! You now have access to Premium')
			navigate(ROUTES.MULTIPLE_RESUMES_JOBS)
		}
	}, [])

	const [, setSearch] = useState<string>('')

	const [activeSurveys, setActiveSurveys] = useState<DocumentData[]>([])

	const alertDetail = useRef<alertInterface>({
		open: false,
		title: '',
		detail: '',
		color: 'warning'
	})
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	const q = getManyResumesJobs()

	const [snapshot, loading, error] = useCollection(q, {
		snapshotListenOptions: { includeMetadataChanges: true }
	})
	// GETTINGS Active Jobs
	const [allJobs, setAllJobs] = useState<Job[]>([])
	const [filteredJobs, setFilteredJobs] = useState<Job[]>([])
	const noDefaultInitializationRef = useRef<boolean>(false)
	const [, contextSearch, setContextActiveJobs] = useNavBar(setAllJobs, setSearch, allJobs, noDefaultInitializationRef.current)

	useEffect(() => {
		if (allJobs && allJobs.length > 0) {
			if (contextSearch) {
				const filteredJobs = allJobs.filter((job) => job.title && job.title.toLowerCase().search((contextSearch ?? '').toLowerCase()) !== -1)
				setFilteredJobs(filteredJobs)
			} else {
				setFilteredJobs(allJobs)
			}
		}
	}, [contextSearch])
	// GETTINGS Active Jobs
	useEffect(() => {
		if (!loading && error == null) {
			let tempJobs = snapshot?.docs.map((doc) => ({
				...doc.data(),
				id: doc.id
			})) as Job[]
			noDefaultInitializationRef.current = true
			tempJobs = tempJobs.reduce((prev: Job[], jobData: Job) => {
				if (prev.find((prevJob: Job) => prevJob.groupId === jobData.groupId)) {
					return prev
				}
				return [...prev, jobData]
			}, [])
			setAllJobs([...tempJobs])
			setFilteredJobs([...tempJobs])
			if (setContextActiveJobs) {
				setContextActiveJobs([...tempJobs])
			}
		}
	}, [loading, snapshot])

	useEffect(() => {
		if (user?.uid) {
			getDocs(getSurveysQuery())
				.then((surveys) => {
					const newSurveys = surveys.docs.filter((doc) => !((doc.data().usersAttempted as string[])?.includes(user.uid) ?? false))
					setActiveSurveys(newSurveys)
				})
				.catch((err) => console.log(err))
		}
	}, [user?.uid])

	useEffect(() => {
		setPagetitle('Job Posts')
	}, [])

	const jobsFiltersHandler = (selectedTab: number) => {
		setActiveTab(selectedTab)
	}

	let tabFilterJobs = []
	if (activeTab === 0) {
		tabFilterJobs = filteredJobs
	} else {
		tabFilterJobs = filteredJobs.filter((job) => job.status === JobsFiltersTabs[activeTab].filter)
	}
	return (
		<>
			{activeSurveys.length !== 0 && user !== undefined && user !== null && activeSurveys.map((doc, idx) => <SurveyModal key={doc.id} survey={doc} uid={user.uid} zIndex={`${idx + 1}`} />)}
			{!isMobile ? (
				<Box
					sx={{
						minHeight: '100vh',
						backgroundColor: '#DFDFDF',
						position: 'relative',
						width: '100%'
					}}
				>
					<Box sx={{ display: 'flex', minHeight: screen.height - 70, width: '100%' }}>
						<Box
							className="job-list"
							sx={{
								width: '100%',
								overflow: 'scroll',
								paddingX: { lg: '40px', xs: '20px' }
							}}
						>
							<ShowNotifications />
							<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '30px 0 20px 0' }}>
								<Typography
									variant="h2"
									sx={{
										fontWeight: '600',
										fontSize: { lg: '24px', md: '20px', xs: '18px' },
										lineHeight: '36px',
										letterSpacing: '0.1px'
									}}
								>
									My Job Posts
								</Typography>
								<Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
									<JobsFilters activeTab={activeTab} jobsFiltersHandler={jobsFiltersHandler} />
									<Button
										onClick={() => navigate(ROUTES.ADD_MULTIPLE_RESUMES_JOBS)}
										sx={{
											fontSize: { lg: '14px', sm: '12px' },
											lineHeight: '21px',
											letterSpacing: '0.1px',
											backgroundColor: '#0062FF',
											borderRadius: '10px',
											textTransform: 'none'
										}}
										variant="contained"
										startIcon={<Add />}
									>
										New Job Post
									</Button>
								</Box>
							</Box>

							{/* Showing Active jobs for Desktop View */}
							{!loading ? (
								<>
									{tabFilterJobs.length > 0 ? (
										<motion.div variants={container} initial="hidden" animate="show">
											<Box
												className="job-list"
												sx={{
													paddingBottom: '20px',
													maxWidth: '100%',
													overflow: 'scroll'
												}}
												component={motion.div}
												variants={item}
											>
												{tabFilterJobs.map((job: Job) => {
													return (
														<Box key={job.id}>
															<JobDetailBar
																id={job?.id ?? ''}
																title={job.title}
																company={job.company}
																location={job.location}
																description={job.description}
																moreInfo={job.moreInfo}
																status={job.status}
																created={job.created !== undefined && job.created !== null ? new Date(job.created.seconds * 1000).toDateString() : undefined}
																lastMod={job.lastMod !== undefined && job.lastMod !== null ? new Date(job.lastMod.seconds * 1000).toDateString() : undefined}
																isMultiResume={true}
																groupId={job.groupId}
															/>
														</Box>
													)
												})}
											</Box>
										</motion.div>
									) : (
										<>
											<Typography
												variant="h2"
												sx={{
													fontWeight: '600',
													fontSize: { lg: '24px', md: '20px', xs: '18px' },
													marginTop: {
														lg: alertDetail.current.open ? '30px' : '42px',
														sm: '28px',
														xs: '28px'
													},
													textAlign: 'center',
													lineHeight: '36px',
													letterSpacing: '0.1px',
													marginBottom: { lg: '30px', xs: '15px' }
												}}
											>
												No {activeTab === 0 ? `Job` : `${JobsFiltersTabs[activeTab].text} job`} Found.
											</Typography>
										</>
									)}
								</>
							) : (
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
										alignItems: 'center',
										mt: '30px'
									}}
								>
									<Typography
										sx={{
											color: '#0062FF',
											fontStyle: 'normal',
											fontWeight: '600',
											fontSize: { md: '24px', sm: '20px', xs: '18px' },
											lineHeight: '36px',
											letterSpacing: '0.15px'
										}}
										component={'p'}
									>
										Loading Active Jobs Please wait ...
									</Typography>
									<Box
										sx={{
											marginTop: '20px'
										}}
										component={'div'}
									>
										<CircularProgress />
									</Box>
								</Box>
							)}
							{/* Showing Active jobs for Desktop View */}
						</Box>
					</Box>
				</Box>
			) : (
				<MobileOverview
					createNewJobPost={() => {
						navigate(ROUTES.ADD_MULTIPLE_RESUMES_JOBS)
					}}
					alertDetail={alertDetail}
					loading={loading}
					activeTab={activeTab}
					tabFilterJobs={tabFilterJobs}
					jobsFiltersHandler={jobsFiltersHandler}
					isMultiResume={true}
				/>
			)}
		</>
	)
}
export default JobPosts
