import { FC } from 'react'
import { Box, TextField, Typography } from '@mui/material'
import { Job } from '../../models/Job'
import { FirestoreError } from 'firebase/firestore'
const MobileJobTitle: FC<{ editMode: boolean; resumes?: Job[]; setTitle: (index: number, value: string) => void; jobLoading: boolean; jobError: FirestoreError | undefined; title: string }> = ({
	editMode,
	resumes,
	setTitle,
	title,
	jobLoading,
	jobError
}) => {
	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center'
			}}
		>
			{editMode ? (
				<TextField
					id="outlined-basic"
					label={'Job Title'}
					variant="outlined"
					value={resumes && resumes.length ? resumes[0]?.title : title}
					onChange={(e) => setTitle(0, e.target.value)}
					inputProps={{
						style: {
							height: 2
						}
					}}
					sx={{
						input: {
							color: '#44444F',
							fontWeight: '500',
							fontSize: '12px',
							lineHeight: '18px',
							letterSpacing: '0.1px',
							height: '18px',
							width: '280px'
						},
						marginBottom: '10px'
					}}
				/>
			) : (
				<Typography
					variant="h3"
					sx={{
						fontWeight: '400',
						fontSize: '14px',
						lineHeight: '22px',
						color: '#7F879E',
						marginBottom: '6px'
					}}
				>
					{jobLoading || jobError ? 'Loading...' : resumes && resumes.length ? resumes[0]?.title : title}
				</Typography>
			)}
		</Box>
	)
}

export default MobileJobTitle
