import { Typography, Button } from '@mui/material'
import { Dispatch, FC, SetStateAction, SyntheticEvent } from 'react'
import CustomInput from '../CustomInput'
import { RateReviewOutlined } from '@mui/icons-material'

const FeedbackSupportLinkSection: FC<{
	feedback: string
	setFeedback: Dispatch<SetStateAction<string>>
	handleFeedback: (e: SyntheticEvent) => void
}> = ({ feedback, setFeedback, handleFeedback }) => {
	return (
		<>
			<Typography
				component="h3"
				fontWeight="bold"
				fontSize="large"
				sx={{
					marginTop: { sm: '28px', xs: '18px' },
					marginBottom: '10px',
					color: '#7F879E'
				}}
			>
				Feedback
			</Typography>
			<CustomInput
				placeHolder="Feedback"
				type="text"
				value={feedback}
				onChange={(e) => setFeedback(e.target.value)}
				required={true}
				Icon={<RateReviewOutlined />}
				endElement={
					<Button variant="contained" onClick={handleFeedback} sx={{ borderRadius: '12px', textTransform: 'none' }}>
						Submit
					</Button>
				}
				InputStyles={{
					background: '#F6F6F6',
					borderRadius: '12px'
				}}
			/>
		</>
	)
}

export default FeedbackSupportLinkSection
