import { createContext, ReactNode, useState, FC } from 'react'

const LoaderContext = createContext({
	showLoading: false,
	setShowLoading: (val: boolean) => {}
})

const LoaderContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
	const [showLoading, setShowLoading] = useState(false)

	const val = {
		showLoading,
		setShowLoading: (val: boolean) => setShowLoading(val)
	}

	return <LoaderContext.Provider value={val}>{children}</LoaderContext.Provider>
}

export { LoaderContext, LoaderContextProvider }
