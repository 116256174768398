import { ToastContainer } from 'react-toastify'
import { FC } from 'react'
import AppRouter from './router'
import 'react-toastify/dist/ReactToastify.css'
import { BrowserRouter } from 'react-router-dom'
import AnonymousJobsCTXProvider from './contexts/anonymousJobs'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import { LoaderContextProvider } from './contexts/loader-context'
import GoogleAnalytics from './components/GoogleAnalytics/index'

dayjs.extend(advancedFormat)

const App: FC = () => {
	return (
		<>
			<ToastContainer position="top-center" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss pauseOnHover theme="light" />
			<BrowserRouter>
				<GoogleAnalytics />
				<AnonymousJobsCTXProvider>
					<LoaderContextProvider>
						<AppRouter />
					</LoaderContextProvider>
				</AnonymousJobsCTXProvider>
			</BrowserRouter>
		</>
	)
}
export default App
