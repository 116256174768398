import { BlogSections, FormObj } from '../models/Blog'

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
class BlogFormFactory {
	static createBlogObject(templateName: BlogSections): FormObj | null {
		const formObj: FormObj = {
			isMultiple: false,
			values: [],
			isFile: false,
			isText: false,
			isRtc: false,
			isAiButton: false,
			file: null,
			text: '',
			rtcData: '',
			rtcText: '',
			descText: '',
			fileText: '',
			templateName,
			dynamicPointsTitle: ''
		}
		switch (templateName) {
			case BlogSections.description:
				return { ...formObj, isRtc: true, rtcText: 'Description' }
			case BlogSections.dynamicImagePoints:
				return {
					isMultiple: true,
					templateName,
					dynamicPointsTitle: 'Topics',
					values: [BlogFormFactory.getDynamicObject(formObj) as FormObj]
				}
			case BlogSections.dynamicPoints:
				return {
					isMultiple: true,
					templateName,
					dynamicPointsTitle: 'Topics',
					values: [BlogFormFactory.getDynamicObject(formObj) as FormObj]
				}
			case BlogSections.summery:
				return { ...formObj, isRtc: true, rtcText: 'Summary' }
			case BlogSections.title:
				return { ...formObj, isText: true, descText: 'Blog Title', isAiButton: true, targetTemplate: BlogSections.description }
			case BlogSections.titleBannerImage:
				return { ...formObj, isText: true, isFile: true, descText: 'Blog Title', fileText: 'Upload Image' }
			case BlogSections.toc:
			case BlogSections.advertisement:
				return { ...formObj, isFile: false, isText: false, isRtc: false }
		}
		return null
	}

	static getDynamicObject(formObj: FormObj): FormObj | null {
		if (formObj?.templateName === BlogSections.dynamicImagePoints) {
			return {
				...formObj,
				isFile: true,
				isText: true,
				isRtc: true,
				rtcText: 'Description',
				descText: 'Title',
				fileText: 'Upload Image',
				templateName: formObj?.templateName,
				isAiButton: true,
				targetTemplate: BlogSections.dynamicImagePoints
			}
		}

		if (formObj?.templateName === BlogSections.dynamicPoints) {
			return {
				...formObj,
				isFile: false,
				isText: true,
				isRtc: true,
				rtcText: 'Description',
				descText: 'Title',
				isAiButton: true,
				targetTemplate: BlogSections.dynamicImagePoints
			}
		}
		return null
	}
}

export default BlogFormFactory
