import { Box, TextField, Typography } from '@mui/material'
import { FirestoreError } from 'firebase/firestore'
import { FC } from 'react'

const MobileJobMoreInfoField: FC<{
	editMode: boolean
	moreInfo: string
	setMoreInfo: (index: number, value: string) => void
	jobLoading: boolean
	jobError: FirestoreError | undefined
}> = ({ editMode, moreInfo, setMoreInfo, jobLoading, jobError }) => {
	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center'
			}}
		>
			{editMode ? (
				<TextField
					id="outlined-basic"
					label={'More Info'}
					variant="outlined"
					value={moreInfo}
					onChange={(e) => setMoreInfo(0, e.target.value)}
					inputProps={{
						style: {
							height: 2
						}
					}}
					sx={{
						input: {
							color: '#44444F',
							fontWeight: '500',
							fontSize: '12px',
							lineHeight: '18px',
							letterSpacing: '0.1px',
							height: '18px',
							width: '280px'
						},
						marginBottom: '10px'
					}}
				/>
			) : (
				<Typography
					variant="h3"
					sx={{
						fontWeight: '400',
						fontSize: '14px',
						lineHeight: '22px',
						marginBottom: '22px',
						color: '#7F879E',
						textAlign: 'center'
					}}
				>
					{jobLoading || jobError ? 'Loading...' : moreInfo}
				</Typography>
			)}
		</Box>
	)
}

export default MobileJobMoreInfoField
