import { Dispatch, SetStateAction, useContext, useEffect, useRef } from 'react'
import { AnonymousJobsCTX } from '../contexts/anonymousJobs'
import { Job } from '../models/Job'

const useNavBar = (setActiveJobs: Dispatch<SetStateAction<Job[]>>, setSearch: Dispatch<SetStateAction<string>>, activeJobs: Job[], noDefaultInitialization?: boolean) => {
	const { activeJobs: contextActiveJob, search: contextSearch, setActiveJobs: setContextActiveJobs } = useContext(AnonymousJobsCTX)

	const isMountJobsRef = useRef<Job[] | boolean>(false)
	const isSearchJobsRef = useRef<string | boolean>('')
	useEffect(() => {
		if (!noDefaultInitialization && isMountJobsRef.current && contextActiveJob && isMountJobsRef.current !== contextActiveJob) {
			isMountJobsRef.current = contextActiveJob
			setActiveJobs(contextActiveJob)
		} else {
			isMountJobsRef.current = true
		}
	}, [contextActiveJob])

	useEffect(() => {
		if (isSearchJobsRef.current && contextSearch && isSearchJobsRef.current !== contextSearch) {
			isSearchJobsRef.current = contextSearch
			setSearch(contextSearch)
		} else {
			isSearchJobsRef.current = true
		}
	}, [contextSearch])

	return [contextActiveJob, contextSearch, setContextActiveJobs] as const
}

export default useNavBar
