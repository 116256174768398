import { FormGroup, FormControlLabel, Checkbox } from '@mui/material'
import { ChangeEvent, FC } from 'react'

const JobFieldSections2Checkboxes: FC<{
	skillsOnly: boolean
	skillsCheckboxHandler: (index: number, e: ChangeEvent<HTMLInputElement>) => Promise<void>
	isMultiResumes?: boolean
	resumeIndex: number
	isSorted?: boolean
	sortAccordingToHardSkill: (e: ChangeEvent<HTMLInputElement>) => void
}> = ({ skillsOnly, skillsCheckboxHandler, isMultiResumes, resumeIndex, isSorted, sortAccordingToHardSkill }) => {
	return (
		<FormGroup
			sx={{
				marginBottom: { sm: '11px', xs: '5px' },
				marginTop: { sm: '80px', xs: '50px' }
			}}
		>
			{!isMultiResumes ? (
				<FormControlLabel
					control={
						<Checkbox
							checked={skillsOnly}
							onChange={(value) => {
								skillsCheckboxHandler(resumeIndex, value)
							}}
							inputProps={{ 'aria-label': 'controlled' }}
						/>
					}
					label="Analyze skills only?"
				/>
			) : null}

			{isMultiResumes && resumeIndex === 0 && (
				<FormControlLabel
					control={<Checkbox checked={isSorted} onChange={(e) => sortAccordingToHardSkill && sortAccordingToHardSkill(e)} inputProps={{ 'aria-label': 'controlled' }} />}
					label="Sort based on hard skills only?"
				/>
			)}
		</FormGroup>
	)
}

export default JobFieldSections2Checkboxes
