import { useState, useEffect, FC } from 'react'

import { Box } from '@mui/material'
import { Button, Typography, useMediaQuery, CircularProgress } from '@mui/material'
import { getResumesQuery } from '../../utils/queries'

import { motion } from 'framer-motion'

import AddIcon from '@mui/icons-material/Add'

import theme from '../../theme'

import { useCollection } from 'react-firebase-hooks/firestore'
import ShowNotifications from '../../components/ShowNotifications'
import { ResumeType } from '../../models/Resume'
import { useNavigate } from 'react-router'
import { ROUTES } from '../../constants/routes'
import { addNewResume } from '../../utils/resumes'
import { toast } from 'react-toastify'
import ResumeDetailBar from '../../components/Resume/ResumeDetailBar'
import MobileResumes from '../../components/ResumeComponents/MobileResumes'
import usePageTitle from '../../hooks/usePageTitle'
const container = {
	show: {
		transition: {
			staggerChildren: 0.05
		}
	}
}
const item = {
	hidden: { opacity: 0, y: 40 },
	show: { opacity: 1, y: 0 }
}

const Resumes: FC = () => {
	const navigate = useNavigate()
	const [setPagetitle] = usePageTitle()

	const [search, setSearch] = useState<string>('')

	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	const q = getResumesQuery()

	const [snapshot, loading, error] = useCollection(q, {
		snapshotListenOptions: { includeMetadataChanges: true }
	})

	// GETTINGS Resumes
	const [resumes, setResumes] = useState<ResumeType[]>([])

	const newResumeHandler = () => {
		addNewResume()
			.then((id) => {
				if (id !== 'unauthenticated') {
					navigate(ROUTES.RESUMES + `/${id}`)
				}
			})
			.catch(() => toast.error('an error occured in saving resume'))
	}

	// GETTINGS Resumes
	useEffect(() => {
		if (!loading && error == null) {
			const tempResumes = snapshot?.docs.map((doc) => ({
				...doc.data(),
				id: doc.id
			})) as ResumeType[]
			setResumes([...tempResumes])
		}
	}, [loading, snapshot])

	useEffect(() => {
		setPagetitle('Resumes')
	}, [])

	return (
		<>
			{!isMobile ? (
				<Box
					sx={{
						minHeight: '100vh',
						backgroundColor: '#DFDFDF',
						position: 'relative',
						width: '100%'
					}}
				>
					<Box sx={{ display: 'flex', minHeight: screen.height - 70 }}>
						<Box
							className="job-list"
							sx={{
								width: '100%',
								overflow: 'scroll',
								paddingX: { lg: '40px', xs: '20px' }
							}}
						>
							<ShowNotifications />
							<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '30px 0 20px 0' }}>
								<Typography
									variant="h2"
									sx={{
										fontWeight: '600',
										fontSize: { lg: '24px', md: '20px', xs: '18px' },
										lineHeight: '36px',
										letterSpacing: '0.1px'
									}}
								>
									My Resumes
								</Typography>
								<Button
									onClick={newResumeHandler}
									sx={{
										fontSize: { lg: '14px', sm: '12px' },
										lineHeight: '21px',
										letterSpacing: '0.1px',
										backgroundColor: '#0062FF',
										borderRadius: '10px',
										textTransform: 'none'
									}}
									variant="contained"
									startIcon={<AddIcon />}
								>
									New Resume
								</Button>
							</Box>

							{/* Showing resumes for Desktop View */}
							{!loading ? (
								<>
									{resumes.length > 0 ? (
										<motion.div variants={container} initial="hidden" animate="show">
											<Box
												className="job-list"
												sx={{
													paddingBottom: '20px',
													maxWidth: '100%',
													overflowX: 'scroll'
												}}
												component={motion.div}
												variants={item}
											>
												{resumes.map((resume: ResumeType) => {
													return (
														<Box key={resume.id}>
															<ResumeDetailBar
																id={resume.id ?? ''}
																title={resume.title}
																resumeName={resume.resumeName}
																skills={resume.skills}
																created={resume.created !== undefined && resume.created !== null ? new Date(resume.created.seconds * 1000).toDateString() : undefined}
															/>
														</Box>
													)
												})}
											</Box>
										</motion.div>
									) : (
										<>
											<Typography
												variant="h2"
												sx={{
													fontWeight: '600',
													fontSize: { lg: '24px', md: '20px', xs: '18px' },
													marginTop: {
														lg: '42px',
														sm: '28px',
														xs: '28px'
													},
													textAlign: 'center',
													lineHeight: '36px',
													letterSpacing: '0.1px',
													marginBottom: { lg: '30px', xs: '15px' }
												}}
											>
												No Resumes Found!
											</Typography>
										</>
									)}
								</>
							) : (
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
										alignItems: 'center',
										mt: '30px'
									}}
								>
									<Typography
										sx={{
											color: '#0062FF',
											fontStyle: 'normal',
											fontWeight: '600',
											fontSize: { md: '24px', sm: '20px', xs: '18px' },
											lineHeight: '36px',
											letterSpacing: '0.15px'
										}}
										component={'p'}
									>
										Loading Resumes Please wait ...
									</Typography>
									<Box
										sx={{
											marginTop: '20px'
										}}
										component={'div'}
									>
										<CircularProgress />
									</Box>
								</Box>
							)}
							{/* Showing resumes for Desktop View */}
						</Box>
					</Box>
				</Box>
			) : (
				<MobileResumes newResumeHandler={newResumeHandler} search={search} setSearch={setSearch} loading={loading} resumes={resumes} />
			)}
		</>
	)
}
export default Resumes
