import { Dispatch, FC, useEffect, useRef } from 'react'
import ImageIcon from '@mui/icons-material/Image'
import { EventObj, MessageDataObj } from './adminChatModals'
import { DocumentData } from 'firebase/firestore'
import { ScrollElement } from './adminChatModals'
import { useMediaQuery } from '@mui/material'
import theme from '../../theme'
interface SupportChatProps {
	newMessageArrived: {
		status: boolean
		to: string
	}
	setIsAdminChat: Dispatch<boolean>
	messages: DocumentData[] | MessageDataObj[]
	message: string
	setEnableSendButton: Dispatch<boolean>
	setMessage: Dispatch<string>
	enableSendButton: boolean
	sendClickHandler: () => Promise<void>
	sendButtonText: string
	onMediaFileSelected: (event: EventObj) => void
	onBottom: () => void
	onTop: () => void
	notOnTop: () => void
	showLoadMore: boolean
	onLoadMore: () => void
}
const SupportChat: FC<SupportChatProps> = ({
	newMessageArrived,
	setIsAdminChat,
	messages,
	message,
	setEnableSendButton,
	setMessage,
	enableSendButton,
	sendClickHandler,
	sendButtonText,
	onMediaFileSelected,
	onBottom,
	onTop,
	showLoadMore,
	notOnTop,
	onLoadMore
}) => {
	const messageListRef = useRef<null | HTMLDivElement>(null)
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	useEffect(() => {
		if (messageListRef.current) {
			setTimeout(() => {
				messageListRef?.current?.scrollTo(0, messageListRef.current.scrollHeight)
			}, 500)
			messageListRef.current.addEventListener('scroll', (e) => {
				const element: ScrollElement = e.target as unknown as ScrollElement
				if (element.scrollTop === 0 && onTop) {
					onTop()
				}

				if (element.scrollTop !== 0 && showLoadMore && notOnTop) {
					notOnTop()
				}
				const remaingHeight = Math.ceil(element.scrollHeight - element.scrollTop)
				if ((remaingHeight === element.clientHeight || remaingHeight === element.clientHeight - 1 || remaingHeight === element.clientHeight + 1) && onBottom) {
					onBottom()
				}
			})
		}
	}, [messageListRef.current])
	return (
		<>
			<div
				className="admin-chat"
				style={{
					bottom: isMobile ? '66px' : '0px'
				}}
			>
				<div className="top-bar">
					<div>
						Support Talk{' '}
						{newMessageArrived.status && newMessageArrived.to === 'user' && (
							<span
								style={{
									display: 'inline-block',
									width: '10px',
									height: '10px',
									background: 'rgb(255,0,0)',
									borderRadius: '50%',
									position: 'relative',
									bottom: '5px',
									right: '2px'
								}}
							></span>
						)}
					</div>
					<span
						style={{
							cursor: 'pointer'
						}}
						onClick={() => {
							setIsAdminChat(false)
						}}
						className="close"
					>
						&#10060;
					</span>
				</div>
				<div className="message-container">
					<div className="messages" ref={(ref) => (messageListRef.current = ref)}>
						{messages.map((msg) => {
							return (
								<>
									<div className="message-item">
										{msg?.text ? <div className="message-title"> {msg?.text} </div> : null}
										{msg.imageUrl && !msg.storageUri ? <img src={msg.imageUrl} style={{ width: 'auto' }} /> : null}
										{msg.imageUrl && msg.storageUri ? <img src={msg.imageUrl} style={{ width: '200px' }} /> : null}
										<div className="message-name">{msg?.from === 'admin' ? 'admin' : 'You'}</div>
									</div>
								</>
							)
						})}
					</div>
					{showLoadMore ? (
						<div className="load-more" onClick={onLoadMore}>
							Load More
						</div>
					) : null}
				</div>
				<div className="bottom-bar">
					<input
						value={message}
						onKeyDown={(e) => {
							if (e.key === 'Enter' && message && message.trim() && enableSendButton) {
								sendClickHandler()
							}
						}}
						onChange={(e) => {
							if (!e.target.value || !e.target.value.trim()) {
								setEnableSendButton(false)
							} else {
								setEnableSendButton(true)
							}
							setMessage(e.target.value)
						}}
						type="text"
						className="message-input-box"
						placeholder="message..."
					/>
					<button disabled={!enableSendButton} onClick={sendClickHandler} className="message-send-btn">
						{sendButtonText}
					</button>
					<span
						onClick={() => {
							const fileUpload: HTMLInputElement = document.querySelector('.file-upload') as HTMLInputElement
							if (fileUpload) {
								fileUpload?.click()
							}
						}}
						className={'message-upload-file-btn'}
					>
						<ImageIcon />
						<input type="file" onChange={(event) => onMediaFileSelected(event as unknown as EventObj)} className="file-upload" style={{ display: 'none' }} />
					</span>
				</div>
			</div>
		</>
	)
}

export default SupportChat
