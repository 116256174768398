import { Box, useMediaQuery } from '@mui/material'
import CustomTextField from '../AddJobDialog/CustomTextField'
import { FC, useEffect, useRef } from 'react'
import { findNameFromCsv, getContactInfo } from '../../utils/functions'
import theme from '../../theme'
import { analyzeJobDescription } from '../../utils/job'
const ResumeFields: FC<{
	name: string
	title: string
	location: string
	contact: string
	salary: string
	setName: (index: number, input: string) => void
	setTitle: (index: number, input: string) => void
	setLocation: (index: number, input: string) => void
	setContact: (index: number, input: string) => void
	setSalary: (index: number, input: string) => void
	index: number
	resumeText: string
	isEdit?: boolean
}> = ({ name, title, location, contact, salary, setName, setTitle, setLocation, setContact, setSalary, index, resumeText, isEdit }) => {
	const renderCount = useRef(0)
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
	const timeRef = useRef<NodeJS.Timeout | null>(null)

	useEffect(() => {
		return () => {
			if (timeRef.current) clearTimeout(timeRef.current)
		}
	}, [])

	useEffect(() => {
		if (isEdit && renderCount.current === 2 ? true : !isEdit && renderCount.current === 1) {
			;(async () => {
				try {
					if (timeRef.current) {
						clearTimeout(timeRef.current)
					}
					timeRef.current = setTimeout(async () => {
						timeRef.current = null
						const { analyzedTitle: title } = analyzeJobDescription(resumeText)
						const name = await findNameFromCsv(resumeText ?? '', 'first_names.csv', 1)
						let cities = await findNameFromCsv(resumeText ?? '', 'cities.csv', 1)
						const stateCode = await findNameFromCsv(resumeText ?? '', 'state_code.csv', 2, 'g', false)
						const contactInfo = getContactInfo(resumeText ?? '')
						if (!cities) {
							const { analyzedLocation } = analyzeJobDescription(resumeText)
							cities = analyzedLocation
						}
						setName(index, name ?? '')
						setContact(index, contactInfo ?? '')
						setTitle(index, title ?? '')
						setLocation(index, (cities ?? '') + ' ' + (stateCode ?? ''))
					}, 1000)
				} catch (error) {
					console.log('error while processing resume for autofill')
					alert('Somthing went wrong. Pease try after some time.')
				}
			})()
		} else {
			renderCount.current++
		}
	}, [resumeText])
	return (
		<Box
			sx={{
				backgroundColor: 'white',
				bordeRadius: 20,
				margintop: 19,
				width: '100%',
				paddingBottom: '0px',
				borderRadius: '20px',
				paddingTop: isMobile ? '10px' : '0px'
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: { sm: 'row', xs: 'column' },
					marginBottom: { md: '48px', sm: '30px', xs: '0px' }
				}}
			>
				<Box
					sx={{
						width: '100%',
						marginRight: { md: '42px', sm: '20px', xs: '0px' }
					}}
				>
					<CustomTextField name="Name" value={name} onChange={(e) => setName(index, e.target.value)} placeholder="Name" />
				</Box>
				<Box
					sx={{
						width: '100%',
						marginRight: { md: '42px', sm: '20px', xs: '0px' }
					}}
				>
					<CustomTextField name="Title" value={title} onChange={(e) => setTitle(index, e.target.value)} placeholder="Title" />
				</Box>
				<Box
					sx={{
						width: '100%',
						marginLeft: { md: '42px', sm: '20px', xs: '0px' },
						marginTop: { sm: '0', xs: '15px' }
					}}
				>
					<CustomTextField name="Location" value={location} onChange={(e) => setLocation(index, e.target.value)} placeholder="Location" />
				</Box>
			</Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: { sm: 'row', xs: 'column' },
					marginBottom: { md: '48px', sm: '20px', xs: '15px' }
				}}
			>
				<Box
					sx={{
						width: '100%',
						marginRight: { md: '42px', sm: '20px', xs: '0px' },
						marginTop: { sm: '0', xs: '15px' }
					}}
				>
					<CustomTextField name="Contact" value={contact} onChange={(e) => setContact(index, e.target.value)} placeholder="Contact" />
				</Box>
				<Box
					sx={{
						width: '100%',
						marginLeft: { md: '42px', sm: '20px', xs: '0px' },
						marginTop: { sm: '0', xs: '15px' }
					}}
				>
					<CustomTextField name="Salary" value={salary} onChange={(e) => setSalary(index, e.target.value)} placeholder="Salary" />
				</Box>
			</Box>
		</Box>
	)
}

export default ResumeFields
