import { Typography } from '@mui/material'
import { FC } from 'react'
import JobAnalysisTable from '../JobAnalysisTable'
import { TABLE_NAMES } from '../../constants'
import { RowType } from '../JobAnalysisTable/jobType'
const RelatedSkills: FC<{
	tableRelated: RowType[]
	markIrrelevantSkill: (index: number, skill: string, tableName: string) => Promise<void>
}> = ({ tableRelated, markIrrelevantSkill }) => {
	return (
		<>
			<Typography
				component="p"
				sx={{
					color: '#44444F',
					fontWeight: '500',
					fontSize: '14px',
					lineHeight: '18px',
					letterSpacing: '0.1px',
					marginBottom: { sm: '11px', xs: '5px' },
					marginTop: { sm: '25px', xs: '10px' }
				}}
			>
				Usually corresponding but not mentioned in this Job Description
			</Typography>
			<JobAnalysisTable
				rows={tableRelated}
				markIrrelevantSkill={(skill, tableName) => {
					markIrrelevantSkill(0, skill, tableName)
				}}
				tableName={TABLE_NAMES.RELATED_SKILLS}
				related={true}
			/>
		</>
	)
}
export default RelatedSkills
