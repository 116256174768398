import { FC } from 'react'
import MobileJobSection2ResumeTitle from './MobileJobSection2ResumeTitle'
import CustomFileField from '../AddJobDialog/CustomFileField'
import ReadMoreText from '../ReadMoreText'
import { ResumeInputMode } from './types'

const MobileJobResumeText: FC<{
	index: number
	isMultiResumes: boolean
	editMode: boolean
	handleDelete: (index: number) => void
	jobText: string
	setJobText: (index: number, value: string) => void
	file: File | null
	setFile: (index: number, value: File | null) => void
	resumeMode: ResumeInputMode
	setResumeMode: (index: number, value: ResumeInputMode) => void
	setSelectedResume: (index: number, value: string) => void
	selectedResume: string
	newResume?: boolean
	jobLoading: boolean
	fileName?: string
}> = ({ index, isMultiResumes, editMode, handleDelete, jobText, setJobText, file, setFile, resumeMode, setResumeMode, setSelectedResume, selectedResume, newResume, jobLoading, fileName }) => {
	return (
		<>
			<MobileJobSection2ResumeTitle index={index} isMultiResumes={isMultiResumes} />
			{editMode ? (
				<CustomFileField
					handleDelete={handleDelete}
					jobText={jobText}
					setJobText={(value) => setJobText(index, value)}
					file={file}
					setFile={(value) => setFile(index, value)}
					resumeMode={resumeMode}
					setResumeMode={setResumeMode}
					setSelectedResume={setSelectedResume}
					selectedResume={selectedResume}
					resumeIndex={index}
					resumeCount={index + 1}
					newResume={newResume}
					resumeFileName={fileName}
				/>
			) : (
				<ReadMoreText
					text={!jobLoading && jobText !== '' ? jobText : ''}
					maxLetterLength={70}
					styles={{
						color: '#7F879E',
						fontWeight: '400',
						fontSize: '14px',
						lineHeight: '22px'
					}}
				/>
			)}
		</>
	)
}

export default MobileJobResumeText
