import classes from './styles.module.css'
import './styles.css'
import { Unsubscribe } from 'firebase/auth'
import { getFirestore, collection, query, orderBy, DocumentData, onSnapshot, updateDoc, doc, getDoc, limit, startAt, getDocs, QueryDocumentSnapshot } from 'firebase/firestore'
import { useEffect, useRef, useState } from 'react'
import { UserData, MessageDataObj, EventObj, LIMIT, UserProfileData, INITIAL_MESSAGES, LIMIT_USERS } from './adminChatModals'
import AdminUserList from './AdminUserList'
import { db, auth } from '../../firebase/index'
import useAuthProfileData from '../../hooks/useAuthProfileData'
import SupportChat from './SupportChat'
import CallToActionChatButton from './CallToActionChatButton'
import { sendMessages, saveImageMessage } from './AdminChatHelpers'

const AdminChat = () => {
	const [message, setMessage] = useState('')
	const [enableSendButton, setEnableSendButton] = useState(false)
	const [sendButtonText, setSendButtonText] = useState('Send')
	const [messages, setMessages] = useState<DocumentData[] | MessageDataObj[]>([])
	const isUpdate = useRef(true)
	const [isAdminChat, setIsAdminChat] = useState(false)
	const [userList, setUserList] = useState<UserData[] | DocumentData[]>([])
	const [hideUserList, setHideUserList] = useState(true)
	const selectedUser = useRef<DocumentData | null>(null)
	const [isSelectedUser, setIsSelectedUser] = useState(false)
	const messagesUnsubscribeRef = useRef<Unsubscribe | null>(null)
	const userMessagesUnsubscribeRef = useRef<Unsubscribe | null>(null)
	const adminUserListMessagesUnsubscribeRef = useRef<Unsubscribe | null>(null)
	const [isAdminUserChat, setIsAdminUserChat] = useState(false)
	const [showLoadMore, setShowLoadMore] = useState(false)
	const authData = useAuthProfileData()
	const pageRef = useRef<QueryDocumentSnapshot<DocumentData> | undefined | null>(null)
	const isMore = useRef(false)
	const isFirstTime = useRef(true)
	const isMoreUsers = useRef(false)
	const userPageRef = useRef(1)
	const [newMessageArrived, setNewMessageArrived] = useState({
		status: false,
		to: ''
	})
	const [userLoadMore, setUserLoadMore] = useState(false)
	const getUserName = () => {
		return auth?.currentUser?.displayName ?? ''
	}

	const setSelectedUser = (user: DocumentData) => {
		selectedUser.current = user
		unreadMessage()
		isMore.current = false
		isFirstTime.current = true
		setMessages(() => {
			loadMessages()
			return []
		})
	}

	const unreadMessage = async () => {
		const selectedUserRefrence = doc(db, 'chat_messages', selectedUser.current?.uid as string)
		try {
			await updateDoc(selectedUserRefrence, {
				newMessage: false,
				newMessageTo: ''
			})
		} catch (e) {
			return false
		}
	}
	const getId = () => {
		return auth?.currentUser?.uid ?? ''
	}

	// Saves a new message on the Cloud Firestore.
	async function saveMessage(messageText: string) {
		try {
			await sendMessages(isAdminUserChat, getUserName(), getId(), selectedUser, messageText)
		} catch (error) {
			console.error('Error writing new message to Firebase Database', error)
		}
	}

	const sendClickHandler = async () => {
		try {
			setSendButtonText('Sending...')
			setEnableSendButton(false)
			isUpdate.current = true
			await saveMessage(message)
		} catch (err) {
			// alert('Error in sending message.')
		} finally {
			setSendButtonText('Send')
			setMessage('')
			setEnableSendButton(false)
		}
	}
	// Loads chat messages history and listens for upcoming ones.
	const loadMessages = () => {
		isFirstTime.current = true
		if (messagesUnsubscribeRef.current) {
			messagesUnsubscribeRef.current()
		}
		if (userMessagesUnsubscribeRef.current) {
			userMessagesUnsubscribeRef.current()
		}
		// Create the query to load the last 12 messages and listen for new ones.
		const recentlyUpdatedMessage = doc(getFirestore(), `chat_messages`, (isAdminUserChat ? selectedUser?.current?.uid : getId()) as string)

		userMessagesUnsubscribeRef.current = onSnapshot(recentlyUpdatedMessage, function (snapshot) {
			if (snapshot.exists()) {
				const snapshotData = snapshot.data()
				setNewMessageArrived({
					status: snapshotData.newMessage,
					to: snapshotData.newMessageTo
				})
			}
		})

		const recentMessagesQuery = query(
			collection(getFirestore(), `chat_messages/${isAdminUserChat ? selectedUser?.current?.uid : getId()}/messages`),
			orderBy('timestamp', 'desc'),
			limit(LIMIT + 1)
		)
		messagesUnsubscribeRef.current = onSnapshot(recentMessagesQuery, function (snapshot) {
			const docs = snapshot.docChanges()
			let newMessages = docs.filter((docChange) => docChange.type === 'added').map((change) => change.doc.data())
			const updateMessages = docs.filter((docChange) => docChange.type === 'modified' && docChange.doc.data().storageUri).map((changeData) => changeData.doc.data())
			if (isFirstTime.current) {
				isFirstTime.current = false
				isMore.current = newMessages.length === LIMIT + 1
				if (isMore.current) {
					newMessages.pop()
					pageRef.current = docs.at(newMessages.length)?.doc
				}
			}
			setMessages((messages) => {
				let oldMessages = messages.map((data) => ({ ...data })).filter((message) => !message.isCustom)
				if (updateMessages.length > 0) {
					oldMessages = oldMessages.filter((data) => !updateMessages.find((updateMessage) => updateMessage.id === data.id))
					newMessages = updateMessages
				}
				if (isFirstTime.current) {
					oldMessages = []
				}
				return [...[...(!isAdminUserChat && !isMore.current ? INITIAL_MESSAGES : [])], ...oldMessages, ...[...newMessages].reverse()]
			})
			isUpdate.current = false
		})
	}

	const loadUsers = () => {
		if (adminUserListMessagesUnsubscribeRef.current) {
			adminUserListMessagesUnsubscribeRef.current()
		}
		setTimeout(() => {
			const recentMessagesQuery = query(collection(getFirestore(), `chat_messages`), orderBy('lastMessageTimestamp', 'desc'), limit(LIMIT_USERS * userPageRef.current + 1))
			// Start listening to the query.
			adminUserListMessagesUnsubscribeRef.current = onSnapshot(recentMessagesQuery, function (snapshot) {
				const messages = snapshot.docs.map((change) => change.data())
				isMoreUsers.current = messages.length === LIMIT_USERS * userPageRef.current + 1
				if (isMoreUsers.current) {
					messages.pop()
				}
				if (isMoreUsers && !isMoreUsers.current) {
					setUserLoadMore(false)
				}
				setUserList(messages)
			})
		}, 1500)
	}

	useEffect(() => {
		if ((authData as UserProfileData)?.role === 'admin') {
			loadUsers()
			setIsAdminUserChat(true)
		} else if (authData && Object.keys(authData).length > 0 && auth?.currentUser && !messagesUnsubscribeRef.current) {
			loadMessages()
		}
	}, [authData])

	const onMediaFileSelected = (event: EventObj) => {
		const file = event.target.files[0]
		if (event.target) {
			event.target.value = ''
		}

		if (!file.type.match('image.*')) {
			alert('Only images are allowed to upload')
			return
		}
		saveImage(file)
	}

	const saveImage = async (file: File) => {
		saveImageMessage(file, isAdminUserChat, getUserName(), selectedUser, getId(), auth)
	}

	// Saves a new message containing an image in Firebase.
	// This first saves the image in Firebase storage.

	const handleOnBottom = async () => {
		const isChatExists = await getDoc(doc(db, 'chat_messages', isAdminUserChat ? (selectedUser?.current?.uid as string) : getId()))
		if (isChatExists.exists()) {
			updateDoc(isChatExists.ref, {
				newMessage: false
			})
			setNewMessageArrived({
				...newMessageArrived,
				status: false
			})
		}
	}

	const handleOnTop = () => {
		setShowLoadMore(isMore.current)
	}

	const loadMoreMessages = async () => {
		const loadMoreQuery = query(
			collection(getFirestore(), `chat_messages/${isAdminUserChat ? selectedUser?.current?.uid : getId()}/messages`),
			orderBy('timestamp', 'desc'),
			startAt(pageRef.current),
			limit(LIMIT + 1)
		)
		const docs = await getDocs(loadMoreQuery)
		const docsData = docs.docs.map((mapData) => {
			return mapData.data()
		})
		const oldMessagesCopy = messages.filter((message) => !message.isCustom)
		isMore.current = docsData.length === LIMIT + 1
		if (isMore.current) {
			docsData.pop()
			pageRef.current = docs.docs.at(docsData.length)
		}
		setShowLoadMore(isMore.current)
		setMessages([...[...(!isAdminUserChat && !isMore.current ? INITIAL_MESSAGES : [])], ...[...docsData].reverse(), ...oldMessagesCopy])
	}

	const handleUserLoadMore = () => {
		userPageRef.current++
		loadUsers()
	}
	return (
		<>
			<div className={classes.charButton}>
				{isAdminUserChat ? (
					<AdminUserList
						hideUserList={hideUserList}
						setHideUserList={setHideUserList}
						userList={userList}
						loadMessages={loadMessages}
						setIsSelectedUser={setIsSelectedUser}
						setSelectedUser={setSelectedUser}
						isSelectedUser={isSelectedUser}
						selectedUser={selectedUser.current}
						message={message}
						setEnableSendButton={setEnableSendButton}
						setMessage={setMessage}
						enableSendButton={enableSendButton}
						sendClickHandler={sendClickHandler}
						sendButtonText={sendButtonText}
						onMediaFileSelected={onMediaFileSelected}
						messages={messages}
						newMessageArrived={newMessageArrived}
						onBottom={handleOnBottom}
						showLoadMore={showLoadMore}
						notOnTop={() => setShowLoadMore(false)}
						onLoadMore={() => {
							loadMoreMessages()
						}}
						onTop={handleOnTop}
						onUserTop={() => {}}
						userNotOnTop={() => {
							setUserLoadMore(false)
						}}
						onUserBottom={() => {
							setUserLoadMore(isMoreUsers.current)
						}}
						showUserLoadMore={userLoadMore}
						onUserLoadMore={handleUserLoadMore}
						setUserList={setUserList}
					/>
				) : null}
				{!isAdminUserChat && !isAdminChat ? <CallToActionChatButton setIsAdminChat={setIsAdminChat} newMessageArrived={newMessageArrived} onChatButtonClicked={handleOnBottom} /> : null}
				{isAdminChat ? (
					<SupportChat
						newMessageArrived={newMessageArrived}
						setIsAdminChat={setIsAdminChat}
						messages={messages}
						message={message}
						setEnableSendButton={setEnableSendButton}
						setMessage={setMessage}
						enableSendButton={enableSendButton}
						sendClickHandler={sendClickHandler}
						sendButtonText={sendButtonText}
						onMediaFileSelected={onMediaFileSelected}
						onBottom={handleOnBottom}
						onTop={handleOnTop}
						showLoadMore={showLoadMore}
						notOnTop={() => setShowLoadMore(false)}
						onLoadMore={() => {
							loadMoreMessages()
						}}
					/>
				) : null}
			</div>
		</>
	)
}

export default AdminChat
