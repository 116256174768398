import { Box, Card, Button } from '@mui/material'
import { FC } from 'react'
// import { USER_TYPES } from '../../constants/authentication'
import { NavigateFunction } from 'react-router'
interface AuthModalMobileFieldsProps {
	// handleAnonymousSignIn: (type: string) => void
	navigate: NavigateFunction | (() => {})
	setOpenRecruitModal: ((isOpen: boolean) => void) | undefined
	setOpenJobSeekerModal?: (isOpen: boolean) => void
}

const AuthModalMobileFields: FC<AuthModalMobileFieldsProps> = ({ navigate = () => {}, setOpenRecruitModal = (e) => {}, setOpenJobSeekerModal = (e) => {} }) => {
	return (
		<>
			<Box sx={{ paddingX: '16px' }}>
				<Card
					sx={{
						padding: '24px 24px 20px 24px',
						borderRadius: '16px',
						marginBottom: '10px'
					}}
				>
					{/* <Typography
                    variant="h2"
                    sx={{
                        color: '#121212',
                        fontWeight: '700',
                        fontSize: '24px',
                        lineHeight: '32px',
                        textAlign: 'center',
                        marginBottom: '24px'
                    }}
                >
                    Easy steps to organize your project
                </Typography> */}
					{/* <Button
                    onClick={() => handleAnonymousSignIn(USER_TYPES.RECRUITER)}
                    variant="contained"
                    fullWidth
                    sx={{
                        backgroundColor: '#171725',
                        fontWeight: '600',
                        fontSize: '16px',
                        lineHeight: '24px',
                        paddingY: '16px',
                        marginBottom: '16px',
                        borderRadius: '16px',
                        textTransform: 'none',
                        '&:hover': {
                            backgroundColor: '#3e3b3b'
                        }
                    }}
                >
                    Continue as guest Recruiter
                </Button> */}
					{/* <Button
						onClick={() => handleAnonymousSignIn(USER_TYPES.JOB_SEEKER)}
						variant="contained"
						fullWidth
						sx={{
							backgroundColor: '#171725',
							fontWeight: '600',
							fontSize: '16px',
							lineHeight: '24px',
							paddingY: '16px',
							marginBottom: '16px',
							borderRadius: '16px',
							textTransform: 'none',
							'&:hover': {
								backgroundColor: '#3e3b3b'
							}
						}}
					>
						Try As A Guest Without Registration
					</Button> */}
					<Button
						variant="contained"
						onClick={() => navigate('/signup')}
						fullWidth
						sx={{
							backgroundColor: '#3865F3',
							fontWeight: '600',
							fontSize: '16px',
							lineHeight: '24px',
							paddingY: '16px',
							marginBottom: '16px',
							borderRadius: '16px',
							textTransform: 'none',
							'&:hover': {
								backgroundColor: '#294ec4'
							}
						}}
					>
						Sign Up
					</Button>

					{/* <Button
                    variant="text"
                    fullWidth
                    onClick={() => setOpenRecruitModal && setOpenRecruitModal(true)}
                    sx={{
                        fontWeight: '600',
                        fontSize: '16px',
                        lineHeight: '24px',
                        paddingY: '16px',
                        marginBottom: '16px',
                        borderRadius: '16px',
                        border: 'none',
                        backgroundColor: '#F5F6FF',
                        textTransform: 'none'
                    }}
                >
                    Login as recruiter
                </Button> */}

					<Button
						variant="text"
						fullWidth
						onClick={() => setOpenJobSeekerModal && setOpenJobSeekerModal(true)}
						sx={{
							fontWeight: '600',
							fontSize: '16px',
							lineHeight: '24px',
							paddingY: '16px',
							marginBottom: '16px',
							borderRadius: '16px',
							border: 'none',
							backgroundColor: '#F5F6FF',
							textTransform: 'none'
						}}
					>
						Login
					</Button>
				</Card>

				{/* <Card
                sx={{
                    paddingX: "24px",
                    paddingY: "12px",
                    borderRadius: "16px",
                }}
            >
                <CustomInput
                    placeHolder={"Feedback"}
                    type="text"
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                    required={true}
                    Icon={<RateReviewOutlinedIcon />}
                    endElement={
                        <Button
                            variant="contained"
                            sx={{ borderRadius: "12px", textTransform: "none" }}
                        >
                            Submit
                        </Button>
                    }
                    InputStyles={{
                        background: "#F6F6F6",
                        borderRadius: "12px",
                    }}
                />
            </Card> */}
			</Box>
		</>
	)
}

export default AuthModalMobileFields
