import { FC, useState } from 'react'
import { JobDetailsContentProps } from './types'
import { DocumentData, DocumentReference, Timestamp, addDoc, collection, doc, getDoc, serverTimestamp, setDoc } from 'firebase/firestore'
import { generateFilename } from '../../utils'
import { ref, uploadBytes } from 'firebase/storage'
import { db, storage } from '../../firebase'
import JobDetailContentLoading from './JobDetailContentLoading'
import { COLLECTION_NAMES } from '../../constants/collections'
import { ResumeType, defaultResume } from '../../models/Resume'
import { convertResumeToText } from '../../utils/resumes'
import { updateJobDetailSingleResume } from './JobDetailsContentFunctions'
import { User } from 'firebase/auth'
import JobDetailContentError from './JobDetailContentError'
import JobDetailsContentWrapper from './JobDetailsContentWrapper'
import { deleteJob as deleteResume } from './JobHelpers'
import { getGptData } from '../../utils/httpTrigger'
const JobDetailsContent: FC<JobDetailsContentProps> = ({
	jobError,
	navigate,
	container,
	jobLoading,
	progressBtnClickHandler,
	isTab,
	editMode,
	setEditMode,
	company,
	setCompany,
	setTitle,
	setLocation,
	moreInfo,
	setMoreInfo,
	location,
	created,
	jobRef,
	user,
	jobText,
	setJobText,
	file,
	setFile,
	description,
	setDescription,
	skillsOnly,
	skillsCheckboxHandler,
	percentAvg,
	isMobile,
	addSuggestedSkill,
	percentHard,
	tableHard,
	markIrrelevantSkill,
	percentSoft,
	tableSoft,
	percentSales,
	tableRelated,
	resumeAnalysis,
	topTitles,
	markInaccurateTitle,
	status,
	item,
	title,
	resumeMode,
	setResumeMode,
	selectedResume,
	setSelectedResume,
	isMultiResumes,
	resumes,
	handleAddResume,
	isSorted,
	sortAccordingToHardSkill,
	handleDelete,
	setResumeName,
	setResumeTitle,
	setResumeLocation,
	setResumeContact,
	setResumeSalary,
	resumeCandidateName,
	resumeTitle,
	resumeCity,
	resumePhone,
	resumeSalary,
	oldDescription,
	oldGptData
}) => {
	const deleteJob = (): void => deleteResume(navigate, isMultiResumes, resumes ?? [], db, jobRef)
	const [isDisabled, setIsDisabled] = useState<boolean | undefined>(false)
	const updateJobDetails = async (): Promise<void> => {
		setIsDisabled(true)
		if (!isMultiResumes) {
			let gptData = oldGptData
			if (oldDescription !== description) {
				const result = await getGptData(description)
				gptData = JSON.stringify((await result.json()).result)
			}

			updateJobDetailSingleResume(
				editMode,
				jobLoading,
				{
					title,
					company,
					location,
					description,
					lastMod: serverTimestamp(),
					moreInfo,
					resume: jobText as string,
					resumeMode,
					selectedResume,
					resumeCandidateName,
					resumeTitle,
					resumeCity,
					resumePhone,
					resumeSalary,
					gpt_data: gptData
				},
				user as User,
				resumeMode,
				jobRef,
				selectedResume,
				setEditMode,
				file as File
			)
			return setIsDisabled(false)
		}
		try {
			if (editMode && !jobLoading) {
				if (!user) return
				let gptData = oldGptData
				if (resumes?.[0]?.description !== oldDescription) {
					const result = await getGptData(resumes?.[0]?.description ?? '')
					gptData = JSON.stringify((await result.json()).result)
				}
				const updatedDocs = resumes?.map((resume, index) => ({
					...resume,
					gpt_data: gptData
				}))
				if (updatedDocs) {
					const resumesPromise = updatedDocs
						.map((resume) => {
							if (resume.resumeMode === 'resumes')
								return new Promise((resolve) => {
									getDoc(doc(db, COLLECTION_NAMES.USERS_COLLECTION, user.uid, COLLECTION_NAMES.RESUMES, resume.selectedResume)).then((resumeDoc) => {
										if (resumeDoc.exists()) {
											const resumeData = convertResumeToText(resumeDoc.data() as ResumeType)
											resume.resume = resumeData
										} else resume.selectedResume = ''
										resolve(true)
									})
								})
							return null
						})
						.filter((uploadPromise) => uploadPromise)
					await Promise.all(resumesPromise)
					const uploadResponsesPromises = updatedDocs.map(async (resume) => {
						if (!resume?.id) {
							return await addDoc(collection(db, 'Jobs'), {
								...defaultResume,
								groupId: resume.groupId,
								uid: user.uid,
								email: user.email ? user.email : 'anonymous',
								displayName: user.displayName ? user.displayName : 'anonymous',
								title: resume.title,
								company: resume.company,
								location: resume.location,
								description: resume.description,
								moreInfo: resume.moreInfo,
								created: serverTimestamp() as Timestamp,
								lastMod: serverTimestamp() as Timestamp,
								status: resume.status,
								resume: resume.resume,
								sharedWith: user.isAnonymous ? [] : [user.email ? user.email : ''],
								skillsOnly: !!resume.skillsOnly,
								selectedResume: resume.selectedResume,
								resumeMode: resume.resumeMode,
								resumeCandidateName: resume?.resumeCandidateName ?? '',
								resumeTitle: resume?.resumeTitle ?? '',
								resumeCity: resume?.resumeCity ?? '',
								resumePhone: resume?.resumePhone ?? '',
								resumeSalary: resume?.resumeSalary ?? ''
							})
						}
						const updatedDoc = doc(db, 'Jobs', resume?.id ?? '')
						return await setDoc(
							updatedDoc,
							{
								title: resume.title,
								company: resume.company,
								location: resume.location,
								description: resume.description,
								lastMod: serverTimestamp(),
								moreInfo: resume.moreInfo,
								resume: resume.resume,
								resumeMode: resume.resumeMode,
								selectedResume: resume.selectedResume,
								resumeCandidateName: resume?.resumeCandidateName ?? '',
								resumeTitle: resume?.resumeTitle ?? '',
								resumeCity: resume?.resumeCity ?? '',
								resumePhone: resume?.resumePhone ?? '',
								resumeSalary: resume?.resumeSalary ?? ''
							},
							{ merge: true }
						)
					})
					let resolvedRefs: Array<DocumentReference<DocumentData>> = []
					if (uploadResponsesPromises && uploadResponsesPromises.length > 0) {
						resolvedRefs = (await Promise.all(uploadResponsesPromises)) as Array<DocumentReference<DocumentData>>
					}
					setEditMode(false)
					const finalUpdatePromises = updatedDocs
						.map((resume, i) => {
							if (resume.resumeMode === 'file' && resume.file) {
								const destinationName = generateFilename(resume.file, resolvedRefs[i]?.id)
								const resumeRef = ref(storage, destinationName)
								return uploadBytes(resumeRef, resume.file)
							}
							return null
						})
						.filter((updatedResume) => updatedResume)
					if (finalUpdatePromises && finalUpdatePromises.length > 0) await Promise.all(finalUpdatePromises)
					setIsDisabled(false)
				}
				setIsDisabled(false)
			} else setIsDisabled(false)
		} catch (err) {
			setIsDisabled(false)
			console.log('Error updating document: ', err)
		}
	}

	if (jobLoading) return <JobDetailContentLoading />
	else if (jobError !== undefined) return <JobDetailContentError />
	else
		return (
			<JobDetailsContentWrapper
				handleDelete={handleDelete}
				updateJobDetails={updateJobDetails}
				deleteJob={deleteJob}
				jobError={jobError}
				navigate={navigate}
				container={container}
				jobLoading={jobLoading}
				progressBtnClickHandler={progressBtnClickHandler}
				isTab={isTab}
				editMode={editMode}
				setEditMode={setEditMode}
				company={company}
				setCompany={setCompany}
				setTitle={setTitle}
				setLocation={setLocation}
				moreInfo={moreInfo}
				setMoreInfo={setMoreInfo}
				location={location}
				created={created}
				jobRef={jobRef}
				user={user}
				jobText={jobText}
				setJobText={setJobText}
				file={file}
				setFile={setFile}
				description={description}
				setDescription={setDescription}
				skillsOnly={skillsOnly}
				skillsCheckboxHandler={skillsCheckboxHandler}
				percentAvg={percentAvg}
				isMobile={isMobile}
				addSuggestedSkill={addSuggestedSkill}
				percentHard={percentHard}
				tableHard={tableHard}
				markIrrelevantSkill={markIrrelevantSkill}
				percentSoft={percentSoft}
				tableSoft={tableSoft}
				percentSales={percentSales}
				tableRelated={tableRelated}
				resumeAnalysis={resumeAnalysis}
				topTitles={topTitles}
				markInaccurateTitle={markInaccurateTitle}
				status={status}
				item={item}
				title={title}
				resumeMode={resumeMode}
				setResumeMode={setResumeMode}
				selectedResume={selectedResume}
				setSelectedResume={setSelectedResume}
				isMultiResumes={isMultiResumes}
				resumes={resumes}
				handleAddResume={handleAddResume}
				isSorted={isSorted}
				sortAccordingToHardSkill={sortAccordingToHardSkill}
				setResumeName={setResumeName}
				setResumeTitle={setResumeTitle}
				setResumeLocation={setResumeLocation}
				setResumeContact={setResumeContact}
				setResumeSalary={setResumeSalary}
				resumeCandidateName={resumeCandidateName}
				resumeTitle={resumeTitle}
				resumeCity={resumeCity}
				resumePhone={resumePhone}
				resumeSalary={resumeSalary}
				isDisabled={isDisabled}
			/>
		)
}

export default JobDetailsContent
