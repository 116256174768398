import { Typography } from '@mui/material'
import { FC } from 'react'

const MobileResumeNoJobsFound: FC<{}> = () => {
	return (
		<>
			<Typography
				variant="h2"
				sx={{
					fontWeight: '600',
					fontSize: { lg: '24px', md: '20px', xs: '18px' },
					marginTop: {
						lg: '42px',
						sm: '28px',
						xs: '28px'
					},
					textAlign: 'center',
					lineHeight: '36px',
					letterSpacing: '0.1px',
					marginBottom: { lg: '30px', xs: '15px' }
				}}
			>
				No Resumes Found!
			</Typography>
		</>
	)
}

export default MobileResumeNoJobsFound
