import { useState, useEffect, SyntheticEvent } from 'react'
// Importing MUI
import { Box, Modal } from '@mui/material'

// Importing firebase
import { createUserWithEmailAndPassword, updateProfile as updateProfileFirebase } from 'firebase/auth'
import { auth } from '../../firebase'

// Importing for navigating to other pages
import { useNavigate } from 'react-router'

// Importing Styles
import '../../index.css'
import { useAuthState } from 'react-firebase-hooks/auth'
import { ROUTES } from '../../constants/routes'
import { useSearchParams } from 'react-router-dom'
import { setUserData } from '../../utils/authentication'
// import { AnonymousJobsCTX } from '../../contexts/anonymousJobs'
import CustomLoader from '../../components/CustomLoader'
import SignupSection1 from '../../components/SignupComponents/SignupSection1'

// const SignUp: FC = () => {
const SignUp = () => {
	const [searchParams] = useSearchParams()

	let callback = searchParams.get('callback')
	// const userType = searchParams.get('userType')
	// const via = searchParams.get('via')

	if (callback) callback = decodeURIComponent(callback)
	// navigate hook
	const navigate = useNavigate()

	// const { setHasAnonymousJobs, setAnonymousJobs } = useContext(AnonymousJobsCTX)

	// if (!userType) {
	// 	navigate(ROUTES.SIGNUP + (via ? '?via=' + via + '&' : '?') + (callback ? 'callback=' + encodeURIComponent(callback) + '&' : '') + 'userType=' + USER_TYPES.JOB_SEEKER)
	// } else if (userType !== USER_TYPES.JOB_SEEKER) {
	// 	navigate(ROUTES.SIGNUP + (via ? '?via=' + via + '&' : '?') + (callback ? 'callback=' + encodeURIComponent(callback) + '&' : '') + 'userType=' + USER_TYPES.JOB_SEEKER)
	// }

	// const x = useMotionValue(0)
	// const opacity = useTransform(x, [-200, 0, 200], [0, 1, 0])

	// Defining States
	const [referral, setReferral] = useState<string>('')
	const [firstName, setFirstName] = useState<string>('')
	const [lastName, setLastName] = useState<string>('')
	const [username, setUsername] = useState<string>('')
	const [email, setEmail] = useState<string>('')
	const [password, setPassword] = useState<string>('')
	const [confirmPassword, setConfirmPassword] = useState<string>('')

	const [isSignUpClicked, setIsSignUpClicked] = useState<boolean>(false)

	// const [feedback, setFeedback] = useState<string>("");
	const [, userLoading] = useAuthState(auth)

	const navigator = () => {
		if (callback) navigate(callback)
		if (auth?.currentUser?.isAnonymous) {
			navigate(ROUTES.OVERVIEW)
		} else navigate(ROUTES.PRICING)
	}

	// Sign Up Function
	const handleSignUpWithEmail = (e: SyntheticEvent) => {
		e.preventDefault()

		// if (password.length < 6) {
		//     alert("Password must be at least 6 characters long");
		//     return;
		// }

		if (password !== confirmPassword) {
			alert('Passwords do not match')
			return
		}

		if (firstName === '' || lastName === '' || username === '' || email === '' || password === '' || confirmPassword === '') {
			// alert("Please fill all the fields");
		} else {
			setIsSignUpClicked(true)
			createUserWithEmailAndPassword(auth, email, password)
				.then(async (userCredential) => {
					// Signed in
					const newUser = userCredential.user
					await updateProfileFirebase(newUser, {
						displayName: username
					})
					await setUserData({
						referral: referral === '' ? null : referral,
						firstName,
						lastName
					})
					navigator()
				})
				.catch((error) => {
					setIsSignUpClicked(false)
					const errorCode = error.code
					const errorMessage = error.message
					console.log('error is: ', errorCode, errorMessage)
					alert(`Error Signing Up: ${errorMessage}`)
					// ..
				})
		}
	}

	// Anonymous Login
	// const handleAnonymousSignIn = () => {
	// 	if (setHasAnonymousJobs && setAnonymousJobs) {
	// 		signInAnonymous(navigator, setHasAnonymousJobs, setAnonymousJobs, via ?? '', (userType as UserType) ?? null)
	// 	}
	// }

	useEffect(() => {
		;(window as any).rewardful('ready', () => {
			if ((window as any).Rewardful.referral) {
				setReferral((window as any).Rewardful.referral)
			} else {
				console.log('No Referral')
			}
		})
	}, [])

	return (
		<>
			<Box
				component="div"
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center'
				}}
			>
				<SignupSection1
					userLoading={userLoading}
					// handleAnonymousSignIn={handleAnonymousSignIn}
					navigate={navigate}
					callback={callback}
					handleSignUpWithEmail={handleSignUpWithEmail}
					firstName={firstName}
					setFirstName={setFirstName}
					email={email}
					setEmail={setEmail}
					password={password}
					setPassword={setPassword}
					confirmPassword={confirmPassword}
					setConfirmPassword={setConfirmPassword}
					username={username}
					setUsername={setUsername}
					lastName={lastName}
					setLastName={setLastName}
				/>
			</Box>
			<Modal open={isSignUpClicked} onClose={() => {}} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
				<Box>
					<CustomLoader />
				</Box>
			</Modal>
		</>
	)
}
export default SignUp
