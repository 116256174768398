import React, { FC } from 'react'
import { AnimatedText } from '../../libs'
import { CircularProgress, Box, Typography } from '@mui/material'

import logo from '../../assets/Navbars/Logo.png'

const CustomLoader: FC = () => {
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				mt: '30px'
			}}
		>
			<Box sx={{ display: 'flex', alignItems: 'center', mt: '100px' }}>
				<Box sx={{ display: 'block', width: '100px' }} component="img" src={logo} alt="logo" title="Logo" />
			</Box>
			<Typography
				sx={{
					color: 'text.dark',
					fontStyle: 'normal',
					fontWeight: '600',
					fontSize: { md: '24px', sm: '20px', xs: '18px' },
					lineHeight: '36px',
					letterSpacing: '0.15px'
				}}
				component="div"
			>
				<AnimatedText
					type="chars" // animate words or chars
					animation={{
						x: '200px',
						y: '-20px',
						scale: 1.1,
						ease: 'ease-in-out'
					}}
					animationType="wave"
					interval={0.3}
					duration={0.9}
					tag="p"
					className="animated-paragraph"
					includeWhiteSpaces
					threshold={0.1}
					rootMargin="20%"
				>
					Gambit Resume
				</AnimatedText>
			</Typography>
			<Box
				sx={{
					marginTop: '20px'
				}}
				component={'div'}
			>
				<CircularProgress color="primary" />
			</Box>
		</Box>
	)
}
export default CustomLoader
