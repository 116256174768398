import { Link } from 'react-router-dom'
import { Description, PhoneInTalk, Work, TableView } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import { ROUTES } from '../../constants/routes'
import ReferralModal from '../ReferralModal'
import { auth, db } from '../../firebase'
import { FC, useEffect } from 'react'
import { doc, DocumentData, DocumentSnapshot, onSnapshot } from 'firebase/firestore'
import { COLLECTION_NAMES } from '../../constants/collections'
import usePremiumStatus from '../../hooks/usePremiumStatus'
import SearchInput from '../Header/SearchInput'
import { useAuthState } from 'react-firebase-hooks/auth'
const navLinks = [
	{ icon: Work, text: 'Job Posts', to: ROUTES.JOBS },
	{ icon: TableView, text: 'Scan Multiple Resumes', to: ROUTES.MULTIPLE_RESUMES_JOBS },
	{ icon: Description, text: 'Resumes', to: ROUTES.RESUMES },
	{ icon: Description, text: 'Cover Letter', to: ROUTES.COVER_LETTER },
	{ icon: PhoneInTalk, text: 'Support', to: ROUTES.SUPPORT }
]

const NavLinks: FC = () => {
	return (
		<Box>
			<Box sx={{ paddingBottom: '1px', marginBottom: '-20px' }}>
				{navLinks.map((l) => (
					<Link key={l.to} style={{ textDecoration: 'none' }} to={l.to}>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								marginBottom: '28px'
							}}
						>
							{(location.pathname.startsWith(l.to) || (l.to === ROUTES.JOBS && location.pathname === ROUTES.OVERVIEW)) && (
								<Box
									sx={{
										borderRadius: ' 0px 100px 100px 0px;',
										backgroundColor: '#0062FF',
										width: '3px',
										height: '32px'
									}}
								></Box>
							)}
							<l.icon
								sx={{
									marginX: '22px',
									color: location.pathname.startsWith(l.to) || (l.to === ROUTES.JOBS && location.pathname === ROUTES.OVERVIEW) ? '#0062FF' : 'grey',
									width: '20px',
									height: '20px'
								}}
							/>
							<Typography
								component="p"
								sx={{
									margin: '0',
									fontWeight: '500',
									fontSize: '14px',
									lineHeight: '21px',
									color: location.pathname.startsWith(l.to) || (l.to === ROUTES.JOBS && location.pathname === ROUTES.OVERVIEW) ? '#0062FF' : '#171725',
									'&:hover,:active, &:focus': {
										fontWeight: '600'
									}
								}}
							>
								{l.text}
							</Typography>
						</Box>
					</Link>
				))}
			</Box>
		</Box>
	)
}

const LeftNavbar: FC = () => {
	const [isPremium, premiumLoading] = usePremiumStatus()
	const [user, userLoading] = useAuthState(auth)

	useEffect(() => {
		if (premiumLoading) return
		if (isPremium) return
		const listenerFunction = (snap: DocumentSnapshot<DocumentData>) => {}
		onSnapshot(doc(db, COLLECTION_NAMES.USERS_COLLECTION, auth.currentUser?.uid ?? ''), (snap) => {
			listenerFunction(snap)
		})
	}, [premiumLoading, isPremium])

	return (
		<>
			<Box
				className="nav-wrapper"
				sx={{
					boxShadow: 'inset -1px 0px 0px #E2E2EA;',
					background: 'white',
					display: { xs: 'none', sm: 'unset' },
					minWidth: { md: '247px', sm: '180px' },
					minHeight: screen.height - 70,
					position: 'relative'
				}}
			>
				<Box sx={{ position: 'fixed' }}>
					<SearchInput
						parenetWrapperStyles={{ margin: '15px 5px', border: '0.9px solid gray', borderRadius: '3px' }}
						inputWrapperStyles={{ paddingLeft: '0px' }}
						inputStyles={{ width: '185px', paddingRight: 0 }}
					/>
					<NavLinks />
					<Box marginTop="20px" marginX="15px">
						{!userLoading && !user?.isAnonymous && <ReferralModal />}
					</Box>
				</Box>
			</Box>
		</>
	)
}

export default LeftNavbar
