import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import App from './App'
import './index.css'
import theme from './theme'

ReactDOM.render(
	<StrictMode>
		<ThemeProvider theme={theme}>
			{/* MUI CssBaseline */}
			<CssBaseline />
			<App />
		</ThemeProvider>
	</StrictMode>,
	document.getElementById('root')
)
