import { Box, Tooltip, Button } from '@mui/material'
import { Dispatch, FC, SetStateAction } from 'react'
import { DonutLarge, Done, Save } from '@mui/icons-material'
import Edit from '../../assets/JobDetail/Edit.png'
import Delete from '../../assets/JobDetail/Delete.png'
import { JobStatus } from '../../models/Job'
import { JobStatuses } from '../../constants/jobs'

const JobTabs: FC<{
	isTab: boolean
	status: JobStatus
	progressBtnClickHandler: () => void
	jobLoading: boolean
	editMode: boolean
	updateJobDetails: () => void
	setEditMode: Dispatch<SetStateAction<boolean>>
	deleteJob: () => void
	isDisabled?: boolean
}> = ({ isTab, status, progressBtnClickHandler, jobLoading, editMode, updateJobDetails, setEditMode, deleteJob, isDisabled }) => {
	const progressStatus = status === 'in progress' ? 'Completed' : 'in progress'
	return (
		<Box sx={{ display: 'flex' }}>
			{isTab ? (
				<Box
					onClick={progressBtnClickHandler}
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						width: { lg: '36px', sm: '28px', xs: '26px' },
						height: { lg: '36px', sm: '28px', xs: '26px' },
						borderRadius: '6.4px',
						marginRight: { lg: '20px', xs: '10px' },
						cursor: 'pointer',
						border: `0.5px solid ${JobStatuses[progressStatus].textColor}`,
						background: JobStatuses[progressStatus].backgroundColor,
						'&:hover': {
							transform: 'translateY(0.5px)',
							background: JobStatuses[progressStatus].hoverBackgroundColor
						},
						'&:active, &:focus': {
							background: JobStatuses[progressStatus].hoverBackgroundColor,
							transform: 'translateY(1.3px)'
						}
					}}
				>
					<Tooltip title={status === 'in progress' ? 'Mark as done' : 'Mark as in progress'}>
						{status === 'in progress' ? (
							<Done
								sx={{
									width: {
										lg: 'full',
										xs: '17px',
										color: JobStatuses[progressStatus].textColor
									}
								}}
							/>
						) : (
							<DonutLarge
								sx={{
									width: {
										lg: 'full',
										xs: '17px',
										color: JobStatuses[progressStatus].textColor
									}
								}}
							/>
						)}
					</Tooltip>
				</Box>
			) : (
				<Button
					onClick={progressBtnClickHandler}
					sx={{
						padding: { lg: '8px 17px', xs: '5px 12px' },
						fontSize: { lg: '14px', xs: '12px' },
						fontWeight: '500',
						lineHeight: '21px',
						borderRadius: '10px',
						backgroundColor: 'transparent',
						color: JobStatuses[progressStatus].textColor,
						border: `1px solid ${JobStatuses[progressStatus].textColor}`,
						marginRight: { lg: '43px', sm: '20px', xs: '15px' },
						textTransform: 'none'
					}}
				>
					{jobLoading ? <>Loading ...</> : <>{status === 'in progress' ? 'Mark as Complete' : 'Mark as In Progress'}</>}
				</Button>
			)}
			<Tooltip title={editMode ? 'Update' : 'Edit'}>
				<Box
					onClick={
						editMode
							? () => {
									if (isDisabled) return
									updateJobDetails()
							  }
							: () => setEditMode(true)
					}
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						width: { lg: '36px', sm: '28px', xs: '26px' },
						height: { lg: '36px', sm: '28px', xs: '26px' },
						background: editMode ? '#04bf45' : '#0062FF',
						borderRadius: '6.4px',
						marginRight: { lg: '20px', xs: '10px' },
						cursor: 'pointer',
						'&:hover': {
							background: editMode ? (isDisabled ? '#ccc' : '#17e35e') : '#0062ffcc',
							transform: 'translateY(0.5px)'
						},
						'&:active, &:focus': {
							background: '#0062ffcc;',
							transform: 'translateY(1px)'
						},
						backgroundColor: isDisabled ? '#ccc' : undefined
					}}
				>
					{!editMode ? (
						<Box
							sx={{
								width: { lg: 'full', xs: '17px' },
								height: { lg: 'full', xs: '17px' }
							}}
							component="img"
							src={Edit}
						/>
					) : (
						<Save fontSize="small" sx={{ color: 'white' }} />
					)}
				</Box>
			</Tooltip>
			<Tooltip title="Delete">
				<Box
					onClick={() => deleteJob()}
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						width: { lg: '36px', sm: '28px', xs: '26px' },
						height: { lg: '36px', sm: '28px', xs: '26px' },
						borderRadius: '6.4px',
						marginRight: {
							lg: '50px',
							sm: '30px',
							xs: '25px'
						},
						border: '1px solid #FC5A5A',
						cursor: 'pointer',
						'&:hover': {
							background: '#fc5a5a17',
							transform: 'translateY(0.5px)'
						},
						'&:active, &:focus': {
							background: '#fc5a5a17',
							transform: 'translateY(1px)'
						}
					}}
				>
					<Box
						sx={{
							width: { md: '20px', xs: '17px' }
						}}
						component="img"
						src={Delete}
					/>
				</Box>
			</Tooltip>
		</Box>
	)
}

export default JobTabs
