import { Typography, Box, TextField } from '@mui/material'
import { FirestoreError } from 'firebase/firestore'
import { FC } from 'react'
const JobFieldsSections1Fields1: FC<{
	editMode: boolean
	company: string
	setCompany: (index: number, value: string) => void
	jobLoading: boolean
	setLocation: (index: number, value: string) => void
	location: string
	moreInfo: string
	setMoreInfo: (index: number, value: string) => void
	jobError: FirestoreError | undefined
	resumeIndex: number
	newResume?: boolean
}> = ({ editMode, company, setCompany, jobLoading, setLocation, location, moreInfo, setMoreInfo, jobError, resumeIndex }) => {
	return (
		<>
			<Typography component="p" sx={{ display: 'flex', alignItems: 'center' }}>
				{editMode ? (
					<Box>
						<TextField
							id="outlined-basic"
							label={'Company'}
							variant="outlined"
							value={company}
							onChange={(e) => setCompany(resumeIndex, e.target.value)}
							inputProps={{
								style: {
									height: 2
								}
							}}
							sx={{
								input: {
									color: '#44444F',
									fontWeight: '500',
									fontSize: '12px',
									lineHeight: '18px',
									letterSpacing: '0.1px',
									height: '18px'
								}
							}}
						/>
					</Box>
				) : (
					<Box
						component="span"
						sx={{
							color: '#44444F',
							fontWeight: '500',
							fontSize: { lg: '14px', xs: '12px' },
							lineHeight: '18px',
							letterSpacing: '0.1px'
						}}
					>
						{jobLoading ? 'Loading...' : company}
					</Box>
				)}

				<Box
					component="span"
					sx={{
						marginX: '7px',
						background: '#D8D8D8',
						width: '3px',
						height: '3px',
						borderRadius: '50%'
					}}
				></Box>

				{editMode ? (
					<Box>
						<TextField
							id="outlined-basic"
							label={'Location'}
							variant="outlined"
							value={location}
							onChange={(e) => setLocation(resumeIndex, e.target.value)}
							inputProps={{
								style: {
									height: 2
								}
							}}
							sx={{
								input: {
									color: '#44444F',
									fontWeight: '500',
									fontSize: '12px',
									lineHeight: '18px',
									letterSpacing: '0.1px',
									height: '18px'
								}
							}}
						/>
					</Box>
				) : (
					<Box
						component="span"
						sx={{
							color: '#92929D',
							fontWeight: '400',
							fontSize: { md: '14px', xs: '12px' },
							lineHeight: '16px',
							letterSpacing: '0.1px'
						}}
					>
						{jobLoading ? 'Loading...' : location}
					</Box>
				)}
			</Typography>

			{/* More Info Container Starts */}
			<Typography
				component="p"
				sx={{
					display: 'flex',
					alignItems: 'center',
					marginTop: '6px'
				}}
			>
				{editMode ? (
					<Box>
						<TextField
							id="outlined-basic"
							label={'more Info'}
							variant="outlined"
							value={moreInfo}
							onChange={(e) => setMoreInfo(resumeIndex, e.target.value)}
							inputProps={{
								style: {
									height: 2
								}
							}}
							sx={{
								input: {
									color: '#44444F',
									fontWeight: '500',
									fontSize: '12px',
									lineHeight: '18px',
									letterSpacing: '0.1px',
									height: '18px'
								}
							}}
						/>
					</Box>
				) : (
					<Box
						component="span"
						sx={{
							color: '#44444F',
							fontWeight: '500',
							fontSize: { lg: '14px', xs: '12px' },
							lineHeight: '18px',
							letterSpacing: '0.1px'
						}}
					>
						{jobLoading || jobError ? 'Loading...' : moreInfo}
					</Box>
				)}
			</Typography>
			{/* More Info Container Ends */}
		</>
	)
}

export default JobFieldsSections1Fields1
