import { useEffect, useState, FC } from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material'
import { RowType } from './jobType'
import { Button, Typography, Chip } from '@mui/material'
import { Close, Done } from '@mui/icons-material/'

interface JobAnalysisProps {
	rows: RowType[]
	markIrrelevantSkill: (skill: string, tableName: string) => void
	tableName: string
	related?: boolean
}

const FONT_SIZE = { xs: '9px', sm: '12px', lg: '14px' }

const JobAnalysisTable: FC<JobAnalysisProps> = ({ rows, markIrrelevantSkill, tableName, related = false }) => {
	const handleButtonClick = (skill: string) => {
		markIrrelevantSkill(skill, tableName)
	}

	const [rowsToShow, setRowsToShow] = useState<RowType[]>([])
	const [isExpanded, setIsExpanded] = useState<boolean>(false)

	const showLess = () => {
		setRowsToShow(rows.slice(0, 5))
		setIsExpanded(false)
	}

	const showMore = () => {
		setRowsToShow(rows)
		setIsExpanded(true)
	}

	const updateRows = () => {
		if (isExpanded) {
			showLess()
		} else {
			showMore()
		}
	}

	useEffect(() => {
		showLess()
	}, [rows])

	return rows.length > 0 ? (
		<>
			<TableContainer
				elevation={0}
				component={Paper}
				sx={{
					width: { xs: 'auto', sm: '100%' },
					overflowX: 'visible'
				}}
			>
				<Table
					sx={{
						minWidth: 280
					}}
					aria-label="sticky table"
				>
					<TableHead>
						<TableRow>
							<TableCell
								component="th"
								scope="row"
								sx={{
									fontWeight: 'bold',
									fontSize: FONT_SIZE
								}}
							>
								Skill
							</TableCell>
							<TableCell
								component="th"
								scope="row"
								sx={{
									fontWeight: 'bold',
									fontSize: FONT_SIZE
								}}
							>
								Resume
							</TableCell>
							{!related && (
								<>
									<TableCell
										component="th"
										scope="row"
										sx={{
											fontWeight: 'bold',
											fontSize: FONT_SIZE
										}}
									>
										JD
									</TableCell>
									<TableCell
										component="th"
										scope="row"
										sx={{
											fontWeight: 'bold',
											fontSize: FONT_SIZE
										}}
									>
										Gap
									</TableCell>
								</>
							)}

							<TableCell
								component="th"
								scope="row"
								sx={{
									fontWeight: 'bold',
									fontSize: FONT_SIZE
								}}
							>
								Not Relevant?
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{rowsToShow.map((row) => (
							<TableRow
								key={row.skill}
								sx={{
									'&:last-child td, &:last-child th': {
										border: 0
									}
								}}
							>
								<TableCell sx={{ fontSize: FONT_SIZE }}>{row.skill}</TableCell>
								<TableCell sx={{ fontSize: FONT_SIZE }}>{row.res_count}</TableCell>
								{!related && (
									<>
										<TableCell sx={{ fontSize: FONT_SIZE }}>{row.desc_count}</TableCell>
										<TableCell sx={{ fontSize: FONT_SIZE }}>
											{row.res_count - row.desc_count >= 0 ? (
												<Chip
													sx={{
														fontSize: FONT_SIZE,
														width: '40px'
													}}
													label={<Done fontSize="small" />}
													color="success"
													size="small"
												/>
											) : (
												<Chip
													sx={{
														fontSize: FONT_SIZE,
														width: '40px'
													}}
													label={row.res_count - row.desc_count}
													color="error"
													size="small"
												/>
											)}
										</TableCell>
									</>
								)}
								<TableCell sx={{ fontSize: FONT_SIZE }}>
									<Button
										variant="outlined"
										color="error"
										sx={{
											padding: '4px',
											minWidth: '10px'
										}}
										onClick={(e) => handleButtonClick(row.skill)}
										size="small"
									>
										<Close
											sx={{
												fontSize: FONT_SIZE
											}}
										/>
									</Button>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			{rows.length > 5 && (
				<Button
					variant="contained"
					component="label"
					color="info"
					sx={{
						marginTop: '10px',
						textTransform: 'none',
						borderRadius: '6px',
						paddingTop: '6px',
						paddingBottom: '6px',
						fontSize: { md: '18px', xs: '16px' },
						fontWeight: '600',
						lineHeight: { sm: '27px', xs: '24px' },
						letterSpacing: '2%',
						width: '100%'
					}}
					onClick={updateRows}
				>
					{isExpanded ? 'Show less skills' : 'Show more skills'}
				</Button>
			)}
		</>
	) : (
		<Typography
			component="p"
			sx={{
				fontWeight: '400',
				fontSize: '12px',
				lineHeight: '21px',
				marginBottom: { sm: '11px', xs: '5px' },
				marginTop: { sm: '18px', xs: '10px' }
			}}
		>
			No data found
		</Typography>
	)
}

export default JobAnalysisTable
