import { FC } from 'react'
import MobileJobSection2 from './MobileJobSection2'
import { Job } from '../../models/Job'
import { FirestoreError } from 'firebase/firestore'
import { ResumeInputMode } from './types'
import ResumeFields from '../ResumeFields'
import UploadResumes from './UploadResumes'
const MobileJobSection2Edit: FC<{
	resumes: Job[] | undefined
	isSorted?: boolean | undefined
	jobError: FirestoreError | undefined
	setResumeMode: ((index: number, value: ResumeInputMode) => void) | undefined
	editMode: boolean
	setJobText: ((index: number, value: string) => void) | undefined
	setFile: ((index: number, value: File | null) => void) | undefined
	percentAvg: number | undefined
	isMobile: boolean
	jobLoading?: boolean | undefined
	setSelectedResume: ((index: number, value: string) => void) | undefined
	selectedResume?: string | undefined
	setResumeName: (index: number, input: string) => void
	setResumeTitle: (index: number, input: string) => void
	setResumeLocation: (index: number, input: string) => void
	setResumeContact: (index: number, input: string) => void
	setResumeSalary: (index: number, input: string) => void
	handleAddResume?: (file?: File) => void
}> = ({
	resumes,
	isSorted,
	jobError,
	setResumeMode,
	editMode,
	setJobText,
	setFile,
	percentAvg,
	isMobile,
	jobLoading,
	setSelectedResume,
	selectedResume,
	setResumeName,
	setResumeTitle,
	setResumeLocation,
	setResumeContact,
	setResumeSalary,
	handleAddResume
}) => {
	return (
		<>
			<UploadResumes
				handleChange={(e) => {
					if (e.target.files && e.target.files.length > 0) {
						for (let i = 0; i < e.target.files.length; i++) handleAddResume && handleAddResume(e.target.files[i])
						alert(`${e.target.files.length} resume(s) added.`)
					}
					e.target.value = ''
				}}
			/>
			{resumes?.map((job, index) => {
				return (
					<>
						<ResumeFields
							index={index}
							name={job?.resumeCandidateName ?? ''}
							title={job?.resumeTitle ?? ''}
							location={job?.resumeCity ?? ''}
							contact={job?.resumePhone ?? ''}
							salary={job?.resumeSalary ?? ''}
							setName={setResumeName}
							setTitle={setResumeTitle}
							setLocation={setResumeLocation}
							setContact={setResumeContact}
							setSalary={setResumeSalary}
							resumeText={job?.resume ?? ''}
							isEdit
						/>
						<MobileJobSection2
							key={index}
							isFitScore={true}
							showResumeOnFooter={false}
							newResume={job?.newResume}
							isSorted={isSorted}
							jobError={jobError}
							index={index}
							isMultiResumes={true}
							resumeMode={job?.resumeMode}
							setResumeMode={setResumeMode}
							editMode={editMode}
							jobText={job?.resume}
							setJobText={setJobText}
							file={job?.file ?? null}
							setFile={setFile}
							description={job?.description}
							skillsOnly={job?.skillsOnly}
							percentAvg={percentAvg as number}
							isMobile={isMobile}
							percentHard={job?.percentage_hard}
							tableHard={job?.table_hard}
							jobLoading={jobLoading}
							setSelectedResume={setSelectedResume}
							selectedResume={selectedResume}
							resumeTitle={job?.resumeTitle}
							resumePhone={job?.resumePhone}
							resumeCity={job?.resumeCity}
							resumeEmail={job?.resumeEmail}
							resumeCandidateName={job?.resumeCandidateName}
							hideDescription={true}
							hideResume={false}
							showMoreDetails={false}
							fileName={job.fileName}
						/>
					</>
				)
			})}
		</>
	)
}

export default MobileJobSection2Edit
