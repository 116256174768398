import { useState, useEffect, FC, ChangeEvent, useContext } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useMediaQuery } from '@mui/material'
import theme from '../../../theme'
// import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { auth, db } from '../../../firebase'
import { doc, setDoc, arrayUnion, arrayRemove, Timestamp } from 'firebase/firestore'
// For referencing a single document
import { useDocument } from 'react-firebase-hooks/firestore'
import { calculateAveragePercentage } from '../../../utils'
import { RowType } from '../../../components/JobAnalysisTable/jobType'
import { TABLE_NAMES } from '../../../constants'
import { toast } from 'react-toastify'
import { analyzeJobDescription } from '../../../utils/job'
import { useAuthState } from 'react-firebase-hooks/auth'
import { ResumeAnalysis } from '../../../models/ResumeAnalysis'
import JobDetailsContent from '../../../components/JobDetailComponents/JobDetailsContent'
import { container, item } from '../../../components/JobDetailComponents/JobConstants'
import usePageTitle from '../../../hooks/usePageTitle'
import { ResumeInputMode } from '../../../components/JobDetailComponents/types'
import { Job, JobStatus } from '../../../models/Job'
import { ROUTES } from '../../../constants/routes'
import { LoaderContext } from '../../../contexts/loader-context'

const JobDetail: FC = () => {
	const navigate = useNavigate()
	const [setPagetitle] = usePageTitle()
	const [selectedResume, setSelectedResume] = useState<string>('')
	const [resumeMode, setResumeMode] = useState<ResumeInputMode>('text')
	const [file, setFile] = useState<File | null>(null)
	const [status, setStatus] = useState<JobStatus>('draft')
	// const [loading, setLoading] = useState<Boolean>(true);

	// States for Editing Jobs
	const [editMode, setEditMode] = useState<boolean>(false)

	const [tableRelated, setTableRelated] = useState<RowType[]>([])
	const [tableHard, setTableHard] = useState<RowType[]>([])
	const [tableSoft, setTableSoft] = useState<RowType[]>([])
	const [topTitles, setTopTitles] = useState<string[]>([])
	const [resumeAnalysis, setResumeAnalysis] = useState<ResumeAnalysis>({})

	const [percentAvg, setPercentAvg] = useState<number>(0.0)
	const [percentHard, setPercentHard] = useState<number>(0.0)
	const [percentSoft, setPercentSoft] = useState<number>(0.0)
	const [percentSales, setPercentSales] = useState<number>(0.0)
	const [jobText, setJobText] = useState('')
	const [title, setTitle] = useState<string>('')
	const [company, setCompany] = useState<string>('')
	const [location, setLocation] = useState<string>('')
	const [description, setDescription] = useState<string>('')
	const [moreInfo, setMoreInfo] = useState<string>('')
	const [created, setCreated] = useState<Timestamp | null>(null)
	const [skillsOnly, setSkillsOnly] = useState<boolean>(false)
	const [resumeCandidateName, setResumeCandidateName] = useState<string | undefined>('')
	const [resumeTitle, setResumeTitle] = useState<string | undefined>('')
	const [resumeCity, setResumeCity] = useState<string | undefined>('')
	const [resumePhone, setResumePhone] = useState<string | undefined>('')
	const [resumeSalary, setResumeSalary] = useState<string | undefined>('')
	const [oldDescription, setOldDescription] = useState<string>('')
	const [oldGptData, setOldGptDescription] = useState<string>('')
	const { setShowLoading } = useContext(LoaderContext)
	// Get the userId param from the URL.
	const { id } = useParams()

	const [user] = useAuthState(auth)

	const jobRef = doc(db, 'Jobs', id ?? '')

	const [jobSnap, jobLoading, jobError] = useDocument(jobRef, {
		snapshotListenOptions: { includeMetadataChanges: true }
	})

	useEffect(() => {
		setShowLoading(jobLoading)
	}, [jobLoading])

	const skillsCheckboxHandler = async (index: number, e: ChangeEvent<HTMLInputElement>): Promise<void> => {
		const checked = e.target.checked
		setSkillsOnly(checked)
		await setDoc(
			jobRef,
			{
				skillsOnly: checked
			},
			{
				merge: true
			}
		)
	}

	const markInaccurateTitle = async (index: number, title: string): Promise<void> => {
		await setDoc(
			jobRef,
			{
				inaccurate_titles: arrayUnion(title),
				top_titles: arrayRemove(title)
			},
			{ merge: true }
		)
	}

	const addSuggestedSkill = async (index: number, tableName: string, skill: string): Promise<void> => {
		await setDoc(
			jobRef,
			{
				[`${tableName}_suggested`]: arrayUnion(skill)
			},
			{ merge: true }
		)
	}

	const markIrrelevantSkill = async (index: number, skill: string, tableName: string): Promise<void> => {
		let tempTable: RowType[] = []
		const updatedDoc: any = {}
		switch (tableName) {
			case TABLE_NAMES.HARD_SKILLS:
				tempTable = tableHard.filter((row) => row.skill !== skill)
				setTableHard([...tempTable])
				break

			case TABLE_NAMES.SOFT_SKILLS:
				tempTable = tableSoft.filter((row) => row.skill !== skill)
				setTableSoft([...tempTable])
				break

			case TABLE_NAMES.RELATED_SKILLS:
				tempTable = tableRelated.filter((row) => row.skill !== skill)
				setTableRelated([...tempTable])
				break

			default:
				break
		}
		updatedDoc[tableName] = tempTable
		await setDoc(
			jobRef,
			{
				...updatedDoc,
				irrelevant_words: arrayUnion(skill)
			},
			{ merge: true }
		)
	}

	useEffect(() => {
		if (jobError !== undefined && jobError.code === 'permission-denied') {
			toast("You don't have permission to view the Job", {
				type: 'error'
			})
			navigate(ROUTES.OVERVIEW)
		}
	}, [jobError, navigate])

	useEffect(() => {
		if (!jobLoading && jobError == null) {
			if (!jobSnap?.exists()) {
				navigate(ROUTES.OVERVIEW)
				return
			}

			const data = jobSnap.data() as Job

			setStatus(data.status)
			setTitle(data.title)
			setCompany(data.company)
			setLocation(data.location)
			setDescription(data.description)
			setMoreInfo(data.moreInfo)
			setTableHard([...data.table_hard])
			setTableSoft([...data.table_soft])
			setTableRelated([...data.table_related])
			setTopTitles([...data.top_titles])
			setPercentHard(data.percentage_hard)
			setPercentSoft(data.percentage_soft)
			setPercentSales(data.percentage_sales)
			setResumeAnalysis(data.resume_analysis)
			setJobText(data.resume)
			setCreated(data.created)
			setSkillsOnly(data.skillsOnly)
			setSelectedResume(data.selectedResume)
			setResumeMode(data.resumeMode)
			setResumeCandidateName(data?.resumeCandidateName)
			setResumeTitle(data?.resumeTitle)
			setResumeCity(data?.resumeCity)
			setResumePhone(data?.resumePhone)
			setResumeSalary(data?.resumeSalary)
			setOldDescription(data.description)
			setOldGptDescription(data?.gpt_data ?? '')
		}
	}, [jobLoading, jobSnap, jobError, navigate])

	useEffect(() => {
		if (editMode) {
			const { analyzedTitle, analyzedCompany, analyzedLocation, analyzedMoreInfo } = analyzeJobDescription(description)
			setTitle(analyzedTitle)
			setCompany(analyzedCompany)
			setLocation(analyzedLocation)
			setMoreInfo(analyzedMoreInfo)
		}
	}, [description])

	useEffect(() => {
		setPercentAvg(calculateAveragePercentage(skillsOnly, percentHard, percentSoft, percentSales))
	}, [percentHard, percentSoft, percentSales, skillsOnly])

	const progressBtnClickHandler = async (): Promise<void> => {
		if (status) {
			const newStatus = status === 'in progress' ? 'Completed' : 'in progress'
			if (status === 'draft' ? confirm('Are you sure you want to mark this job as an active one?') : true) {
				await setDoc(
					jobRef,
					{
						status: newStatus
					},
					{
						merge: true
					}
				)
			}
		} else {
			alert('Error')
		}
	}

	useEffect(() => {
		setPagetitle(title || '', moreInfo)
	}, [title, moreInfo])

	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
	const isTab = useMediaQuery(theme.breakpoints.down('md'))

	const updateResumeMetadata = (index: number, key: string, value: string) => {
		switch (key) {
			case 'resumeCandidateName':
				setResumeCandidateName(value)
				return
			case 'resumeTitle':
				setResumeTitle(value)
				return
			case 'resumeCity':
				setResumeCity(value)
				return
			case 'resumePhone':
				setResumePhone(value)
				return
			case 'resumeSalary':
				setResumeSalary(value)
		}
	}

	return (
		<JobDetailsContent
			isMultiResumes={false}
			resumes={[]}
			resumeMode={resumeMode}
			setResumeMode={(index, value) => setResumeMode(value)}
			jobError={jobError}
			navigate={navigate}
			container={container}
			jobLoading={jobLoading}
			progressBtnClickHandler={progressBtnClickHandler}
			isTab={isTab}
			editMode={editMode}
			setEditMode={setEditMode}
			company={company}
			setCompany={(index, value) => setCompany(value)}
			setTitle={(index, value) => setTitle(value)}
			setLocation={(index, value) => setLocation(value)}
			moreInfo={moreInfo}
			setMoreInfo={(index, value) => setMoreInfo(value)}
			location={location}
			created={created}
			jobRef={jobRef}
			user={user}
			jobText={jobText}
			setJobText={(index, value) => setJobText(value)}
			file={file}
			setFile={(index, value) => setFile(value)}
			description={description}
			setDescription={(index, value) => setDescription(value)}
			skillsOnly={skillsOnly}
			skillsCheckboxHandler={skillsCheckboxHandler}
			percentAvg={percentAvg}
			isMobile={isMobile}
			addSuggestedSkill={addSuggestedSkill}
			percentHard={percentHard}
			tableHard={tableHard}
			markIrrelevantSkill={markIrrelevantSkill}
			percentSoft={percentSoft}
			tableSoft={tableSoft}
			percentSales={percentSales}
			tableRelated={tableRelated}
			resumeAnalysis={resumeAnalysis}
			topTitles={topTitles}
			markInaccurateTitle={markInaccurateTitle}
			status={status}
			item={item}
			title={title}
			setSelectedResume={(index, value) => setSelectedResume(value)}
			selectedResume={selectedResume}
			setResumeName={(index, value) => updateResumeMetadata(index, 'resumeCandidateName', value)}
			setResumeTitle={(index, value) => updateResumeMetadata(index, 'resumeTitle', value)}
			setResumeLocation={(index, value) => updateResumeMetadata(index, 'resumeCity', value)}
			setResumeContact={(index, value) => updateResumeMetadata(index, 'resumePhone', value)}
			setResumeSalary={(index, value) => updateResumeMetadata(index, 'resumeSalary', value)}
			resumeCandidateName={resumeCandidateName}
			resumeTitle={resumeTitle}
			resumeCity={resumeCity}
			resumePhone={resumePhone}
			resumeSalary={resumeSalary}
			oldDescription={oldDescription}
			oldGptData={oldGptData}
		/>
	)
}
export default JobDetail
