import { Dispatch, SetStateAction, FC, MouseEventHandler, useState } from 'react'
import CustomTextField from '../../../AddJobDialog/CustomTextField'
import { Box, Button, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'

interface Props {
	skills: string[]
	setSkills: Dispatch<SetStateAction<string[]>>
	label?: string
	placeholder?: string
}

const SkillsForm: FC<Props> = ({ skills, setSkills, label = 'Skills', placeholder = 'Skills: (e.g React, Java)' }) => {
	const [input, setInput] = useState<string>('')

	const skillAddHandler: MouseEventHandler<HTMLButtonElement> = (e) => {
		if (input !== '') {
			setSkills([...new Set([...skills, input])])
			setInput('')
		}
	}

	return (
		<>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: { md: '48px', sm: '20px', xs: '15px' }
				}}
			>
				<Box
					sx={{
						display: 'flex',
						gap: '10px',
						alignItems: 'end'
					}}
				>
					<Box
						sx={{
							width: '100%'
						}}
					>
						<CustomTextField
							name={label}
							value={input}
							onChange={(e) => setInput(e.target.value)}
							placeholder={placeholder}
							type="text"
							onEnter={() => {
								if (input !== '') {
									setSkills([...new Set([...skills, input])])
									setInput('')
								}
							}}
						/>
					</Box>
					<Button
						sx={{
							textTransform: 'none',
							borderRadius: '12px',
							marginLeft: '5px',
							padding: {
								md: '16px 24px;',
								sm: '10px 16px;',
								sx: '8px 16px'
							},
							fontSize: { md: '18px', xs: '16px' },
							fontWeight: '600',
							lineHeight: { sm: '27px', xs: '24px' },
							letterSpacing: '2%',
							width: { xs: '50%', sm: 'unset' },
							height: '70px'
						}}
						variant="contained"
						color="primary"
						onClick={skillAddHandler}
					>
						Add
					</Button>
				</Box>
				<Box
					sx={{
						display: 'flex',
						gap: '10px',
						flexWrap: 'wrap'
					}}
				>
					{skills.map((skill) => (
						<Box
							key={skill}
							sx={{
								display: 'inline-flex',
								borderRadius: '10px',
								padding: '5px',
								backgroundColor: 'lightgreen',
								maxWidth: 'fit-content'
							}}
						>
							<Typography>{skill}</Typography>
							<Close onClick={() => setSkills([...skills.filter((temp) => temp !== skill)])} sx={{ cursor: 'pointer' }} />
						</Box>
					))}
				</Box>
			</Box>
		</>
	)
}

export default SkillsForm
