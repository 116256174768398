import { Box, Button, Divider, List, ListItem, Modal, SxProps, TextField, Typography } from '@mui/material'
import { useState, useEffect, FC } from 'react'
import ShareIcon from '@mui/icons-material/Share'
import { isValidEmail } from '../../utils/email'
import { DocumentData, DocumentReference } from 'firebase/firestore'
import { shareJob } from '../../utils/job'
import { toast } from 'react-toastify'

interface ShareModalProps {
	docRef: DocumentReference<DocumentData>
	jobTitle: string | undefined
	location: string | undefined
	moreInfo: string | undefined
	userName: string | undefined
	isMobile?: boolean
}

const MODAL_STYLE: SxProps = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 500,
	bgcolor: 'background.paper',
	boxShadow: 24,
	borderRadius: 3,
	p: 2,
	outline: 0
}

const ShareModal: FC<ShareModalProps> = ({ docRef, jobTitle, location, moreInfo, userName, isMobile = false }) => {
	const [email, setEmail] = useState<string>('')
	const [open, setOpen] = useState<boolean>(false)
	const [subject, setSubject] = useState<string>('')
	const [message, setMessage] = useState<string>('')
	const [error, setError] = useState<boolean>(false)

	const closeModal = (): void => {
		setEmail('')
		setOpen(false)
	}

	const shareHandler = async (): Promise<void> => {
		if (isValidEmail(email)) {
			setError(false)
			try {
				await shareJob(email, subject, message, docRef)
				toast.success('Job successfully shared')
			} catch (err) {
				console.log(err)
			}
			closeModal()
		} else {
			setError(true)
		}
	}

	useEffect(() => {
		setSubject(`Take a Look at ${jobTitle !== '' ? jobTitle : 'this job'} ${location !== '' ? 'in ' + (location ?? '') + ' ' : ''}${moreInfo !== '' ? '(' + (moreInfo ?? '') + ')' : ''}`)
	}, [jobTitle, location, moreInfo])

	useEffect(() => {
		setMessage(`${userName !== '' ? userName : 'someone'} wants to share with you this job details`)
	}, [userName])

	return (
		<>
			<Button
				variant="contained"
				color="primary"
				sx={{
					paddingX: '10px',
					fontSize: '16px'
				}}
				onClick={() => setOpen(true)}
				size={isMobile ? 'small' : 'medium'}
			>
				<ShareIcon
					sx={{
						marginRight: '4px',
						fontSize: '20px'
					}}
				/>
				Share
			</Button>
			<Modal open={open} onClose={closeModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
				<Box sx={MODAL_STYLE}>
					<Typography
						id="modal-modal-title"
						variant="h6"
						component="h2"
						sx={{
							mb: 4,
							mt: 0,
							fontWeight: 'bold'
						}}
					>
						Share job with user
					</Typography>
					<Divider />
					<Typography id="modal-modal-description" sx={{ mt: 2 }}>
						Email:
					</Typography>
					<TextField
						InputProps={{
							style: {
								padding: '8px'
							}
						}}
						inputProps={{
							style: {
								fontSize: '14px',
								padding: '0px'
							}
						}}
						type="email"
						placeholder="abc@example.com"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>
					<Typography id="modal-modal-description" sx={{ mt: 1 }}>
						Title:
					</Typography>
					<TextField
						sx={{
							width: '100%'
						}}
						InputProps={{
							style: {
								padding: '8px'
							}
						}}
						inputProps={{
							style: {
								fontSize: '14px',
								padding: '0px'
							}
						}}
						type="text"
						placeholder="Job edit invitation"
						value={subject}
						onChange={(e) => setSubject(e.target.value)}
					/>
					<Typography id="modal-modal-description" sx={{ mt: 1 }}>
						Message:
					</Typography>
					<TextField
						InputProps={{
							style: {
								padding: '2px 6px'
							}
						}}
						sx={{
							width: '100%'
						}}
						inputProps={{
							style: {
								fontSize: '14px'
							}
						}}
						multiline
						rows={4}
						type="text"
						placeholder="some custom message to the receiver"
						value={message}
						onChange={(e) => setMessage(e.target.value)}
					/>
					{error && (
						<Typography
							sx={{
								mt: 1,
								fontSize: '12px'
							}}
							color="red"
						>
							Please enter valid email
						</Typography>
					)}
					<Divider
						sx={{
							my: 2
						}}
					/>
					<Box>
						<Typography component="p" fontSize="large" fontWeight="bold">
							The Person You are Sharing With :
						</Typography>
						<List disablePadding>
							<ListItem disablePadding sx={{ fontSize: '16px' }}>
								1- Will be able to View and Edit the job details and resume.
							</ListItem>
							<ListItem disablePadding sx={{ fontSize: '16px' }}>
								2- Will be able to Share This job.
							</ListItem>
							<ListItem disablePadding sx={{ fontSize: '16px' }}>
								3- Will Be Not Be Able to View, Edit or Share Jobs You did Not Share with them.
							</ListItem>
						</List>
					</Box>
					<Divider
						sx={{
							mt: 4
						}}
					/>
					<Box
						sx={{
							mt: 4,
							display: 'flex',
							justifyContent: 'right'
						}}
					>
						<Button
							variant="contained"
							sx={{
								marginRight: '12px'
							}}
							onClick={shareHandler}
							color="info"
						>
							Share
						</Button>
						<Button variant="contained" color="error" onClick={closeModal}>
							Close
						</Button>
					</Box>
				</Box>
			</Modal>
		</>
	)
}

export default ShareModal
