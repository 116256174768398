import { FC } from 'react'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const AdminHideShowIcon: FC<{
	hideUserList: boolean
}> = ({ hideUserList }) => {
	return (
		<>
			{hideUserList ? (
				<ExpandLessIcon
					style={{
						fontSize: '2rem'
					}}
				/>
			) : (
				<ExpandMoreIcon
					style={{
						fontSize: '2rem'
					}}
				/>
			)}
		</>
	)
}

export default AdminHideShowIcon
