import { Edit } from '@mui/icons-material'
import { Box, Button, TextField, Typography, useMediaQuery } from '@mui/material'
import { FC, Dispatch, SetStateAction } from 'react'
import ArrowLeft from '../../../assets/JobDetail/ArrowLeft.png'
import { NavigateFunction } from 'react-router-dom'
import CLFormField from './CLFormField'
import { getRandomParagraph } from '../../../constants/coverLetter'
import CustomFileField from '../../AddJobDialog/CustomFileField'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import theme from '../../../theme'
import { createPDF, downloadPDF, getPDFPreview } from '../../../utils/coverLetter'
import { ROUTES } from '../../../constants/routes'
import { PDF_WORKER_URL } from '../../../constants'
import { Viewer, Worker } from '@react-pdf-viewer/core'
import '@react-pdf-viewer/core/lib/styles/index.css'

const TrySample = ({ setSampleText }: { setSampleText: () => void }) => (
	<Typography sx={{ marginX: '10px', color: '#0062FF', cursor: 'pointer' }} onClick={setSampleText}>
		Try Sample
	</Typography>
)
const CoverLetterForm: FC<{
	contact: string
	setContact: Dispatch<SetStateAction<string>>
	location: string
	setLocation: Dispatch<SetStateAction<string>>
	clTitle: string
	setclTitle: Dispatch<SetStateAction<string>>
	clName: string
	setclName: Dispatch<SetStateAction<string>>
	openingParagraph: string
	setOpeningParagraph: Dispatch<SetStateAction<string>>
	greetings: string
	setGreetings: Dispatch<SetStateAction<string>>
	closingParagraph: string
	setClosingParagraph: Dispatch<SetStateAction<string>>
	signature: string
	setSignature: Dispatch<SetStateAction<string>>
	editTitle: boolean
	setEditTitle: Dispatch<SetStateAction<boolean>>
	date: Date
	setDate: Dispatch<SetStateAction<Date>>
	navigate: NavigateFunction
	updateCoverLetterDetails: () => void
}> = ({
	contact,
	setContact,
	location,
	setLocation,
	clTitle,
	setclTitle,
	clName,
	setclName,
	openingParagraph,
	setOpeningParagraph,
	closingParagraph,
	setClosingParagraph,
	signature,
	setSignature,
	editTitle,
	setEditTitle,
	date,
	setDate,
	greetings,
	setGreetings,
	navigate,
	updateCoverLetterDetails
}) => {
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
	const pdf = createPDF(clName, date, contact, location, greetings, openingParagraph, closingParagraph, signature)
	const pdfPreview = getPDFPreview(pdf)
	const coverLetterDownloadHandler = () => {
		downloadPDF(pdf, clTitle)
	}
	return (
		<Box sx={{ flexGrow: 1, paddingX: { lg: '40px', sm: '20px', xs: '12px' }, paddingY: '30px' }}>
			<Box sx={{ display: 'flex', gap: '15px', flexDirection: { xs: 'column', sm: 'row' }, alignItems: 'center', paddingBottom: '20px', justifyContent: 'space-between' }}>
				<Box sx={{ display: 'flex', alignItems: 'center', alignSelf: 'flex-start' }}>
					<Box
						onClick={() => navigate(ROUTES.COVER_LETTER)}
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							backgroundColor: '#171725',
							boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.02);',
							borderRadius: '50%',
							cursor: 'pointer',
							width: { lg: '46px', sm: '35px', xs: '30px' },
							height: { lg: '46px', sm: '35px', xs: '30px' }
						}}
					>
						<Box component="img" src={ArrowLeft} />
					</Box>
					{!editTitle ? (
						<Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', cursor: 'pointer' }} onClick={() => setEditTitle(true)}>
							<Typography
								variant="h2"
								sx={{
									fontWeight: '600',
									fontSize: { lg: '24px', md: '20px', xs: '17px' },
									lineHeight: '36px',
									letterSpacing: '0.1px',
									marginLeft: { sm: '18px', xs: '12px' },
									color: '#171725'
								}}
							>
								{clTitle}
							</Typography>
							<Edit />
						</Box>
					) : (
						<Box sx={{ marginLeft: { sm: '18px', xs: '12px' } }}>
							<TextField
								autoFocus
								id="outlined-basic"
								label={'Cover Letter Name'}
								variant="outlined"
								value={clTitle}
								onChange={(e) => setclTitle(e.target.value)}
								onBlur={() => setEditTitle(false)}
								sx={{ input: { color: '#44444F', fontWeight: '500', lineHeight: '18px', letterSpacing: '0.1px', height: '18px' } }}
								InputProps={{ endAdornment: <Edit /> }}
							/>
						</Box>
					)}
				</Box>
				<Box sx={{ display: 'flex', alignSelf: 'flex-end' }}>
					<Button
						sx={{
							textTransform: 'none',
							borderRadius: '12px',
							marginLeft: '5px',
							padding: '4px 20px',
							fontSize: { md: '18px', xs: '16px' },
							fontWeight: '600'
						}}
						variant="contained"
						color="primary"
						onClick={coverLetterDownloadHandler}
					>
						Download
					</Button>
					<Button
						sx={{
							textTransform: 'none',
							borderRadius: '12px',
							marginLeft: '5px',
							padding: '4px 20px',
							fontSize: { md: '18px', xs: '16px' },
							fontWeight: '600'
						}}
						variant="contained"
						color="primary"
						onClick={updateCoverLetterDetails}
					>
						Save Cover Letter
					</Button>
				</Box>
			</Box>
			<Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: '10px', justifyContent: 'space-between' }}>
				<Box
					sx={{
						backgroundColor: 'white',
						justifyContent: 'space-between',
						padding: { md: '32px', sm: '25px', xs: '20px' },
						width: { xs: '100%', md: '49%' },
						borderRadius: '20px',
						marginTop: '19px'
					}}
				>
					<Box sx={{ display: 'flex', flexDirection: 'column', gap: { md: '48px', sm: '20px', xs: '15px' } }}>
						<CLFormField name="Name" value={clName} onChange={(e) => setclName(e.target.value)} placeholder="John Doe" type="text" />
						<Box>
							<Box sx={{ position: 'relative', display: 'flex', alignItems: 'center', marginBottom: { sm: '16px', xs: '10px' } }}>
								<Typography component="p" sx={{ fontWeight: { sm: '600', xs: '700' }, fontSize: { sm: '18px', xs: '14px' }, lineHeight: { sm: '27px', xs: '18px' } }}>
									Date
								</Typography>
							</Box>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								{isMobile ? (
									<MobileDatePicker
										inputFormat="MM/DD/YYYY"
										value={date}
										InputProps={{ disableUnderline: true }}
										onChange={(newDate) => setDate(newDate ?? new Date())}
										renderInput={(params) => <TextField sx={{ background: '#F6F6F6', padding: '20px 16px' }} variant="standard" id="Abc" fullWidth {...params} />}
									/>
								) : (
									<DesktopDatePicker
										inputFormat="MM/DD/YYYY"
										value={date}
										InputProps={{ disableUnderline: true }}
										onChange={(newDate) => setDate(newDate ?? new Date())}
										renderInput={(params) => <TextField sx={{ background: '#F6F6F6', padding: '20px 16px' }} variant="standard" id="Abc" fullWidth {...params} />}
									/>
								)}
							</LocalizationProvider>
						</Box>
						<CLFormField name="Contact" value={contact} onChange={(e) => setContact(e.target.value)} placeholder="abc@example.com" type="contact">
							<Box sx={{ flexGrow: 1, margin: '0 10px' }}>
								<CustomFileField
									jobText={''}
									setJobText={() => {}}
									file={null}
									setFile={() => {}}
									resumeMode="resumes"
									setSelectedResume={setContact}
									selectedResume={contact}
									selectedResumeSelectionKey="contact"
									dropdownLabel="Select from Resumes"
								/>
							</Box>
						</CLFormField>
						<CLFormField name="Location" value={location} onChange={(e) => setLocation(e.target.value)} placeholder="Location" type="text">
							<Box sx={{ flexGrow: 1, margin: '0 10px' }}>
								<CustomFileField
									jobText={''}
									setJobText={() => {}}
									file={null}
									setFile={() => {}}
									resumeMode="resumes"
									setSelectedResume={setLocation}
									selectedResume={location}
									selectedResumeSelectionKey="location"
									dropdownLabel="Select from Resumes"
								/>
							</Box>
						</CLFormField>
						<CLFormField multiline name="Greetings" value={greetings} onChange={(e) => setGreetings(e.target.value)} placeholder="Greetings" type="text"></CLFormField>
						<CLFormField multiline name="Opening Paragraph" value={openingParagraph} onChange={(e) => setOpeningParagraph(e.target.value)} placeholder="Opening Paragraph" type="text">
							<TrySample setSampleText={() => setOpeningParagraph(getRandomParagraph('CL_OPENING_PARAGRAPH_DEFAULT_TEXT'))} />
						</CLFormField>
						<CLFormField multiline name="Closing Paragraph" value={closingParagraph} onChange={(e) => setClosingParagraph(e.target.value)} placeholder="City" type="text">
							<TrySample setSampleText={() => setClosingParagraph(getRandomParagraph('CL_CLOSING_PARAGRAPH_DEFAULT_TEXT'))} />
						</CLFormField>
						<CLFormField multiline name="Signature" value={signature} onChange={(e) => setSignature(e.target.value)} placeholder="Signature" type="text">
							<TrySample setSampleText={() => setSignature(getRandomParagraph('CL_SIGNATURE_DEFAULT_TEXT'))} />
						</CLFormField>
					</Box>
				</Box>
				<Box sx={{ width: { xs: '100%', md: '49%' }, marginTop: '19px', position: 'relative', borderRadius: '20px' }}>
					<Box sx={{ position: { xs: 'static', md: 'sticky' }, top: '75px' }}>
						<Worker workerUrl={PDF_WORKER_URL}>
							<Viewer fileUrl={pdfPreview} />
						</Worker>
					</Box>
				</Box>
			</Box>
		</Box>
	)
}

export default CoverLetterForm
