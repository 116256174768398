import { DocumentData, Firestore, doc, getDoc } from 'firebase/firestore'
import { Job } from '../models/Job'
import { COLLECTION_NAMES } from '../constants/collections'
import { User } from 'firebase/auth'

export const makeid = (length: number) => {
	let result = ''
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
	const charactersLength = characters.length
	for (let i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength))
	}
	return result
}

export const sortResumes = (resumes: Job[], callback: (arg1: Job, arg2: Job) => number) => {
	const resumesCopy = [...(resumes ?? [])]
	let commonData = null
	if (resumesCopy[0]) {
		commonData = {
			groupId: resumesCopy ? resumesCopy[0]?.groupId : '',
			title: resumesCopy ? resumesCopy[0]?.title : '',
			company: resumesCopy ? resumesCopy[0]?.company : '',
			location: resumesCopy ? resumesCopy[0]?.location : '',
			description: resumesCopy ? resumesCopy[0]?.description : '',
			moreInfo: resumesCopy ? resumesCopy[0]?.moreInfo : '',
			status: resumesCopy ? resumesCopy[0]?.status : ''
		}
	}
	resumesCopy?.sort(callback)
	if (commonData) {
		const clonedFirstResume = { ...(resumesCopy[0] ?? {}), ...commonData }
		if (resumesCopy) resumesCopy[0] = clonedFirstResume as Job
	}
	return resumesCopy
}

export const formatedDate = (date: Date): string => {
	const day = date.getDate()
	const month = date.getMonth()
	const year = date.getFullYear()
	return `${month + 1 >= 10 ? month + 1 : '0' + String(month + 1)}/${day >= 10 ? day : '0' + String(day)}/${year}`
}

export const getResumeData = (
	db: Firestore,
	user: User | null | undefined,
	selectedResume: string,
	success: (arg: {
		metaData: {
			resumeCandidateName: string
			resumeTitle: string
			resumePhone: string
			resumeCity: string
			resumeEmail: string
		}
		data: DocumentData
	}) => void
) => {
	getDoc(doc(db, COLLECTION_NAMES.USERS_COLLECTION, user ? user.uid : '', COLLECTION_NAMES.RESUMES, selectedResume)).then((resumeDoc) => {
		if (resumeDoc.exists()) {
			const data = resumeDoc.data()
			success({
				metaData: {
					resumeCandidateName: data.fullName ?? '',
					resumeTitle: data.title ?? '',
					resumePhone: data.phone ?? '',
					resumeCity: data.city ?? '',
					resumeEmail: data.email ?? ''
				},
				data
			})
		}
	})
}

export function toTitleCase(text: string) {
	return text
		? text
				.split(' ')
				?.map((textToken) => {
					return textToken[0].toUpperCase() + textToken.substring(1, textToken.length)
				})
				?.join(' ')
		: ''
}

export function getTextByType(text: string, type: 1 | 2) {
	if (type === 1) {
		return toTitleCase(text)
	}

	if (type === 2) {
		return text.toUpperCase()
	}

	return text
}

export async function findNameFromCsv(text: string, filename: string, type: 1 | 2, flags = 'gi', isLowerCase = true) {
	try {
		const result = await fetch(`/csvs/${filename}`)
		const namesText = await result.text()
		if (namesText) {
			const namesArray = namesText.split(',').filter((data) => data)
			const namesRegexp = new RegExp('\\b' + namesArray.join('\\b|\\b') + '\\b', flags)
			const names = namesRegexp.exec(isLowerCase ? text.toLowerCase() : text)
			const name = getTextByType(names?.[0] ?? '', type)
			return name
		}
		return null
	} catch (error) {
		return null
	}
}

export function getContactInfo(text: string) {
	try {
		const phoneRegex = /(\+\d{1,2}[\s-]?)?(\(\d{3}\)|\d{3})[\s-]?\d{3}[\s-]?\d{4}/g
		const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/g
		const emails = text.match(emailRegex)
		const phoneNumbers = text.match(phoneRegex)
		const result1 = phoneNumbers ? phoneNumbers.join(', ') : ''
		const result2 = emails ? emails.join(', ') : ''
		return result1 && result2 ? result1 + ';' + result2 : result1 || result2
	} catch (error) {
		return ''
	}
}

export function getTitle(text: string) {
	return text.substring(0, 100)
}

export function textToSlug(text: string) {
	return text
		.toLowerCase()
		.replaceAll(' ', '-')
		.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')
}
