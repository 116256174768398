import { useState, useEffect, FC, MouseEvent, useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import { useMediaQuery, Box, Modal } from '@mui/material'

import theme from '../../theme'

// Importing firebase
import { auth } from '../../firebase'
import { signOut, User } from 'firebase/auth'
import { ROUTES } from '../../constants/routes'
import { useAuthState } from 'react-firebase-hooks/auth'
import { redirectToCustomerPortal } from '../../utils/stripe'
import CustomLoader from '../CustomLoader'
import usePremiumStatus from '../../hooks/usePremiumStatus'
import { AnonymousJobsCTX } from '../../contexts/anonymousJobs'
import MobileHeader from './MobileHeader'
import DesktopHeader from './DesktopHeader'
import FixedLoader from '../CustomLoader/FixedLoader'

const Header: FC = () => {
	const [user, userLoading] = useAuthState(auth)
	const { loading: loadingForAnonymousUserCreation } = useContext(AnonymousJobsCTX)
	const navigate = useNavigate()

	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const [isSubscriptionClicked, setIsSubscriptionClicked] = useState<boolean>(false)
	const [premiumStatus, premiumStatusLoading] = usePremiumStatus()
	const open = Boolean(anchorEl)

	const handleAvatarClick = (event: MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}

	// signed in user data
	const [signedInUserData, setSignedInUserData] = useState<User | null | undefined>(null)
	const [Loading, setloading] = useState(true)

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' })
	}, [window.location.pathname])

	useEffect(() => {
		if (userLoading) return
		if (user !== null) {
			if (signedInUserData === null) {
				setSignedInUserData(user)
				setloading(false)
			}
		}
	}, [user, userLoading])

	const manageSubscription = () => {
		setIsSubscriptionClicked(true)
		redirectToCustomerPortal()
			.then(() => {})
			.catch(() => {
				setIsSubscriptionClicked(false)
			})
	}

	const logoutUser = () => {
		if (window.confirm('Are you sure you want to logout?') && !Loading) {
			signOut(auth)
				.then(() => {
					// alert("You have been signed out successfully");
					navigate(ROUTES.LOGIN)
				})
				.catch((error) => {
					// An error happened.
					// alert(`Error Logging Out : ${error.message}`);
					console.log(`Error Logging out==> ${error}`)
				})
		}
	}

	return (
		<>
			{loadingForAnonymousUserCreation ? <FixedLoader /> : null}
			{!isMobile ? (
				<DesktopHeader
					signedInUserData={signedInUserData}
					Loading={Loading}
					premiumStatusLoading={premiumStatusLoading}
					manageSubscription={manageSubscription}
					premiumStatus={premiumStatus}
					user={user}
					userLoading={userLoading}
					anchorEl={anchorEl}
					open={open}
					logoutUser={logoutUser}
					handleClose={handleClose}
					navigate={navigate}
					handleAvatarClick={handleAvatarClick}
					loadingForAnonymousUserCreation={Boolean(loadingForAnonymousUserCreation)}
				/>
			) : (
				<MobileHeader
					signedInUserData={signedInUserData}
					Loading={Loading}
					userLoading={userLoading}
					user={user}
					premiumStatusLoading={premiumStatusLoading}
					premiumStatus={premiumStatus}
					manageSubscription={manageSubscription}
					navigate={navigate}
					handleAvatarClick={handleAvatarClick}
					anchorEl={anchorEl}
					open={open}
					logoutUser={logoutUser}
					handleClose={handleClose}
				/>
			)}
			{/* To cover the height of fixed navbar */}
			{isMobile ? <Box sx={{ backgroundColor: '#2d3345', height: '60px' }} /> : <Box height="70px" />}
			<Modal open={isSubscriptionClicked} onClose={() => {}} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
				<Box>
					<CustomLoader />
				</Box>
			</Modal>
		</>
	)
}
export default Header
