import { Box } from '@mui/material'
import { Dispatch, FC, SetStateAction } from 'react'
import CustomFileField from './CustomFileField'
import { ResumeInputMode } from '../JobDetailComponents/types'
import ResumeFields from '../ResumeFields'

interface AddJobDialogFieldsProps {
	jobText: string
	setJobText: Dispatch<SetStateAction<string>> | (() => void)
	file: File | null
	setFile: Dispatch<SetStateAction<File | null>> | (() => void)
	resumeMode: ResumeInputMode
	setResumeMode: Dispatch<SetStateAction<ResumeInputMode>> | ((index: number | undefined, value: ResumeInputMode) => void)
	resumesList: Array<{ id: string; name: string }>
	selectedResume: string
	setSelectedResume: Dispatch<SetStateAction<string>> | ((index: number, value: string) => void)
	resumeCount: number
	handleDelete?: () => void
	isMultipleResumes?: boolean
	newResume?: boolean
	resumeName: string
	resumeTitle: string
	resumeLocation: string
	resumeContact: string
	resumeSalary: string
	setResumeName: (index: number, input: string) => void
	setResumeTitle: (index: number, input: string) => void
	setResumeLocation: (index: number, input: string) => void
	setResumeContact: (index: number, input: string) => void
	setResumeSalary: (index: number, input: string) => void
	index: number
	fileName?: string
}

const AddJobDialogFields: FC<AddJobDialogFieldsProps> = ({
	jobText,
	setJobText,
	file,
	setFile,
	resumeMode,
	setResumeMode,
	selectedResume,
	setSelectedResume,
	resumeCount,
	handleDelete,
	isMultipleResumes,
	newResume,
	resumeName,
	resumeTitle,
	resumeLocation,
	resumeContact,
	resumeSalary,
	setResumeName,
	setResumeTitle,
	setResumeLocation,
	setResumeContact,
	setResumeSalary,
	index,
	fileName
}) => {
	return (
		<>
			<ResumeFields
				index={index}
				name={resumeName}
				title={resumeTitle}
				location={resumeLocation}
				contact={resumeContact}
				salary={resumeSalary}
				setName={setResumeName}
				setTitle={setResumeTitle}
				setLocation={setResumeLocation}
				setContact={setResumeContact}
				setSalary={setResumeSalary}
				resumeText={jobText ?? ''}
			/>
			<Box
				sx={{
					marginBottom: { md: '48px', sm: '20px' },
					marginTop: { sm: '0', xs: '15px' }
				}}
			>
				<CustomFileField
					handleDelete={() => handleDelete && handleDelete()}
					resumeCount={resumeCount}
					name="Resume"
					jobText={jobText}
					setJobText={setJobText}
					file={file}
					setFile={setFile}
					resumeMode={resumeMode}
					setResumeMode={setResumeMode as (index: number | undefined, value: ResumeInputMode) => void}
					setSelectedResume={setSelectedResume as (index: number, value: string) => void}
					selectedResume={selectedResume}
					resumeIndex={isMultipleResumes ? resumeCount - 1 : null}
					newResume={newResume}
					onResumeData={(value) => {
						setResumeName(index, value?.resumeCandidateName ?? '')
						setResumeTitle(index, value?.resumeTitle ?? '')
						setResumeLocation(index, value?.resumeCity ?? '')
						setResumeContact(index, value?.resumePhone ?? '')
					}}
					resumeFileName={fileName}
				/>
			</Box>
		</>
	)
}

export default AddJobDialogFields
