import { Box, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { Dispatch, FC, SetStateAction } from 'react'
import { ResumeInputMode } from '../../JobDetailComponents/types'
const RadioControls: FC<{
	isMobile: boolean
	setResumeMode: Dispatch<SetStateAction<ResumeInputMode>> | ((index: number, value: ResumeInputMode) => void)
	resumeMode: string
	resumeIndex?: number | null
}> = ({ isMobile, setResumeMode, resumeMode, resumeIndex = null }) => {
	return (
		<Box sx={{ width: isMobile ? '100%' : '20%' }}>
			<RadioGroup aria-labelledby="demo-controlled-radio-buttons-group" name="controlled-radio-buttons-group" value={resumeMode} row={isMobile}>
				<FormControlLabel
					value="file"
					control={
						<Radio
							onClick={() => {
								resumeIndex !== null
									? (setResumeMode as (index: number, value: string) => void)(resumeIndex, 'file')
									: (setResumeMode as Dispatch<SetStateAction<ResumeInputMode>>)('file')
							}}
						/>
					}
					label="as file"
				/>
				<FormControlLabel
					value="text"
					control={
						<Radio
							onClick={() => {
								resumeIndex !== null
									? (setResumeMode as (index: number, value: string) => void)(resumeIndex, 'text')
									: (setResumeMode as Dispatch<SetStateAction<ResumeInputMode>>)('text')
							}}
						/>
					}
					label="as text"
				/>
				<FormControlLabel
					value="resumes"
					control={
						<Radio
							onClick={() => {
								resumeIndex !== null
									? (setResumeMode as (index: number, value: string) => void)(resumeIndex, 'resumes')
									: (setResumeMode as Dispatch<SetStateAction<ResumeInputMode>>)('resumes')
							}}
						/>
					}
					label="resumes"
				/>
			</RadioGroup>
		</Box>
	)
}

export default RadioControls
