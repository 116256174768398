import { Box, Typography } from '@mui/material'
import { ROUTES } from '../../constants/routes'
import ArrowLeftMobile from '../../assets/JobDetail/ArrowLeftMobile.png'
import { useNavigate } from 'react-router'
import { FC } from 'react'
import { JobStatus } from '../../models/Job'
import Logo from '../../assets/Navbars/Logo.png'

const MobileJobBar: FC<{
	status: JobStatus
	isMultiResume: boolean
}> = ({ status, isMultiResume }) => {
	const navigate = useNavigate()
	return (
		<>
			<Box
				sx={{
					paddingTop: '30px',
					position: 'relative',
					display: 'flex',
					justifyContent: 'center',
					marginBottom: '50px'
				}}
			>
				<Box
					component="img"
					sx={{
						position: 'absolute',
						left: '0',
						cursor: 'pointer'
					}}
					src={ArrowLeftMobile}
					onClick={() => (isMultiResume ? navigate(ROUTES.MULTIPLE_RESUMES_JOBS) : navigate(ROUTES.JOBS))}
				/>
				<Typography
					variant="h5"
					sx={{
						fontWeight: '700',
						fontSize: '14px',
						lineHeight: '18px'
					}}
				>
					Job Detail {status === 'draft' && '/ Draft'}
				</Typography>
			</Box>
			<Box
				sx={{
					width: '84px',
					height: '84px',
					backgroundColor: '#F8F8FD',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					marginX: 'auto',
					marginBottom: '20px'
				}}
			>
				<Box component="img" src={Logo} sx={{ width: '36px', height: '36px' }} />
			</Box>
		</>
	)
}

export default MobileJobBar
