import { useState, useEffect, FC, ChangeEvent, useContext } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useMediaQuery } from '@mui/material'
import theme from '../../../theme'
import { auth, db } from '../../../firebase'
import { doc, setDoc, arrayUnion, arrayRemove, Timestamp, writeBatch, DocumentReference, DocumentData, serverTimestamp } from 'firebase/firestore'
import { RowType } from '../../../components/JobAnalysisTable/jobType'
import { TABLE_NAMES } from '../../../constants'
import { toast } from 'react-toastify'
import { analyzeJobDescription } from '../../../utils/job'
import { useAuthState } from 'react-firebase-hooks/auth'
import JobDetailsContent from '../../../components/JobDetailComponents/JobDetailsContent'
import { container, item } from '../../../components/JobDetailComponents/JobConstants'
import { defaultResume } from '../../../models/Resume'
import { ResumeInputMode } from '../../../components/JobDetailComponents/types'
import { Job, JobStatus } from '../../../models/Job'
import { ROUTES } from '../../../constants/routes'
import { getJobsByGroupId } from '../../../utils/queries'
import { useCollection } from 'react-firebase-hooks/firestore'
import { sortResumes } from '../../../utils/functions'
import { calculateAveragePercentage } from '../../../utils'
import { LoaderContext } from '../../../contexts/loader-context'
const JobDetail: FC = () => {
	const navigate = useNavigate()
	const [selectedResume] = useState<string>('')
	const [resumeMode] = useState<ResumeInputMode>('text')
	const [file] = useState<File | null>(null)
	const [status, setStatus] = useState<JobStatus>('draft')
	const [editMode, setEditMode] = useState<boolean>(false)
	const [description] = useState<string>('')
	const [created] = useState<Timestamp | null>(null)
	const [skillsOnly] = useState<boolean>(false)
	const [resumes, setResumes] = useState<Job[] | undefined>([])
	const [isSorted, setIsSorted] = useState(false)
	const [oldDescription, setOldDescription] = useState('')
	const [oldGptData, setOldGptData] = useState('')
	const { id } = useParams()
	const { setShowLoading } = useContext(LoaderContext)
	const [user] = useAuthState(auth)

	const jobRef = doc(db, 'Jobs', id ?? '')

	const q = getJobsByGroupId(id)

	const [jobSnap, jobLoading, jobError] = useCollection(q, {
		snapshotListenOptions: { includeMetadataChanges: true }
	})

	useEffect(() => {
		setShowLoading(jobLoading)
	}, [jobLoading])
	const skillsCheckboxHandler = async (index: number, e: ChangeEvent<HTMLInputElement>): Promise<void> => {
		let jobRef: DocumentReference<DocumentData> | null = null
		const updatedResumes = resumes?.map((resume, i) => {
			if (index === i) {
				jobRef = doc(db, 'Jobs', resume.id ?? '')
				return { ...resume, skillsOnly: e.target.checked }
			}
			return resume
		})
		if (updatedResumes) setResumes(updatedResumes)
		await setDoc(
			jobRef as unknown as DocumentReference<DocumentData>,
			{
				skillsOnly: e.target.checked
			},
			{
				merge: true
			}
		)
	}

	const markInaccurateTitle = async (index: number, title: string): Promise<void> => {
		const batch = writeBatch(db)
		const resumesRefs = resumes
			?.filter((resume) => {
				return resume.top_titles?.includes(title)
			})
			?.map((resumeData) => doc(db, 'Jobs', resumeData?.id ?? ''))
		resumesRefs?.forEach((ref, i) => {
			batch.update(ref, 'inaccurate_titles', arrayUnion(title))
			batch.update(ref, 'top_titles', arrayRemove(title))
		})
		await batch.commit()
	}

	const addSuggestedSkill = async (index: number, tableName: string, skill: string): Promise<void> => {
		const batch = writeBatch(db)
		const resumesRefs = resumes?.map((resumeData) => doc(db, 'Jobs', resumeData?.id ?? ''))

		const skillsArray = skill.split(',').map((s, index) => s.trim())
		resumesRefs?.forEach((ref) => {
			skillsArray.forEach((skill) => {
				batch.update(ref, `${tableName}_suggested`, arrayUnion(skill))
			})
		})
		await batch.commit()
	}

	const markIrrelevantSkill = async (index: number, skill: string, tableName: string): Promise<void> => {
		const tempTables: RowType[][] = []
		switch (tableName) {
			case TABLE_NAMES.HARD_SKILLS: {
				const updatedResumes = resumes?.map((resume, i) => {
					tempTables[i] = resume.table_hard.filter((row) => row.skill !== skill)
					return { ...resume, table_hard: tempTables[i] }
				})
				setResumes(updatedResumes)
				break
			}
			case TABLE_NAMES.SOFT_SKILLS: {
				const updatedResumes = resumes?.map((resume, i) => {
					tempTables[i] = resume.table_soft.filter((row) => row.skill !== skill)
					return { ...resume, table_soft: tempTables[i] }
				})
				setResumes(updatedResumes)
				break
			}
			case TABLE_NAMES.RELATED_SKILLS:
				{
					const updatedResumes = resumes?.map((resume, i) => {
						tempTables[i] = resume.table_related.filter((row) => row.skill !== skill)
						return { ...resume, table_related: tempTables[i] }
					})
					setResumes(updatedResumes)
				}
				break
			default:
				break
		}

		const batch = writeBatch(db)
		const resumesRefs = resumes?.map((resumeData) => doc(db, 'Jobs', resumeData?.id ?? ''))
		resumesRefs?.forEach((ref, i) => {
			batch.update(ref, tableName, tempTables[i])
			batch.update(ref, 'irrelevant_words', arrayUnion(skill))
		})
		await batch.commit()
	}

	useEffect(() => {
		if (jobError !== undefined && jobError.code === 'permission-denied') {
			toast("You don't have permission to view the Job", { type: 'error' })
			navigate(ROUTES.OVERVIEW)
		}
	}, [jobError, navigate])

	useEffect(() => {
		if (!jobLoading && jobError == null) {
			if (jobSnap?.empty) {
				return navigate(ROUTES.MULTIPLE_RESUMES_JOBS)
			}
			const data = jobSnap?.docs[0].data() as Job
			let resumes = jobSnap?.docs.map((docSnapshot) => ({
				id: docSnapshot.id,
				...docSnapshot.data()
			})) as Job[]
			setStatus(data.status)
			setOldDescription(data.description ?? '')
			setOldGptData(data.gpt_data ?? '')
			setIsSorted(data.isSortedAccordingHardSkill ?? false)
			if (data.isSortedAccordingHardSkill) {
				resumes = sortResumes(resumes, (resume1: Job, resume2: Job) => Number(resume2.percentage_hard) - Number(resume1.percentage_hard))
			} else {
				resumes = sortResumes(
					resumes,
					(resume1: Job, resume2: Job) =>
						calculateAveragePercentage(false, resume2.percentage_hard, resume2.percentage_soft, resume2.percentage_sales) -
						calculateAveragePercentage(false, resume1.percentage_hard, resume1.percentage_soft, resume1.percentage_sales)
				)
			}
			setResumes(resumes)
		}
	}, [jobLoading, jobSnap, jobError, navigate])

	const progressBtnClickHandler = async (index: number): Promise<void> => {
		let selectedResume = resumes?.find((_, i) => i === index)
		if (selectedResume?.isSortedAccordingHardSkill) selectedResume = (resumes as Job[])[(resumes as Job[]).length - 1]
		if (selectedResume?.status) {
			const newStatus = selectedResume?.status === 'in progress' ? 'Completed' : 'in progress'
			const jobRef = doc(db, 'Jobs', selectedResume?.id ?? '')
			if (selectedResume?.status === 'draft' ? confirm('Are you sure you want to mark this job as an active one?') : true) {
				await setDoc(
					jobRef,
					{
						status: newStatus
					},
					{
						merge: true
					}
				)
			}
		}
	}

	const resumeUpdateHandler = (index: number, value: string | File | null, key: string) => {
		const resumeUpdated = resumes?.map((resume, i) => {
			if (key === 'description') {
				const { analyzedTitle, analyzedCompany, analyzedLocation, analyzedMoreInfo } = analyzeJobDescription((value as string) || '')
				return {
					...resume,
					[key as string]: value,
					company: analyzedCompany || resume?.company,
					location: analyzedLocation || resume.location,
					moreInfo: analyzedMoreInfo || resume.moreInfo,
					title: analyzedTitle || resume.title
				}
			}
			if (index === i || (key !== 'resume' && key !== 'resumeMode' && i === resumes.length - 1)) {
				return { ...resume, [key]: value }
			}
			return resume
		})
		if (resumeUpdated) setResumes(() => resumeUpdated)
	}

	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
	const isTab = useMediaQuery(theme.breakpoints.down('md'))

	const handleAddNewResume = (file?: File) => {
		setResumes(
			(resumes) =>
				[
					...(resumes ?? []),
					{
						...defaultResume,
						groupId: resumes ? resumes[0]?.groupId : '',
						title: resumes ? resumes[0]?.title : '',
						company: resumes ? resumes[0]?.company : '',
						location: resumes ? resumes[0]?.location : '',
						description: resumes ? resumes[0]?.description : '',
						moreInfo: resumes ? resumes[0]?.moreInfo : '',
						status: resumes ? resumes[0]?.status : '',
						newResume: true,
						resumeMode: file ? 'file' : 'text',
						file: file ?? '',
						created: serverTimestamp() as Timestamp,
						fileName: file ? file.name : ''
					}
				] as Job[]
		)
		setEditMode(true)
	}

	const handleSortAccordingToHardSkill = async () => {
		try {
			const batch = writeBatch(db)
			resumes?.forEach((data) => batch.update(doc(db, 'Jobs', data?.id as string), 'isSortedAccordingHardSkill', !isSorted))
			await batch.commit()
		} catch (err) {
			alert('Error deleting Job: ' + (err as Error)?.message)
		}
	}

	const handleDelete = (index: number) => setResumes(resumes?.filter((job, i) => i !== index))

	const updateResumeMetadata = (index: number, key: string, value: string) => {
		setResumes((prev) => prev?.map((item, i) => (i === index ? { ...item, [key]: value } : item)))
	}

	return (
		<JobDetailsContent
			handleDelete={handleDelete}
			handleAddResume={handleAddNewResume}
			isMultiResumes={true}
			resumes={resumes}
			resumeMode={resumeMode}
			setResumeMode={(index, value) => resumeUpdateHandler(index, value, 'resumeMode')}
			jobError={jobError}
			navigate={navigate}
			container={container}
			jobLoading={jobLoading}
			progressBtnClickHandler={progressBtnClickHandler}
			isTab={isTab}
			editMode={editMode}
			setEditMode={setEditMode}
			company={''}
			setCompany={(index, value) => resumeUpdateHandler(index, value, 'company')}
			setTitle={(index, value) => resumeUpdateHandler(index, value, 'title')}
			setLocation={(index, value) => resumeUpdateHandler(index, value, 'location')}
			moreInfo={''}
			setMoreInfo={(index, value) => resumeUpdateHandler(index, value, 'moreInfo')}
			location={''}
			created={created}
			jobRef={jobRef}
			user={user}
			setJobText={(index, value) => resumeUpdateHandler(index, value, 'resume')}
			file={file}
			setFile={(index, value) => {
				resumeUpdateHandler(index, value, 'file')
			}}
			description={description}
			setDescription={(index, value) => resumeUpdateHandler(index, value, 'description')}
			skillsOnly={skillsOnly}
			skillsCheckboxHandler={skillsCheckboxHandler}
			isMobile={isMobile}
			addSuggestedSkill={addSuggestedSkill}
			markIrrelevantSkill={markIrrelevantSkill}
			markInaccurateTitle={markInaccurateTitle}
			status={status}
			item={item}
			title={''}
			setSelectedResume={(index, value) => resumeUpdateHandler(index, value, 'selectedResume')}
			selectedResume={selectedResume}
			isSorted={isSorted}
			sortAccordingToHardSkill={() => {
				handleSortAccordingToHardSkill()
			}}
			setResumeName={(index, value) => updateResumeMetadata(index, 'resumeCandidateName', value)}
			setResumeTitle={(index, value) => updateResumeMetadata(index, 'resumeTitle', value)}
			setResumeLocation={(index, value) => updateResumeMetadata(index, 'resumeCity', value)}
			setResumeContact={(index, value) => updateResumeMetadata(index, 'resumePhone', value)}
			setResumeSalary={(index, value) => updateResumeMetadata(index, 'resumeSalary', value)}
			oldDescription={oldDescription}
			oldGptData={oldGptData}
		/>
	)
}
export default JobDetail
