import ArrowLeft from '../../../assets/JobDetail/ArrowLeft.png'
import { Box, Button, Typography } from '@mui/material'
import { FC, SyntheticEvent, useContext, useEffect, useMemo, useState } from 'react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { Blog } from '../../../models/Blog'
import { EventInfo } from '@ckeditor/ckeditor5-utils'
import { useNavigate, useParams } from 'react-router'
import { deleteDoc, doc, getDoc, setDoc } from 'firebase/firestore'
import { db } from '../../../firebase'
import CustomTextField from '../../../components/AddJobDialog/CustomTextField'
import RitchTextArea from '../../../components/RitchTextArea'
import { textToSlug } from '../../../utils/functions'
import { ROUTES } from '../../../constants/routes'
import { LoaderContext } from '../../../contexts/loader-context'
import DeleteIcon from '@mui/icons-material/Delete'
import SaveIcon from '@mui/icons-material/Save'
import SkillsForm from '../../../components/Resume/Forms/SkillsForm'

const EditBlog: FC<{}> = () => {
	const [blogTitle, setBlogTitle] = useState('')
	const [blogContent, setBlogContent] = useState('')
	const [blogCategory, setBlogCategory] = useState('')
	const [tags, setTags] = useState<string[]>([])
	const navigate = useNavigate()
	const { setShowLoading, showLoading } = useContext(LoaderContext)

	const handleBlogTitleChange = (e: SyntheticEvent<HTMLInputElement, Event>) => {
		setBlogTitle(e.currentTarget.value)
	}

	const handleRtcChange = (event: EventInfo, editor: ClassicEditor) => {
		setBlogContent(editor.data?.get())
	}

	const { id } = useParams()
	const blog = useMemo(async () => {
		const docRef = doc(db, 'blogs', id ?? '')
		const docSnap = await getDoc(docRef)
		if (docSnap.exists()) {
			return docSnap?.data() as Blog
		}
		return null
	}, [id])

	useEffect(() => {
		if (!showLoading) setShowLoading(true)
		if (blog) {
			blog.then(async (blogData) => {
				setBlogCategory(blogData?.category ?? 'all')
				setBlogTitle(blogData?.title ?? '')
				setBlogContent(blogData?.details ?? '')
				setTags(blogData?.tags ?? [])
				setShowLoading(false)
				await new Promise((resolve) => setTimeout(() => resolve(true), 1))
			})
		}
	}, [blog])

	const saveBlog = async () => {
		if (blogTitle?.trim() === '') {
			alert('Blog title should not be left blank')
			return
		}
		setShowLoading(true)
		await new Promise((resolve) => setTimeout(() => resolve(true), 1))
		const details = `${blogContent}</div>`
		const slug = textToSlug(blogTitle ?? '')
		const title = blogTitle
		const docRef = doc(db, 'blogs', id ?? '')
		setDoc(
			docRef,
			{
				details,
				slug,
				title,
				tags
			},
			{ merge: true }
		)
			.then((docRef) => {
				const docSiteMapRef = doc(db, 'sitemap', id ?? '')
				if (docSiteMapRef) {
					setDoc(docSiteMapRef, {
						changefreq: 'monthly',
						loc: window.location.origin + '/blog/' + blogCategory + '/' + slug,
						priority: 0.5
					})
						.then(() => {
							setShowLoading(false)
							navigate(ROUTES.BLOG_BUILDER)
						})
						.catch((error) => {
							setShowLoading(false)
							console.log({ error })
						})
				}
			})
			.catch((error) => {
				setShowLoading(false)
				console.log({ error })
			})
	}

	const deleteBlog = async () => {
		const answer = confirm('Are you sure you want to delete this blog')
		if (answer) {
			try {
				setShowLoading(true)
				await new Promise((resolve) => setTimeout(() => resolve(true), 1))
				const docRef = doc(db, 'blogs', id ?? '')
				const docSiteMapRef = doc(db, 'sitemap', id ?? '')
				await deleteDoc(docRef)
				if (docSiteMapRef) {
					await deleteDoc(docSiteMapRef)
				}
				navigate(ROUTES.BLOG_BUILDER)
				setShowLoading(false)
			} catch (error) {}
			setShowLoading(false)
		}
	}
	return (
		<Box
			sx={{
				minHeight: '100vh',
				backgroundColor: { sm: '#DFDFDF', xs: '#ffffff' },
				width: '100%'
			}}
		>
			<Box>
				<Box sx={{ display: 'flex' }}>
					<Box
						sx={{
							paddingX: { lg: '40px', sm: '20px', xs: '12px' },
							flex: 'auto'
						}}
					>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								padding: '30px 0 20px 0',
								justifyContent: 'space-between'
							}}
						>
							<Box sx={{ display: 'flex', alignItems: 'center' }}>
								<Box
									onClick={() => navigate(ROUTES.BLOG_BUILDER)}
									sx={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										backgroundColor: '#171725',
										boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.02);',
										borderRadius: '50%',
										cursor: 'pointer',
										width: { lg: '46px', sm: '35px', xs: '30px' },
										height: { lg: '46px', sm: '35px', xs: '30px' }
									}}
								>
									<Box component="img" src={ArrowLeft} />
								</Box>
								<Box
									sx={{
										display: 'flex',
										alignItems: 'center',
										gap: '10px',
										cursor: 'pointer'
									}}
									// onClick={() => setEditName(true)}
								>
									<Typography
										variant="h2"
										sx={{
											fontWeight: '600',
											fontSize: { lg: '24px', md: '20px', xs: '17px' },
											lineHeight: '36px',
											letterSpacing: '0.1px',
											marginLeft: { sm: '18px', xs: '12px' },
											color: '#171725'
										}}
									>
										Add Blog
									</Typography>
								</Box>
							</Box>
							<Box>
								<Button
									sx={{
										textTransform: 'none',
										borderRadius: '12px',
										marginLeft: '5px',
										marginBottom: '5px',
										padding: { md: '10px 24px;', sm: '10px 16px;', sx: '8px 16px' },
										fontSize: { md: '18px', xs: '16px' },
										fontWeight: '600',
										lineHeight: { sm: '27px', xs: '24px' },
										letterSpacing: '2%',
										width: { xs: '50%', sm: 'unset' }
									}}
									variant="contained"
									color="primary"
									onClick={() => {
										saveBlog()
									}}
								>
									<SaveIcon />
								</Button>
								<Button
									sx={{
										textTransform: 'none',
										borderRadius: '12px',
										marginLeft: '5px',
										marginBottom: '5px',
										padding: { md: '10px 24px;', sm: '10px 16px;', sx: '8px 16px' },
										fontSize: { md: '18px', xs: '16px' },
										fontWeight: '600',
										lineHeight: { sm: '27px', xs: '24px' },
										letterSpacing: '2%',
										width: { xs: '50%', sm: 'unset' }
									}}
									variant="contained"
									color="error"
									onClick={() => {
										deleteBlog()
									}}
								>
									<DeleteIcon />
								</Button>
							</Box>
						</Box>
					</Box>
				</Box>
				<Box
					sx={{
						borderRadius: '20px',
						width: '94%',
						display: 'flex',
						flexDirection: 'column',
						padding: '30px 30px 30px 30px',
						background: '#fff',
						margin: 'auto',
						marginBottom: '20px'
					}}
				>
					<Box>
						<CustomTextField width="100%" name={'Blog title'} value={blogTitle} onChange={handleBlogTitleChange} placeholder="Blog title" type="text" />
						<SkillsForm skills={tags} setSkills={setTags} label="Tags" placeholder="Tags like news,trending etc..." />
						<RitchTextArea data={blogContent} label={'Blog Details'} onChange={handleRtcChange} />
					</Box>
				</Box>
			</Box>
		</Box>
	)
}

export default EditBlog
