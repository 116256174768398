import { httpsCallable } from 'firebase/functions'
import { functions } from '../firebase'

export const redirectToCustomerPortal = async () => {
	const functionRef = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink')
	const { data } = await functionRef({
		returnUrl: window.location.href,
		locale: 'auto'
	})
	window.location.assign((data as any).url)
}
