import { useState, useEffect } from 'react'
import { auth, db } from '../firebase/index'
import { doc, getDoc } from 'firebase/firestore'

const useAuthProfileData = () => {
	const [userProfile, setUserProfile] = useState({})
	useEffect(() => {
		;(async () => {
			const userId = auth?.currentUser?.uid
			if (userId) {
				const docRef = doc(db, 'users', userId)
				const userDocSnap = await getDoc(docRef)
				if (userDocSnap.exists()) {
					setUserProfile(userDocSnap.data())
				}
			}
		})()
	}, [auth?.currentUser])
	return userProfile
}

export default useAuthProfileData
