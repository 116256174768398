import { Box, Button, Divider, Radio, RadioGroup, Modal, SxProps, FormControl, Typography, FormControlLabel, TextField, useMediaQuery } from '@mui/material'
import { useState, useEffect, FC, ChangeEvent } from 'react'
import { toast } from 'react-toastify'
import { arrayUnion, doc, DocumentData, increment, setDoc } from '@firebase/firestore'
import { db } from '../../firebase'
import { COLLECTION_NAMES } from '../../constants/collections'
import { FieldValue } from 'firebase/firestore'
import { Answer } from '../../models/Answer'
import theme from '../../theme'

interface SurveyModalProps {
	uid: string
	survey: DocumentData
	zIndex: string
}

const MODAL_STYLE: SxProps = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '80%',
	height: '80%',
	bgcolor: 'background.paper',
	boxShadow: 24,
	borderRadius: 3,
	outline: 0,
	display: 'flex',
	justifyContent: 'center',
	flexDirection: 'column',
	overflowY: 'scroll'
}

const SurveyModal: FC<SurveyModalProps> = ({ uid, survey, zIndex }) => {
	const [open, setOpen] = useState<boolean>(true)
	const [value, setValue] = useState<string>('0')
	const [isCustom, setIsCustom] = useState<boolean>(false)
	const [error, setError] = useState<boolean>(false)
	const [customAnswer, setCustomAnswer] = useState<string>('')
	const [answers, setAnswers] = useState<Answer[]>([])
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
		const newValue = (event.target as HTMLInputElement).value
		setValue(newValue)
		if (newValue === `${survey.data().options.length as number}`) {
			setIsCustom(true)
		} else {
			setIsCustom(false)
		}
	}

	const updateSurveyDoc = (updateDoc: DocumentData) => {
		setDoc(doc(db, COLLECTION_NAMES.SURVEYS_COLLECTION, survey.id), updateDoc, { merge: true })
			.then(() => {})
			.catch((err) => console.log(err))
	}

	const submitHandler = (): void => {
		const updateDoc: {
			usersAttempted: FieldValue
			options?: FieldValue
			answers?: { [key: string]: { answer: string; count: FieldValue } }
		} = {
			usersAttempted: arrayUnion(uid)
		}
		let flag = true
		let answer = ''
		if (isCustom) {
			if (customAnswer === '') {
				flag = false
				setError(true)
			} else {
				answer = customAnswer
				updateDoc.options = arrayUnion(customAnswer)
			}
		} else {
			answer = answers[parseInt(value)].text
		}
		if (flag) {
			updateDoc.answers = survey.data().answers
			if (updateDoc.answers === undefined) {
				updateDoc.answers = {}
			}
			updateDoc.answers[value] = { answer, count: increment(1) }
			toast.success('Thanks for completing the survey!')
			setOpen(false)
			updateSurveyDoc(updateDoc)
		}
	}

	const closeModal = (): void => {
		setOpen(false)
		updateSurveyDoc({ usersAttempted: arrayUnion(uid) })
	}

	useEffect(() => {
		const newRows = survey.data().options.map((option: string, idx: number) => ({
			value: `${idx}`,
			text: option
		}))
		setAnswers(newRows)
	}, [survey])

	return (
		<>
			<Modal open={open} onClose={closeModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
				<Box sx={{ ...MODAL_STYLE, zIndex, p: isMobile ? 4 : 10 }}>
					<Typography component="h2" fontSize="large" fontWeight="bold" id="modal-modal-description" sx={{ mt: 2 }}>
						{survey.data().question}
					</Typography>
					<FormControl>
						<RadioGroup aria-labelledby="demo-controlled-radio-buttons-group" name="controlled-radio-buttons-group" value={value} onChange={handleChange}>
							{answers.length !== 0 && answers.map((answer) => <FormControlLabel key={answer.value} value={answer.value} control={<Radio />} label={answer.text} />)}
							<FormControlLabel value={`${survey.data().options.length as number}`} control={<Radio />} label="Other" />
							{isCustom && (
								<>
									<TextField type="text" placeholder="Your answer" value={customAnswer} onChange={(e) => setCustomAnswer(e.target.value)} />
									{error && (
										<Typography
											sx={{
												mt: 1,
												fontSize: '12px'
											}}
											color="red"
										>
											Please enter a valid custom answer
										</Typography>
									)}
								</>
							)}
						</RadioGroup>
					</FormControl>
					<Divider
						sx={{
							mt: 4
						}}
					/>
					<Box
						sx={{
							mt: 4,
							display: 'flex',
							justifyContent: 'right'
						}}
					>
						<Button
							variant="contained"
							sx={{
								marginRight: '12px'
							}}
							onClick={submitHandler}
							color="info"
						>
							Submit
						</Button>
						<Button variant="contained" color="error" onClick={closeModal}>
							Close
						</Button>
					</Box>
				</Box>
			</Modal>
		</>
	)
}

export default SurveyModal
