import { Dispatch, SetStateAction, FC, ChangeEvent } from 'react'
import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, Select, TextField, Typography, useMediaQuery } from '@mui/material'
import { Add, Close } from '@mui/icons-material'
import { Experience } from '../../../../models/Experience'
import theme from '../../../../theme'
import { MONTH_NAMES } from '../../../../constants'
import ExperiencePoints from './ExperiencePoints'

interface Props {
	experience: Experience[]
	setExperience: Dispatch<SetStateAction<Experience[]>>
}

const ExperienceForm: FC<Props> = ({ experience, setExperience }) => {
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	const educationAddHandler = () => {
		const tempEducation: Experience = {
			company: '',
			end: {
				month: '',
				year: ''
			},
			start: {
				month: '',
				year: ''
			},
			points: [],
			title: ''
		}
		setExperience([tempEducation, ...experience])
	}

	const removeHandler = (index: number) => {
		setExperience([...experience.filter((temp, idx) => idx !== index)])
	}

	const changeHandler = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, idx: number, field: keyof Experience) => {
		if (field !== 'end' && field !== 'start' && field !== 'points') {
			const tempExperience: Experience[] = [...experience]
			tempExperience[idx][field] = e.target.value
			setExperience([...tempExperience])
		}
	}

	const timeChangeHandler = (value: string, idx: number, field: keyof Experience, subField: 'month' | 'year') => {
		if (field === 'end' || field === 'start') {
			const tempExperience: Experience[] = [...experience]
			tempExperience[idx][field][subField] = value
			setExperience([...tempExperience])
		}
	}

	return (
		<>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: { md: '48px', sm: '20px', xs: '15px' },
					justifyContent: 'center',
					alignItems: 'center'
				}}
			>
				<Button
					sx={{
						textTransform: 'none',
						borderRadius: 50,
						fontSize: { md: '18px', xs: '16px' },
						fontWeight: '600',
						letterSpacing: '2%',
						textAlign: 'center',
						width: isMobile ? '100%' : '80%'
					}}
					variant="outlined"
					color="primary"
					onClick={educationAddHandler}
				>
					<Add /> Add New
				</Button>
				{experience.map((data, idx) => (
					<Box
						key={idx}
						sx={{
							display: 'flex',
							flexDirection: 'column',
							gap: '15px',
							position: 'relative',
							width: isMobile ? '100%' : '80%',
							backgroundColor: '#F6F6F6',
							padding: '20px',
							paddingTop: '30px',
							borderRadius: '20px'
						}}
					>
						<IconButton
							color="error"
							onClick={() => removeHandler(idx)}
							sx={{
								position: 'absolute',
								top: 4,
								right: 4,
								cursor: 'pointer'
							}}
						>
							<Close />
						</IconButton>
						<TextField
							label="Job Title"
							value={data.title}
							onChange={(e) => changeHandler(e, idx, 'title')}
							type="text"
							variant="standard"
							sx={{
								fontWeight: { sm: '400', xs: '500' },
								fontFamily: { sm: 'roboto' }
							}}
							fullWidth
							size="small"
							InputProps={{
								sx: {
									fontSize: '14px'
								}
							}}
						/>
						<TextField
							label="Company"
							value={data.company}
							onChange={(e) => changeHandler(e, idx, 'company')}
							type="text"
							variant="standard"
							sx={{
								fontWeight: { sm: '400', xs: '500' },
								fontFamily: { sm: 'roboto' }
							}}
							fullWidth
							size="small"
							InputProps={{
								sx: {
									fontSize: '14px'
								}
							}}
						/>
						<Box
							sx={{
								display: 'flex',
								flexDirection: isMobile ? 'column' : 'row',
								justifyContent: 'center',
								alignItems: 'center',
								width: '100%'
							}}
						>
							<Box
								sx={{
									display: 'flex',
									gap: '10px',
									justifyContent: isMobile ? 'flex-start' : 'center',
									alignItems: 'end'
								}}
							>
								<FormControl
									variant="standard"
									size="small"
									sx={{
										minWidth: 120,
										fontWeight: { sm: '400', xs: '500' },
										fontFamily: { sm: 'roboto' },
										width: '60%'
									}}
								>
									<InputLabel id={`start-month-label-${idx}`}>Month</InputLabel>
									<Select label="Month" labelId={`start-month-label-${idx}`} value={data.start.month} onChange={(e) => timeChangeHandler(e.target.value, idx, 'start', 'month')}>
										<MenuItem value=""></MenuItem>
										{MONTH_NAMES.map((month) => (
											<MenuItem key={month} value={month}>
												{month}
											</MenuItem>
										))}
									</Select>
								</FormControl>
								<TextField
									label="Year"
									value={data.start.year}
									onChange={(e) => timeChangeHandler(e.target.value, idx, 'start', 'year')}
									type="text"
									variant="standard"
									sx={{
										fontWeight: { sm: '400', xs: '500' },
										fontFamily: { sm: 'roboto' },
										width: '30%'
									}}
									size="small"
									InputProps={{
										sx: {
											fontSize: '14px'
										}
									}}
								/>
							</Box>
							<Typography
								sx={{
									paddingTop: '20px',
									fontWeight: '1000',
									fontSize: '20px'
								}}
							>
								-
							</Typography>
							<Box
								sx={{
									display: 'flex',
									gap: '10px',
									justifyContent: isMobile ? 'flex-start' : 'center',
									alignItems: 'end'
								}}
							>
								<FormControl
									variant="standard"
									size="small"
									sx={{
										minWidth: 120,
										fontWeight: { sm: '400', xs: '500' },
										fontFamily: { sm: 'roboto' },
										width: '60%'
									}}
								>
									<InputLabel id={`end-month-label-${idx}`}>Month</InputLabel>
									<Select label="Month" labelId={`end-month-label-${idx}`} value={data.end.month} onChange={(e) => timeChangeHandler(e.target.value, idx, 'end', 'month')}>
										<MenuItem value=""></MenuItem>
										{MONTH_NAMES.map((month) => (
											<MenuItem key={month} value={month}>
												{month}
											</MenuItem>
										))}
									</Select>
								</FormControl>
								<TextField
									label="Year"
									value={data.end.year}
									onChange={(e) => timeChangeHandler(e.target.value, idx, 'end', 'year')}
									type="text"
									variant="standard"
									sx={{
										fontWeight: { sm: '400', xs: '500' },
										fontFamily: { sm: 'roboto' },
										width: '30%'
									}}
									size="small"
									InputProps={{
										sx: {
											fontSize: '14px'
										}
									}}
								/>
							</Box>
						</Box>
						<ExperiencePoints experienceIdx={idx} points={data.points} experience={experience} setExperience={setExperience} />
					</Box>
				))}
			</Box>
		</>
	)
}

export default ExperienceForm
