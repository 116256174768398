import { createContext, ReactNode, useState, FC, Dispatch, SetStateAction } from 'react'
import { Job } from '../models/Job'
interface AnonymousJobsType {
	hasAnonymousJobs?: boolean
	anonymousJobs?: string[]
	setAnonymousJobs?: Dispatch<SetStateAction<string[]>>
	setHasAnonymousJobs?: Dispatch<SetStateAction<boolean>>
	activeJobs?: Job[]
	search?: string
	setActiveJobs?: Dispatch<SetStateAction<Job[]>>
	setSearch?: Dispatch<SetStateAction<string>>
	loading?: boolean
	setLoading?: Dispatch<SetStateAction<boolean>>
}

const AnonymousJobsCTX = createContext<AnonymousJobsType>({})

const AnonymousJobsCTXProvider: FC<{ children: ReactNode }> = ({ children }) => {
	const [anonymousJobs, setAnonymousJobs] = useState<string[]>([])
	const [hasAnonymousJobs, setHasAnonymousJobs] = useState<boolean>(false)
	const [activeJobs, setActiveJobs] = useState<Job[]>([])
	const [search, setSearch] = useState<string>('')
	const [loading, setLoading] = useState<boolean>(false)
	return (
		<AnonymousJobsCTX.Provider
			value={{
				anonymousJobs,
				setAnonymousJobs,
				hasAnonymousJobs,
				setHasAnonymousJobs,
				activeJobs,
				setActiveJobs,
				search,
				setSearch,
				loading,
				setLoading
			}}
		>
			{children}
		</AnonymousJobsCTX.Provider>
	)
}

export { AnonymousJobsCTX, AnonymousJobsCTXProvider as default }
