// App.jsx / App.tsx

import { FC } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { EventInfo } from '@ckeditor/ckeditor5-utils'

const RitchTextArea: FC<{
	onReady?: (editor: ClassicEditor) => void
	data?: string
	onChange?: (event: EventInfo, editor: ClassicEditor) => void
	onBlur?: (event: EventInfo, editor: ClassicEditor) => void
	onFocus?: (event: EventInfo, editor: ClassicEditor) => void
	label?: string
}> = ({
	onReady = (editor: ClassicEditor): void => {},
	data = '',
	onChange = (event: EventInfo, editor: ClassicEditor) => {},
	onBlur = (event: EventInfo, editor: ClassicEditor) => {},
	onFocus = (event: EventInfo, editor: ClassicEditor) => {},
	label = ''
}) => {
	return (
		<div className="App">
			<h2>{label}</h2>
			<div className="site-rtc-editor">
				<CKEditor editor={ClassicEditor} data={data} onReady={onReady} onChange={onChange} onBlur={onBlur} onFocus={onFocus} />
			</div>
		</div>
	)
}

export default RitchTextArea
