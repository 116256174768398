export const JobsFiltersTabs = [
	{ text: 'All Jobs', filter: '' },
	{ text: 'In Progress', filter: 'in progress' },
	{ text: 'Completed', filter: 'Completed' },
	{ text: 'Draft', filter: 'draft' }
]

export const JobStatuses = {
	'in progress': { textColor: '#FF974A', backgroundColor: '#ff974a1a', hoverBackgroundColor: '#ff974a26', text: 'In Progress' },
	Completed: { textColor: '#3DD598', backgroundColor: '#3dd5981a', hoverBackgroundColor: '#3dd59826', text: 'Completed' },
	draft: { textColor: '#1B2124', backgroundColor: '#1B21241a', hoverBackgroundColor: '#1B212426	', text: 'Draft' }
}
