import { FormGroup, FormControlLabel, Checkbox } from '@mui/material'
import { ChangeEvent, FC } from 'react'

const MobileJobSection2Checkboxes: FC<{
	skillsOnly: boolean
	skillsCheckboxHandler: (index: number, e: ChangeEvent<HTMLInputElement>) => Promise<void>
	isMultiResumes?: boolean
	resumeIndex: number
	isSorted?: boolean
	sortAccordingToHardSkill: (e: ChangeEvent<HTMLInputElement>) => void
}> = ({ skillsOnly, skillsCheckboxHandler, isMultiResumes, resumeIndex, isSorted, sortAccordingToHardSkill }) => (
	<>
		<FormGroup
			sx={{
				marginBottom: { sm: '11px', xs: '5px' },
				marginTop: { sm: '18px', xs: '10px' }
			}}
		>
			{!isMultiResumes ? (
				<FormControlLabel
					control={
						<Checkbox
							checked={skillsOnly}
							onChange={(e) => {
								skillsCheckboxHandler(resumeIndex, e)
							}}
							inputProps={{ 'aria-label': 'controlled' }}
						/>
					}
					label="Analyze skills only?"
				/>
			) : null}
		</FormGroup>
	</>
)

export default MobileJobSection2Checkboxes
