import { useNavigate } from 'react-router-dom'
import { Button } from '@mui/material'
import { ROUTES } from '../../constants/routes'

const NotFound = () => {
	const navigate = useNavigate()

	return (
		<div style={{ height: '500px' }}>
			<h1 style={{ textAlign: 'center', marginTop: '200px' }}>404</h1>
			<h1
				style={{
					textAlign: 'center',
					marginTop: '100px',
					fontFamily: 'revert',
					color: 'red',
					fontWeight: 'lighter'
				}}
			>
				Page not found...
			</h1>

			<div style={{ textAlign: 'center' }}>
				<Button
					variant="contained"
					color="primary"
					onClick={() => {
						navigate(ROUTES.OVERVIEW)
					}}
				>
					Go to Home
				</Button>
			</div>
		</div>
	)
}

export default NotFound
