import { Box, Typography, useMediaQuery } from '@mui/material'
import { FC, useEffect } from 'react'
import SubscriptionList from '../../components/Pricing/SubscriptionList'
import { motion } from 'framer-motion'
import { useNavigate } from 'react-router'
import { ROUTES } from '../../constants/routes'
import { toast } from 'react-toastify'
import usePremiumStatus from '../../hooks/usePremiumStatus'
import theme from '../../theme'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../../firebase'

const Pricing: FC = () => {
	const navigate = useNavigate()
	const [user, userLoading] = useAuthState(auth)
	const [isPremium, premiumLoading] = usePremiumStatus()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
	const isTab = useMediaQuery(theme.breakpoints.down('md'))

	useEffect(() => {
		if (premiumLoading) return
		if (isPremium) {
			toast.info('Go to profile settings for editting subscription')
			navigate(ROUTES.OVERVIEW)
		}
	}, [premiumLoading, isPremium])

	return (
		<>
			<Box>
				<Box
					component={motion.div}
					// drag="x"
					// dragConstraints={{ left: -100, right: 100 }}
					// drag="x"
					// whileHover={{
					//     scale: 1.2,
					//     transition: { duration: 0.3 }
					// }}
					// whileTap={{ scale: 0.9 }}
					sx={{
						width: 1
					}}
				>
					<Box
						component={'div'}
						sx={{
							width: 1
						}}
					>
						<Typography
							variant="h3"
							component="div"
							sx={{
								fontFamily: 'Poppins',
								fontStyle: 'normal',
								fontWeight: '700',
								fontSize: {
									lg: '56px',
									md: '36px',
									sm: '30px',
									xs: '25px'
								},
								lineHeight: '10px',
								// border: "1px solid #FFFFFF",
								textAlign: 'center',
								letterSpacing: '0.233333px'
							}}
							gutterBottom
						>
							<Typography fontSize={isMobile ? '40px' : isTab ? '50px' : '70px'} fontWeight="bold">
								Choose Your Plan
							</Typography>
						</Typography>
					</Box>
				</Box>
				<Box sx={{ position: 'relative' }}>
					{!userLoading && (!user || !isPremium) && (
						<Box
							sx={{
								display: 'grid',
								gap: '40px',
								width: '100vw',
								placeContent: 'center',
								gridAutoRows: '1fr',
								gridTemplateColumns: isTab ? '400px' : '400px 400px'
							}}
						>
							<SubscriptionList />
						</Box>
					)}
				</Box>
			</Box>
		</>
	)
}

export default Pricing
