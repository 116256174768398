import { Box } from '@mui/material'
import { FC } from 'react'
import { Outlet } from 'react-router'
import LeftNavbar from '../../components/LeftNavbar'
import { useMediaQuery } from '@mui/material'
import theme from '../../theme'
import AdminChat from '../../components/AdminChat/'

const AuthLayout: FC = () => {
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
	return (
		<>
			<AdminChat />
			{!isMobile ? (
				<Box
					sx={{
						backgroundColor: '#DFDFDF',
						position: 'relative'
					}}
				>
					<Box sx={{ display: 'flex', minHeight: screen.height - 70 }}>
						<LeftNavbar />
						<Outlet />
					</Box>
				</Box>
			) : (
				<Box sx={{ minHeight: 'calc(100vh - 60px)', display: 'flex', flexDirection: 'column' }}>
					<Outlet />
				</Box>
			)}
		</>
	)
}

export default AuthLayout
