import { Box, Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { FC } from 'react'

const AddResumeButton: FC<{ handleAddResume?: () => void }> = ({ handleAddResume }) => {
	return (
		<Box>
			<Button
				sx={{
					marginBottom: '0.8rem'
				}}
				variant="contained"
				color="info"
				onClick={handleAddResume}
			>
				<AddIcon /> Add Resume
			</Button>
		</Box>
	)
}

export default AddResumeButton
