import { FC } from 'react'
import { Box, TextField, Typography } from '@mui/material'
import { Job } from '../../models/Job'
import { FirestoreError } from 'firebase/firestore'
const MobileCompanyName: FC<{ editMode: boolean; resumes?: Job[]; setCompany: (index: number, value: string) => void; jobLoading: boolean; jobError: FirestoreError | undefined; company: string }> = ({
	editMode,
	resumes,
	setCompany,
	company,
	jobLoading,
	jobError
}) => {
	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center'
			}}
		>
			{editMode ? (
				<TextField
					id="outlined-basic"
					label={'Company'}
					variant="outlined"
					value={resumes && resumes.length ? resumes[0]?.company : company}
					onChange={(e) => setCompany(0, e.target.value)}
					inputProps={{
						style: {
							height: 2
						}
					}}
					sx={{
						input: {
							color: '#44444F',
							fontWeight: '500',
							fontSize: '12px',
							lineHeight: '18px',
							letterSpacing: '0.1px',
							height: '18px',
							width: '280px'
						},
						marginBottom: '10px'
					}}
				/>
			) : (
				<Typography
					variant="h3"
					sx={{
						fontWeight: '700',
						fontSize: '18px',
						lineHeight: '24px',
						color: '#1B2124',
						marginBottom: '6px'
					}}
				>
					{jobLoading || jobError ? 'Loading...' : resumes && resumes.length ? resumes[0]?.company : company}
				</Typography>
			)}
		</Box>
	)
}

export default MobileCompanyName
