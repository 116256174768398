import { useState, useEffect, FC } from 'react'

import { Box } from '@mui/material'
import { Button, Typography, useMediaQuery, CircularProgress } from '@mui/material'
import { getCoverLettersQuery } from '../../utils/queries'

import { motion } from 'framer-motion'

import AddIcon from '@mui/icons-material/Add'

import theme from '../../theme'

import { useCollection } from 'react-firebase-hooks/firestore'
import ShowNotifications from '../../components/ShowNotifications'
import { useNavigate } from 'react-router'
import { ROUTES } from '../../constants/routes'
import { toast } from 'react-toastify'
import CoverLetterDetailBar from '../../components/CoverLetter/CoverLetterDetailBar/index'
import usePageTitle from '../../hooks/usePageTitle'
import { CoverLetterType } from '../../models/CoverLetter'
import MobileCoverLetters from '../../components/CoverLetter/MobileCoverLetters/MobileCoverLetters'
import { addNewCoverLetter } from '../../utils/coverLetter'
const container = {
	show: {
		transition: {
			staggerChildren: 0.05
		}
	}
}
const item = {
	hidden: { opacity: 0, y: 40 },
	show: { opacity: 1, y: 0 }
}

const CoverLetters: FC = () => {
	const navigate = useNavigate()
	const [setPagetitle] = usePageTitle()
	const [search, setSearch] = useState<string>('')
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	const q = getCoverLettersQuery()

	const [snapshot, loading, error] = useCollection(q, {
		snapshotListenOptions: { includeMetadataChanges: true }
	})

	// GETTINGS Cover Letters
	const [coverLetters, setCoverLetters] = useState<CoverLetterType[]>([])

	const newCoverLetterHandler = () => {
		addNewCoverLetter()
			.then((id) => {
				console.log('ID  :', id)
				if (id !== 'unauthenticated') {
					navigate(ROUTES.COVER_LETTER + `/${id}`)
				}
			})
			.catch(() => toast.error('An error occured in saving Cover Letter'))
	}

	// Setting Cover Letters
	useEffect(() => {
		if (!loading && error == null) {
			const tempCoverLetters = snapshot?.docs.map((doc) => ({
				...doc.data(),
				id: doc.id
			})) as CoverLetterType[]
			setCoverLetters([...tempCoverLetters])
		}
	}, [loading, snapshot])

	useEffect(() => {
		setPagetitle('Cover Letters')
	}, [])

	return (
		<>
			{!isMobile ? (
				<Box
					sx={{
						minHeight: '100vh',
						backgroundColor: '#DFDFDF',
						position: 'relative',
						width: '100%'
					}}
				>
					<Box sx={{ display: 'flex', minHeight: screen.height - 70 }}>
						<Box
							className="job-list"
							sx={{
								width: '100%',
								overflow: 'scroll',
								paddingX: { lg: '40px', xs: '20px' }
							}}
						>
							<ShowNotifications />
							<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '30px 0 20px 0' }}>
								<Typography
									variant="h2"
									sx={{
										fontWeight: '600',
										fontSize: { lg: '24px', md: '20px', xs: '18px' },
										lineHeight: '36px',
										letterSpacing: '0.1px'
									}}
								>
									My Cover Letters
								</Typography>
								<Button
									onClick={newCoverLetterHandler}
									sx={{
										fontSize: { lg: '14px', sm: '12px' },
										lineHeight: '21px',
										letterSpacing: '0.1px',
										backgroundColor: '#0062FF',
										borderRadius: '10px',
										textTransform: 'none'
									}}
									variant="contained"
									startIcon={<AddIcon />}
								>
									New Cover Letter
								</Button>
							</Box>

							{/* Showing Cover Letters for Desktop View */}
							{!loading ? (
								<>
									{coverLetters.length > 0 ? (
										<motion.div variants={container} initial="hidden" animate="show">
											<Box
												className="job-list"
												sx={{
													paddingBottom: '20px',
													maxWidth: '100%',
													overflowX: 'scroll'
												}}
												component={motion.div}
												variants={item}
											>
												{coverLetters.map((coverLetter: CoverLetterType) => {
													return (
														<Box key={coverLetter.id}>
															<CoverLetterDetailBar coverLetter={coverLetter} />
														</Box>
													)
												})}
											</Box>
										</motion.div>
									) : (
										<>
											<Typography
												variant="h2"
												sx={{
													fontWeight: '600',
													fontSize: { lg: '24px', md: '20px', xs: '18px' },
													marginTop: {
														lg: '42px',
														sm: '28px',
														xs: '28px'
													},
													textAlign: 'center',
													lineHeight: '36px',
													letterSpacing: '0.1px',
													marginBottom: { lg: '30px', xs: '15px' }
												}}
											>
												No Cover Letter Found!
											</Typography>
										</>
									)}
								</>
							) : (
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
										alignItems: 'center',
										mt: '30px'
									}}
								>
									<Typography
										sx={{
											color: '#0062FF',
											fontStyle: 'normal',
											fontWeight: '600',
											fontSize: { md: '24px', sm: '20px', xs: '18px' },
											lineHeight: '36px',
											letterSpacing: '0.15px'
										}}
										component={'p'}
									>
										Loading Cover Letters Please wait ...
									</Typography>
									<Box
										sx={{
											marginTop: '20px'
										}}
										component={'div'}
									>
										<CircularProgress />
									</Box>
								</Box>
							)}
							{/* Showing cover letters for Desktop View */}
						</Box>
					</Box>
				</Box>
			) : (
				<MobileCoverLetters newCoverLetterHandler={newCoverLetterHandler} search={search} setSearch={setSearch} loading={loading} coverLetters={coverLetters} />
			)}
		</>
	)
}
export default CoverLetters
