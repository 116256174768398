import { FC, ChangeEvent, ClipboardEventHandler, ReactNode } from 'react'
import { Box, TextField, Typography } from '@mui/material'

interface CLFormFieldProps {
	name: string
	placeholder: string
	onChange: (event: ChangeEvent<HTMLInputElement>) => void
	value: string
	onPaste?: ClipboardEventHandler<HTMLDivElement>
	type?: string
	children?: ReactNode
	multiline?: boolean
}

const CLFormField: FC<CLFormFieldProps> = ({ name, placeholder, multiline = false, onChange, value, onPaste, type = 'text', children }) => {
	return (
		<Box>
			{name !== '' && (
				<Box sx={{ position: 'relative', display: 'flex', alignItems: 'flex-end', marginBottom: { sm: '16px', xs: '10px' } }}>
					<Typography
						component="p"
						sx={{
							color: '#171725',
							fontWeight: { sm: '600', xs: '700' },
							fontSize: { sm: '18px', xs: '14px' },
							lineHeight: { sm: '27px', xs: '18px' }
						}}
					>
						{name}
					</Typography>
					{children}
				</Box>
			)}
			<TextField
				fullWidth
				multiline={multiline}
				value={value}
				onChange={onChange}
				type={type}
				sx={{
					backgroundColor: { sm: '#F6F6F6;' },
					paddingY: { md: '20px', xs: '9px' },
					paddingX: { sm: '16px', xs: '14px' },
					fontWeight: { sm: '400', xs: '500' },
					border: { xs: '1.5px solid #F9F9F9', sm: 'none' },
					borderRadius: { xs: '8px', sm: '0px' },
					fontSize: '14px',
					lineHeight: '22px',
					letterSpacing: '0.1px',
					fontFamily: { sm: 'roboto' }
				}}
				placeholder={placeholder}
				variant="standard"
				InputProps={{ disableUnderline: true }}
				onPaste={onPaste}
			/>
		</Box>
	)
}

export default CLFormField
