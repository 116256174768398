import { Box, Button, Divider, Modal, SxProps, TextField, Typography } from '@mui/material'
import { useState, FC, useEffect } from 'react'
import { getReferralLink, isValidEmail } from '../../utils/email'
import { toast } from 'react-toastify'
import { referSite } from '../../utils'
import { auth } from '../../firebase'

interface ShareModalProps {
	isMobile?: boolean
}

const MODAL_STYLE: SxProps = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 500,
	bgcolor: 'background.paper',
	boxShadow: 24,
	borderRadius: 3,
	p: 2,
	outline: 0
}

const ReferralModal: FC<ShareModalProps> = ({ isMobile = false }) => {
	const [email, setEmail] = useState<string>('')
	const [open, setOpen] = useState<boolean>(false)
	const [subject, setSubject] = useState<string>('Gambit Resume Referral')
	const [message, setMessage] = useState<string>(`${auth?.currentUser?.displayName ?? 'anonymous'} referred you Gambit Resume platform to manage your recruitments`)
	const [error, setError] = useState<boolean>(false)

	const [link, setLink] = useState<string>('')

	const closeModal = (): void => {
		setEmail('')
		setOpen(false)
	}

	const shareHandler = async (): Promise<void> => {
		if (isValidEmail(email)) {
			setError(false)
			try {
				await referSite(link, subject, message, email)
				toast.success('Referral email sent successfully')
			} catch (err) {
				console.log(err)
			}
			closeModal()
		} else {
			setError(true)
		}
	}

	const copyLinkHandler = () => {
		navigator.clipboard.writeText(link)
		toast.success('Referral Link copied successfully')
	}

	useEffect(() => {
		const getLink = async () => {
			setLink(await getReferralLink())
		}
		getLink()
	}, [])

	return (
		<>
			{link !== '' && (
				<Button
					variant="contained"
					color="primary"
					sx={{
						paddingX: '5px',
						fontSize: '12px'
					}}
					onClick={() => setOpen(true)}
					size={isMobile ? 'small' : 'medium'}
				>
					Refer and win upto 20$
				</Button>
			)}
			<Modal open={open} onClose={closeModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
				<Box sx={MODAL_STYLE}>
					<Typography
						id="modal-modal-title"
						variant="h6"
						component="h2"
						sx={{
							mb: 4,
							mt: 0,
							fontWeight: 'bold'
						}}
					>
						Refer Gambit Resume to your friends/colleagues
					</Typography>
					<Divider />
					<Box
						sx={{
							display: 'flex',
							mt: 2
						}}
					>
						<TextField
							type="url"
							disabled
							sx={{
								width: '70%',
								marginRight: 'auto'
							}}
							InputProps={{
								style: {
									padding: '8px'
								}
							}}
							inputProps={{
								style: {
									fontSize: '14px',
									padding: '0px'
								}
							}}
							value={link}
						/>
						<Button variant="outlined" onClick={copyLinkHandler} color="info">
							Copy Link
						</Button>
					</Box>
					<Typography id="modal-modal-description" sx={{ mt: 2 }}>
						Or Share to Email:
					</Typography>
					<TextField
						InputProps={{
							style: {
								padding: '8px'
							}
						}}
						inputProps={{
							style: {
								fontSize: '14px',
								padding: '0px'
							}
						}}
						type="email"
						placeholder="abc@example.com"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>
					<Typography id="modal-modal-description" sx={{ mt: 1 }}>
						Title:
					</Typography>
					<TextField
						sx={{
							width: '100%'
						}}
						InputProps={{
							style: {
								padding: '8px'
							}
						}}
						inputProps={{
							style: {
								fontSize: '14px',
								padding: '0px'
							}
						}}
						type="text"
						placeholder="Job edit invitation"
						value={subject}
						onChange={(e) => setSubject(e.target.value)}
					/>
					<Typography id="modal-modal-description" sx={{ mt: 1 }}>
						Message:
					</Typography>
					<TextField
						InputProps={{
							style: {
								padding: '2px 6px'
							}
						}}
						sx={{
							width: '100%'
						}}
						inputProps={{
							style: {
								fontSize: '14px'
							}
						}}
						multiline
						rows={4}
						type="text"
						placeholder="some custom message to the receiver"
						value={message}
						onChange={(e) => setMessage(e.target.value)}
					/>
					{error && (
						<Typography
							sx={{
								mt: 1,
								fontSize: '12px'
							}}
							color="red"
						>
							Please enter valid email
						</Typography>
					)}
					<Divider
						sx={{
							mt: 4
						}}
					/>
					<Box
						sx={{
							mt: 4,
							display: 'flex',
							justifyContent: 'right'
						}}
					>
						<Button
							variant="contained"
							sx={{
								marginRight: '12px'
							}}
							onClick={shareHandler}
							color="info"
						>
							Share
						</Button>
						<Button variant="contained" color="error" onClick={closeModal}>
							Close
						</Button>
					</Box>
				</Box>
			</Modal>
		</>
	)
}

export default ReferralModal
