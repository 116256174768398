export const ROUTES = {
	HOME: '/',
	LOGIN: '/login',
	SIGNUP: '/signup',
	REFERRAL: '/referral',
	FAQ: '/faq',
	TERMS_AND_CONDITIONS: '/terms-and-conditions',
	ALTERNATIVES: '/alternative/JobScan',
	ABOUT_US: '/about-us',
	PRICING: '/pricing',
	OVERVIEW: '/over-view',
	SERVICES: '/service',
	SERVICES_FEEDBACK: '/service/feedback',
	SERVICE_DETAIL: '/service/:slug',
	BLOGS: '/blog',
	BLOGS_DETAILS: '/blog/:category/:slug',
	JOBS: '/over-view/job-detail',
	JOB_DETAIL: '/over-view/job-detail/:id',
	ADD_JOB: '/over-view/job-detail/add',
	SUPPORT: '/over-view/support',
	RESUMES: '/over-view/resumes',
	RESUME_BUILDER: '/over-view/resumes/:id',
	COVER_LETTER: '/over-view/cover-letters',
	COVER_LETTER_BUILDER: '/over-view/cover-letters/:id',
	MULTIPLE_RESUMES_JOBS_DETAIL: '/over-view/multiple-resumes-job-detail/:id',
	MULTIPLE_RESUMES_JOBS: '/over-view/multiple-resumes-job-detail',
	ADD_MULTIPLE_RESUMES_JOBS: '/over-view/multiple-resumes-job-detail/add',
	BLOG_BUILDER: '/over-view/blog-builder',
	ADD_BLOG_BUILDER: '/over-view/add-blog/:slug',
	NOT_FOUND: '*'
}
