const REACT_DATABASE_URL = process.env.REACT_APP_FIRESTORE_TRIGGER_BASE_URL ?? 'https://us-central1-gambitresume-3.cloudfunctions.net/'

export const getGptData = async (description: string) => {
	return await fetch(`${REACT_DATABASE_URL}chatgpt_keywords`, {
		method: 'post',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},

		body: JSON.stringify({
			description
		})
	})
}

export const getGptContent = async (title: string) => {
	return await fetch(`${REACT_DATABASE_URL}chatgpt_content`, {
		method: 'post',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},

		body: JSON.stringify({
			title
		})
	})
}
