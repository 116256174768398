import { useState, useEffect } from 'react'
import { isUserPremium } from '../utils'
import { auth } from '../firebase'
import { useAuthState } from 'react-firebase-hooks/auth'

const usePremiumStatus = () => {
	const [user] = useAuthState(auth)
	const [premiumStatus, setPremiumStatus] = useState<boolean>(false)
	const [loading, setLoading] = useState<boolean>(true)

	useEffect(() => {
		if (user) {
			setLoading(true)
			const checkPremiumStatus = async function () {
				setPremiumStatus(await isUserPremium())
			}
			checkPremiumStatus().finally(() => {
				setLoading(false)
			})
		}
	}, [user])

	return [premiumStatus, loading] as const
}

export default usePremiumStatus
