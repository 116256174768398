import { useState, useEffect, FC, useContext } from 'react'
import { useNavigate, useParams } from 'react-router'
import { Box } from '@mui/material'
import { Button, Typography, useMediaQuery, CircularProgress } from '@mui/material'
import theme from '../../../theme'
// import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { auth, db } from '../../../firebase'
import { doc, setDoc } from 'firebase/firestore'
// For referencing a single document
import { useDocument } from 'react-firebase-hooks/firestore'
import { toast } from 'react-toastify'
import { useAuthState } from 'react-firebase-hooks/auth'
import { COLLECTION_NAMES } from '../../../constants/collections'
import { Experience } from '../../../models/Experience'
import { ResumeType } from '../../../models/Resume'
import { Education } from '../../../models/Education'
import ResumeBuilderContent from '../../../components/ResumeBuilderComponents/ResumeBuilderContent'
import usePageTitle from '../../../hooks/usePageTitle'
import { ROUTES } from '../../../constants/routes'
import { LoaderContext } from '../../../contexts/loader-context'

const ResumeBuilder: FC = () => {
	const navigate = useNavigate()
	const [setPagetitle] = usePageTitle()

	const [resumeName, setResumeName] = useState<string>('')
	const [city, setCity] = useState<string>('')
	const [country, setCountry] = useState<string>('')

	const [fullName, setFullName] = useState<string>('')
	const [resumeTitle, setResumeTitle] = useState<string>('')
	const [experience, setExperience] = useState<Experience[]>([])
	const [summary, setSummary] = useState<string>('')
	const [skills, setSkills] = useState<string[]>([])
	const [editName, setEditName] = useState<boolean>(false)
	const [email, setEmail] = useState<string>('')
	const [phone, setPhone] = useState<string>('')
	const [education, setEducation] = useState<Education[]>([])

	const [currentField, setCurrentField] = useState<string>('1')
	const { setShowLoading } = useContext(LoaderContext)
	// Get the userId param from the URL.
	const { id } = useParams()

	const [user] = useAuthState(auth)

	const [resumeSnap, resumeLoading, resumeError] = useDocument(user && id ? doc(db, COLLECTION_NAMES.USERS_COLLECTION, user.uid, COLLECTION_NAMES.RESUMES, id) : null, {
		snapshotListenOptions: { includeMetadataChanges: true }
	})

	// // Updating the Job details
	const updateResumeDetails = (): void => {
		setShowLoading(true)
		if (!resumeLoading) {
			const experienceData = experience.map((exp) => {
				return {
					...exp,
					points: exp.points.filter((point) => point)
				}
			})
			const updatedDoc: ResumeType = {
				resumeName,
				fullName,
				title: resumeTitle,
				experience: experienceData,
				summary,
				skills,
				email,
				phone,
				education,
				city,
				country
			}

			setDoc(doc(db, COLLECTION_NAMES.USERS_COLLECTION, user?.uid ?? '', COLLECTION_NAMES.RESUMES, id ?? ''), updatedDoc, {
				merge: true
			})
				.then(async () => {
					setShowLoading(false)
				})
				.catch((err: Error) => {
					setShowLoading(false)
					console.log('Error updating document: ', err)
					alert('Error updating document: ' + err.message)
				})
		} else {
			setShowLoading(false)
			// alert("Please fill all the fields correctly. Empty fields are not allowed.");
		}
	}

	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	useEffect(() => {
		if (user) {
			if (resumeError !== undefined && resumeError.code === 'permission-denied') {
				toast("You don't have permission to view the Job", {
					type: 'error'
				})
				navigate(ROUTES.OVERVIEW)
			}
		}
	}, [resumeError, navigate])

	useEffect(() => {
		if (resumeSnap) {
			console.log('updating')

			const data = resumeSnap.data() as ResumeType

			setPhone(data.phone)
			setResumeName(data.resumeName)
			setFullName(data.fullName)
			setResumeTitle(data.title)
			setExperience(data.experience)
			setSummary(data.summary)
			setSkills(data.skills)
			setEmail(data.email)
			setEducation(data.education ?? [])
			setCity(data.city ?? '')
			setCountry(data.country ?? '')
		}
	}, [resumeSnap])

	useEffect(() => {
		if (resumeLoading) return
		if (resumeSnap === undefined) return
		if (resumeSnap.data()?.resumeName === '') {
			setEditName(true)
		}
	}, [resumeName, resumeLoading])

	useEffect(() => {
		setPagetitle(resumeName)
	}, [resumeName])

	return (
		<>
			{resumeLoading && (
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						mt: '30px'
					}}
				>
					<Typography
						sx={{
							color: '#0062FF',
							fontStyle: 'normal',
							fontWeight: '600',
							fontSize: { md: '24px', sm: '20px', xs: '18px' },
							lineHeight: '36px',
							letterSpacing: '0.15px'
						}}
						component={'p'}
					>
						Please wait Loading Resume Builder Page ....
					</Typography>
					<Box
						sx={{
							marginTop: '20px'
						}}
						component={'div'}
					>
						<CircularProgress />
					</Box>
				</Box>
			)}
			{resumeError && (
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						mt: '30px'
					}}
				>
					<Typography
						sx={{
							color: '#0062FF',
							fontStyle: 'normal',
							fontWeight: '600',
							fontSize: { md: '24px', sm: '20px', xs: '18px' },
							lineHeight: '36px',
							letterSpacing: '0.15px'
						}}
						component={'p'}
					>
						Error Loading Job Detail Page ....
					</Typography>
					<Box
						sx={{
							marginTop: '20px'
						}}
						component={'div'}
					>
						<Button
							variant="contained"
							color="primary"
							onClick={() => {
								navigate(ROUTES.OVERVIEW)
							}}
						>
							Go to Home
						</Button>
					</Box>
				</Box>
			)}
			{user && !resumeError && !resumeLoading && (
				<ResumeBuilderContent
					isMobile={isMobile}
					navigate={navigate}
					editName={editName}
					setEditName={setEditName}
					resumeName={resumeName}
					setResumeName={setResumeName}
					updateResumeDetails={updateResumeDetails}
					currentField={currentField}
					email={email}
					fullName={fullName}
					city={city}
					country={country}
					resumeTitle={resumeTitle}
					phone={phone}
					setEmail={setEmail}
					setFullName={setFullName}
					setCity={setCity}
					setCountry={setCountry}
					setResumeTitle={setResumeTitle}
					setPhone={setPhone}
					summary={summary}
					setSummary={setSummary}
					education={education}
					setEducation={setEducation}
					experience={experience}
					setExperience={setExperience}
					skills={skills}
					setSkills={setSkills}
					setCurrentField={setCurrentField}
				/>
			)}
		</>
	)
}
export default ResumeBuilder
