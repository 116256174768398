import { Box } from '@mui/material'
import { motion } from 'framer-motion'
import { Dispatch, FC, SetStateAction } from 'react'
import ShowNotifications from '../../../components/ShowNotifications'
import { container, item } from '../../OverviewComponents/OverviewConstants'
import MobileBlogNoJobsFound from './MobileBlogNotFound'
import MobileBlogLoadingJobs from './MobileBlogLoadingJobs'
import MobileBlogDetailBar from '../BlogDetailBar'
import { Blog } from '../../../models/Blog'

const MobileResumes: FC<{
	search: string
	setSearch: Dispatch<SetStateAction<string>>
	loading: boolean
	blogs: Blog[] | null
}> = ({ loading, blogs }) => {
	return (
		<Box className="mobile-container" sx={{ backgroundColor: '#DFDFDF', flexGrow: 1 }}>
			<Box sx={{ paddingX: '24px' }}>
				<ShowNotifications />
			</Box>
			<Box sx={{ paddingX: '24px' }}>
				{/* // */}
				{/* Showing blogs for Mobile View */}
				{!loading ? (
					<>
						{blogs && blogs?.length > 0 ? (
							<motion.div variants={container} initial="hidden" animate="show">
								<Box
									className="job-list"
									sx={{
										paddingBottom: '20px'
									}}
									component={motion.div}
									variants={item}
								>
									<>
										{blogs.map((blog: Blog) => {
											return (
												<Box
													sx={{
														marginTop: '20px',
														marginBottom: '20px'
													}}
													component={motion.div}
													variants={item}
													key={blog.id}
												>
													<MobileBlogDetailBar
														id={blog.id ?? ''}
														title={blog.title}
														createdAt={blog.createdAt !== undefined && blog.createdAt !== null ? blog.createdAt : undefined}
													/>
												</Box>
											)
										})}
										{/* All Resumes listed here */}
									</>
								</Box>
							</motion.div>
						) : (
							<>
								<MobileBlogNoJobsFound />
							</>
						)}
					</>
				) : (
					<MobileBlogLoadingJobs />
				)}
				{/* Showing resumes for Mobile View */}
			</Box>
		</Box>
	)
}

export default MobileResumes
