import { FC } from 'react'
import { Typography } from '@mui/material'
import TopJobTitleTable from '../TopJobTitleTable'

const MobileMultipleJobTitles: FC<{
	rows: string[]
	markInaccurateTitle: (index: number, title: string) => void
}> = ({ rows, markInaccurateTitle }) => {
	return (
		<>
			<Typography
				component="span"
				sx={{
					fontWeight: '700',
					fontSize: '11px',
					marginBottom: { sm: '11px', xs: '5px' },
					marginTop: { sm: '18px', xs: '10px' }
				}}
			>
				Based on this job description, this role is closely aligned with the following job titles
			</Typography>
			<TopJobTitleTable
				rows={rows}
				markInaccurateTitle={(value) => {
					markInaccurateTitle(0, value)
				}}
			/>
		</>
	)
}

export default MobileMultipleJobTitles
