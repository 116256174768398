import { Box, Typography } from '@mui/material'

const WelcomeText = ({ handleAnonymousSignIn }: { handleAnonymousSignIn: () => void }) => {
	return (
		<Box sx={{ width: { xs: '100%', md: '50%' }, alignSelf: 'center', position: 'relative', zIndex: 1 }}>
			<Box
				sx={{
					padding: '10px',
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center'
				}}
			>
				<Typography
					variant="h3"
					sx={{
						fontFamily: 'Poppins',
						fontStyle: 'normal',
						fontWeight: '700',
						fontSize: {
							lg: '60px',
							md: '40px',
							xs: '35px'
						},
						lineHeight: '10px',
						textAlign: 'center',
						letterSpacing: '0.233333px',
						color: { xs: 'white', sm: 'black' },
						marginY: '40px'
					}}
					style={{
						lineHeight: 'unset'
					}}
				>
					Give Yourself An Unfair Advantage
				</Typography>
				<Typography
					variant="h3"
					sx={{
						fontFamily: 'Poppins',
						fontStyle: 'normal',
						fontWeight: '700',
						fontSize: {
							lg: '40px',
							md: '26px',
							xs: '20px'
						},
						lineHeight: '10px',
						textAlign: 'center',
						letterSpacing: '0.233333px',
						color: { xs: 'white', sm: 'black' }
					}}
					style={{
						lineHeight: 'unset'
					}}
				>
					Write a Bespoke Resume 10x Faster
				</Typography>
				<Typography
					variant="h5"
					sx={{
						fontFamily: "'Poppins'",
						fontStyle: 'normal',
						fontWeight: '600',
						fontSize: {
							xs: '20px'
						},
						lineHeight: '36px',
						letterSpacing: '0.5px',
						wordSpacing: '10px',
						backgroundColor: '#171725',
						cursor: 'pointer',
						width: '80%',
						color: 'white',
						borderRadius: '10px',
						padding: '14px',
						textAlign: 'center',
						marginY: '40px'
					}}
					onClick={() => handleAnonymousSignIn()}
				>
					Try Without Registration
				</Typography>
			</Box>
		</Box>
	)
}

export default WelcomeText
