import { Dispatch, SetStateAction, FC, ChangeEvent } from 'react'
import { Box, Button, List, ListItemButton, ListItemIcon, TextField, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { Experience } from '../../../../../models/Experience'

interface Props {
	points: string[]
	experience: Experience[]
	setExperience: Dispatch<SetStateAction<Experience[]>>
	experienceIdx: number
}

const ExperiencePoints: FC<Props> = ({ points, experience, setExperience, experienceIdx }) => {
	const updateExperienceHandler = (points: string[]) => {
		const tempExperience: Experience[] = [...experience]
		tempExperience[experienceIdx].points = points
		setExperience([...tempExperience])
	}

	const pointsChangeHandler = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, idx: number) => {
		const newPoints = [...points]
		newPoints[idx] = e.target.value
		updateExperienceHandler(newPoints)
	}

	const removePointHandler = (idx: number) => {
		if (points.length === 1) return
		const newPoints = [...points]
		newPoints.splice(idx, 1)
		updateExperienceHandler(newPoints)
	}

	if (!Array.isArray(points) || (points && !points.length)) {
		updateExperienceHandler([''])
	}

	return (
		<Box>
			<Typography
				fontSize={16}
				fontWeight={400}
				style={{
					color: 'rgba(0, 0, 0, 0.6)'
				}}
			>
				Points
			</Typography>
			<List sx={{ width: '100%' }} component="div">
				{!Array.isArray(points)
					? null
					: points?.map((point, idx) => (
							<ListItemButton
								disableRipple
								style={{
									padding: 8,
									justifyContent: 'space-between',
									gap: 10,
									cursor: 'default'
								}}
								key={`resume-experience-point-${idx}`}
							>
								<ListItemIcon
									style={{
										minWidth: 'unset',
										...(idx === 0 && points.length <= 1
											? {
													opacity: 0.5,
													cursor: 'default'
											  }
											: { cursor: 'pointer' })
									}}
									onClick={() => removePointHandler(idx)}
								>
									<CloseIcon color="warning" />
								</ListItemIcon>
								<TextField
									value={point}
									onChange={(e) => pointsChangeHandler(e, idx)}
									type="text"
									variant="standard"
									sx={{
										fontWeight: { sm: '400', xs: '500' },
										fontFamily: { sm: 'roboto' }
									}}
									fullWidth
									size="small"
									InputProps={{
										sx: {
											fontSize: '14px'
										}
									}}
									multiline
								/>
							</ListItemButton>
					  ))}
			</List>
			<Box component="div" textAlign="center">
				<Button type="button" onClick={() => updateExperienceHandler([...points, ''])}>
					Add New Point
				</Button>
			</Box>
		</Box>
	)
}

export default ExperiencePoints
