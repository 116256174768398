import { FC } from 'react'
import MultiResumesSummeryTable from './MultiResumesSummeryTable'
import JobFieldsSections1Fields2 from './JobFieldsSections1Fields2'
import { Grid } from '@mui/material'
import RelatedSkills from './RelatedSkills'
import JobFieldsSections2 from './JobFieldsSections2'
import JobFieldsSections1 from './JobFieldsSections1'
import { Job } from '../../models/Job'
import { ResumeInputMode } from './types'
import { DocumentData, DocumentReference, FirestoreError } from 'firebase/firestore'
import { User } from 'firebase/auth'

const JobSummeryTable: FC<{
	isMultiResumes: boolean
	viewMetrixStatus: { data: Job; index: number } | null
	editMode: boolean
	resumes: Job[]
	handleDelete?: (index: number) => void
	setJobText: (number: number, value: string) => void
	file: File | null
	setFile: (number: number, value: File | null) => void
	setDescription: (number: number, value: string) => void
	resumeMode: ResumeInputMode
	setResumeMode: (index: number, value: ResumeInputMode) => void
	setSelectedResume: (index: number, value: string) => void
	selectedResume: string
	setViewMetrixStatus: (d: { data: Job; index: number } | null) => void
	isSorted?: boolean
	sortAccordingToHardSkill?: (e: React.ChangeEvent<HTMLInputElement>) => void
	skillsCheckboxHandler: (index: number, e: React.ChangeEvent<HTMLInputElement>) => Promise<void>
	addSuggestedSkill: (index: number, tableName: string, skill: string) => Promise<void>
	markInaccurateTitle: (index: number, title: string) => Promise<void>
	isMobile: boolean
	markIrrelevantSkill: (index: number, skill: string, tableName: string) => Promise<void>
	setTitle: (number: number, value: string) => void
	setCompany: (number: number, value: string) => void
	setLocation: (number: number, value: string) => void
	setMoreInfo: (number: number, value: string) => void
	jobError?: FirestoreError
	jobRef: DocumentReference<DocumentData>
	user?: User | null
	jobLoading: boolean
}> = ({
	isMultiResumes,
	viewMetrixStatus,
	editMode,
	resumes,
	handleDelete,
	setJobText,
	file,
	setFile,
	setDescription,
	resumeMode,
	setResumeMode,
	setSelectedResume,
	selectedResume,
	setViewMetrixStatus,
	isSorted,
	sortAccordingToHardSkill,
	skillsCheckboxHandler,
	addSuggestedSkill,
	markInaccurateTitle,
	isMobile,
	markIrrelevantSkill,
	setTitle,
	setCompany,
	setLocation,
	setMoreInfo,
	jobError,
	jobRef,
	user,
	jobLoading
}) => {
	return (
		<Grid container spacing={1}>
			<Grid item xs={!editMode ? 6 : 12}>
				{!editMode ? (
					<MultiResumesSummeryTable resumes={resumes} onView={(isShow, i) => setViewMetrixStatus(isShow && resumes ? { data: resumes[i], index: i } : null)} isHardSkill={isSorted} />
				) : null}
				<JobFieldsSections1Fields2
					hideResume={true}
					handleDelete={handleDelete}
					resumeIndex={0}
					editMode={editMode}
					jobText={resumes[0].resume}
					setJobText={setJobText}
					file={file}
					setFile={setFile}
					description={resumes[0].description}
					setDescription={setDescription}
					resumeMode={resumeMode}
					setResumeMode={setResumeMode}
					setSelectedResume={setSelectedResume}
					selectedResume={selectedResume}
					newResume={resumes[0].newResume}
				/>
				{isMultiResumes && viewMetrixStatus ? <RelatedSkills tableRelated={viewMetrixStatus?.data?.table_related} markIrrelevantSkill={markIrrelevantSkill} /> : null}
			</Grid>
			{!editMode ? (
				<Grid item xs={6}>
					{viewMetrixStatus ? (
						<>
							<JobFieldsSections2
								isFitScore={true}
								isSorted={isSorted}
								sortAccordingToHardSkill={sortAccordingToHardSkill}
								isMultiResumes={true}
								resumeIndex={viewMetrixStatus.index}
								skillsCheckboxHandler={skillsCheckboxHandler}
								percentHard={viewMetrixStatus.data.percentage_hard}
								tableHard={viewMetrixStatus.data.table_hard}
								addSuggestedSkill={addSuggestedSkill}
								percentSoft={viewMetrixStatus.data.percentage_soft}
								tableSoft={viewMetrixStatus.data.table_soft}
								skillsOnly={viewMetrixStatus.data.skillsOnly}
								percentSales={viewMetrixStatus.data.percentage_sales}
								resumeAnalysis={viewMetrixStatus.data.resume_analysis}
								tableRelated={viewMetrixStatus.data.table_related}
								topTitles={viewMetrixStatus.data.top_titles}
								markInaccurateTitle={markInaccurateTitle}
								isMobile={isMobile}
								markIrrelevantSkill={markIrrelevantSkill}
								isFullWidth={true}
							/>
							<JobFieldsSections1
								showMoreDetails={true}
								handleDelete={handleDelete}
								isMultiResumes={true}
								resumeIndex={viewMetrixStatus.index}
								resumeMode={viewMetrixStatus.data.resumeMode}
								setResumeMode={setResumeMode}
								editMode={editMode}
								setTitle={setTitle}
								setCompany={setCompany}
								setLocation={setLocation}
								moreInfo={viewMetrixStatus.data.moreInfo}
								setMoreInfo={setMoreInfo}
								jobError={jobError}
								jobRef={jobRef}
								title={viewMetrixStatus.data.title}
								user={user}
								jobText={viewMetrixStatus.data.resume}
								setDescription={setDescription}
								description={viewMetrixStatus.data.description}
								jobLoading={jobLoading}
								company={viewMetrixStatus.data.company}
								location={viewMetrixStatus.data.location}
								file={viewMetrixStatus.data?.file as File}
								setFile={setFile}
								setJobText={setJobText}
								created={viewMetrixStatus.data.created}
								setSelectedResume={setSelectedResume}
								selectedResume={viewMetrixStatus.data.selectedResume}
								newResume={viewMetrixStatus.data.newResume}
								resumeCandidateName={viewMetrixStatus.data.resumeCandidateName}
								resumeTitle={viewMetrixStatus.data.resumeTitle}
								resumePhone={viewMetrixStatus.data.resumePhone}
								resumeCity={viewMetrixStatus.data.resumeCity}
								resumeEmail={viewMetrixStatus.data.resumeEmail}
								hideDescription={true}
							/>
						</>
					) : null}
				</Grid>
			) : null}
		</Grid>
	)
}

export default JobSummeryTable
