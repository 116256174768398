/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import { FC, useEffect, useRef, useState } from 'react'
import { useCollection } from 'react-firebase-hooks/firestore'
import { getBlogBySlugQuery, getBlogsQuery, getRelatedBlogsQuery } from '../../utils/queries'
import { Box, Button, Container, Grid, Typography } from '@mui/material'
import { useLocation, useNavigate, useParams } from 'react-router'
import { ROUTES } from '../../constants/routes'
import { Blog } from '../../models/Blog'
import { toast } from 'react-toastify'
import BlogsBanner from '../../assets/Blogs/blogs-banner.jpg'
import PureReactCarousel from '../../components/PureReactCarousel/ReactPureCarousel'
import { Slide } from 'pure-react-carousel'
import { getResponsiveNoOfSlides } from '../../utils/getResponsiveNoOfSildes'
import parser from 'html-react-parser'
import { DocumentData, QuerySnapshot, collection, getCountFromServer, getDocs, query } from 'firebase/firestore'
import { db } from '../../firebase'

const Blogs: FC = () => {
	const [blogs, setBlogs] = useState<Blog[]>([])
	const [latestBlogs, setLatestBlogs] = useState<Blog[]>([])
	const [relatedBlogs, setRelatedBlogs] = useState<Blog[]>([])

	const lastBlogDocument = useRef<DocumentData | null>(null)
	const [selectedBlogIndex, setSelectedBlogIndex] = useState<number | undefined>()
	const [totalBlogCount, setTotalBlogCount] = useState(0)
	const { slug, category } = useParams<{ slug: string; category: string }>()
	const location = useLocation()
	const navigate = useNavigate()
	const [selectedBlog, setSelectedBlog] = useState<Blog | null>(null)
	const [currentPage, setCurrentPage] = useState(1)
	const pageSize = 4
	const q = getBlogsQuery(4, true)
	const prevBlogs = useRef<DocumentData[]>([])
	const [snapshot, loading, error] = useCollection(q, {
		snapshotListenOptions: { includeMetadataChanges: true }
	})
	const isSelectedOnceRef = useRef(false)
	const getMoreBlogs = async (isBack: boolean) => {
		let doc = lastBlogDocument.current as DocumentData
		let isFirst = false
		if (blogs.length && (isBack ? currentPage > 1 : currentPage < Math.ceil(totalBlogCount / pageSize))) {
			if (isBack && prevBlogs.current?.length >= 2) {
				if (prevBlogs.current?.length === 2) {
					doc = prevBlogs.current[0]
					prevBlogs.current?.splice(1, 1)
					lastBlogDocument.current = null
				}
				if (prevBlogs.current?.length > 2) {
					doc = prevBlogs.current[prevBlogs.current?.length - 3]
					prevBlogs.current.splice(prevBlogs.current?.length + 1, prevBlogs.current?.length)
				}
			} else if (isBack && prevBlogs.current?.length < 2) {
				isFirst = true
				prevBlogs.current = []
				lastBlogDocument.current = null
			}
			const query = getBlogsQuery(pageSize, isFirst, doc ?? undefined)
			const documentSnapshots = await getDocs(query)
			getBlogData(documentSnapshots, isBack, isFirst)
			setCurrentPage((page) => (isBack ? --page : ++page))
		}
	}

	const getBlogCount = async () => {
		const ref = collection(db, 'blogs')
		const snapshot = await getCountFromServer(query(ref))
		setTotalBlogCount(snapshot.data().count)
	}
	useEffect(() => {
		getBlogCount()
	}, [])

	const blogSelectionHandler = (blogIndex: number) => {
		const selectedBlog = blogs[blogIndex]
		setSelectedBlog(selectedBlog)
		navigate(`${ROUTES.BLOGS}/${selectedBlog.category}/${selectedBlog.slug}`)
	}

	const relatedBlogSelectionHandler = (blogIndex: number) => {
		setSelectedBlogIndex(blogIndex)
		const selectedBlog = relatedBlogs[blogIndex]
		setSelectedBlog(selectedBlog)
		navigate(`${ROUTES.BLOGS}/${selectedBlog.category}/${selectedBlog.slug}`)
	}
	const getBlogData = (snapshot: QuerySnapshot<DocumentData, DocumentData>, isBack: boolean, isFirst?: boolean) => {
		const blogsRes = snapshot?.docs.map((doc: DocumentData) => ({ ...doc.data(), id: doc.id })) as Blog[]
		if (!isBack && lastBlogDocument.current) prevBlogs.current.push(lastBlogDocument.current)
		if (snapshot?.docs.length) lastBlogDocument.current = snapshot.docs[snapshot.docs.length - 1] ?? null
		setBlogs([...blogsRes])
		if (isFirst) setLatestBlogs([...blogsRes])
	}
	useEffect(() => {
		if (!loading && error == null && snapshot) getBlogData(snapshot, false, true)
	}, [loading, snapshot])

	useEffect(() => {
		if (location.pathname === ROUTES.BLOGS) setSelectedBlogIndex(undefined)
	}, [location.pathname])

	const nextBlogHandler = () => {
		if (selectedBlogIndex! < relatedBlogs.length - 1) {
			const selectedBlog = relatedBlogs[selectedBlogIndex! + 1]
			navigate(`${ROUTES.BLOGS}/${selectedBlog.category}/${selectedBlog.slug}`)
			setSelectedBlog(selectedBlog)
			setSelectedBlogIndex((p) => p! + 1)
		}
	}
	const prevBlogHandler = () => {
		if (selectedBlogIndex! > 0) {
			const selectedBlog = relatedBlogs[selectedBlogIndex! - 1]
			navigate(`${ROUTES.BLOGS}/${selectedBlog.category}/${selectedBlog.slug}`)
			setSelectedBlog(selectedBlog)
			setSelectedBlogIndex((p) => p! - 1)
		}
	}

	const copyLinkHandler = () => {
		const selectedBlog = blogs[selectedBlogIndex!]
		const link = `${window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '')}${ROUTES.BLOGS}/${selectedBlog.category}/${
			selectedBlog.slug
		}`
		navigator.clipboard.writeText(link)
		toast.success('Blog Link copied successfully')
	}

	const { noOfSlidesForBlogs } = getResponsiveNoOfSlides()

	const getRelatedBlog = async (category: string, tags: string[] = []) => {
		const query = getRelatedBlogsQuery(category)
		const documentSnapshots = await getDocs(query)
		let relatedBlogs = documentSnapshots?.docs.map((doc: DocumentData) => ({
			...doc.data(),
			id: doc.id
		})) as Blog[]
		if (slug) {
			let selectedBlogIndex
			let blog
			const limitedBlogs: Blog[] = []
			relatedBlogs.forEach((b, i) => {
				if (limitedBlogs.length < 4) {
					limitedBlogs.push(b)
				}
				if (b.slug === slug) {
					selectedBlogIndex = i
					blog = b
					return true
				}
			})
			if (selectedBlogIndex !== undefined) {
				relatedBlogs = limitedBlogs
				setSelectedBlog(blog ?? null)
				setSelectedBlogIndex(selectedBlogIndex)
			} else {
				navigate(ROUTES.BLOGS)
			}
		}
		setRelatedBlogs(relatedBlogs)
	}
	useEffect(() => {
		if (selectedBlog) {
			isSelectedOnceRef.current = true
			getRelatedBlog(selectedBlog?.category, selectedBlog.tags)
		}

		if (!selectedBlog && !isSelectedOnceRef.current && category) {
			isSelectedOnceRef.current = true
			getDocs(getBlogBySlugQuery(slug ?? '')).then((docs) => {
				getRelatedBlog(category, docs.docs.at(0)?.data()?.tags ?? [])
			})
		}
	}, [selectedBlog, slug])
	useEffect(() => {
		if (!slug) setSelectedBlog(null)
	}, [slug])
	if (slug && category && !selectedBlog) return null
	return (
		<Container className="p-0" maxWidth="xl">
			<Box sx={{ minHeight: '100vh' }}>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						width: '100%',
						height: '600px',
						maxHeight: { xs: '300px', sm: '400px', md: '450px', lg: '500px', xl: '600px' },
						backgroundRepeat: 'no-repeat',
						backgroundSize: 'cover',
						backgroundImage: `url(${selectedBlog?.background_title_image ? selectedBlog?.background_title_image : BlogsBanner})`,
						padding: '20px',
						backgroundPosition: 'center'
					}}
				>
					<Typography
						variant="h1"
						sx={{
							background: '#ffffff29',
							padding: '10px 20px',
							border: '3px solid #ffffff61',
							textAlign: 'center',
							color: 'white',
							fontSize: { xs: '30px', md: '50px', lg: '70px' },
							fontWeight: 'bold'
						}}
					>
						{selectedBlog ? selectedBlog.title : 'BLOGS'}
					</Typography>
				</Box>
				<Container maxWidth="xl" className="py-5">
					<Grid container spacing={2}>
						<Grid order={{ xs: 2, md: 1 }} item xs={12} md={8}>
							{selectedBlog ? (
								<Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
									<Box textAlign="end">
										<Button
											sx={{ border: 'none', color: 'black', '&:hover': { background: 'transparent' } }}
											onClick={prevBlogHandler}
											disabled={selectedBlogIndex! <= 0}
											type="button"
											variant="text"
										>
											{'< Previous'}
										</Button>
										<Button
											sx={{ border: 'none', color: 'black', '&:hover': { background: 'transparent' } }}
											onClick={nextBlogHandler}
											disabled={selectedBlogIndex! >= relatedBlogs.length - 1}
											type="button"
										>
											{'Next >'}
										</Button>
									</Box>
									<Box sx={{ alignSelf: 'start', display: 'flex', flexGrow: 1, flexDirection: 'column', gap: '20px' }}>
										<Box>
											<img style={{ maxWidth: '100%' }} src={selectedBlog.preview_url} />
										</Box>
										{selectedBlog.details ? <Box className="container-image">{parser(selectedBlog.details)}</Box> : null}
										{selectedBlog?.tags && selectedBlog?.tags?.length ? (
											<Box style={{ marginTop: 5, marginBottom: 5, color: 'rgb(0, 98, 255)' }}>{selectedBlog?.tags?.map((tag) => '#' + tag + ' ')}</Box>
										) : null}
										<Typography sx={{ background: '#f6f6f6', padding: '5px 20px' }}>
											<b>Share by</b>
											<Button onClick={copyLinkHandler}>Copying Link</Button>
										</Typography>
									</Box>
									{relatedBlogs.length > 1 ? (
										<Box>
											<Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>Related Blogs</Typography>
											<hr />
											<PureReactCarousel CarouselProviderClassName="carousel-provider" totalSlides={3} visibleSlides={noOfSlidesForBlogs} showCarouselButtons={false}>
												{relatedBlogs?.map((b, i) => {
													if (b.id === selectedBlog.id) return null
													return (
														<Slide key={b.slug} index={i}>
															<Box key={b.slug} sx={{ padding: '10px 20px', maxWidth: '100%', cursor: 'pointer' }} onClick={() => relatedBlogSelectionHandler(i)}>
																<img width="100%" style={{ maxHeight: '150px' }} src={b.preview_url} />
																<Typography variant="h6">{b.title}</Typography>
															</Box>
														</Slide>
													)
												})}
											</PureReactCarousel>
										</Box>
									) : null}
								</Box>
							) : (
								<>
									<Box sx={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>
										{blogs.map((blog, i) => {
											let detailPreview = blog.details ? blog.details.replaceAll(/<\/?[^>]+(>|$)/g, '') : ''
											detailPreview = detailPreview.length > 100 ? `${detailPreview.slice(0, 100)}...` : detailPreview
											return (
												<Box key={blog.slug} className="p-5" sx={{ maxWidth: '200px', cursor: 'pointer' }} onClick={() => blogSelectionHandler(i)}>
													<img width={200} style={{ maxHeight: '175px' }} src={blog.preview_url} />
													<Typography variant="h6">{blog.title}</Typography>
													<Typography>{detailPreview}</Typography>
												</Box>
											)
										})}
									</Box>
									<Box sx={{ marginLeft: 'auto', display: 'flex', gap: '10px', flexDirection: 'row', width: 200 }}>
										<Button disabled={!prevBlogs.current.length} onClick={async () => await getMoreBlogs(true)}>
											Previous
										</Button>
										<Button disabled={currentPage >= Math.ceil(totalBlogCount / pageSize)} onClick={async () => await getMoreBlogs(false)}>
											Next
										</Button>
									</Box>
								</>
							)}
						</Grid>

						<Grid order={{ xs: 1, md: 2 }} item xs={12} md={4}>
							<Box sx={{ display: 'flex', gap: '10px', flexDirection: 'column', textAlign: 'center', padding: '10px', boxShadow: '0px 0px 1px black' }}>
								<Typography variant="h5">Latest Blogs</Typography>
								{latestBlogs.slice(0, 5).map((blog, i) => (
									<Typography
										onClick={() => blogSelectionHandler(i)}
										key={blog.slug}
										sx={{ cursor: 'pointer', padding: '5px 10px', color: '#0062FF', backgroundColor: 'white', border: '1px solid #0062FF', borderRadius: '5px' }}
									>
										{blog.title}
									</Typography>
								))}
							</Box>
						</Grid>
					</Grid>
				</Container>
			</Box>
		</Container>
	)
}
export default Blogs
