import { FC } from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material'
import { Button, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'
import { capitalizeFirstLetter } from '../../utils'

interface TopJobTitleProps {
	rows: string[]
	markInaccurateTitle: (title: string) => void
}

const FONT_SIZE = { xs: '10px', sm: '12px', lg: '14px' }

const TopJobTitleTable: FC<TopJobTitleProps> = ({ rows, markInaccurateTitle }) => {
	const handleButtonClick = (title: string) => {
		markInaccurateTitle(title)
	}

	return rows.length > 0 ? (
		<>
			<TableContainer elevation={0} component={Paper} sx={{ width: '100%' }}>
				<Table
					sx={{
						minWidth: 280
					}}
					stickyHeader
					aria-label="sticky table"
				>
					<TableHead>
						<TableRow>
							<TableCell
								component="th"
								scope="row"
								sx={{
									fontWeight: 'bold',
									fontSize: FONT_SIZE
								}}
							>
								Rank
							</TableCell>
							<TableCell
								component="th"
								scope="row"
								sx={{
									fontWeight: 'bold',
									fontSize: FONT_SIZE
								}}
							>
								Title
							</TableCell>
							<TableCell
								component="th"
								scope="row"
								sx={{
									fontWeight: 'bold',
									fontSize: FONT_SIZE
								}}
							>
								Not Accurate?
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.map((row, idx) => (
							<TableRow
								key={row}
								sx={{
									'&:last-child td, &:last-child th': {
										border: 0
									}
								}}
							>
								<TableCell sx={{ fontSize: FONT_SIZE }}>{idx + 1}</TableCell>
								<TableCell sx={{ fontSize: FONT_SIZE }}>{capitalizeFirstLetter(row)}</TableCell>
								<TableCell sx={{ fontSize: FONT_SIZE }}>
									<Button
										variant="outlined"
										color="error"
										sx={{
											padding: '4px',
											minWidth: '10px',
											marginRight: '8px'
										}}
										onClick={(e) => handleButtonClick(row)}
										size="small"
									>
										<Close
											sx={{
												fontSize: FONT_SIZE
											}}
										/>
									</Button>
									Not Accurate
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	) : (
		<Typography
			component="p"
			sx={{
				fontWeight: '400',
				fontSize: '12px',
				lineHeight: '21px',
				marginBottom: { sm: '11px', xs: '5px' },
				marginTop: { sm: '18px', xs: '10px' }
			}}
		>
			No data found
		</Typography>
	)
}

export default TopJobTitleTable
