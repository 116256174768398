import { DocumentData, DocumentReference, Firestore, deleteDoc, doc, writeBatch } from 'firebase/firestore'
import { NavigateFunction } from 'react-router'
import { Job } from '../../models/Job'
import { ROUTES } from '../../constants/routes'

export const deleteJob = (navigate: NavigateFunction, isMultiResumes: boolean, resumes: Job[], db: Firestore, jobRef: DocumentReference<DocumentData>): void => {
	if (window.confirm('Are you sure you want to delete this job?')) {
		if (isMultiResumes) {
			const batch = writeBatch(db)
			resumes?.forEach((data) => {
				batch.delete(doc(db, 'Jobs', data?.id as string))
			})
			batch
				.commit()
				.then(() => {
					navigate(ROUTES.MULTIPLE_RESUMES_JOBS)
				})
				.catch((err: Error) => {
					console.log('Error deleting Job: ', err)
					alert('Error deleting Job: ' + err.message)
				})
		} else {
			deleteDoc(jobRef)
				.then(() => {
					navigate(ROUTES.OVERVIEW)
				})
				.catch((err: Error) => {
					console.log('Error deleting Job: ', err)
					alert('Error deleting Job: ' + err.message)
				})
		}
	}
}
