import { User } from 'firebase/auth'

export type availableColors = 'error' | 'warning' | 'success'
export interface alertInterface {
	open: boolean
	title: string
	detail: string
	color: availableColors
}

export const container = {
	show: {
		transition: {
			staggerChildren: 0.05
		}
	}
}

export const item = {
	hidden: { opacity: 0, y: 40 },
	show: { opacity: 1, y: 0 }
}

export interface Props {
	user: User | null | undefined
}
