import { FC } from 'react'
import { useNavigate } from 'react-router'

import { Box, Typography, useMediaQuery } from '@mui/material'

import theme from '../../../theme'
import { ROUTES } from '../../../constants/routes'
import { Description } from '@mui/icons-material'
import MobileResumeDetailBar from './MobileResumeDetailBar'

interface ResumeDetailBarProps {
	id: string
	title: string
	resumeName: string
	skills: string[]
	created: string | undefined
}
const ResumeDetailBar: FC<ResumeDetailBarProps> = ({ id, title, resumeName, skills, created }) => {
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
	const navigate = useNavigate()

	return (
		// replace navigate with real implementation
		<>
			{!isMobile ? (
				<Box
					onClick={() => navigate(`${ROUTES.RESUMES}/${id}`)}
					sx={{
						borderRadius: '20px',
						width: '100%',
						height: '92px',
						background: 'white',
						marginBottom: '10px',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						minWidth: '705px',
						cursor: 'pointer',
						// border: "1px solid #000000",
						overflowX: 'auto'
					}}
				>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Box
							sx={{
								backgroundColor: '#0062FF',
								borderRadius: '10px',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								width: '38px',
								height: '38px',
								marginLeft: '22px',
								color: 'white'
							}}
						>
							<Description />
						</Box>
						<Box sx={{ marginX: '20px' }}>
							<Typography
								variant="body1"
								sx={{
									color: '#171725',
									fontWeight: '600',
									fontSize: '16px',
									lineHeight: '24px',
									letterSpacing: '0.1px',
									marginBottom: '6px'
								}}
							>
								Resume Name
							</Typography>
							<Typography
								variant="body1"
								sx={{
									color: '#44444F',
									fontWeight: '500',
									fontSize: '14px',
									lineHeight: '21px',
									letterSpacing: '0.1px',
									whiteSpace: 'nowrap',
									width: '150px',
									overflow: 'hidden',
									textOverflow: 'ellipsis'
									// border: "1px solid #44444F",
								}}
							>
								{resumeName}
							</Typography>
						</Box>
					</Box>
					<Box
						sx={{
							paddingX: { lg: '30px', xs: '18px' },
							borderLeft: '1px solid #F1F1F5',
							borderRight: '1px solid #F1F1F5'
						}}
					>
						<Typography
							sx={{
								fontWeight: '400',
								fontSize: { lg: '14px', xs: '12px' },
								lineHeight: '16.4px',
								letterSpacing: '0.1px',
								marginBottom: '10px',
								color: '#696974'
							}}
						>
							Title
						</Typography>
						<Typography
							sx={{
								fontWeight: '600',
								fontSize: { lg: '16px', xs: '14px' },
								lineHeight: '24px',
								letterSpacing: '0.1px',
								color: '#171725',
								whiteSpace: 'nowrap',
								width: '200px',
								overflow: 'hidden',
								textOverflow: 'ellipsis'
								// border: "1px solid #44444F",
							}}
						>
							{title}
						</Typography>
					</Box>
					<Box
						sx={{
							paddingX: { lg: '30px', xs: '18px' },
							borderLeft: '1px solid #F1F1F5',
							borderRight: '1px solid #F1F1F5'
						}}
					>
						<Typography
							sx={{
								fontWeight: '400',
								fontSize: { lg: '14px', xs: '12px' },
								lineHeight: '16.4px',
								letterSpacing: '0.1px',
								marginBottom: '10px',
								color: '#696974'
							}}
						>
							Skills
						</Typography>
						<Typography
							sx={{
								fontWeight: '600',
								fontSize: { lg: '16px', xs: '14px' },
								lineHeight: '24px',
								letterSpacing: '0.1px',
								color: '#171725',
								whiteSpace: 'nowrap',
								width: '200px',
								overflow: 'hidden',
								textOverflow: 'ellipsis'
								// border: "1px solid #44444F",
							}}
						>
							{skills.join(', ')}
						</Typography>
					</Box>
				</Box>
			) : (
				<MobileResumeDetailBar resumeName={resumeName} title={title} skills={skills} created={created} navigate={navigate} id={id} />
			)}
		</>
	)
}

export default ResumeDetailBar
