import { Timestamp } from 'firebase/firestore'

export interface Blog {
	id: string
	order: number
	category: string
	title: string
	slug: string
	details: string
	preview_url: string
	background_title_image?: string
	createdAt?: Timestamp
	tags?: string[]
}

export enum BlogSections {
	titleBannerImage = 'titleBannerImage',
	dynamicPoints = 'dynamicPoints',
	title = 'title',
	toc = 'toc',
	description = 'description',
	dynamicImagePoints = 'dynamicImagePoints',
	advertisement = 'advertisement',
	summery = 'summary'
}

export interface BlogTempate {
	banner: string
	name: string
	sections: BlogSections[]
}

export interface FormObj {
	isMultiple?: boolean
	values?: FormObj[]
	isFile?: boolean
	isText?: boolean
	isRtc?: boolean
	file?: null | File
	text?: string
	rtcText?: string
	descText?: string
	fileText?: string
	rtcData?: string
	dynamicPointsTitle?: string
	templateName?: BlogSections
	fileUrl?: string
	isAiButton?: boolean
	targetTemplate?: BlogSections
}
