import { Box, Button, Divider, Modal, SxProps, Typography } from '@mui/material'
import { useState, FC } from 'react'
import { capitalizeFirstLetter } from '../../utils'
import { TagsInput } from 'react-tag-input-component'

interface SuggestSkillModalProps {
	isMobile: boolean
	modalSkillType: string
	handleAddSkill: (skillType: string, skill: string[]) => void
}

const MODAL_STYLE: SxProps = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 350,
	bgcolor: 'background.paper',
	boxShadow: 24,
	borderRadius: 3,
	p: 2,
	outline: 0
}

const BUTTON_STYLE = {
	mobile: {
		fontWeight: '700',
		fontSize: { xs: '9px', sm: '12px', md: '14px' }
	},
	desktop: {
		fontWeight: '700',
		fontSize: {
			xs: '9px',
			sm: '12px',
			md: '14px'
		},
		letterSpacing: '0.1px',
		marginBottom: { sm: '11px', xs: '5px' },
		marginTop: { sm: '18px', xs: '10px' }
	}
}

const SuggestSkillModal: FC<SuggestSkillModalProps> = ({ isMobile, modalSkillType, handleAddSkill }) => {
	const [skill, setSkill] = useState<string[]>([])
	const [open, setOpen] = useState(false)

	const closeModal = () => {
		setSkill([])
		setOpen(false)
	}

	return (
		<>
			<Button variant="outlined" color="info" sx={isMobile ? BUTTON_STYLE.mobile : BUTTON_STYLE.desktop} onClick={() => setOpen(true)} size={isMobile ? 'small' : 'medium'}>
				Suggest new keyword
			</Button>
			<Modal open={open} onClose={closeModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
				<Box sx={MODAL_STYLE}>
					<Typography
						id="modal-modal-title"
						variant="h6"
						component="h2"
						sx={{
							mb: 4,
							mt: 0,
							fontWeight: 'bold'
						}}
					>
						Suggest new {capitalizeFirstLetter(modalSkillType.split('_')[1])} skill
					</Typography>
					<Divider />
					<Typography id="modal-modal-description" sx={{ mt: 2 }}>
						keyword:
					</Typography>
					<TagsInput value={skill} onChange={setSkill} name="skills" placeHolder="enter skills" />
					<Typography id="modal-modal-description" sx={{ fontSize: '0.9rem', my: 1 }}>
						Note: Please press enter after entering skill
					</Typography>
					<Divider />
					<Box
						sx={{
							mt: 4,
							display: 'flex',
							justifyContent: 'right'
						}}
					>
						<Button
							variant="contained"
							sx={{
								marginRight: '12px'
							}}
							onClick={() => {
								handleAddSkill(modalSkillType, skill)
								closeModal()
							}}
							color="info"
							disabled={skill.length === 0}
						>
							Add
						</Button>
						<Button variant="contained" color="error" onClick={closeModal}>
							Close
						</Button>
					</Box>
				</Box>
			</Modal>
		</>
	)
}

export default SuggestSkillModal
