import { Dispatch, SetStateAction } from 'react'
import { Edit } from '@mui/icons-material'
import { Box, Typography, TextField, Button } from '@mui/material'
import { FC } from 'react'
import { NavigateFunction } from 'react-router'
import { Education } from '../../models/Education'
import { Experience } from '../../models/Experience'
import ResumeForm from './ResumeForm'
import ArrowLeft from '../../assets/JobDetail/ArrowLeft.png'
import CategoryList from '../Resume/ResumeBuilder/CategoryList'
import { ROUTES } from '../../constants/routes'
import { RESUME_FORM_FIELDS } from '../../constants/resume'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'

const ResumeBuilderContent: FC<{
	isMobile: boolean
	navigate: NavigateFunction
	editName: boolean
	setEditName: Dispatch<SetStateAction<boolean>>
	resumeName: string
	setResumeName: Dispatch<SetStateAction<string>>
	updateResumeDetails: () => void
	currentField: string
	email: string
	fullName: string
	city: string
	country: string
	resumeTitle: string
	phone: string
	setEmail: Dispatch<SetStateAction<string>>
	setFullName: Dispatch<SetStateAction<string>>
	setCity: Dispatch<SetStateAction<string>>
	setCountry: Dispatch<SetStateAction<string>>
	setResumeTitle: Dispatch<SetStateAction<string>>
	setPhone: Dispatch<SetStateAction<string>>
	summary: string
	setSummary: Dispatch<SetStateAction<string>>
	education: Education[]
	setEducation: Dispatch<SetStateAction<Education[]>>
	experience: Experience[]
	setExperience: Dispatch<SetStateAction<Experience[]>>
	skills: string[]
	setSkills: Dispatch<SetStateAction<string[]>>
	setCurrentField: Dispatch<SetStateAction<string>>
}> = ({
	isMobile,
	navigate,
	editName,
	setEditName,
	resumeName,
	setResumeName,
	updateResumeDetails,
	currentField,
	email,
	fullName,
	city,
	country,
	resumeTitle,
	phone,
	setEmail,
	setFullName,
	setCity,
	setCountry,
	setResumeTitle,
	setPhone,
	summary,
	setSummary,
	education,
	setEducation,
	experience,
	setExperience,
	skills,
	setSkills,
	setCurrentField
}) => {
	return (
		<>
			<Box
				sx={{
					minHeight: '100vh',
					backgroundColor: { sm: '#DFDFDF', xs: '#ffffff' },
					width: '100%'
				}}
			>
				<Box>
					<Box sx={{ display: 'flex' }}>
						<Box
							sx={{
								paddingX: { lg: '40px', sm: '20px', xs: '12px' },
								flex: 'auto'
							}}
						>
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									padding: '30px 0 20px 0',
									justifyContent: 'space-between'
								}}
							>
								<Box sx={{ display: 'flex', alignItems: 'center' }}>
									<Box
										onClick={() => navigate(ROUTES.RESUMES)}
										sx={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											backgroundColor: '#171725',
											boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.02);',
											borderRadius: '50%',
											cursor: 'pointer',
											width: { lg: '46px', sm: '35px', xs: '30px' },
											height: { lg: '46px', sm: '35px', xs: '30px' }
										}}
									>
										<Box component="img" src={ArrowLeft} />
									</Box>
									{!editName ? (
										<Box
											sx={{
												display: 'flex',
												alignItems: 'center',
												gap: '10px',
												cursor: 'pointer'
											}}
											onClick={() => setEditName(true)}
										>
											<Typography
												variant="h2"
												sx={{
													fontWeight: '600',
													fontSize: { lg: '24px', md: '20px', xs: '17px' },
													lineHeight: '36px',
													letterSpacing: '0.1px',
													marginLeft: { sm: '18px', xs: '12px' },
													color: '#171725'
												}}
											>
												{resumeName}
											</Typography>
											<Edit />
										</Box>
									) : (
										<Box
											sx={{
												marginLeft: { sm: '18px', xs: '12px' }
											}}
										>
											<TextField
												autoFocus
												id="outlined-basic"
												label={'Resume Name'}
												variant="outlined"
												value={resumeName}
												onChange={(e) => setResumeName(e.target.value)}
												onBlur={() => setEditName(false)}
												sx={{
													input: {
														color: '#44444F',
														fontWeight: '500',
														lineHeight: '18px',
														letterSpacing: '0.1px',
														height: '18px'
													}
												}}
												InputProps={{
													endAdornment: <Edit />
												}}
											/>
										</Box>
									)}
								</Box>
								<Button
									sx={{
										textTransform: 'none',
										borderRadius: '12px',
										marginLeft: '5px',
										padding: { md: '10px 24px;', sm: '10px 16px;', sx: '8px 16px' },
										fontSize: { md: '18px', xs: '16px' },
										fontWeight: '600',
										lineHeight: { sm: '27px', xs: '24px' },
										letterSpacing: '2%',
										width: { xs: '50%', sm: 'unset' }
									}}
									variant="contained"
									color="primary"
									onClick={() => updateResumeDetails()}
								>
									Save Resume
								</Button>
							</Box>
							<Box
								sx={{
									borderRadius: '20px',
									width: '100%',
									display: 'flex',
									flexDirection: 'column'
								}}
							>
								{!isMobile && <CategoryList isMobile={isMobile} currentField={currentField} setCurrentField={setCurrentField} />}
								{isMobile && (
									<FormControl sx={{ minWidth: 'calc(100vw - 24px)', maxWidth: 'calc(100vw - 24px)' }}>
										<Select value={currentField} onChange={(e) => setCurrentField(e.target.value)} inputProps={{ 'aria-label': 'Without label' }}>
											{RESUME_FORM_FIELDS.map(({ key, name }) => (
												<MenuItem key={key} value={key}>
													{name}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								)}
								<ResumeForm
									currentField={currentField}
									email={email}
									fullName={fullName}
									city={city}
									country={country}
									resumeTitle={resumeTitle}
									phone={phone}
									setEmail={setEmail}
									setFullName={setFullName}
									setCity={setCity}
									setCountry={setCountry}
									setResumeTitle={setResumeTitle}
									setPhone={setPhone}
									summary={summary}
									setSummary={setSummary}
									education={education}
									setEducation={setEducation}
									experience={experience}
									setExperience={setExperience}
									skills={skills}
									setSkills={setSkills}
								/>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</>
	)
}
export default ResumeBuilderContent
