import { Box, Button } from '@mui/material'
import { motion } from 'framer-motion'
import { Dispatch, FC, SetStateAction } from 'react'
import ShowNotifications from '../../ShowNotifications'
import { container, item } from '../../OverviewComponents/OverviewConstants'
import MobileNoCLFound from './MobileNoCLFound'
import MobileCLLoading from './MobileCLsLoading'
import { CoverLetterType } from '../../../models/CoverLetter'
import CoverLetterDetailBar from '../CoverLetterDetailBar'
import AddIcon from '@mui/icons-material/Add'

const MobileCoverLetters: FC<{
	search: string
	setSearch: Dispatch<SetStateAction<string>>
	loading: boolean
	coverLetters: CoverLetterType[]
	newCoverLetterHandler: () => void
}> = ({ search, setSearch, loading, coverLetters, newCoverLetterHandler }) => {
	return (
		<Box className="mobile-container" sx={{ backgroundColor: '#DFDFDF', flexGrow: 1 }}>
			<Box sx={{ paddingX: '24px' }}>
				<ShowNotifications />
			</Box>
			<Box sx={{ paddingX: '24px' }}>
				{/* // */}
				{/* Showing Cover Letters for Mobile View */}
				{!loading ? (
					<>
						<Button onClick={newCoverLetterHandler} variant="contained" sx={{ width: '100%', marginTop: '20px' }} startIcon={<AddIcon />}>
							New Cover Letter
						</Button>
						{coverLetters.length > 0 ? (
							<motion.div variants={container} initial="hidden" animate="show">
								<Box
									className="job-list"
									sx={{
										paddingY: '20px'
									}}
									component={motion.div}
									variants={item}
								>
									<>
										<Box>All Cover Letters listed here</Box>
										{coverLetters.map((coverLetter: CoverLetterType) => {
											return (
												<Box
													sx={{
														marginTop: '20px',
														marginBottom: '20px'
													}}
													component={motion.div}
													variants={item}
													key={coverLetter.id}
												>
													<CoverLetterDetailBar coverLetter={coverLetter} />
												</Box>
											)
										})}
									</>
								</Box>
							</motion.div>
						) : (
							<>
								<MobileNoCLFound />
							</>
						)}
					</>
				) : (
					<MobileCLLoading />
				)}
				{/* Showing Cover Letters for Mobile View */}
			</Box>
		</Box>
	)
}

export default MobileCoverLetters
