import { Box, Typography, Button, Link } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../constants/routes'
import { auth } from '../../firebase'
import CustomInput from '../CustomInput'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { FC, SyntheticEvent, useState } from 'react'

import { EmailOutlined, LockOutlined } from '@mui/icons-material'
// import { signInAnonymous } from '../../utils/authentication'
// import { USER_TYPES } from '../../constants/authentication'
// import { AnonymousJobsCTX } from '../../contexts/anonymousJobs'
import AuthModalMobileFields from './AuthModalMobileFields'
import ForgotPasswordRememberMe from './ForgotPasswordRemeberMe'

interface AuthModalProps {
	callback: string
	sig: string | null
	isMobile: boolean
	setOpenRecruitModal?: (isOpen: boolean) => void
	setOpenJobSeekerModal?: (isOpen: boolean) => void
}

const AuthModal: FC<AuthModalProps> = ({ callback, sig, isMobile, setOpenRecruitModal, setOpenJobSeekerModal }) => {
	const navigate = useNavigate()

	// const { setHasAnonymousJobs, setAnonymousJobs } = useContext(AnonymousJobsCTX)

	const [email, setEmail] = useState<string>('')
	const [password, setPassword] = useState<string>('')

	const [rememberPassWord, setRememberPassWord] = useState(false)
	// const [feedback, setFeedback] = useState<string>("");
	// const [feedback, setFeedback] = useState<string>("");

	// const navigator = () => {
	// 	if (callback) navigate(callback)
	// 	else navigate('/')
	// }

	// const handleAnonymousSignIn = (type: string) => {
	// 	if (setHasAnonymousJobs && setAnonymousJobs) {
	// 		signInAnonymous(navigator, setHasAnonymousJobs, setAnonymousJobs, type ?? '', USER_TYPES.JOB_SEEKER)
	// 	}
	// }

	const handleLoginWithEmail = (e: SyntheticEvent) => {
		e.preventDefault()

		// if (password.length < 6) {
		//   alert("Password must be at least 6 characters long");
		//   return;
		// }

		if (email === '' || password === '') {
			// alert("Please fill all the fields");
		} else {
			signInWithEmailAndPassword(auth, email, password)
				.then((userCredential) => {})
				.catch((error) => {
					const errorCode = error.code
					const errorMessage = error.message
					console.log('error is: ', errorCode, errorMessage)
					alert(`Error logging In : ${errorMessage}`)
					// ..
				})
		}
	}

	return (
		<>
			{!isMobile ? (
				<>
					<Box
						component={'div'}
						sx={{
							width: { md: '560px', sm: '450px', xs: '90%' },
							margin: '0 auto',
							// border: "1px solid #000000",
							background: '#FFFFFF',
							boxShadow: 1,
							borderRadius: '20px',
							padding: { sm: '30px', xs: '15px' }
						}}
					>
						<Box component="div">
							<Box component="div">
								{/* <Typography
									component="div"
									sx={{
										width: '100%',
										textAlign: 'center',
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'center'
									}}
								>
									<Typography
										variant="h5"
										component="div"
										sx={{
											fontFamily: "'Poppins'",
											fontStyle: 'normal',
											fontWeight: '600',
											fontSize: {
												md: '20px',
												xs: '15px'
											},
											lineHeight: '36px',
											letterSpacing: '0.15px',
											backgroundColor: '#171725',
											cursor: 'pointer',
											width: '100%',
											color: 'white',
											borderRadius: '10px',
											padding: '14px'
										}}
										onClick={() => handleAnonymousSignIn(userType)}
									>
										Try As A Guest Without Registration
									</Typography>
								</Typography> */}

								{/* ####################################################################### */}
								{/* ________________________ Complete Login Form ________________________ */}
								{/* ####################################################################### */}

								<form onSubmit={handleLoginWithEmail}>
									{/* Input Box */}
									<CustomInput
										placeHolder={'Your Email'}
										type="email"
										value={email}
										onChange={(e) => setEmail(e.target.value)}
										required={true}
										Icon={<EmailOutlined />}
										InputStyles={{
											background: '#F6F6F6',
											borderRadius: '12px',
											marginTop: {
												sm: '45px',
												xs: '25px'
											}
										}}
									/>
									<CustomInput
										placeHolder={'Password'}
										type="password"
										value={password}
										onChange={(e) => setPassword(e.target.value)}
										required={true}
										Icon={<LockOutlined />}
										InputStyles={{
											background: '#F6F6F6',
											borderRadius: '12px',
											marginTop: '16px'
										}}
									/>
									{/* Input Box */}
									{/* Forgot Password Section */}
									<ForgotPasswordRememberMe setRememberPassWord={setRememberPassWord} rememberPassWord={rememberPassWord} />
									{/* Forgot Password Section */}
									{/* Login Button */}
									<Button
										variant="contained"
										type="submit"
										sx={{
											height: { sm: '48px', xs: '35px' },
											fontStyle: 'normal',
											fontWeight: '600',
											fontSize: {
												sm: '18px',
												xs: '14px'
											},
											lineHeight: '27px',
											letterSpacing: '0.02em',
											color: '#FFFFFF',
											background: '#3865F3',
											borderRadius: '12px',
											width: '100%',
											textTransform: 'none',
											mt: '13px'
										}}
									>
										Log In
									</Button>
									{/* Login Button */}
									<Typography
										component="div"
										variant="h5"
										sx={{
											fontFamily: "'Poppins'",
											fontStyle: 'normal',
											fontWeight: '400',
											fontSize: {
												sm: '18px',
												xs: '15px'
											},
											lineHeight: '27px',
											letterSpacing: '0.02em',
											color: '#94959B',
											textAlign: 'center',
											marginTop: '11px'
										}}
									>
										Don’t have an account?{' '}
										<Link
											onClick={() => navigate(ROUTES.SIGNUP + (callback !== null ? '?callback=' + encodeURIComponent(callback) : '?'))}
											sx={{
												fontFamily: "'Poppins'",
												fontStyle: 'normal',
												fontWeight: '500',
												cursor: 'pointer',
												fontSize: {
													sm: '18px',
													xs: '15px'
												},
												lineHeight: '27px',
												letterSpacing: '0.02em',
												color: '#3865F3'
											}}
											component={'span'}
										>
											Register
										</Link>
									</Typography>
								</form>
								{/* social icons section */}
								{/* <SocialAuth userType={userType} callback={callback} sig={sig} /> */}
								{/* social icons section */}

								{/* ####################################################################### */}
								{/* ________________________ Complete Login Form ________________________ */}
								{/* ####################################################################### */}
							</Box>
						</Box>
					</Box>
				</>
			) : (
				<AuthModalMobileFields navigate={navigate} setOpenRecruitModal={setOpenRecruitModal} setOpenJobSeekerModal={setOpenJobSeekerModal} />
			)}
		</>
	)
}

export default AuthModal
