import { Timestamp, addDoc, collection, setDoc, getDocs, orderBy, limit } from 'firebase/firestore'
import { ResumeType } from '../models/Resume'
import { auth, db } from '../firebase'
import { COLLECTION_NAMES } from '../constants/collections'
import { getResumesQuery } from './queries'

export const addNewResume = async () => {
	if (auth.currentUser) {
		const resume: ResumeType = {
			created: Timestamp.fromDate(new Date()),
			id: '',
			fullName: '',
			email: auth.currentUser.email ?? '',
			phone: auth.currentUser.phoneNumber ?? '',
			skills: [],
			summary: '',
			title: '',
			experience: [],
			city: '',
			country: '',
			resumeName: 'Resume',
			education: []
		}
		let docRef
		try {
			docRef = await addDoc(collection(db, COLLECTION_NAMES.USERS_COLLECTION, auth.currentUser.uid, COLLECTION_NAMES.RESUMES), resume)
		} catch (error) {
			const userResumes = await getDocs(getResumesQuery([orderBy('created', 'desc'), limit(1)]))
			docRef = userResumes.docs && userResumes.docs[0].ref
		}
		setDoc(docRef, { id: docRef.id }, { merge: true })
		return docRef.id
	} else {
		return 'unauthenticated'
	}
}

export const convertResumeToText = (data: ResumeType): string => {
	let resumeText = data.fullName + '\n'
	resumeText += data.title + '\n'
	resumeText += data.phone + '\n'
	resumeText += data.email + '\n'
	resumeText += data.city ? data.city : ''
	resumeText += data.country ? ' ' + data.country : ''
	resumeText += '\n\n\n\n'

	if (data.summary.length !== 0) {
		resumeText += 'Summary\n'
		resumeText += data.summary
		resumeText += '\n\n\n\n'
	}

	if (data.experience.length !== 0) {
		resumeText += 'Experience\n'
		resumeText += data.experience
			.map((experience) => {
				let text = ''
				text += experience.title + '\t\t'
				text += `${experience.start.month} ${experience.start.year} - ${experience.end.month} ${experience.end.year}\n`
				text += experience.company + '\n'
				text += experience.points.length !== 0 ? '• ' + experience.points.map((point) => point).join('\n• ') + '\n' : ''
				return text
			})
			.join('\n\n')
		resumeText += '\n\n\n\n'
	}

	if (data.education && data.education.length !== 0) {
		resumeText += 'Education\n'
		resumeText += data.education
			.map((education) => {
				let text = ''
				text += education.field + '\n'
				text += `${education.field} - ${education.degree}\t\t${education.completionYear}\n`
				return text
			})
			.join('\n\n')
		resumeText += '\n\n\n\n'
	}

	if (data.skills.length !== 0) {
		resumeText += 'Skills\n'
		resumeText += '• ' + data.skills.map((skill) => skill).join('\n• ') + '\n'
	}

	return resumeText
}
