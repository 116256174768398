import { useState, FC, SyntheticEvent } from 'react'

import { useNavigate } from 'react-router'

import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Box, Button, Divider, IconButton, InputAdornment, TextField, Typography } from '@mui/material'

import Back from '../../assets/Login/Back.png'
import Email from '../../assets/Login/Email.png'
import Password from '../../assets/Login/Password.png'

import { signInWithEmailAndPassword } from 'firebase/auth'
import { auth } from '../../firebase'
import SignupSection from './SignupSection'
import SignupApple from './SignupApple'
import ForgotPasswordSection from './ForgotPassword'

const LoginModal: FC = () => {
	// For navigating to other pages
	const navigate = useNavigate()

	const [email, setEmail] = useState<string>('')
	const [password, setPassword] = useState<string>('')
	const [showPassword, setShowPassword] = useState(false)

	const handleLoginWithEmail = (e: SyntheticEvent) => {
		e.preventDefault()

		// if (password.length < 6) {
		//   alert("Password must be at least 6 characters long");
		//   return;
		// }

		if (email === '' || password === '') {
			// alert("Please fill all the fields");
		} else {
			signInWithEmailAndPassword(auth, email, password)
				.then((userCredential) => {})
				.catch((error) => {
					const errorCode = error.code
					const errorMessage = error.message
					console.log('error is: ', errorCode, errorMessage)
					alert(`Error logging In : ${errorMessage}`)
					// ..
				})
		}
	}

	return (
		<Box sx={{ paddingX: '24px', flexGrow: 1 }}>
			<form onSubmit={handleLoginWithEmail}>
				<Box
					sx={{
						position: 'relative',
						display: 'flex',
						width: '100%',
						justifyContent: 'center',
						marginBottom: '32px'
					}}
				>
					<Box
						sx={{
							marginTop: '8px',
							width: '40px',
							height: '40px',
							border: '1px solid #E8E8E8',
							borderRadius: '10px',
							position: 'absolute',
							left: '0',
							cursor: 'pointer',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							'&:hover': {
								backgroundColor: '#fafafa'
							}
						}}
						onClick={() => navigate(-1)}
					>
						<Box
							component="img"
							sx={{
								width: '20px',
								height: '20px'
							}}
							src={Back}
						/>
					</Box>
					<Typography
						variant="h5"
						sx={{
							color: '#121212',
							fontWeight: '700',
							fontSize: '22px',
							lineHeight: '32px',
							letterSpacing: '0.1px',
							marginTop: '12px'
						}}
					>
						Login
					</Typography>
				</Box>

				<Box sx={{ marginBottom: '16px' }}>
					<Typography
						paragraph
						sx={{
							fontWeight: '600',
							fontSize: '14px',
							lineHeight: '20px',
							marginBottom: '8px'
						}}
					>
						Email
					</Typography>
					<TextField
						type="email"
						variant="outlined"
						required={true}
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						fullWidth
						sx={{
							fontWeight: '500',
							fontSize: '16px',
							lineHeight: '24px',
							color: '#121212',
							'& fieldset': {
								borderRadius: '16px'
							},
							'& input': {
								borderRadius: '0px 16px 16px 0px !important'
							}
						}}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<Box component="img" src={Email} />
								</InputAdornment>
							)
						}}
					/>
				</Box>

				<Box sx={{ marginBottom: '16px' }}>
					<Typography
						paragraph
						sx={{
							fontWeight: '600',
							fontSize: '14px',
							lineHeight: '20px',
							marginBottom: '8px'
						}}
					>
						Password
					</Typography>
					<TextField
						type={showPassword ? 'text' : 'password'}
						variant="outlined"
						required={true}
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						fullWidth
						sx={{
							fontWeight: '500',
							fontSize: '16px',
							lineHeight: '24px',
							color: '#121212',
							'& fieldset': {
								borderRadius: '16px'
							}
						}}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<Box component="img" src={Password} />
								</InputAdornment>
							),
							endAdornment: (
								<InputAdornment position="end">
									<IconButton onClick={() => setShowPassword(!showPassword)}>
										{showPassword ? <VisibilityOff sx={{ color: '#B8B8B8' }} /> : <Visibility sx={{ color: '#B8B8B8' }} />}
									</IconButton>
								</InputAdornment>
							)
						}}
					/>
				</Box>
				<ForgotPasswordSection navigate={navigate} />
				<Button
					type="submit"
					variant="contained"
					fullWidth
					sx={{
						backgroundColor: '#3865F3',
						fontWeight: '600',
						fontSize: '16px',
						lineHeight: '24px',
						paddingY: '16px',
						marginBottom: '32px',
						borderRadius: '16px',
						textTransform: 'none',
						'&:hover': {
							backgroundColor: '#294ec4'
						}
					}}
				>
					Login
				</Button>
				<Box
					sx={{
						position: 'relative',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						marginBottom: '48px'
					}}
				>
					<Divider
						sx={{
							borderTop: '1px solid #E8E8E8',
							position: 'absolute',
							top: '50%',
							left: '0',
							width: '100%',
							zIndex: '2'
						}}
					/>
					<Box
						sx={{
							width: '30px',
							position: 'relative',
							zIndex: '3',
							backgroundColor: 'white',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center'
						}}
					>
						<Typography
							paragraph
							sx={{
								fontWeight: '500',
								fontSize: '12px',
								lineHeight: '20px',
								textAlign: 'center',
								marginBottom: '0',
								color: '#B8B8B8'
							}}
						>
							OR
						</Typography>
					</Box>
				</Box>
				{/* <SocialAuth userType={userType} callback={callback} sig={sig} /> */}
				<SignupApple />
				<SignupSection navigate={navigate} />
			</form>
		</Box>
	)
}

export default LoginModal
