import { FC, useEffect } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import ReactGA from 'react-ga'
import { useLocation } from 'react-router-dom'

// Initialize React Ga with your tracking ID
ReactGA.initialize('G-HW6Q9X5RJ9')

const GoogleAnalytics: FC = () => {
	const location = useLocation()
	useEffect(() => {
		ReactGA.pageview(location.pathname + location.search)
	}, [location])

	return null
}
export default GoogleAnalytics
