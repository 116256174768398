import { Button } from '@mui/material'
import { Dispatch, FC, SetStateAction, SyntheticEvent } from 'react'
import CustomInput from '../CustomInput'
import { Person, AccountCircle, EmailOutlined, LockOutlined } from '@mui/icons-material'

const SignupSection1Form: FC<{
	username: string
	setUsername: Dispatch<SetStateAction<string>>
	handleSignUpWithEmail: (e: SyntheticEvent) => void
	firstName: string
	setFirstName: Dispatch<SetStateAction<string>>
	email: string
	setEmail: Dispatch<SetStateAction<string>>
	password: string
	setPassword: Dispatch<SetStateAction<string>>
	confirmPassword: string
	setConfirmPassword: Dispatch<SetStateAction<string>>
	lastName: string
	setLastName: Dispatch<SetStateAction<string>>
}> = ({ handleSignUpWithEmail, firstName, setFirstName, email, setEmail, password, setPassword, confirmPassword, setConfirmPassword, username, setUsername, lastName, setLastName }) => {
	return (
		<form onSubmit={handleSignUpWithEmail}>
			{/* Input Box */}
			<CustomInput
				placeHolder={'First Name'}
				type="text"
				value={firstName}
				onChange={(e) => setFirstName(e.target.value)}
				required={true}
				Icon={<Person />}
				InputStyles={{
					background: '#F6F6F6',
					borderRadius: '12px',
					marginTop: { xs: '25px' }
				}}
			/>
			<CustomInput
				placeHolder={'Last Name'}
				type="text"
				value={lastName}
				onChange={(e) => setLastName(e.target.value)}
				required={true}
				Icon={<Person />}
				InputStyles={{
					background: '#F6F6F6',
					borderRadius: '12px',
					marginTop: '16px'
				}}
			/>
			<CustomInput
				placeHolder={'User Name'}
				type="text"
				value={username}
				onChange={(e) => setUsername(e.target.value)}
				required={true}
				Icon={<AccountCircle />}
				InputStyles={{
					background: '#F6F6F6',
					borderRadius: '12px',
					marginTop: '16px'
				}}
			/>
			<CustomInput
				placeHolder={'Your Email'}
				type="email"
				value={email}
				onChange={(e) => setEmail(e.target.value)}
				required={true}
				Icon={<EmailOutlined />}
				InputStyles={{
					background: '#F6F6F6',
					borderRadius: '12px',
					marginTop: '16px'
				}}
			/>
			<CustomInput
				placeHolder={'Password'}
				type="password"
				value={password}
				onChange={(e) => setPassword(e.target.value)}
				required={true}
				Icon={<LockOutlined />}
				InputStyles={{
					background: '#F6F6F6',
					borderRadius: '12px',
					marginTop: '16px'
				}}
			/>
			<CustomInput
				placeHolder={'Confirm Password'}
				type="password"
				value={confirmPassword}
				onChange={(e) => setConfirmPassword(e.target.value)}
				required={true}
				Icon={<LockOutlined />}
				InputStyles={{
					background: '#F6F6F6',
					borderRadius: '12px',
					marginTop: '16px',
					marginBottom: { sm: '25px', xs: '10px' }
				}}
			/>
			{/* Input Box */}

			{/* Login Button */}
			<Button
				variant="contained"
				type="submit"
				sx={{
					height: { sm: '48px', xs: '35px' },
					fontStyle: 'normal',
					fontWeight: '600',
					fontSize: { sm: '18px', xs: '14px' },
					lineHeight: '27px',
					letterSpacing: '0.02em',
					color: '#FFFFFF',
					background: '#3865F3',
					borderRadius: '12px',
					width: '100%',
					textTransform: 'none',
					mt: '13px'
				}}
			>
				Sign Up
			</Button>
			{/* Login Button */}
		</form>
	)
}

export default SignupSection1Form
