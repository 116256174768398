import { Typography } from '@mui/material'
import { FC } from 'react'
import { NavigateFunction } from 'react-router'

interface ForgotPasswordSectionProps {
	navigate: NavigateFunction | (() => {})
}

const ForgotPasswordSection: FC<ForgotPasswordSectionProps> = ({ navigate }) => {
	return (
		<Typography
			onClick={() => navigate('/forgot-password')}
			paragraph
			sx={{
				fontWeight: '500',
				fontSize: '14px',
				lineHeight: '20px',
				color: '#3865F3',
				textAlign: 'right',
				marginBottom: '32px'
			}}
		>
			Forgot password
		</Typography>
	)
}

export default ForgotPasswordSection
