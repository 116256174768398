import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import { FC } from 'react'

const AddSkillsCheckbox: FC<{
	skillsOnly: boolean
	setSkillsOnly: (e: boolean) => void
}> = ({ skillsOnly, setSkillsOnly }) => {
	return (
		<FormGroup>
			<FormControlLabel
				control={<Checkbox checked={skillsOnly} onChange={(e) => setSkillsOnly(e.target.checked)} inputProps={{ 'aria-label': 'controlled' }} />}
				label="Analyze hard skills only?"
			/>
		</FormGroup>
	)
}

export default AddSkillsCheckbox
