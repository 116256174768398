import { useEffect, FC, useContext } from 'react'
// Importing MUI
import { Box, useMediaQuery } from '@mui/material'

// Importing firebase
import { auth } from '../../firebase'

// Importing for navigating to other pages
import { useNavigate } from 'react-router'

// Importing Styles
import '../../index.css'

// Importing Components
import theme from '../../theme'
import LoginModal from '../../components/LoginModal'

// import { db } from "../../firebase";
// import { collection, addDoc } from "firebase/firestore";
import { useAuthState } from 'react-firebase-hooks/auth'
import { useSearchParams } from 'react-router-dom'
import AuthModal from '../../components/AuthModal'
import { ROUTES } from '../../constants/routes'
import { AnonymousJobsCTX } from '../../contexts/anonymousJobs'

const Login: FC = () => {
	// navigate hook
	const navigate = useNavigate()

	const [searchParams] = useSearchParams()

	let callback = searchParams.get('callback')
	const sig: string | null = null

	if (callback) callback = decodeURIComponent(callback)

	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
	const { loading: loadingForAnonymousUserCreation } = useContext(AnonymousJobsCTX)

	const [user, userLoading] = useAuthState(auth)

	useEffect(() => {
		if (userLoading) return
		if (user !== null) {
			if (callback) navigate(callback)
			else if (!loadingForAnonymousUserCreation) navigate(ROUTES.OVERVIEW, { replace: true })
		}
	}, [user, userLoading])

	return (
		<>
			{!isMobile ? (
				<Box
					component="div"
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						flexGrow: 1
					}}
				>
					<AuthModal callback={callback ?? ''} isMobile={isMobile} sig={sig} />
				</Box>
			) : (
				<Box
					component="div"
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						flexGrow: 1
					}}
				>
					<LoginModal />
				</Box>
			)}
		</>
	)
}
export default Login
