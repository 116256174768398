import { ChangeEvent } from 'react'
import { NavigateFunction } from 'react-router'
import { Dispatch, SetStateAction } from 'react'
import { DocumentData, DocumentReference, FirestoreError, Timestamp } from 'firebase/firestore'
import { User } from 'firebase/auth'
import { RowType } from '../JobAnalysisTable/jobType'
import { ResumeAnalysis } from '../../models/ResumeAnalysis'
import { Job, JobStatus } from '../../models/Job'

export interface MobileJobProps {
	title: string
	navigate: NavigateFunction
	jobLoading: boolean
	progressBtnClickHandler: (index: number) => Promise<void>
	editMode: boolean
	updateJobDetails: () => void
	setEditMode: Dispatch<SetStateAction<boolean>> | (() => void)
	company: string
	setCompany: (index: number, value: string) => void
	setTitle: (index: number, value: string) => void
	setLocation: (index: number, value: string) => void
	moreInfo: string
	setMoreInfo: (index: number, value: string) => void
	jobError: FirestoreError | undefined
	location: string
	created: Timestamp | null
	jobText?: string
	setJobText: (index: number, value: string) => void
	file: File | null
	setFile: (index: number, value: File | null) => void
	description: string
	setDescription: (index: number, value: string) => void
	skillsOnly: boolean
	skillsCheckboxHandler: (index: number, e: ChangeEvent<HTMLInputElement>) => Promise<void>
	percentAvg?: number
	isMobile: boolean
	addSuggestedSkill: (index: number, tableName: string, skill: string) => Promise<void>
	percentHard?: number
	tableHard?: RowType[]
	markIrrelevantSkill: (index: number, skill: string, tableName: string) => Promise<void>
	percentSoft?: number
	tableSoft?: RowType[]
	percentSales?: number
	tableRelated?: RowType[]
	resumeAnalysis?: ResumeAnalysis
	topTitles?: string[]
	markInaccurateTitle: (index: number, title: string) => Promise<void>
	status: JobStatus
	resumeMode: ResumeInputMode
	setResumeMode: (index: number, value: ResumeInputMode) => void
	selectedResume: string
	setSelectedResume: (index: number, value: string) => void
	resumes: Job[] | undefined
	isMultiResumes: boolean
	handleAddResume?: (file?: File) => void
	handleDelete?: (index: number) => void
	isSorted?: boolean
	sortAccordingToHardSkill?: (e: ChangeEvent<HTMLInputElement>) => void
	isMultiResume?: boolean
	setResumeName: (index: number, input: string) => void
	setResumeTitle: (index: number, input: string) => void
	setResumeLocation: (index: number, input: string) => void
	setResumeContact: (index: number, input: string) => void
	setResumeSalary: (index: number, input: string) => void
}

export interface JobFieldsProps {
	editMode: boolean
	title: string
	setTitle: (number: number, value: string) => void
	setCompany: (number: number, value: string) => void
	company: string
	jobLoading: boolean
	setLocation: (number: number, value: string) => void
	moreInfo: string
	setMoreInfo: (number: number, value: string) => void
	location: string
	jobError: FirestoreError | undefined
	created: Timestamp | null
	jobRef: DocumentReference<DocumentData>
	user: User | null | undefined
	jobText?: string
	setJobText: (number: number, value: string) => void
	file: File | null
	setFile: (number: number, value: File | null) => void
	description: string
	setDescription: (number: number, value: string) => void
	skillsOnly: boolean
	skillsCheckboxHandler: (index: number, e: ChangeEvent<HTMLInputElement>) => Promise<void>
	percentAvg?: number
	isMobile: boolean
	addSuggestedSkill: (index: number, tableName: string, skill: string) => Promise<void>
	percentHard?: number
	tableHard?: RowType[]
	percentSoft?: number
	tableSoft?: RowType[]
	percentSales?: number
	tableRelated?: RowType[]
	resumeAnalysis?: ResumeAnalysis
	topTitles?: string[]
	markInaccurateTitle: (index: number, title: string) => Promise<void>
	markIrrelevantSkill: (index: number, skill: string, tableName: string) => Promise<void>
	resumeMode: ResumeInputMode
	setResumeMode: (index: number, value: ResumeInputMode) => void
	selectedResume: string
	setSelectedResume: (index: number, value: string) => void
	isMultiResumes: boolean
	resumes: Job[] | undefined
	handleAddResume?: (file?: File) => void
	isSorted?: boolean
	sortAccordingToHardSkill?: (e: ChangeEvent<HTMLInputElement>) => void
	handleDelete?: (index: number) => void
	setResumeName: (index: number, input: string) => void
	setResumeTitle: (index: number, input: string) => void
	setResumeLocation: (index: number, input: string) => void
	setResumeContact: (index: number, input: string) => void
	setResumeSalary: (index: number, input: string) => void
	resumeCandidateName?: string
	resumeTitle?: string
	resumeCity?: string
	resumePhone?: string
	resumeSalary?: string
}

export interface JobDetailsContentProps {
	jobError: FirestoreError | undefined
	navigate: NavigateFunction
	container: {
		show: {
			transition: {
				staggerChildren: number
			}
		}
	}
	jobLoading: boolean
	progressBtnClickHandler: (index: number) => Promise<void>
	isTab: boolean
	editMode: boolean
	setEditMode: Dispatch<SetStateAction<boolean>>
	company: string
	setCompany: (index: number, value: string) => void
	setTitle: (index: number, value: string) => void
	setLocation: (index: number, value: string) => void
	moreInfo: string
	setMoreInfo: (index: number, value: string) => void
	location: string
	created: Timestamp | null
	jobRef: DocumentReference<DocumentData>
	user: User | null | undefined
	jobText?: string
	setJobText: (index: number, value: string) => void
	file: File | null
	setFile: (index: number, value: File | null) => void
	description: string
	setDescription: (index: number, value: string) => void
	skillsOnly: boolean
	skillsCheckboxHandler: (index: number, e: ChangeEvent<HTMLInputElement>) => Promise<void>
	percentAvg?: number
	isMobile: boolean
	addSuggestedSkill: (index: number, tableName: string, skill: string) => Promise<void>
	percentHard?: number
	tableHard?: RowType[]
	markIrrelevantSkill: (index: number, skill: string, tableName: string) => Promise<void>
	percentSoft?: number
	tableSoft?: RowType[]
	percentSales?: number
	tableRelated?: RowType[]
	resumeAnalysis?: ResumeAnalysis
	topTitles?: string[]
	markInaccurateTitle: (index: number, title: string) => Promise<void>
	status: JobStatus
	item: {
		hidden: { opacity: number; y: number }
		show: { opacity: number; y: number }
	}
	title: string
	resumeMode: ResumeInputMode
	setResumeMode: (index: number, value: ResumeInputMode) => void
	selectedResume: string
	setSelectedResume: (index: number, value: string) => void
	resumes: Job[] | undefined
	isMultiResumes: boolean
	handleAddResume?: (file?: File) => void
	isSorted?: boolean
	sortAccordingToHardSkill?: (e: ChangeEvent<HTMLInputElement>) => void
	handleDelete?: (index: number) => void
	setResumeName: (index: number, input: string) => void
	setResumeTitle: (index: number, input: string) => void
	setResumeLocation: (index: number, input: string) => void
	setResumeContact: (index: number, input: string) => void
	setResumeSalary: (index: number, input: string) => void
	resumeCandidateName?: string
	resumeTitle?: string
	resumeCity?: string
	resumePhone?: string
	resumeSalary?: string
	oldDescription?: string
	oldGptData?: string
}

export interface JobDetailsContentWrapperProps extends JobDetailsContentProps {
	updateJobDetails: () => void
	deleteJob: () => void
	isDisabled?: boolean
}
export interface MobileJobSection2Props {
	editMode?: boolean
	jobText?: string
	setJobText?: (index: number, value: string) => void
	file?: File | null
	setFile?: (index: number, value: File | null) => void
	description?: string
	setDescription?: (index: number, value: string) => void
	skillsOnly?: boolean
	skillsCheckboxHandler?: (index: number, e: React.ChangeEvent<HTMLInputElement>) => Promise<void>
	percentAvg?: number
	isMobile?: boolean
	addSuggestedSkill?: (index: number, tableName: string, skill: string) => Promise<void>
	percentHard?: number
	tableHard?: RowType[]
	markIrrelevantSkill?: (index: number, skill: string, tableName: string) => Promise<void>
	percentSoft?: number
	tableSoft?: RowType[]
	percentSales?: number
	tableRelated?: RowType[]
	resumeAnalysis?: ResumeAnalysis
	topTitles?: string[]
	markInaccurateTitle?: (index: number, title: string) => Promise<void>
	jobLoading?: boolean
	resumeMode?: ResumeInputMode
	setResumeMode?: (index: number, value: ResumeInputMode) => void
	selectedResume?: string
	setSelectedResume?: (index: number, value: string) => void
	index?: number
	isMultiResumes?: boolean
	handleDelete?: (index: number) => void
	resumeTitle?: string
	resumePhone?: string
	resumeCity?: string
	resumeEmail?: string
	resumeCandidateName?: string
	jobError: FirestoreError | undefined
	isSorted?: boolean
	sortAccordingToHardSkill?: (e: ChangeEvent<HTMLInputElement>) => void
	newResume?: boolean
	hideDescription?: boolean
	showMoreDetails?: boolean
	hideResume?: boolean
	showResumeOnFooter?: boolean
	isFitScore?: boolean
	fileName?: string
}

export interface DialogProps {
	open: boolean
	closeDialog: () => void
}

export interface ScannedResume {
	selectedResume: string
	resumeMode: ResumeInputMode
	jobText: string
	file: File | null
	skillsOnly: false
	newResume?: boolean
	resumeCandidateName: string
	resumeTitle: string
	resumeCity: string
	resumePhone: string
	resumeSalary: string
	fileName?: string
	multipleSelected?: boolean
}
export const defaultResume: ScannedResume = {
	selectedResume: '',
	resumeMode: 'text',
	jobText: '',
	file: null,
	skillsOnly: false,
	resumeCandidateName: '',
	resumeTitle: '',
	resumeCity: '',
	resumePhone: '',
	resumeSalary: ''
}

export type ResumeInputMode = 'text' | 'file' | 'resumes'

export interface SelectResume {
	id: string
	name: string
	contact: string
	location: string
}
