import { Box, Typography } from '@mui/material'
import PercentProgress from '../PercentProgress'
import SalesIndexTable from '../SalesIndexTable'
import { FC } from 'react'
import { ResumeAnalysis } from '../../models/ResumeAnalysis'

const MobileJobSection2SkillOnlyDisableFields: FC<{
	percentSales: number
	resumeAnalysis: ResumeAnalysis
}> = ({ percentSales, resumeAnalysis }) => (
	<>
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				marginBottom: '3px',
				marginTop: '18px'
			}}
		>
			<Box>
				<Typography
					component="span"
					sx={{
						fontWeight: '700',
						fontSize: '11px',
						marginBottom: { sm: '11px', xs: '5px' },
						marginTop: { sm: '18px', xs: '10px' }
					}}
				>
					Sales Index
				</Typography>
			</Box>
			<PercentProgress percent={percentSales} />
		</Box>
		<SalesIndexTable resume={resumeAnalysis} />
	</>
)

export default MobileJobSection2SkillOnlyDisableFields
