import { Box, TextField, Typography, Button } from '@mui/material'
import { FirestoreError, Timestamp } from 'firebase/firestore'
import { FC, useState } from 'react'
import Location from '../../assets/JobDetail/Location.png'
import Calendar from '../../assets/JobDetail/Calendar.png'
import { JobStatus } from '../../models/Job'
import { JobStatuses } from '../../constants/jobs'

const MobileJobSection1: FC<{
	editMode: boolean
	setLocation: (index: number, value: string) => void
	location: string
	jobLoading: boolean
	jobError: FirestoreError | undefined
	created: Timestamp | null
	status: JobStatus
	progressBtnClickHandler: (value: number) => void
}> = ({ editMode, setLocation, location, jobLoading, jobError, created, status, progressBtnClickHandler }) => {
	const textColor = JobStatuses[status === 'in progress' ? 'Completed' : 'in progress']?.textColor
	const [createdDate] = useState(created !== undefined && created !== null ? new Date(created?.seconds * 1000).toDateString() : new Date().toDateString())
	return (
		<>
			<Box
				sx={{
					width: '100%',
					border: '1.5px solid #F1F1FA',
					height: '60px',
					borderRadius: '8px',
					padding: '20px 13px',
					marginRight: '10px',
					display: 'flex',
					justifyContent: 'space-between',
					marginBottom: '18px'
				}}
			>
				<Box sx={{ display: 'flex', alignItems: 'center' }}>
					{editMode ? (
						<TextField
							id="outlined-basic"
							label={'Location'}
							variant="outlined"
							value={location}
							onChange={(e) => setLocation(0, e.target.value)}
							inputProps={{
								style: {
									height: 2
								}
							}}
							sx={{
								input: {
									color: '#44444F',
									fontWeight: '500',
									fontSize: '12px',
									lineHeight: '18px',
									letterSpacing: '0.1px',
									height: '18px'
								}
							}}
						/>
					) : (
						<>
							<Box
								component="img"
								src={Location}
								sx={{
									width: '20px',
									height: '20px',
									border: '#7F879E',
									marginRight: '8px'
								}}
							/>
							<Typography
								variant="subtitle2"
								sx={{
									fontWeight: '500',
									fontSize: '12px',
									lineHeight: '19.2px',
									color: '#7F879E'
								}}
							>
								{jobLoading || jobError ? 'Loading...' : location}
							</Typography>
						</>
					)}
				</Box>
				<Box sx={{ display: 'flex', alignItems: 'center' }}>
					<Box
						component="img"
						src={Calendar}
						sx={{
							width: '20px',
							height: '20px',
							border: '#7F879E'
						}}
					/>
					<Typography
						variant="subtitle2"
						sx={{
							fontWeight: '500',
							fontSize: '12px',
							lineHeight: '19.2px',
							color: '#7F879E',
							marginLeft: '8px'
						}}
					>
						{createdDate}
					</Typography>
				</Box>
			</Box>

			<Box
				sx={{
					marginBottom: '18px',
					display: 'flex',
					justifyContent: 'space-between'
				}}
			>
				<Box
					sx={{
						padding: '2px 12px',
						fontSize: '12px',
						fontWeight: '500',
						lineHeight: '20px',
						borderRadius: '12px',
						background: JobStatuses[status].backgroundColor,
						color: JobStatuses[status].textColor
					}}
				>
					{!jobLoading && !jobError ? JobStatuses[status].text : 'Loading...'}
				</Box>
				<Button
					onClick={() => progressBtnClickHandler(0)}
					sx={{
						fontSize: '12px',
						fontWeight: '500',
						lineHeight: '20px',
						borderRadius: '12px',
						height: '24px',
						backgroundColor: 'transparent',
						color: textColor,
						border: `1px solid ${textColor}`,
						textTransform: 'none'
					}}
				>
					{!jobLoading && !jobError ? <>{status === 'in progress' ? 'Mark as Complete' : 'Mark as In Progress'}</> : <>Loading...</>}
				</Button>
			</Box>
		</>
	)
}

export default MobileJobSection1
