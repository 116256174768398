import { Box, Button } from '@mui/material'
import { FC } from 'react'

const AddJobDialogFooter: FC<{
	saveJob: (status: 'in progress' | 'draft') => void
	isDisabled: boolean
}> = ({ saveJob, isDisabled }) => (
	<Box
		sx={{
			marginBottom: { md: '60px', sm: '30px', xs: '20px' },
			marginTop: { sm: '0px', xs: '30px' },
			display: { xs: 'flex', sm: 'inherit' },
			justifyContent: 'space-between'
		}}
	>
		<Button
			disabled={isDisabled}
			sx={{
				cursor: isDisabled ? 'not-allowed' : 'pointer',
				textTransform: 'none',
				borderRadius: '12px',
				padding: {
					md: '16px 24px;',
					sm: '10px 16px;',
					xs: '8px 16px'
				},
				fontSize: { md: '18px', xs: '16px' },
				fontWeight: '600',
				lineHeight: { sm: '27px', xs: '24px' },
				letterSpacing: '2%',
				width: { xs: '50%', sm: 'unset' }
			}}
			variant="outlined"
			color="info"
			onClick={() => saveJob('draft')}
		>
			Save Draft
		</Button>
		<Button
			disabled={isDisabled}
			sx={{
				cursor: isDisabled ? 'not-allowed' : 'pointer',
				textTransform: 'none',
				borderRadius: '12px',
				marginLeft: '5px',
				padding: {
					md: '16px 24px;',
					sm: '10px 16px;',
					sx: '8px 16px'
				},
				fontSize: { md: '18px', xs: '16px' },
				fontWeight: '600',
				lineHeight: { sm: '27px', xs: '24px' },
				letterSpacing: '2%',
				width: { xs: '50%', sm: 'unset' }
			}}
			variant="contained"
			color="info"
			onClick={() => saveJob('in progress')}
		>
			Create
		</Button>
	</Box>
)

export default AddJobDialogFooter
