import { FC, useState } from 'react'

import { Alert, AlertColor, AlertTitle, Snackbar } from '@mui/material'

interface AlertProps {
	title?: string
	detail: string
	severity: AlertColor
}
const CustomAlert: FC<AlertProps> = ({ title = '', detail, severity }) => {
	const [open, setOpen] = useState(true)

	return (
		<Snackbar open={open} sx={{ position: 'initial', marginTop: '10px' }}>
			<Alert severity={severity} onClose={() => setOpen(false)} sx={{ width: '100%' }}>
				{title !== '' ? <AlertTitle>{title}</AlertTitle> : <></>}
				{detail}
			</Alert>
		</Snackbar>
	)
}

export default CustomAlert
