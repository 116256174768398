import { FC } from 'react'
import { NavigateFunction } from 'react-router-dom'
import { User } from 'firebase/auth'
import { Box, useMediaQuery, IconButton } from '@mui/material'
import { WorkspacePremium } from '@mui/icons-material'
import { ROUTES } from '../../constants/routes'
import { orange, green } from '@mui/material/colors'
import theme from '../../theme'

interface SubscriptionInfoProps {
	user: User | null | undefined
	userLoading: boolean
	premiumStatusLoading: boolean
	premiumStatus: boolean
	manageSubscription: () => void
	navigate: NavigateFunction
}
const SubscriptionInfo: FC<SubscriptionInfoProps> = ({ user, userLoading, premiumStatusLoading, premiumStatus, manageSubscription, navigate }) => {
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
	return (
		<>
			{!userLoading && !user?.isAnonymous && !premiumStatusLoading && (
				<Box>
					<IconButton
						sx={{
							fontSize: isMobile ? '10px' : '15px',
							fontWeight: 'bold',
							padding: '0',
							color: premiumStatus ? green[300] : orange[300]
						}}
						onClick={premiumStatus ? manageSubscription : () => navigate(ROUTES.PRICING)}
					>
						<WorkspacePremium fontSize={isMobile ? 'small' : 'medium'} />
						{premiumStatus ? 'Premium' : 'Upgrade'}
					</IconButton>
				</Box>
			)}
		</>
	)
}

export default SubscriptionInfo
