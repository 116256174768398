import { FC } from 'react'
import { SxProps, Typography, Table, TableBody, TableCell, TableContainer, TableRow, Paper } from '@mui/material'
import { Close, Done } from '@mui/icons-material'
import { SALES_INDEX_HEADINGS, SALES_INDEX_TABLE_ORDER } from '../../constants'
import { ResumeAnalysis } from '../../models/ResumeAnalysis'

interface SalesIndexProps {
	resume: ResumeAnalysis
}

interface tableStyles {
	name: SxProps
	desc: SxProps
}

const STYLES: tableStyles = {
	name: {
		fontSize: {
			xs: '12px',
			sm: '14px',
			lg: '16px'
		},
		fontWeight: 'bold',
		color: 'black'
	},
	desc: {
		fontSize: {
			xs: '9px',
			sm: '11px',
			lg: '14px'
		},
		color: 'silver',
		overflowX: 'auto',
		whiteSpace: 'pre-wrap',
		wordWrap: 'break-word'
	}
}

const SalesIndexTable: FC<SalesIndexProps> = ({ resume }) => {
	return (
		<>
			<TableContainer elevation={0} component={Paper} sx={{ width: '100%' }}>
				<Table
					sx={{
						minWidth: 280
					}}
					stickyHeader
					aria-label="sticky table"
				>
					<TableBody>
						{Object.keys(resume).length > 0 ? (
							SALES_INDEX_TABLE_ORDER.map((key) => (
								<>
									<TableRow
										sx={{
											'&:last-child td, &:last-child th': { border: 0 }
										}}
									>
										<TableCell sx={STYLES.name}>{SALES_INDEX_HEADINGS[key as keyof typeof SALES_INDEX_HEADINGS]}</TableCell>
										<TableCell>{resume[key].status ? <Done color="success" /> : <Close color="error" />}</TableCell>
										<TableCell>
											<Typography sx={STYLES.desc} component={'pre'}>
												{resume[key].desc}
											</Typography>
										</TableCell>
									</TableRow>
								</>
							))
						) : (
							<>
								<Typography
									component="p"
									sx={{
										fontWeight: '400',
										fontSize: '12px',
										lineHeight: '21px',
										marginBottom: { sm: '11px', xs: '5px' },
										marginTop: { sm: '18px', xs: '10px' }
									}}
								>
									No data found
								</Typography>
							</>
						)}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	)
}

export default SalesIndexTable
