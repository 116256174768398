import { CSSProperties, FC, useContext, useState, useEffect, useRef } from 'react'
import { Box, TextField, InputAdornment } from '@mui/material'
import SearchIconn from '../../assets/Navbars/Search.png'
import { AnonymousJobsCTX } from '../../contexts/anonymousJobs'
import { Job } from '../../models/Job'
import { useCollection } from 'react-firebase-hooks/firestore'
import { getJobsQuery } from '../../utils/queries'
import { Query, DocumentData } from 'firebase/firestore'

interface SearchInputProps {
	parenetWrapperStyles?: CSSProperties
	inputWrapperStyles?: CSSProperties
	inputStyles?: CSSProperties
}

const SearchInput: FC<SearchInputProps> = ({ parenetWrapperStyles = {}, inputWrapperStyles = {}, inputStyles = {} }) => {
	const [filteredJobs, setFilteredJobs] = useState<Job[] | null>(null)
	const { setActiveJobs, activeJobs, search, setSearch } = useContext(AnonymousJobsCTX)
	const debounceRef = useRef<null | NodeJS.Timeout>(null)
	const q: Query<DocumentData> = getJobsQuery()

	const [snapshot, loading, error] = useCollection(q, {
		snapshotListenOptions: { includeMetadataChanges: true }
	})

	useEffect(() => {
		if (!loading && error == null) {
			setFilteredJobs(
				snapshot?.docs.map((doc) => ({
					...doc.data(),
					id: doc.id
				})) as Job[]
			)
		}
	}, [loading, snapshot, error])

	useEffect(() => {
		if (debounceRef.current) clearTimeout(debounceRef.current)
		debounceRef.current = setTimeout(() => {
			handleSearch(search ?? '')
		}, 200)
	}, [activeJobs])

	const handleSearch = (e: string) => {
		if (activeJobs !== null) {
			const filtered = filteredJobs?.filter(function (job: Job) {
				return (
					job.company.toLowerCase().includes(e.toLowerCase()) ||
					job.created.toString().toLowerCase().includes(e.toLowerCase()) ||
					job.description.toLowerCase().includes(e.toLowerCase()) ||
					job.lastMod.toString().toLowerCase().includes(e.toLowerCase()) ||
					job.location.toLowerCase().includes(e.toLowerCase()) ||
					job.moreInfo.toLowerCase().includes(e.toLowerCase()) ||
					job.status.toLowerCase().includes(e.toLowerCase()) ||
					job.title.toLowerCase().includes(e.toLowerCase())
				)
			})
			setActiveJobs && setActiveJobs(filtered ?? [])
			if (e === '') {
				setActiveJobs &&
					setActiveJobs(
						snapshot?.docs.map((doc) => ({
							...doc.data(),
							id: doc.id
						})) as Job[]
					)
			}
		}
	}

	const searchChangeHandler = (searchedValue: string) => {
		handleSearch(searchedValue)
		setSearch && setSearch(searchedValue)
	}
	return (
		<TextField
			placeholder="Search Gambit, Skill or Job Title"
			size="small"
			onChange={(e) => searchChangeHandler(e.target.value)}
			value={search}
			sx={{
				marginY: '15px',
				border: 'none',
				backgroundColor: 'white',
				color: '#7F879E',
				borderRadius: '12px',
				width: 1,
				'>div': {
					...inputWrapperStyles
				},
				'& label': { display: 'none' },
				'& input': {
					fontStyle: 'normal',
					fontWeight: '500',
					fontSize: '14px',
					lineHeight: '21px',
					height: '30px',
					border: 'none',
					color: '#7F879E',
					...inputStyles
				},
				'& fieldset': {
					borderRadius: '12px',
					border: 'none'
				},
				'& .MuiOutlinedInput-root:hover': {
					'& > fieldset': {
						border: 'none'
					}
				},
				...parenetWrapperStyles
			}}
			InputProps={{
				startAdornment: (
					<InputAdornment sx={{ color: '#7F879E', paddingLeft: '6px' }} position="start">
						<Box component="img" src={SearchIconn} />
					</InputAdornment>
				)
			}}
			variant="outlined"
		/>
	)
}

export default SearchInput
