import { Typography, Box } from '@mui/material'
import { ChangeEvent, FC, useEffect, useState } from 'react'
import JobAnalysisTable from '../JobAnalysisTable'
import PercentProgress from '../PercentProgress'
import SuggestSkillModal from '../SuggestSkillModal'
import TopJobTitleTable from '../TopJobTitleTable'
import { TABLE_NAMES } from '../../constants'
import { MobileJobSection2Props, ResumeInputMode } from './types'
import MobileJobSection2JobMatchTitle from './MobileJobSection2JobMatchTitle'
import MobileJobSection2Description from './MobileJobSection2Description'
// import JobFieldsSections1ResumeFields from './JobFieldsSections1ResumeFields'
import MobileJobSection2SkillOnlyDisableFields from './MobileJobSection2SkillOnlyDisableFields'
import MobileJobSection2Checkboxes from './MobileJobSection2Checkboxes'
import { calculateAveragePercentage } from '../../utils'
import MobileJobResumeText from './MobileJobResumeText'
const MobileJobSection2: FC<MobileJobSection2Props> = ({
	editMode = false,
	jobText = '',
	setJobText = (index: number, value: string) => {},
	file = null,
	setFile = (index: number, value: File | null) => {},
	description = '',
	setDescription = (index: number, value: string) => {},
	skillsOnly = false,
	skillsCheckboxHandler = async (index: number, e: ChangeEvent<HTMLInputElement>) => await Promise.resolve(),
	percentAvg = 0,
	isMobile = false,
	addSuggestedSkill = (index: number, tableName: string, skill: string) => {},
	percentHard = 0,
	tableHard = [],
	markIrrelevantSkill = (index: number, skill: string, tableName: string) => {},
	percentSoft = 0,
	tableSoft = [],
	percentSales = 0,
	tableRelated = [],
	resumeAnalysis = {},
	topTitles = [],
	markInaccurateTitle = (index: number, title: string) => {},
	jobLoading = false,
	resumeMode = 'text',
	setResumeMode = (index: number, value: ResumeInputMode) => {},
	selectedResume = '',
	setSelectedResume = (index: number, value: string) => {},
	index = 0,
	isMultiResumes = false,
	handleDelete = (index: number) => {},
	jobError,
	isSorted,
	sortAccordingToHardSkill,
	newResume,
	hideDescription,
	showMoreDetails,
	hideResume,
	showResumeOnFooter,
	isFitScore,
	fileName
}) => {
	const [percentageAverage, setPercentageAverage] = useState(percentAvg)
	useEffect(() => {
		if (isMultiResumes && isSorted) {
			setPercentageAverage(percentHard)
			return
		}

		if (isMultiResumes && !isSorted) {
			setPercentageAverage(calculateAveragePercentage(skillsOnly, percentHard, percentSoft, percentSales))
			return
		}

		if (!isMultiResumes) {
			setPercentageAverage(calculateAveragePercentage(skillsOnly, percentHard, percentSoft, percentSales))
		}
	}, [percentHard, percentSoft, percentSales, skillsOnly, isSorted])

	return (
		<>
			{!hideResume ? (
				<>
					<MobileJobResumeText
						handleDelete={handleDelete}
						jobText={jobText}
						setJobText={setJobText}
						file={file}
						setFile={setFile}
						resumeMode={resumeMode}
						setResumeMode={setResumeMode}
						setSelectedResume={setSelectedResume}
						selectedResume={selectedResume}
						index={index}
						newResume={newResume}
						jobLoading={jobLoading}
						isMultiResumes={isMultiResumes}
						editMode={editMode}
						fileName={fileName}
					/>
				</>
			) : null}

			{!hideDescription && (
				<>
					<Typography
						component="h4"
						sx={{
							fontWeight: '700',
							fontSize: '16px',
							lineHeight: '21px',
							marginBottom: '20px',
							marginTop: '25px'
						}}
					>
						Job Description
					</Typography>
					<MobileJobSection2Description index={index} jobLoading={jobLoading} editMode={editMode} description={description} setDescription={setDescription} />
				</>
			)}

			{showMoreDetails ? (
				<>
					{!isMultiResumes ? (
						<Typography
							component="h3"
							sx={{
								fontWeight: '800',
								fontSize: '20px',
								lineHeight: '21px',
								marginBottom: '11px',
								marginTop: '25px'
							}}
						>
							Other details
						</Typography>
					) : null}

					<MobileJobSection2Checkboxes
						skillsOnly={skillsOnly}
						skillsCheckboxHandler={skillsCheckboxHandler}
						isMultiResumes={isMultiResumes}
						resumeIndex={index}
						isSorted={isSorted}
						sortAccordingToHardSkill={sortAccordingToHardSkill as (e: ChangeEvent<HTMLInputElement>) => void}
					/>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '5%',
							marginBottom: { sm: '11px', xs: '5px' }
						}}
					>
						<Box>
							<Typography
								component="h4"
								sx={{
									fontWeight: '700',
									fontSize: '16px',
									lineHeight: '21px'
								}}
							>
								{isFitScore ? 'Fit Score' : 'Job Analysis'}
							</Typography>
						</Box>
						<PercentProgress percent={percentageAverage} width="50%" />
					</Box>
					{!isMultiResumes ? (
						<>
							<MobileJobSection2JobMatchTitle />
							<TopJobTitleTable rows={topTitles} markInaccurateTitle={(value) => markInaccurateTitle(index, value)} />
						</>
					) : null}
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							marginBottom: '3px',
							marginTop: '18px'
						}}
					>
						<Box>
							<Typography
								component="span"
								sx={{
									fontWeight: '700',
									fontSize: '11px',
									marginBottom: { sm: '11px', xs: '5px' },
									marginTop: { sm: '18px', xs: '10px' }
								}}
							>
								Hard Skills Match
							</Typography>
						</Box>
						<Box>
							<SuggestSkillModal
								isMobile={isMobile}
								modalSkillType={TABLE_NAMES.HARD_SKILLS}
								handleAddSkill={(tableName, skill) => {
									const skillstring = skill.join(', ')
									addSuggestedSkill(index, tableName, skillstring)
								}}
							/>
						</Box>
						<PercentProgress percent={percentHard} />
					</Box>
					<JobAnalysisTable
						rows={tableHard}
						markIrrelevantSkill={(skill, tableName) => {
							markIrrelevantSkill(index, skill, tableName)
						}}
						tableName={TABLE_NAMES.HARD_SKILLS}
					/>

					{!isSorted ? (
						<>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
									marginBottom: '3px',
									marginTop: '18px'
								}}
							>
								<Box>
									<Typography
										component="span"
										sx={{
											fontWeight: '700',
											fontSize: '11px',
											marginBottom: { sm: '11px', xs: '5px' },
											marginTop: { sm: '18px', xs: '10px' }
										}}
									>
										Soft Skills Match
									</Typography>
								</Box>
								<Box>
									<SuggestSkillModal
										isMobile={isMobile}
										modalSkillType={TABLE_NAMES.SOFT_SKILLS}
										handleAddSkill={(skillType, skill) => {
											const skillstring = skill.join(', ')
											addSuggestedSkill(index, skillType, skillstring)
										}}
									/>
								</Box>
								<PercentProgress percent={percentSoft} />
							</Box>
							<JobAnalysisTable
								rows={tableSoft}
								markIrrelevantSkill={(skill, tableName) => {
									markIrrelevantSkill(index, skill, tableName)
								}}
								tableName={TABLE_NAMES.SOFT_SKILLS}
							/>
							{!skillsOnly && <MobileJobSection2SkillOnlyDisableFields percentSales={percentSales} resumeAnalysis={resumeAnalysis} />}
							<Typography
								component="p"
								sx={{
									fontWeight: '500',
									fontSize: '11px',
									lineHeight: '21px',
									marginBottom: { sm: '11px', xs: '5px' },
									marginTop: { sm: '25px', xs: '10px' }
								}}
							>
								Usually corresponding but not mentioned in this Job Description
							</Typography>
							<JobAnalysisTable
								rows={tableRelated}
								markIrrelevantSkill={(skill, tableName) => {
									markIrrelevantSkill(index, skill, tableName)
								}}
								tableName={TABLE_NAMES.RELATED_SKILLS}
								related={true}
							/>
						</>
					) : null}
				</>
			) : null}
			{showResumeOnFooter ? (
				<>
					<MobileJobResumeText
						handleDelete={handleDelete}
						jobText={jobText}
						setJobText={setJobText}
						file={file}
						setFile={setFile}
						resumeMode={resumeMode}
						setResumeMode={setResumeMode}
						setSelectedResume={setSelectedResume}
						selectedResume={selectedResume}
						index={index}
						newResume={newResume}
						jobLoading={jobLoading}
						isMultiResumes={isMultiResumes}
						editMode={editMode}
						fileName={fileName}
					/>
				</>
			) : null}
		</>
	)
}
export default MobileJobSection2
