/* eslint-disable max-lines */

import { ROUTES } from './routes'

export const HASH_ALGO = 'sha256'

export const DEFAULT_FILENAME = 'resume.txt'

export const ACCEPTED_FILE_TYPES = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/plain']

export const PDF_WORKER_URL = `https://unpkg.com/pdfjs-dist@3.3.122/build/pdf.worker.min.js`

export const BLOGS_SLIDER_CONFIG = {
	0: {
		items: 1
	},
	568: {
		items: 2
	},
	1024: {
		items: 3,
		itemsFit: 'contain'
	}
}
export const TABLE_NAMES = {
	HARD_SKILLS: 'table_hard',
	SOFT_SKILLS: 'table_soft',
	RELATED_SKILLS: 'table_related'
}

export const SALES_INDEX_HEADINGS = {
	word_count: 'Word Count',
	measurable_results: 'Measurable results',
	action_verbs: 'Action Verbs',
	buzz_words: 'Cliches and Buzzwords'
}

export const SALES_INDEX_TABLE_ORDER = ['word_count', 'measurable_results', 'action_verbs', 'buzz_words']

export const LOGIN_SIGNUP_ROUTES = [ROUTES.LOGIN, ROUTES.SIGNUP]

export const JOB_STATUS = {
	COMPLETED: 'Completed',
	PROGRESS: 'in progress'
}

export const JOB_TYPES = {
	ACTIVE_JOB: 'active',
	DRAFT_JOB: 'draft'
}

export const REGEX = {
	JOB_TITLE: [
		// regex to look for string 'is looking for' or 'is seeking' and capture 3 words after that or till you hit a full-stop,who|which|that|is|to, whichever condition is met first
		/job title\.?\s+((?:(?!(?:who|which|that|is|to))\w+\s*){0,3})/,
		/title\.?\s+((?:(?!(?:who|which|that|is|to))\w+\s*){0,3})/,
		/ looking for\.?\s+((?:(?!(?:who|which|that|is|to))\w+\s*){0,3})/,
		/ seeking (?:a|an)\.?\s+((?:(?!(?:who|which|that|is|to))\w+\s*){0,3})/,
		// regex to look for string 'is looking for' or 'is seeking' and capture 3 words after that or till you hit a full-stop,who|which|that|is|to, whichever condition is met first
		/are you (?:a|an)\.?\s+((?:(?!(?:who|which|that|is|to))\w+\s*){0,5})/,
		/((?:\s*\w+(?<!(?:who|the|which|that|is|to))){0,5})\s+(?=is responsible for)/, // regex to look for 3 words before 'is responsible for'
		/((?:\s*\w+(?<!(?:who|the|which|that|is|to))){0,5})\s+(?=(?:\w+) is responsible for)/, // regex to look for 3 words before 'is responsible for'
		/((?:\s*\w+(?<!(?:who|the|which|that|is|to))){0,5})\s+(?=(?:is going to be|will be)\s+(?:\w+\s+|)responsible for)/, // regex to look for 3 words before 'is responsible for'
		/as (?:a|an|our)\.?\s+((?:(?!(?:who|which|that|is|to))\w+\s*){0,5})/,
		/is (?:\w+\s+|)hiring\.?\s+((?:(?!(?:who|which|that|is|to))\w+\s*){0,5})/
	],
	COMPANY_NAME: [
		/@([\w][^.]+)/, // regex to capture company domain name from any email provided in job description
		/www.([\w][^.]+)/, // regex to capture company domain name from website link
		/((?:\s*\w+){5})\s+(?=is (?:|\w+\s+)looking for)/,
		/((?:\s*\w+){5})\s+(?=is (?:|\w+\s+)seeking)/,
		/we are a\.?\s+((?:(?!(?:who|which|that|is|to))\w+\s*){0,5})/, // regex to look for string 'cleint' or 'we are a' and capture 3 words after that or till you hit a full-stop,who|which|that|is|to, whichever condition is met first
		/((?:\s*\w+){5})\s+(?=is an equal opportunity employer)/,
		/((?:\s*\w+){5})\s+(?=an equal employ)/,
		/((?:\s*\w+){5})\s+(?=equal opportunity employer)/
	],
	LOCATION: [
		/\b((?:remotely|on-?site|remote))\b/, // regex for capturing string 'remote'
		/located at\.?\s+((?:\w+\s*){0,3})/, // regex to look for string 'located at' and capture 3 words after that or till you hit full-stop, whichever condition is met first
		/((?:\b\d{5}-\d{4}\b)|(?:\b\d{9}\b)|(?:\b\d{5}\b))/, // regex for matching zip-code patterns
		/location\.?\s+((?:(?!(?:where|which|that|is|to))\w+\s*){0,5})/ // regex to look for string 'locattion' and capture 5 words after that or till you hit full-stop, whichever condition is met first
	],
	MORE_INFO: [
		/\b(full-* *time)\b/, // regex for capturing string 'full-time' or 'fulltime' or 'full time'
		/\b(part-* *time)\b/, // regex for capturing string 'part-time' or 'parttime' or 'part time'
		/\b(contract to hire)\b/, // regex for capturing string 'contract to hire'
		/\b(remote)\b/, // regex for capturing string 'remote'
		/\b(remote 100%)/, // regex for capturing string 'remote 100%'
		/\b((?:permanent |)direct hire)\b/, // regex for capturing string 'permanent direct hire'
		/(\$[ ]*[\d\-k]+[,\d]*)/, // regex for capturing salary range
		/([a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/ // regex for capturing emails
	]
}

export const MEMBERSHIPS = [
	{ id: 1, name: 'free', price: 0 },
	{ id: 2, name: 'normal', price: 29 },
	{ id: 3, name: 'premium', price: 59 }
]

export const MONTH_NAMES = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

const alternativeOfferedColor = '#3DD598'
const alternativeNotOfferedColor = '#FC5A5A'

export const AlternativesFeatureComparison = [
	{
		name: 'ATS Resume scan',
		offeredByGR: {
			title: 'Yes',
			color: alternativeOfferedColor
		},
		offeredByJS: {
			title: 'Yes',
			color: alternativeOfferedColor
		}
	},
	{
		name: 'Tailor Skills for Resume scanner',
		offeredByGR: {
			title: 'Yes',
			color: alternativeOfferedColor
		},
		offeredByJS: {
			title: 'No',
			color: alternativeNotOfferedColor
		}
	},
	{
		name: 'Hard Skills Only scanner (Ideal for IT/Tech Candidates)',
		offeredByGR: {
			title: 'Yes',
			color: alternativeOfferedColor
		},
		offeredByJS: {
			title: 'No',
			color: alternativeNotOfferedColor
		}
	},
	{
		name: 'Price (both 1 month or 3 months)',
		offeredByGR: {
			title: 'Cheaper',
			color: alternativeOfferedColor
		},
		offeredByJS: {
			title: 'Expensive',
			color: alternativeNotOfferedColor
		}
	},
	{
		name: 'Shows Scan Score Progress While Editing resume in real time',
		offeredByGR: {
			title: 'Yes',
			color: alternativeOfferedColor
		},
		offeredByJS: {
			title: 'Yes',
			color: alternativeOfferedColor
		}
	},
	{
		name: 'User Experience',
		offeredByGR: {
			title: 'Simple',
			color: alternativeOfferedColor
		},
		offeredByJS: {
			title: 'Complex',
			color: alternativeNotOfferedColor
		}
	},
	{
		name: 'Cover letter & LinkedIn',
		offeredByGR: {
			title: 'No',
			color: alternativeNotOfferedColor
		},
		offeredByJS: {
			title: 'Yes',
			color: alternativeOfferedColor
		}
	},
	{
		name: 'Resume Writers & career Coach',
		offeredByGR: {
			title: '$60/Yr',
			color: alternativeOfferedColor
		},
		offeredByJS: {
			title: '$199/Yr',
			color: alternativeNotOfferedColor
		}
	}
]

export const InterviewOn = [
	{
		name: 'Phone',
		value: 1
	},
	{
		name: 'Zoom',
		value: 2
	},
	{
		name: 'MS Teams',
		value: 3
	},
	{
		name: 'Google Meet',
		value: 4
	}
]
export const TERMS_AND_CONDITIONS = [
	'Services: We provide professional resume writing and scanning services to our clients. We do not guarantee that our services will result in job offers or interviews.',

	'Payments: All payments must be made through our website and are non-refundable. We reserve the right to refuse service to anyone who fails to make payment.',

	'Intellectual Property: All content and materials on our website, including but not limited to text, graphics, logos, images, and software, are the property of GambitResume or our licensors and protected by copyright and trademark laws.',

	'User Content: By submitting content to our website, you grant GambitResume a non-exclusive, worldwide, royalty-free license to use, reproduce, display, and distribute such content for the purpose of providing our services. You are responsible for any content that you upload or post on the website, including but not limited to resumes, cover letters, and profiles. You represent and warrant that you have all necessary rights and licenses to upload or post any content on the website.',

	'User Conduct: You agree to use our website only for lawful purposes and in compliance with all applicable laws. You agree not to use our website to post or transmit any content that is offensive, harmful, threatening, or in violation of any law.',

	'Disclaimer of Warranties: Our website and services are provided "as is" without warranty of any kind, either express or implied. We do not warrant that our website or services will be uninterrupted or error-free.',

	'Limitation of Liability: Under no circumstances shall GambitResume be liable for any damages, including but not limited to, direct, indirect, special, incidental, or consequential damages, arising out of or in connection with the use of our website or services. GambitResume will not be liable for any damages arising from your use of the website, including but not limited to damages for loss of business, loss of profits, or loss of data.',

	"Governing Law: These terms and conditions shall be governed by and construed in accordance with the laws of the country of GambitResume's incorporation, without giving effect to any principles of conflicts of law",

	'Changes to terms and conditions: We reserve the right to make changes to these terms and conditions at any time. Your continued use of our website following any changes indicates your acceptance of such changes.',

	"Dispute Resolution: Any dispute arising out of or in connection with these terms and conditions or the use of our website shall be resolved through binding arbitration in accordance with the rules of GambitResume's arbitration organization.",

	'Eligibility: By using GambitResume, you represent and warrant that you are at least 18 years old and have the legal capacity to enter into this agreement.',

	'Account Creation: In order to use certain features of the website, you may be required to create an account. You agree to provide accurate and complete information when creating your account, and you are responsible for maintaining the confidentiality of your account information.',

	'Service Providers: GambitResume is a marketplace that connects job seekers with professional resume writers and career coaches. We do not endorse or guarantee the work of any service provider on our platform. You are responsible for evaluating the qualifications and abilities of any service provider you choose to work with, and you are encouraged to read reviews and check references before making a decision.',

	'Payment and Fees: GambitResume charges a fee for the use of our platform and the services provided by our service providers. You agree to pay all fees associated with your use of the website.',

	'Dispute Resolution: If you have a dispute with a service provider, you agree to try to resolve the dispute informally before escalating it to GambitResume. If you are unable to resolve the dispute with the service provider, you can report it to GambitResume and we will investigate the matter and take appropriate action.',

	'Intellectual Property: The content on the website, including but not limited to text, graphics, logos, images, and software, is the property of GambitResume or its licensors and is protected by copyright and trademark laws. You may not use any content on the website for commercial purposes without the express written consent of GambitResume.'
]

export const FAQs = [
	{
		question: 'What is a resume?',
		answer: 'A resume is a document that summarizes your education, work experience, skills, and qualifications for a potential employer. It is typically used when applying for a job or an internship.'
	},
	{
		question: 'Why is it important to have a professional resume?',
		answer: 'A professional resume is important because it is often the first impression a potential employer has of you. A well-written, polished resume can help you stand out from other candidates and increase your chances of getting an interview.'
	},
	{
		question: 'How do I create a resume?',
		answer: 'There are many resources available to help you create a resume, including online templates and professional resume writers. You can also research best practices for resume writing and tailor your resume to the specific job you are applying for.'
	},
	{
		question: 'What should be included in a resume?',
		answer: 'A typical resume includes your contact information, a summary or objective, education, work experience, skills, and any relevant certifications or awards.'
	},
	{
		question: 'How do I format my resume?',
		answer: 'There are different ways to format a resume, but a common format includes a clear, easy-to-read layout with clear headings and bullet points. You should also use a font that is easy to read and consistent throughout the document.'
	},
	{
		question: 'What is a resume scan?',
		answer: 'A resume scan is a process in which a computer program or software analyzes your resume for certain keywords, qualifications, and formatting. This is often used by employers to quickly sort through a large number of resumes and identify the most qualified candidates.'
	},
	{
		question: 'How can I optimize my resume for a scan?',
		answer: 'To optimize your resume for a scan, you should include relevant keywords that match the qualifications listed in the job posting. You should also use a clear and consistent format, and avoid graphics or fancy formatting that may not be readable by the scan.'
	},
	{
		question: 'How do I prepare for an interview?',
		answer: 'You can prepare for an interview by researching the company and the position, practicing answering common interview questions, and thinking about how you will highlight your qualifications and experiences. You should also be prepared to ask questions about the position and the company.'
	},

	{
		question: 'What should I wear to an interview?',
		answer: 'The general rule of thumb is to dress professionally for an interview. This usually means wearing a suit or business attire. You should also be well-groomed, and avoid excessive jewelry or cologne.'
	},
	{
		question: 'How can I stand out in a job interview?',
		answer: 'To stand out in a job interview, you should be well-prepared and ready to discuss your qualifications and experiences. You should also be able to demonstrate your knowledge of the company and the position, and be able'
	},
	{
		question: 'What should I include in my resume?',
		answer: 'Your resume should include your contact information, professional summary, relevant work experience, education, and skills.'
	},
	{
		question: 'How do I format my resume?',
		answer: 'Your resume should be clean, easy to read, and well-organized. Use a simple font and format, and make sure to use headings and bullet points to make it easy to scan.'
	},
	{
		question: 'How long should my resume be?',
		answer: 'Ideally, your resume should be one or two pages. Keep it concise and only include the most relevant information.'
	},
	{
		question: 'What is a resume scanner?',
		answer: 'A resume scanner is a software that is used by companies to automatically scan resumes and identify keywords that match the requirements of a job.'
	},
	{
		question: 'What are the benefits of a resume scanning service?',
		answer: 'A resume scanning service can help you optimize your resume to ensure that it gets past the initial screening process and increases your chances of getting an interview.'
	},
	{
		question: 'How do I make sure my resume is scanned by a resume scanner?',
		answer: 'You can optimize your resume for scanning by including relevant keywords, phrases, and industry-specific language. You can also use a resume scanning service to check your resume for errors and make sure it is optimized for scanning.'
	},
	{
		question: 'What should I include in my cover letter?',
		answer: "Your cover letter should include your contact information, a brief introduction, an explanation of why you're applying for the job, and how your skills and experience align with the job requirements."
	},
	{
		question: 'How do I prepare for an interview?',
		answer: 'To prepare for an interview, research the company, practice answering common interview questions, and think about examples of how your skills and experience align with the job requirements.'
	},
	{
		question: 'What should I wear to an interview?',
		answer: 'Dress professionally and in a way that is consistent with the company culture.'
	},
	{
		question: 'How do I follow up after an interview?',
		answer: 'After an interview, send a thank-you note or email to the interviewer expressing your appreciation for their time and reiterating your interest in the job.'
	},
	{
		question: 'How do I write an effective resume summary?',
		answer: 'An effective resume summary should be a brief and concise summary of your qualifications, skills, and experience. It should be no longer than 3-4 lines and should be written in a way that highlights your most relevant qualifications for the job.'
	},
	{
		question: 'How do I format my resume for an ATS?',
		answer: 'To format your resume for an ATS, use a simple, easy-to-read font, include relevant keywords and phrases, and make sure that your resume is well'
	}
]
