import { User } from 'firebase/auth'
import { DocumentData, DocumentReference, doc, getDoc, setDoc } from 'firebase/firestore'
import { ResumeInputMode } from './types'
import { UpdatableFieldsInJob } from '../../models/UpdatableFieldsInJob'
import { COLLECTION_NAMES } from '../../constants/collections'
import { convertResumeToText } from '../../utils/resumes'
import { db, storage } from '../../firebase'
import { ResumeType } from '../../models/Resume'
import { generateFilename } from '../../utils'
import { ref, uploadBytes } from 'firebase/storage'
export const updateJobDetailSingleResume = async (
	editMode: boolean,
	jobLoading: boolean,
	updatedDoc: UpdatableFieldsInJob,
	user: User,
	resumeMode: ResumeInputMode,
	jobRef: DocumentReference<DocumentData>,
	selectedResume: string,
	setEditMode: (value: boolean) => void,
	file: File
): Promise<void> => {
	if (
		editMode &&
		!jobLoading
		// title !== "" &&
		// company !== "" &&
		// location !== "" &&
		// description !== ""
	) {
		if (!user) return

		let resumeData = ''
		if (resumeMode === 'resumes') {
			const resumeDoc = await getDoc(doc(db, COLLECTION_NAMES.USERS_COLLECTION, user.uid, COLLECTION_NAMES.RESUMES, selectedResume))
			if (resumeDoc.exists()) {
				resumeData = convertResumeToText(resumeDoc.data() as ResumeType)
				updatedDoc.resume = resumeData
			}
		} else {
			updatedDoc.selectedResume = ''
		}

		setDoc(jobRef, updatedDoc, { merge: true })
			.then(async () => {
				setEditMode(false)
				// checking if resume input is file or text
				if (resumeMode === 'file') {
					// checking if user provided a resume file
					if (file !== null) {
						const destinationName = generateFilename(file, jobRef.id)

						const resumeRef = ref(storage, destinationName)

						uploadBytes(resumeRef, file)
							.then(() => console.log('file uploaded successfully'))
							.catch((err: Error) => {
								console.warn(err)
								alert(`Error uploading file: ${err.message}`)
							})
					} else {
						console.warn('No resume provided')
					}
				}
			})
			.catch((err: Error) => {
				console.log('Error updating document: ', err)
				alert('Error updating document: ' + err.message)
			})
	} else {
		// alert("Please fill all the fields correctly. Empty fields are not allowed.");
	}
}
