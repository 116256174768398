import { FC } from 'react'

import { Box, LinearProgress, SxProps, Typography, useMediaQuery } from '@mui/material'

import theme from '../../theme'

interface PercentProgressProps {
	percent: number
	width?: string
}

interface Styles {
	MOBILE: SxProps
	DESKTOP: SxProps
}

const STYLES: Styles = {
	MOBILE: {
		fontWeight: '700',
		fontSize: '9px',
		lineHeight: '21px'
	},
	DESKTOP: {
		color: '#44444F',
		fontWeight: '700',
		fontSize: '12px',
		lineHeight: '18px',
		letterSpacing: '0.1px'
	}
}

const PercentProgress: FC<PercentProgressProps> = ({ percent, width = '25%' }) => {
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	return (
		<Box
			width={width}
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-evenly'
			}}
		>
			<Typography component={'span'} sx={isMobile ? STYLES.MOBILE : STYLES.DESKTOP}>
				{percent}%
			</Typography>
			<LinearProgress
				variant={'determinate'}
				color={percent >= 75 ? 'success' : 'error'}
				sx={{
					height: '10px',
					width: '70%'
				}}
				value={percent}
			></LinearProgress>
		</Box>
	)
}
export default PercentProgress
