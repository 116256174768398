import { Box, FormGroup, FormControlLabel, Checkbox, Link } from '@mui/material'
import { Dispatch, FC, SetStateAction } from 'react'

interface ForgotPasswordRememberMeProps {
	setRememberPassWord: Dispatch<SetStateAction<boolean>> | (() => {})
	rememberPassWord: boolean
}

const ForgotPasswordRememberMe: FC<ForgotPasswordRememberMeProps> = ({ setRememberPassWord = () => {}, rememberPassWord = false }) => {
	return (
		<Box component={'div'}>
			<FormGroup
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					marginTop: '42px'
				}}
			>
				<FormControlLabel
					control={<Checkbox onChange={() => setRememberPassWord(!rememberPassWord)} checked={rememberPassWord} size="medium" />}
					label={
						<Box
							sx={{
								fontStyle: 'normal',
								fontWeight: '600',
								fontSize: { sm: '18px', xs: '14px' },
								lineHeight: '27px',
								letterSpacing: '0.02em',
								color: '#222222'
							}}
						>
							Remember me
						</Box>
					}
				/>
				<Link
					// onClick={
					//   () => alert("Forgot Password")
					// }

					sx={{
						lineHeight: '50px',
						cursor: 'pointer',
						fontStyle: 'normal',
						fontWeight: '600',
						fontSize: { sm: '18px', xs: '14px' },
						letterSpacing: '0.02em',
						color: '#3865F3'
					}}
					underline="hover"
				>
					Forgot Password?
				</Link>
			</FormGroup>
		</Box>
	)
}

export default ForgotPasswordRememberMe
