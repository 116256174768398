import { Box, Typography, CircularProgress } from '@mui/material'
import { FC } from 'react'

const MobileResumeLoadingJobs: FC<{}> = () => {
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				mt: '30px'
			}}
		>
			<Typography
				sx={{
					color: '#0062FF',
					fontStyle: 'normal',
					fontWeight: '600',
					fontSize: { md: '24px', sm: '20px', xs: '18px' },
					lineHeight: '36px',
					letterSpacing: '0.15px'
				}}
				component={'p'}
			>
				Loading Resumes Please wait ...
			</Typography>
			<Box
				sx={{
					marginTop: '20px'
				}}
				component={'div'}
			>
				<CircularProgress />
			</Box>
		</Box>
	)
}

export default MobileResumeLoadingJobs
