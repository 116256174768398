import { CSSProperties } from 'react'
import { Box } from '@mui/material'
import { Link } from 'react-router-dom'
import AlternativesLinks from '../Alternatives/AlternativeLinks'
import { ROUTES } from '../../constants/routes'

const Footer = ({ styles = {} }: { styles?: CSSProperties }) => {
	return (
		<Box
			sx={{
				position: 'relative',
				display: 'flex',
				gap: '15px',
				flexDirection: { xs: 'column', sm: 'row' },
				justifyContent: 'space-between',
				alignItems: { xs: 'flex-start', sm: 'center' },
				backgroundColor: 'black',
				color: 'white',
				padding: '10px 30px',
				a: {
					color: 'white',
					cursor: 'pointer',
					textDecoration: 'none',
					fontSize: '14px'
				},
				...styles
			}}
		>
			<Box order={{ xs: 6, md: 1 }} fontSize="12px">
				&copy; 2023 Gambit Resume
			</Box>
			<Box order={{ xs: 1, md: 2 }}>
				<Link to={ROUTES.ABOUT_US}>About Us</Link>
			</Box>
			<Box order={{ xs: 2, md: 3 }}>
				<Link to={ROUTES.FAQ}>FAQs</Link>
			</Box>
			<Box order={{ xs: 3, md: 4 }}>
				<AlternativesLinks />
			</Box>
			<Box order={{ xs: 4, md: 5 }}>
				<Link to={ROUTES.PRICING}>Pricing</Link>
			</Box>
			<Box order={{ xs: 5, md: 6 }}>
				<Link to={ROUTES.TERMS_AND_CONDITIONS}>Terms and Conditions</Link>
			</Box>
		</Box>
	)
}

export default Footer
