import { collection, DocumentData, limit, orderBy, query, Query, QueryConstraint, startAfter, where, WhereFilterOp } from 'firebase/firestore'
import { COLLECTION_NAMES } from '../constants/collections'
import { auth, db } from '../firebase'

export interface Condition {
	field: string
	op: WhereFilterOp
	value: string
}

export const getQueryConstraintsForUser = (): QueryConstraint[] => {
	const queryConstraints: QueryConstraint[] = []

	if (auth.currentUser !== null) {
		if (auth.currentUser.isAnonymous) {
			queryConstraints.push(where('uid', '==', auth.currentUser.uid))
		} else {
			queryConstraints.push(where('sharedWith', 'array-contains', auth.currentUser.email))
		}
	}

	return queryConstraints
}

export const getJobsQuery = (type: string = ''): Query => {
	const queryConstraints = getQueryConstraintsForUser()

	if (type !== '') {
		queryConstraints.push(where('type', '==', type))
		queryConstraints.push(orderBy('lastMod', 'desc'))
	}
	queryConstraints.push(where('groupId', '==', null))
	const jobsQuery = query(collection(db, COLLECTION_NAMES.JOBS_COLLECTION), ...queryConstraints)

	return jobsQuery
}

export const getManyResumesJobs = (type: string = ''): Query => {
	const queryConstraints = getQueryConstraintsForUser()

	if (type !== '') {
		queryConstraints.push(where('type', '==', type))
		queryConstraints.push(orderBy('lastMod', 'desc'))
	}
	queryConstraints.push(where('groupId', '!=', null))
	// queryConstraints.push(orderBy('created', 'asc'))
	const jobsQuery = query(collection(db, COLLECTION_NAMES.JOBS_COLLECTION), ...queryConstraints)

	return jobsQuery
}

export const getJobsByGroupId = (groupId: string = ''): Query => {
	const queryConstraints = getQueryConstraintsForUser()
	queryConstraints.push(where('groupId', '==', groupId))
	queryConstraints.push(orderBy('created', 'asc'))
	const jobsQuery = query(collection(db, COLLECTION_NAMES.JOBS_COLLECTION), ...queryConstraints)
	return jobsQuery
}
export const getNotificationsQuery = (): Query => {
	return query(collection(db, COLLECTION_NAMES.NOTIFICATIONS_COLLECTION), where('active', '==', true))
}

export const getSurveysQuery = (): Query => {
	return query(collection(db, COLLECTION_NAMES.SURVEYS_COLLECTION), where('active', '==', true))
}

export const getResumesQuery = (constraints?: QueryConstraint[]): Query => {
	return query(collection(db, COLLECTION_NAMES.USERS_COLLECTION, auth.currentUser?.uid ?? '', COLLECTION_NAMES.RESUMES), ...(constraints ?? []))
}

export const getAdminUsersList = (): Query => {
	return query(collection(db, COLLECTION_NAMES.USERS_COLLECTION))
}

export const getInterviews = (): Query => {
	const queryConstraints: QueryConstraint[] = []
	if (auth.currentUser !== null) {
		queryConstraints.push(where('uid', '==', auth.currentUser.uid))
	}
	queryConstraints.push(orderBy('created', 'desc'))
	return query(collection(db, COLLECTION_NAMES.INTERVIEWS), ...queryConstraints)
}

export const getCoverLettersQuery = (constraints?: QueryConstraint[]): Query => {
	return query(collection(db, COLLECTION_NAMES.USERS_COLLECTION, auth.currentUser?.uid ?? '', COLLECTION_NAMES.COVER_LETTERS))
}

export const getInterviewSearchQuery = (type: string, conditons: Condition[][]): Array<Query<DocumentData>> => {
	const queries = conditons.map((conditions) => {
		const conditionsWhere = conditions.map((condition) => {
			return where(condition.field, condition.op, condition.value)
		})
		return query(collection(db, COLLECTION_NAMES.INTERVIEWS), where('type', '==', type), ...conditionsWhere)
	})
	return queries
}

export const getServicesQuery = (): Query => {
	return query(collection(db, COLLECTION_NAMES.SERVICES), orderBy('order'))
}

export const getServiceBySlugQuery = (serviceSlug: string): Query => {
	return query(collection(db, COLLECTION_NAMES.SERVICES), where('slug', '==', serviceSlug), orderBy('order'))
}

export const getBlogsQuery = (pageSize: number, first: boolean, lastVisible?: DocumentData): Query => {
	if (!first && lastVisible) {
		return query(collection(db, COLLECTION_NAMES.BLOGS), orderBy('createdAt', 'desc'), startAfter(lastVisible), limit(pageSize))
	}
	return query(collection(db, COLLECTION_NAMES.BLOGS), limit(pageSize), orderBy('createdAt', 'desc'))
}

export const getRelatedBlogsQuery = (category: string): Query => {
	return query(collection(db, COLLECTION_NAMES.BLOGS), where('category', '==', category), orderBy('createdAt', 'desc'))
}
export const getBlogBySlugQuery = (blogSlug: string): Query => {
	return query(collection(db, COLLECTION_NAMES.BLOGS), where('slug', '==', blogSlug))
}

export const getBlogTemplate = (): Query => {
	return query(collection(db, COLLECTION_NAMES.BLOG_TEMPLATE))
}

export const getTemplateByName = (name: string): Query => {
	return query(collection(db, COLLECTION_NAMES.BLOG_TEMPLATE), where('slug', '==', name))
}

export const getBlogs = (): Query => {
	return query(collection(db, COLLECTION_NAMES.BLOGS))
}

export const getBlogCategories = (): Query => {
	return query(collection(db, COLLECTION_NAMES.BLOG_CATEGORIES))
}
