import { useState, SyntheticEvent, FC, useEffect, useContext } from 'react'

import { Typography, useMediaQuery, Box } from '@mui/material'

import ArrowLeftMobile from '../../assets/JobDetail/ArrowLeftMobile.png'
import ArrowLeft from '../../assets/JobDetail/ArrowLeft.png'

import { useNavigate } from 'react-router'

// Importing firebase
import { auth } from '../../firebase'

import { db } from '../../firebase'
import { collection, addDoc } from 'firebase/firestore'
import { useAuthState } from 'react-firebase-hooks/auth'
import theme from '../../theme'
import { toast } from 'react-toastify'
import { COLLECTION_NAMES } from '../../constants/collections'
import { User } from 'firebase/auth'
import FeedbackSupportLinkSection from './FeedbackSupportLinkSection'
import { LoaderContext } from '../../contexts/loader-context'

const SupportLinks: FC = () => {
	const navigate = useNavigate()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	// States for status of login users
	const [signedInUserData, setSignedInUserData] = useState<User | null>(null)
	const [isSignedIn, setIsSignedIn] = useState<boolean>(false)

	const [feedback, setFeedback] = useState<string>('')

	const [user, userLoading] = useAuthState(auth)
	const { setShowLoading } = useContext(LoaderContext)
	useEffect(() => {
		if (user !== null) {
			// When the user state is known, we set the state isSignedIn to true
			if (signedInUserData === null) {
				setSignedInUserData(user ?? null)
				setIsSignedIn(true)
			}
		} else {
			setIsSignedIn(false)
		}
	}, [user, userLoading])

	const handleFeedback = (e: SyntheticEvent) => {
		if (feedback !== '') {
			setShowLoading(true)
			if (isSignedIn) {
				const feedBackData = {
					name: user?.displayName ?? 'anonymous',
					email: user?.email ?? 'anonymous',
					uid: user?.uid ?? '',
					message: feedback
				}

				console.log(feedBackData)

				addDoc(collection(db, COLLECTION_NAMES.FEEDBACKS_COLLECTION), feedBackData)
					.then(() => {
						setShowLoading(false)
						toast.success('Feedback Sent Successfully')
						setFeedback('')
					})
					.catch((err) => {
						setShowLoading(false)
						console.error(err)
						toast.error('Error Sending Feedback')
					})
			}
		}
	}

	return (
		<Box
			sx={{
				paddingX: { lg: '40px', xs: '24px' },
				flex: 'auto'
			}}
		>
			{isMobile ? (
				<Box
					sx={{
						paddingTop: '42px',
						position: 'relative',
						display: 'flex',
						justifyContent: 'center',
						marginBottom: '50px'
					}}
				>
					<Box component="img" sx={{ position: 'absolute', left: '0', cursor: 'pointer' }} src={ArrowLeftMobile} onClick={() => navigate(-1)} />
					<Typography variant="h5" sx={{ fontWeight: '700', fontSize: '14px', lineHeight: '18px' }}>
						Support
					</Typography>
				</Box>
			) : (
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						padding: '30px 0 20px 0',
						color: '#121212',
						fontWeight: '700',
						fontSize: { xs: '20px', sm: '24px' },
						lineHeight: '32px',
						letterSpacing: '0.1px'
					}}
				>
					<Box
						onClick={() => navigate(-1)}
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							backgroundColor: '#171725',
							boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.02);',
							borderRadius: '50%',
							cursor: 'pointer',
							width: { lg: '46px', sm: '35px', xs: '30px' },
							height: { lg: '46px', sm: '35px', xs: '30px' }
						}}
					>
						<Box component="img" src={ArrowLeft} />
					</Box>
					<Typography
						variant="h2"
						sx={{
							fontWeight: '600',
							fontSize: { lg: '24px', md: '20px', xs: '17px' },
							lineHeight: '36px',
							letterSpacing: '0.1px',
							marginLeft: { sm: '18px', xs: '12px' },
							color: '#171725'
						}}
					>
						Support
					</Typography>
				</Box>
			)}
			<Box sx={{ paddingBottom: '20px', paddingX: '10px' }}>
				<FeedbackSupportLinkSection feedback={feedback} setFeedback={setFeedback} handleFeedback={handleFeedback} />
			</Box>
		</Box>
	)
}

export default SupportLinks
