import { useState, FC } from 'react'
import { Box, Typography } from '@mui/material'
interface ReadMoreTextProps {
	text: string
	maxLetterLength: number
	styles?: object
}
const ReadMoreText: FC<ReadMoreTextProps> = ({ text, maxLetterLength, styles }) => {
	const [isExpanded, setIsExpanded] = useState(false)
	return (
		<Box>
			<Typography paragraph sx={styles}>
				{text.slice(0, maxLetterLength - 1)}
				{text.length > maxLetterLength && (
					<>
						{isExpanded && <Box component="span">{text.slice(maxLetterLength - 1)}</Box>}
						<Box
							component="span"
							sx={{
								fontWeight: '500',
								fontSize: '14px',
								height: '21px',
								color: '#3860E2',
								cursor: 'pointer'
							}}
							onClick={() => setIsExpanded(!isExpanded)}
						>
							{isExpanded ? ' Read Less' : ' Read More...'}
						</Box>
					</>
				)}
			</Typography>
		</Box>
	)
}

export default ReadMoreText
