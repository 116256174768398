import { Box, Button, CircularProgress, List, ListItem, Typography } from '@mui/material'
import { collection, query, where } from 'firebase/firestore'
import { FC, useEffect } from 'react'
import { useCollection } from 'react-firebase-hooks/firestore'
import { useNavigate } from 'react-router'
import { COLLECTION_NAMES } from '../../../constants/collections'
import { ROUTES } from '../../../constants/routes'
import { auth, db } from '../../../firebase'
import SubscriptionCard from '../SuscriptionCard'

const SubscriptionList: FC = () => {
	const navigate = useNavigate()

	const [subscriptions, loading, error] = useCollection(query(collection(db, COLLECTION_NAMES.STRIPE_PRODUCTS), where('active', '==', true)))

	useEffect(() => {
		if (error) console.log(error)
	}, [error])

	return (
		<>
			{loading && <CircularProgress />}
			{!loading && (
				<>
					<Box
						sx={{
							boxShadow: '0 8px 8px rgba(0,0,0,0.2)',
							width: '100%',
							height: '100%',
							borderRadius: '30px',
							backgroundColor: '#f1f1f1',
							padding: '30px'
						}}
					>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'space-between',
								height: '100%'
							}}
						>
							<Box>
								<Typography component="h2" fontWeight="bold" variant="h5">
									Basic
								</Typography>
								<Typography
									component="p"
									sx={{
										color: 'gray'
									}}
								>
									Free plan to try out features
								</Typography>
								<Typography
									component="p"
									fontSize="medium"
									sx={{
										marginTop: '10px',
										padding: '0px'
									}}
								>
									Includes:
								</Typography>
								<List
									sx={{
										listStyleType: 'disc',
										pl: 2,
										'& .MuiListItem-root': {
											display: 'list-item'
										}
									}}
								>
									<ListItem disableGutters disablePadding sx={{ fontSize: '14px' }}>
										5 calculations/month
									</ListItem>
								</List>
							</Box>
							<Box>
								<Box
									sx={{
										marginTop: '20px',
										height: '30px',
										display: 'flex',
										gap: '5px'
									}}
								>
									Free
								</Box>
								<Button variant="contained" fullWidth sx={{ borderRadius: 50, marginTop: '20px' }} onClick={() => navigate(auth.currentUser ? ROUTES.OVERVIEW : ROUTES.LOGIN)}>
									Continue
								</Button>
							</Box>
						</Box>
					</Box>
					{error === undefined &&
						subscriptions !== undefined &&
						subscriptions.docs.map((sub) => (
							<SubscriptionCard key={sub.id} id={sub.id} name={sub.data().name} description={sub.data().description} features={sub.data().metadata.features} />
						))}
				</>
			)}
		</>
	)
}

export default SubscriptionList
