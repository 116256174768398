import { useEffect, FC, useState } from 'react'

import { Box, CircularProgress } from '@mui/material'
import { Typography, useMediaQuery } from '@mui/material'
import { getBlogTemplate, getBlogs } from '../../utils/queries'

import theme from '../../theme'

import ShowNotifications from '../../components/ShowNotifications'
import { useNavigate } from 'react-router'
import usePageTitle from '../../hooks/usePageTitle'
import { ROUTES } from '../../constants/routes'
import { useCollection } from 'react-firebase-hooks/firestore'
import { Blog, BlogTempate } from '../../models/Blog'
import MenuDropDown from '../../components/BlogBuilderComponents/MenuDropdown'
import { textToSlug } from '../../utils/functions'
import BlogDetailBar from '../../components/BlogBuilderComponents/BlogDetailBar'
import { motion } from 'framer-motion'
import MobileBlogList from '../../components/BlogBuilderComponents/MobileBlogBar/MobileBlog'
import { DocumentData } from 'firebase/firestore'
const container = {
	show: {
		transition: {
			staggerChildren: 0.05
		}
	}
}
const item = {
	hidden: { opacity: 0, y: 40 },
	show: { opacity: 1, y: 0 }
}

const Resumes: FC = () => {
	const navigate = useNavigate()
	const [setPagetitle] = usePageTitle()
	const [blogs, setBlogs] = useState<Blog[] | null>(null)
	const [search, setSearch] = useState<string>('')

	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	const q = getBlogTemplate()

	const [snapshot, loading, error] = useCollection(q, {
		snapshotListenOptions: { includeMetadataChanges: false }
	})

	const [blogTemplates, setBlogTemplates] = useState<Array<{ name: string; slug: string }>>([])

	const qBlogs = getBlogs()
	const [snapshotBlogs, loadingBlogs] = useCollection(qBlogs, {
		snapshotListenOptions: { includeMetadataChanges: false }
	})

	useEffect(() => {
		if (snapshotBlogs && !loadingBlogs && error == null) {
			setBlogs(
				snapshotBlogs.docs.map((doc: DocumentData) => ({
					...doc.data(),
					id: doc.id
				})) as Blog[]
			)
		}
	}, [snapshotBlogs, loadingBlogs])

	useEffect(() => {
		if (snapshot && !loading && error == null) {
			setBlogTemplates(
				snapshot.docs
					.map((doc) => doc.data() as BlogTempate)
					.map((data) => {
						return {
							name: data.name,
							slug: textToSlug(data.name)
						}
					})
			)
		}
	}, [loading, snapshot])

	const handleNewBlog = (slug: string) => {
		navigate(ROUTES.ADD_BLOG_BUILDER.replace(':slug', slug))
	}

	useEffect(() => {
		setPagetitle('Blog Builder')
	}, [])

	const handleTemplateSelected = (slug: string) => {
		handleNewBlog(slug)
	}
	return (
		<>
			{!isMobile ? (
				<Box
					sx={{
						minHeight: '100vh',
						backgroundColor: '#DFDFDF',
						position: 'relative',
						width: '100%'
					}}
				>
					<Box sx={{ display: 'flex', minHeight: screen.height - 70 }}>
						<Box
							className="job-list"
							sx={{
								width: '100%',
								overflow: 'scroll',
								paddingX: { lg: '40px', xs: '20px' }
							}}
						>
							<ShowNotifications />
							<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '30px 0 20px 0' }}>
								<Typography
									variant="h2"
									sx={{
										fontWeight: '600',
										fontSize: { lg: '24px', md: '20px', xs: '18px' },
										lineHeight: '36px',
										letterSpacing: '0.1px'
									}}
								>
									Blogs
								</Typography>

								<MenuDropDown width="auto" values={blogTemplates} title="New Blog" handleDropdownSelected={handleTemplateSelected} />
							</Box>

							{/* Showing resumes for Desktop View */}
							{blogs ? (
								<>
									{blogs.length > 0 ? (
										<motion.div variants={container} initial="hidden" animate="show">
											<Box
												className="job-list"
												sx={{
													paddingBottom: '20px',
													maxWidth: '100%',
													overflowX: 'scroll'
												}}
												component={motion.div}
												variants={item}
											>
												{blogs.map((resume: Blog) => {
													return (
														<Box key={resume.id}>
															<BlogDetailBar id={resume.id ?? ''} title={resume.title} createdAt={resume?.createdAt} />
														</Box>
													)
												})}
											</Box>
										</motion.div>
									) : (
										<>
											<Typography
												variant="h2"
												sx={{
													fontWeight: '600',
													fontSize: { lg: '24px', md: '20px', xs: '18px' },
													marginTop: {
														lg: '42px',
														sm: '28px',
														xs: '28px'
													},
													textAlign: 'center',
													lineHeight: '36px',
													letterSpacing: '0.1px',
													marginBottom: { lg: '30px', xs: '15px' }
												}}
											>
												No Blogs Found!
											</Typography>
										</>
									)}
								</>
							) : (
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
										alignItems: 'center',
										mt: '30px'
									}}
								>
									<Typography
										sx={{
											color: '#0062FF',
											fontStyle: 'normal',
											fontWeight: '600',
											fontSize: { md: '24px', sm: '20px', xs: '18px' },
											lineHeight: '36px',
											letterSpacing: '0.15px'
										}}
										component={'p'}
									>
										Loading Blogs Please wait ...
									</Typography>
									<Box
										sx={{
											marginTop: '20px'
										}}
										component={'div'}
									>
										<CircularProgress />
									</Box>
								</Box>
							)}
							{/* Showing resumes for Desktop View */}
						</Box>
					</Box>
				</Box>
			) : (
				<>
					<Box
						sx={{
							margin: '20px'
						}}
					>
						<MenuDropDown width="100%" values={blogTemplates} title="New Blog" handleDropdownSelected={handleTemplateSelected} />
						<MobileBlogList search={search} setSearch={setSearch} loading={loading} blogs={blogs} />
					</Box>
				</>
			)}
		</>
	)
}
export default Resumes
