import { FC } from 'react'
import { Job } from '../../models/Job'
import JobFieldsSections1 from './JobFieldsSections1'
import { DocumentData, DocumentReference, FirestoreError } from 'firebase/firestore'
import { ResumeInputMode } from './types'
import ResumeFields from '../ResumeFields'
const JobFieldSection1Edit: FC<{
	resumes: Job[] | undefined
	handleDelete: ((index: number) => void) | undefined
	setResumeMode: (index: number, value: ResumeInputMode) => void
	jobError: FirestoreError | undefined
	jobLoading: boolean
	setFile: (number: number, value: File | null) => void
	setSelectedResume: (index: number, value: string) => void
	setJobText: (number: number, value: string) => void
	jobRef: DocumentReference<DocumentData>
	setResumeName: (index: number, input: string) => void
	setResumeTitle: (index: number, input: string) => void
	setResumeLocation: (index: number, input: string) => void
	setResumeContact: (index: number, input: string) => void
	setResumeSalary: (index: number, input: string) => void
}> = ({
	resumes,
	handleDelete,
	setResumeMode,
	jobError,
	jobRef,
	jobLoading,
	setFile,
	setSelectedResume,
	setJobText,
	setResumeName,
	setResumeTitle,
	setResumeLocation,
	setResumeContact,
	setResumeSalary
}) => {
	return (
		<>
			{resumes?.map((resume, index) => {
				return (
					<>
						<ResumeFields
							index={index}
							name={resume?.resumeCandidateName ?? ''}
							title={resume?.resumeTitle ?? ''}
							location={resume?.resumeCity ?? ''}
							contact={resume?.resumePhone ?? ''}
							salary={resume?.resumeSalary ?? ''}
							setName={setResumeName}
							setTitle={setResumeTitle}
							setLocation={setResumeLocation}
							setContact={setResumeContact}
							setSalary={setResumeSalary}
							resumeText={resume?.resume ?? ''}
							isEdit
						/>
						<JobFieldsSections1
							key={index}
							showMoreDetails={true}
							handleDelete={handleDelete}
							isMultiResumes={true}
							resumeIndex={index}
							resumeMode={resume?.resumeMode}
							setResumeMode={setResumeMode}
							editMode={true}
							setTitle={() => {}}
							setCompany={() => {}}
							setLocation={() => {}}
							moreInfo={resume?.moreInfo}
							setMoreInfo={() => {}}
							jobError={jobError}
							jobRef={jobRef}
							title={resume?.title}
							user={null}
							jobText={resume?.resume}
							setDescription={() => {}}
							description={resume?.description}
							jobLoading={jobLoading}
							company={resume?.company}
							location={resume?.location}
							file={resume?.file as File}
							setFile={setFile}
							setJobText={setJobText}
							created={resume?.created}
							setSelectedResume={setSelectedResume}
							selectedResume={resume?.selectedResume}
							newResume={resume?.newResume}
							resumeCandidateName={resume?.resumeCandidateName}
							resumeTitle={resume?.resumeTitle}
							resumePhone={resume?.resumePhone}
							resumeCity={resume?.resumeCity}
							resumeEmail={resume?.resumeEmail}
							hideDescription={true}
							fileName={resume?.fileName}
						/>
					</>
				)
			})}
		</>
	)
}

export default JobFieldSection1Edit
