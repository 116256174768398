import { Box, Typography } from '@mui/material'
import { FC } from 'react'
import { NavigateFunction } from 'react-router'

interface SignupSectionProps {
	navigate: NavigateFunction | (() => {})
}
const SignupSection: FC<SignupSectionProps> = ({ navigate = () => {} }) => {
	return (
		<Typography
			paragraph
			sx={{
				fontWeight: '400',
				fontSize: '14px',
				lineHeight: '20px',
				textAlign: 'center'
			}}
		>
			Don’t have an account?{' '}
			<Box onClick={() => navigate('/signup')} component="span" sx={{ color: '#5C68FF', fontWeight: '600', cursor: 'pointer' }}>
				Sign Up
			</Box>
		</Typography>
	)
}

export default SignupSection
