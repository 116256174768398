import { Box, TextField, Typography } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import ShareModal from '../ShareModal'
import TravelBox from '../../assets/JobDetail/TravelBox.png'
import { User } from 'firebase/auth'
import { DocumentData, DocumentReference, FirestoreError, Timestamp } from 'firebase/firestore'
import JobFieldsSections1Fields1 from './JobFieldsSections1Fields1'
import JobFieldsSections1Fields2 from './JobFieldsSections1Fields2'
import { ResumeInputMode } from './types'
// import JobFieldsSections1ResumeFields from './JobFieldsSections1ResumeFields'
import { analyzeJobDescription } from '../../utils/job'

const JobFieldsSections1: FC<{
	editMode: boolean
	setTitle: (index: number, value: string) => void
	setCompany: (index: number, value: string) => void
	setLocation: (index: number, value: string) => void
	moreInfo: string
	setMoreInfo: (index: number, value: string) => void
	jobError: FirestoreError | undefined
	jobRef: DocumentReference<DocumentData>
	title: string
	user: User | null | undefined
	jobText: string
	setDescription: (index: number, value: string) => void
	description: string
	jobLoading: boolean
	company: string
	location: string
	file: File | null
	setFile: (index: number, value: File | null) => void
	setJobText: (index: number, value: string) => void
	created: Timestamp | null
	resumeMode: ResumeInputMode
	setResumeMode: (index: number, value: ResumeInputMode) => void
	selectedResume: string
	setSelectedResume: (index: number, value: string) => void
	resumeIndex: number
	isMultiResumes: boolean
	newResume?: boolean
	handleDelete?: (index: number) => void
	resumeCandidateName?: string
	resumeTitle?: string
	resumePhone?: string
	resumeCity?: string
	resumeEmail?: string
	showMoreDetails?: boolean
	hideDescription?: boolean
	showBasicDetails?: boolean
	fileName?: string
}> = ({
	editMode,
	setTitle,
	setCompany,
	setLocation,
	moreInfo,
	setMoreInfo,
	jobError,
	jobRef,
	title,
	user,
	jobText,
	setDescription,
	description,
	jobLoading,
	company,
	location,
	file,
	setFile,
	setJobText,
	created,
	resumeMode,
	setResumeMode,
	selectedResume,
	setSelectedResume,
	resumeIndex,
	newResume,
	handleDelete,
	showMoreDetails,
	hideDescription,
	showBasicDetails,
	fileName
}) => {
	const [createdDate] = useState(created !== undefined && created !== null ? new Date(created?.seconds * 1000).toDateString() : new Date().toDateString())
	useEffect(() => {
		if (editMode) {
			const { analyzedTitle, analyzedCompany, analyzedLocation, analyzedMoreInfo } = analyzeJobDescription(description)
			setTitle(resumeIndex, analyzedTitle)
			setCompany(resumeIndex, analyzedCompany)
			setLocation(resumeIndex, analyzedLocation)
			setMoreInfo(resumeIndex, analyzedMoreInfo)
		}
	}, [description])
	return (
		<>
			{((showBasicDetails ?? false) || !showMoreDetails) && resumeIndex === 0 ? (
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						paddingTop: { md: '32px', sm: '25px', xs: '20px' }
					}}
				>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center'
						}}
					>
						<Box
							sx={{
								height: { lg: '84px', sm: '50px', xs: '30px' },
								width: { lg: '84px', sm: '50px', xs: '30px' },
								background: '#0062FF',
								borderRadius: '10px',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center'
							}}
						>
							<Box
								sx={{
									width: {
										lg: 'unset',
										sm: '30px',
										xs: '18px'
									},
									height: {
										lg: 'unset',
										sm: '30px',
										xs: '18px'
									}
								}}
								component="img"
								src={TravelBox}
							/>
						</Box>
						{/* sdfa */}
						<Box
							sx={{
								// border: "1px solid red",
								marginLeft: {
									lg: '20px',
									sm: '15px',
									xs: '10px'
								},
								width: { lg: '90%', sm: 'initial' }
							}}
						>
							{editMode ? (
								<Box
									sx={{
										marginBottom: { lg: '10px', xs: '5px' }
									}}
								>
									<TextField
										id="outlined-basic"
										label={'Title'}
										variant="outlined"
										value={title}
										onChange={(e) => setTitle(resumeIndex, e.target.value)}
										inputProps={{
											style: {
												height: 5
											}
										}}
										sx={{
											input: {
												color: '#44444F',
												fontWeight: '700',
												fontSize: '14px',
												lineHeight: '18px',
												letterSpacing: '0.1px',
												height: '18px'
											}
										}}
									/>
								</Box>
							) : (
								<Typography
									component="p"
									sx={{
										marginTop: { lg: '0px' },
										color: '#171725',
										fontWeight: '600',
										fontSize: {
											lg: '18px',
											sm: '15px',
											xs: '13px'
										},
										lineHeight: '27px',
										marginBottom: { lg: '3px', md: '2px' }
									}}
								>
									{jobLoading ? 'Loading...' : title}
								</Typography>
							)}

							<JobFieldsSections1Fields1
								resumeIndex={resumeIndex}
								editMode={editMode}
								company={company}
								setCompany={setCompany}
								jobLoading={jobLoading}
								setLocation={setLocation}
								location={location}
								moreInfo={moreInfo}
								setMoreInfo={setMoreInfo}
								jobError={jobError}
							/>
							{/* More Info Container Ends */}
						</Box>
					</Box>

					<Box
						sx={{
							width: '50%',
							display: 'flex',
							justifyContent: 'space-between'
						}}
					>
						<Box>
							<Typography
								component="p"
								sx={{
									color: '#696974',
									fontWeight: '400',
									fontSize: { lg: '14px', xs: '12px' },
									lineHeight: '16px',
									letterSpacing: '0.1px',
									marginBottom: {
										lg: '10px',
										sm: '7px',
										xs: '3px'
									}
								}}
							>
								Created Date
							</Typography>
							<Typography
								component="p"
								sx={{
									color: '#171725',
									fontWeight: '600',
									fontSize: { lg: '16px', xs: '14px' },
									lineHeight: '24px',
									letterSpacing: '0.1px'
								}}
							>
								{jobLoading || jobError ? 'Loading...' : <>{createdDate}</>}
							</Typography>
						</Box>
						<Box
							sx={{
								marginRight: '20%'
							}}
						>
							<ShareModal docRef={jobRef} jobTitle={title} location={location} moreInfo={moreInfo} userName={user?.displayName ?? 'anonymous'} />
						</Box>
					</Box>
				</Box>
			) : null}

			{showMoreDetails ? (
				<JobFieldsSections1Fields2
					handleDelete={handleDelete}
					resumeIndex={resumeIndex}
					editMode={editMode}
					jobText={jobText}
					setJobText={setJobText}
					file={file}
					setFile={setFile}
					description={description}
					setDescription={setDescription}
					resumeMode={resumeMode}
					setResumeMode={setResumeMode}
					setSelectedResume={setSelectedResume}
					selectedResume={selectedResume}
					newResume={newResume}
					hideDescription={hideDescription}
					fileName={fileName}
				/>
			) : null}
		</>
	)
}

export default JobFieldsSections1
