import { Dispatch, FC } from 'react'
import { Fab } from '@mui/material'
import ModeCommentIcon from '@mui/icons-material/ModeComment'

interface Props {
	setIsAdminChat: Dispatch<boolean>
	newMessageArrived: {
		status: boolean
		to: string
	}
	onChatButtonClicked: () => void
}

const CallToActionChatButton: FC<Props> = ({ setIsAdminChat, newMessageArrived, onChatButtonClicked }) => {
	return (
		<Fab
			onClick={() => {
				setIsAdminChat(true)
				if (onChatButtonClicked) {
					onChatButtonClicked()
				}
			}}
			color="primary"
			aria-label="add"
		>
			{newMessageArrived.status && newMessageArrived.to === 'user' && (
				<span
					style={{
						display: 'inline-block',
						width: '10px',
						height: '10px',
						background: 'rgb(255,0,0)',
						borderRadius: '50%',
						position: 'absolute',
						top: '15px',
						left: '15px'
					}}
				></span>
			)}
			<ModeCommentIcon />
		</Fab>
	)
}

export default CallToActionChatButton
