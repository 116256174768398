import { Dispatch, SetStateAction, FC } from 'react'
import CustomTextField from '../../../AddJobDialog/CustomTextField'
import { Box } from '@mui/material'

interface Props {
	summary: string
	setSummary: Dispatch<SetStateAction<string>>
}

const SummaryForm: FC<Props> = ({ summary, setSummary }) => {
	return (
		<>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: { md: '48px', sm: '20px', xs: '15px' }
				}}
			>
				<Box>
					<CustomTextField name="Summary" value={summary} onChange={(e) => setSummary(e.target.value)} placeholder="Write a breif intro of your career.." type="text" rows={10} />
				</Box>
			</Box>
		</>
	)
}

export default SummaryForm
