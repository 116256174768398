import { FC } from 'react'
import { useNavigate } from 'react-router'

import { Box, Typography, useMediaQuery } from '@mui/material'

import Job from '../../assets/Overview/Job.png'
import theme from '../../theme'
import MobileJobDetailsBar from './MobileJobDetailsBar'
import { ROUTES } from '../../constants/routes'
import { JobStatus } from '../../models/Job'
import { JobStatuses } from '../../constants/jobs'

interface DetailBarProps {
	id: string
	title: string
	company: string
	location: string
	description: string
	moreInfo?: string
	status: JobStatus
	created?: string
	lastMod?: string
	isMultiResume?: boolean
	groupId?: string
}
const JobDetailBar: FC<DetailBarProps> = ({ id, title, company, location, description, moreInfo, status, created, lastMod, isMultiResume, groupId }) => {
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
	const navigate = useNavigate()

	return (
		// replace navigate with real implementation
		<>
			{!isMobile ? (
				<Box
					onClick={() => navigate(isMultiResume ? ROUTES.MULTIPLE_RESUMES_JOBS_DETAIL.replace(':id', groupId ?? '') : `${ROUTES.OVERVIEW}/job-detail/${id}`)}
					sx={{
						borderRadius: '20px',
						width: '100%',
						height: '92px',
						background: 'white',
						marginBottom: '10px',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						minWidth: '705px',
						cursor: 'pointer',
						// border: "1px solid #000000",
						overflowX: 'auto'
					}}
				>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Box
							sx={{
								backgroundColor: '#0062FF',
								borderRadius: '10px',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								width: '38px',
								height: '38px',
								marginLeft: '22px'
							}}
						>
							<Box component="img" src={Job}></Box>
						</Box>
						<Box sx={{ marginX: '20px' }}>
							<Typography
								variant="body1"
								sx={{
									color: '#171725',
									fontWeight: '600',
									fontSize: '16px',
									lineHeight: '24px',
									letterSpacing: '0.1px',
									marginBottom: '6px'
								}}
							>
								Job Title
							</Typography>
							<Typography
								variant="body1"
								sx={{
									color: '#44444F',
									fontWeight: '500',
									fontSize: '14px',
									lineHeight: '21px',
									letterSpacing: '0.1px',
									whiteSpace: 'nowrap',
									width: '150px',
									overflow: 'hidden',
									textOverflow: 'ellipsis'
									// border: "1px solid #44444F",
								}}
							>
								{title}
							</Typography>
						</Box>
					</Box>
					<Box sx={{ display: 'flex' }}>
						<Box
							sx={{
								paddingX: { lg: '30px', xs: '18px' },
								borderLeft: '1px solid #F1F1F5',
								borderRight: '1px solid #F1F1F5'
							}}
						>
							<Typography
								sx={{
									fontWeight: '400',
									fontSize: { lg: '14px', xs: '12px' },
									lineHeight: '16.4px',
									letterSpacing: '0.1px',
									marginBottom: '10px',
									color: '#696974'
								}}
							>
								Info
							</Typography>
							<Typography
								sx={{
									fontWeight: '600',
									fontSize: { lg: '16px', xs: '14px' },
									lineHeight: '24px',
									letterSpacing: '0.1px',
									color: '#171725',
									whiteSpace: 'nowrap',
									width: '150px',
									overflow: 'hidden',
									textOverflow: 'ellipsis'
								}}
							>
								{moreInfo}
							</Typography>
						</Box>
						<Box
							sx={{
								paddingX: { lg: '30px', xs: '18px' },
								borderLeft: '1px solid #F1F1F5',
								borderRight: '1px solid #F1F1F5'
							}}
						>
							<Typography
								sx={{
									fontWeight: '400',
									fontSize: { lg: '14px', xs: '12px' },
									lineHeight: '16.4px',
									letterSpacing: '0.1px',
									marginBottom: '10px',
									color: '#696974'
								}}
							>
								Company
							</Typography>
							<Typography
								sx={{
									fontWeight: '600',
									fontSize: { lg: '16px', xs: '14px' },
									lineHeight: '24px',
									letterSpacing: '0.1px',
									color: '#171725',
									whiteSpace: 'nowrap',
									width: '100px',
									overflow: 'hidden',
									textOverflow: 'ellipsis'
									// border: "1px solid #44444F",
								}}
							>
								{company}
							</Typography>
						</Box>
						<Box
							sx={{
								paddingX: { lg: '30px', xs: '18px' },
								borderRight: '1px solid #F1F1F5'
							}}
						>
							<Typography
								sx={{
									fontWeight: '400',
									fontSize: { lg: '14px', xs: '12px' },
									lineHeight: '16.4px',
									letterSpacing: '0.1px',
									marginBottom: '10px',
									color: '#696974'
								}}
							>
								Location
							</Typography>
							<Typography
								sx={{
									fontWeight: '600',
									fontSize: { lg: '16px', xs: '14px' },
									lineHeight: '24px',
									letterSpacing: '0.1px',
									color: '#171725',
									whiteSpace: 'nowrap',
									width: '74px',
									overflow: 'hidden',
									textOverflow: 'ellipsis'
								}}
							>
								{location}
							</Typography>
						</Box>
						<Box
							sx={{
								paddingX: { lg: '30px', xs: '18px' }
							}}
						>
							<Typography
								sx={{
									fontWeight: '400',
									fontSize: { lg: '14px', xs: '12px' },
									lineHeight: '16.4px',
									letterSpacing: '0.1px',
									marginBottom: '10px',
									color: '#696974'
								}}
							>
								Description
							</Typography>
							<Typography
								sx={{
									fontWeight: '600',
									fontSize: { lg: '16px', xs: '14px' },
									lineHeight: '24px',
									letterSpacing: '0.1px',
									color: '#171725',
									whiteSpace: 'nowrap',
									width: '160px',
									overflow: 'hidden',
									textOverflow: 'ellipsis'
									// border: "1px solid #44444F",
								}}
							>
								{description}
							</Typography>
						</Box>
						<Box
							sx={{
								marginLeft: { lg: '38px', xs: '15px' },
								marginRight: { lg: '50px', xs: '20px' },
								borderRadius: '20px',
								minWidth: '116px',
								textAlign: 'center',
								color: JobStatuses[status].textColor,
								backgroundColor: 'transparent',
								padding: { lg: '8px 17px', xs: '4px 11px' },
								border: '1px solid',
								borderColor: JobStatuses[status].textColor,
								whiteSpace: 'nowrap',
								alignSelf: 'center'
							}}
						>
							{JobStatuses[status].text}
						</Box>
					</Box>
				</Box>
			) : (
				<MobileJobDetailsBar
					title={title}
					company={company}
					moreInfo={moreInfo}
					location={location}
					created={created}
					lastMod={lastMod}
					description={description}
					navigate={navigate}
					id={id}
					status={status}
					isMultiResume={isMultiResume}
					groupId={groupId}
				/>
			)}
		</>
	)
}

export default JobDetailBar
