import { Typography } from '@mui/material'
import { FC } from 'react'

const MobileJobSection2ResumeTitle: FC<{
	index: number
	isMultiResumes?: boolean
}> = ({ index, isMultiResumes }) => (
	<Typography
		component="h4"
		sx={{
			fontWeight: '700',
			fontSize: '16px',
			lineHeight: '21px',
			marginBottom: '20px',
			marginTop: isMultiResumes && index !== 0 ? '20px' : 'inherit'
		}}
	>
		Resume
	</Typography>
)

export default MobileJobSection2ResumeTitle
